import * as z from 'zod';

import { EscrowBalanceLine } from '../../loan/EscrowDue';
import { DateString } from '../../validations/fields';

// Actual validator
export const EscrowAnalysisValidator = z.object({
  effectiveDate: DateString,
  cushionNumMonths: z.number().min(1),

  // Shortage is negative, overage is positive. This is the actual number, not the fudged number for disbursement
  // (the reqs say an overage less than $49 does not need to be disbursed)
  shortageOrOverageAmount: z.number(),

  // If shortageOrOverage > $49, this equals that number. Otherwise, it's zero. (storing this makes it easier to filter/sort on in the Drafts table)
  disbursementDueAmount: z.number(),

  // Add this to escrow amount for new monthly total
  monthlyPIAmount: z.number(),

  // Base without shortage spread over it?
  // IMO it will be easier to store the base rather than the `base + shortage` because
  // we can get the `base + shortage` by taking shortage and dividing it by 12 and adding it to this number
  oldMonthlyEscrowAmount: z.number(),
  newMonthlyEscrowAmount: z.number(),

  // Only the active, escrowed escrowSettings.
  // Non-escrowed or inactive settings are omitted as they have no bearing on escrowAnalysis.
  activeEscrowedItems: z
    .object({
      escrowSettingId: z.string(),
      updatedAt: DateString,
      amount: z.number(),
    })
    .array(),

  projectedEscrowBalanceItems: EscrowBalanceLine.array(),
  historyEscrowBalanceItems: EscrowBalanceLine.array(),
  isCorrected: z.boolean().optional(),
});
export type EscrowAnalysisValidator = z.infer<typeof EscrowAnalysisValidator>;

export const EscrowAnalysisPartial = EscrowAnalysisValidator.partial();
export type EscrowAnalysisPartial = z.infer<typeof EscrowAnalysisPartial>;
