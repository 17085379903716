import { z } from 'zod';

import { BaseTask } from '../BaseTask';
import {
  Chapter13StepAttorneyReferralCollectedData,
  Chapter13StepCreditorMeetingCollectedData,
  Chapter13StepFilingStayCollectedData,
  Chapter13StepPlanInPlaceCollectedData,
} from '../collectedData/Chapter13CollectedData';
import { EmptyCollectedData } from '../collectedData/TaskCollectedData';
import { EmptyContext } from '../EmptyContext';

export const Chapter13StepFilingStayType = z.literal('chapter13StepFilingStay');
export const Chapter13StepAttorneyReferralType = z.literal('chapter13StepAttorneyReferral');
export const Chapter13StepCreditorMeetingType = z.literal('chapter13StepCreditorMeeting');
export const Chapter13StepPlanInPlaceType = z.literal('chapter13StepPlanInPlace');
export const Chapter13StepPlanMonitoringType = z.literal('chapter13StepPlanMonitoring');
export const Chapter13StepType = z.union([
  Chapter13StepFilingStayType,
  Chapter13StepAttorneyReferralType,
  Chapter13StepCreditorMeetingType,
  Chapter13StepPlanInPlaceType,
  Chapter13StepPlanMonitoringType,
]);

export const Chapter13StepFilingStay = BaseTask.extend({
  type: Chapter13StepFilingStayType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter13StepFilingStayCollectedData]),
});
export type Chapter13StepFilingStay = z.infer<typeof Chapter13StepFilingStay>;

export const Chapter13StepAttorneyReferral = BaseTask.extend({
  type: Chapter13StepAttorneyReferralType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter13StepAttorneyReferralCollectedData]),
});
export type Chapter13StepAttorneyReferral = z.infer<typeof Chapter13StepAttorneyReferral>;

export const Chapter13StepCreditorMeeting = BaseTask.extend({
  type: Chapter13StepCreditorMeetingType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter13StepCreditorMeetingCollectedData]),
});
export type Chapter13StepCreditorMeeting = z.infer<typeof Chapter13StepCreditorMeeting>;

export const Chapter13StepPlanInPlace = BaseTask.extend({
  type: Chapter13StepPlanInPlaceType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter13StepPlanInPlaceCollectedData]),
});
export type Chapter13StepPlanInPlace = z.infer<typeof Chapter13StepPlanInPlace>;

export const Chapter13StepPlanMonitoring = BaseTask.extend({
  type: Chapter13StepPlanMonitoringType,
  context: EmptyContext,
  collectedData: EmptyCollectedData,
});
export type Chapter13StepPlanMonitoring = z.infer<typeof Chapter13StepPlanMonitoring>;

export const Chapter13Step = z.discriminatedUnion('type', [
  Chapter13StepFilingStay,
  Chapter13StepAttorneyReferral,
  Chapter13StepCreditorMeeting,
  Chapter13StepPlanInPlace,
  Chapter13StepPlanMonitoring,
]);
