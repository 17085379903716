import s from './TypingIndicator.module.scss';

export const TypingIndicator = () => {
  return (
    <div className="py-2">
      <div className={s.typingIndicator}>
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};
