import {
  AuthorizedContactFragment as AppAuthorizedContactFragment,
  AuthorizedContactType as AppAuthorizedContactType,
  SharedAuthorizedContactFragment,
} from '@willow/graphql-iso/src/app';
import { AuthorizedContactType as PortalAuthorizedContactType } from '@willow/graphql-iso/src/portal';

import { assertNever } from './assertNever';

export function formatThirdPartyContactType(contactType: AppAuthorizedContactType): string;
export function formatThirdPartyContactType(contactType: PortalAuthorizedContactType): string;
export function formatThirdPartyContactType(
  contactType: AppAuthorizedContactType | PortalAuthorizedContactType,
): string {
  switch (contactType) {
    case AppAuthorizedContactType.Attorney:
    case PortalAuthorizedContactType.Attorney:
      return 'Attorney';
    case AppAuthorizedContactType.Builder:
    case PortalAuthorizedContactType.Builder:
      return 'Builder';
    case AppAuthorizedContactType.Guarantor:
    case PortalAuthorizedContactType.Guarantor:
      return 'Guarantor';
    case AppAuthorizedContactType.NonBorrowingSpouse:
    case PortalAuthorizedContactType.NonBorrowingSpouse:
      return 'Non-Borrowing Spouse';
    case AppAuthorizedContactType.Other:
    case PortalAuthorizedContactType.Other:
      return 'Other';
    case AppAuthorizedContactType.Title:
    case PortalAuthorizedContactType.Title:
      return 'Title Representative';
    default:
      assertNever(contactType);
  }
}
export function formatAuthorizedContactName(contact: SharedAuthorizedContactFragment): string;
export function formatAuthorizedContactName(contact: AppAuthorizedContactFragment): string;
export function formatAuthorizedContactName(
  contact: AppAuthorizedContactFragment | SharedAuthorizedContactFragment,
): string {
  switch (contact.__typename) {
    case 'BuilderAuthorizedContact':
      return `${contact.builderCompanyName} (Builder)`;
    case 'ThirdPartyAuthorizedContact':
      return `${contact.name.firstName} ${contact.name.lastName} (${formatThirdPartyContactType(contact.type)})`;
    default:
      assertNever(contact);
  }
}
