import { z } from 'zod';

import { QcDocumentId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const BaseUpdateEscrowPaymentEvent = BaseLoanEvent.extend({
  type: z.literal('updateEscrowPayment'),
  newMonthlyAmount: z.number(),
});

export const InitialUpdateEscrowPaymentEvent = BaseUpdateEscrowPaymentEvent.extend({
  source: z.literal('initial'),
});

export type InitialUpdateEscrowPaymentEvent = z.infer<typeof InitialUpdateEscrowPaymentEvent>;

export const ManualUpdateEscrowPaymentEvent = BaseUpdateEscrowPaymentEvent.extend({
  source: z.literal('manualUpdate'),
});

export type ManualUpdateEscrowPaymentEvent = z.infer<typeof ManualUpdateEscrowPaymentEvent>;

export const ShortagePaymentUpdateEscrowPaymentEvent = BaseUpdateEscrowPaymentEvent.extend({
  source: z.literal('shortagePayment'),
});

export type ShortagePaymentUpdateEscrowPaymentEvent = z.infer<typeof ShortagePaymentUpdateEscrowPaymentEvent>;

export const AnalysisUpdateEscrowPaymentEvent = BaseUpdateEscrowPaymentEvent.extend({
  source: z.literal('analysis'),
  effectiveDate: zodDateOrString,
  shortageAmount: z.number(),
  newMonthlyAmountIfShortagePaidUpfront: z.number().optional(),
  qcDocumentId: zodBrandedUuid<QcDocumentId>(),
  disbursementDueAmount: z.number(),
});

export type AnalysisUpdateEscrowPaymentEvent = z.infer<typeof AnalysisUpdateEscrowPaymentEvent>;

export const UpdateEscrowPaymentEvent = z.discriminatedUnion('source', [
  InitialUpdateEscrowPaymentEvent,
  ManualUpdateEscrowPaymentEvent,
  ShortagePaymentUpdateEscrowPaymentEvent,
  AnalysisUpdateEscrowPaymentEvent,
]);

export type UpdateEscrowPaymentEvent = z.infer<typeof UpdateEscrowPaymentEvent>;
