import { z } from 'zod';

import { FeeId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const UpsertFeeSideEffectContext = BaseLoanIdSideEffect.extend({
  feeId: zodBrandedUuid<FeeId>(),
});
export const UpsertFeeSideEffect = UpsertFeeSideEffectContext.extend({
  type: z.literal('upsertFee'),
});
export type UpsertFeeSideEffect = z.infer<typeof UpsertFeeSideEffect>;
