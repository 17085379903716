import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const DeferDelinquentInterestEvent = BaseLoanEvent.extend({
  type: z.literal('deferDelinquentInterest'),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
});

export type DeferDelinquentInterestEvent = z.infer<typeof DeferDelinquentInterestEvent>;
