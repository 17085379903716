import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ChangePrimaryBorrowerEvent = BaseLoanEvent.extend({
  type: z.literal('changePrimaryBorrower'),
  from: z.object({
    id: zodBrandedUuid<BorrowerId>(),
    name: z.string().optional(),
  }),
  to: z.object({
    id: zodBrandedUuid<BorrowerId>(),
    name: z.string().optional(),
  }),
});
export type ChangePrimaryBorrowerEvent = z.infer<typeof ChangePrimaryBorrowerEvent>;
