import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

/**
 * @deprecated
 * goodbye letters handled via generateLoanTransferDocuments
 */
export const GenerateGoodbyeLetterPdfEvent = BaseLoanEvent.extend({
  type: z.literal('generateGoodbyeLetterPdf'),
});
export type GenerateGoodbyeLetterPdfEvent = z.infer<typeof GenerateGoodbyeLetterPdfEvent>;
