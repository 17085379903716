import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';

interface NavSectionItemProps {
  key: string;
  label: string;
  children: ReactNode;
}
export const NavSectionItem = NamedMemo<NavSectionItemProps>('NavSectionItem', ({ children }) => <>{children}</>);
