import { z } from 'zod';

import { BaseTask } from '../BaseTask';
import {
  Chapter7StepAttorneyReliefCollectedData,
  Chapter7StepCreditorMeetingCollectedData,
  Chapter7StepFilingStayCollectedData,
  Chapter7StepHearingDateCollectedData,
  Chapter7StepHearingOutcomeCollectedData,
} from '../collectedData/Chapter7CollectedData';
import { EmptyCollectedData } from '../collectedData/TaskCollectedData';
import { EmptyContext } from '../EmptyContext';

export const Chapter7StepFilingStayType = z.literal('chapter7StepFilingStay');
export const Chapter7StepCreditorMeetingType = z.literal('chapter7StepCreditorMeeting');
export const Chapter7StepAttorneyReliefType = z.literal('chapter7StepAttorneyRelief');
export const Chapter7StepHearingDateType = z.literal('chapter7StepHearingDate');
export const Chapter7StepHearingOutcomeType = z.literal('chapter7StepHearingOutcome');
export const Chapter7StepType = z.union([
  Chapter7StepFilingStayType,
  Chapter7StepCreditorMeetingType,
  Chapter7StepAttorneyReliefType,
  Chapter7StepHearingDateType,
  Chapter7StepHearingOutcomeType,
]);

export const Chapter7StepFilingStay = BaseTask.extend({
  type: Chapter7StepFilingStayType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter7StepFilingStayCollectedData]),
});
export type Chapter7StepFilingStay = z.infer<typeof Chapter7StepFilingStay>;

export const Chapter7StepCreditorMeeting = BaseTask.extend({
  type: Chapter7StepCreditorMeetingType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter7StepCreditorMeetingCollectedData]),
});
export type Chapter7StepCreditorMeeting = z.infer<typeof Chapter7StepCreditorMeeting>;

export const Chapter7StepAttorneyRelief = BaseTask.extend({
  type: Chapter7StepAttorneyReliefType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter7StepAttorneyReliefCollectedData]),
});
export type Chapter7StepAttorneyRelief = z.infer<typeof Chapter7StepAttorneyRelief>;

export const Chapter7StepHearingDate = BaseTask.extend({
  type: z.literal('chapter7StepHearingDate'),
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter7StepHearingDateCollectedData]),
});
export type Chapter7StepHearingDate = z.infer<typeof Chapter7StepHearingDate>;

export const Chapter7StepHearingOutcome = BaseTask.extend({
  type: Chapter7StepHearingOutcomeType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, Chapter7StepHearingOutcomeCollectedData]),
});
export type Chapter7StepHearingOutcome = z.infer<typeof Chapter7StepHearingOutcome>;

export const Chapter7Step = z.discriminatedUnion('type', [
  Chapter7StepFilingStay,
  Chapter7StepCreditorMeeting,
  Chapter7StepAttorneyRelief,
  Chapter7StepHearingDate,
  Chapter7StepHearingOutcome,
]);
