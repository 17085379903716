import { z } from 'zod';

import { PortalPaymentStopSetting } from '../loan/StopSettings';

export const NonPerformanceSetting = z
  .object({
    daysPastDue: z.number().positive().int(),
    emailsPaused: z.boolean(),
    documentAccessPaused: z.boolean(),
    portalPayments: PortalPaymentStopSetting,
  })
  .strict();
export type NonPerformanceSetting = z.infer<typeof NonPerformanceSetting>;

export const CompanyNonPerformanceSettings = z.object({
  delinquent: NonPerformanceSetting,
  defaulted: NonPerformanceSetting,
});
export type CompanyNonPerformanceSettings = z.infer<typeof CompanyNonPerformanceSettings>;

export const DEFAULT_NON_PERFORMANCE_SETTINGS: CompanyNonPerformanceSettings = {
  delinquent: {
    daysPastDue: 31,
    emailsPaused: false,
    documentAccessPaused: false,
    portalPayments: 'any',
  },
  defaulted: {
    daysPastDue: 91,
    emailsPaused: true,
    documentAccessPaused: true,
    portalPayments: 'none',
  },
};
