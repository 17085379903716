import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const EditEscrowFieldsEvent = BaseLoanEvent.extend({
  type: z.literal('editEscrowFields'),
  escrowBalance: z.number().optional(),
  monthlyEscrowPayment: z.number().optional(),
  monthlyMortgageInsurancePayment: z.number().optional(),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  sendEmail: z.boolean(),
});
export type EditEscrowFieldsEvent = z.infer<typeof EditEscrowFieldsEvent>;
