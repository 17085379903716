import * as z from 'zod';

import { LoanFilter } from '../../loanFilter/LoanFilter';
import { LoanTransactionFilter } from '../../loanTransactionFilter/LoanTransactionFilter';

// Page name based on page + tab (ie transactions = dashboard page, transaction tab)
export const LenderChatPage = z.enum(['transactions', 'loans']);
export type LenderChatPage = z.infer<typeof LenderChatPage>;

const NavigateToTransactions = z.object({
  page: z.literal(LenderChatPage.Enum.transactions),
  queryParams: z.object({
    filters: z.array(LoanTransactionFilter).optional(),
    search: z.string().optional(),
  }),
});
export type NavigateToTransactions = z.infer<typeof NavigateToTransactions>;

const NavigateToLoans = z.object({
  page: z.literal(LenderChatPage.Enum.loans),
  queryParams: z
    .object({
      filters: z.array(LoanFilter).optional(),
      search: z.string().optional(),
    })
    .optional(),
});
export type NavigateToLoans = z.infer<typeof NavigateToLoans>;

export const LenderChatBotResponseNavigateTo = z.discriminatedUnion('page', [NavigateToTransactions, NavigateToLoans]);
export type LenderChatBotResponseNavigateTo = z.infer<typeof LenderChatBotResponseNavigateTo>;

/* RESPONSE DETAILS */
export const ChatResponseVariant = z.enum(['info', 'error']);
export type ChatResponseVariant = z.infer<typeof ChatResponseVariant>;

export const ChatResponseMessage = z.object({
  variant: ChatResponseVariant,
  message: z.string(),
});
export type ChatResponseMessage = z.infer<typeof ChatResponseMessage>;

export const LenderChatBotResponse = z.preprocess(
  (data: any) => {
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return data;
  },
  z.object({
    message: ChatResponseMessage.optional(), // TODO @leslie why is this optional?
    navigateTo: LenderChatBotResponseNavigateTo.optional(),
    suggestions: z.string().array().optional(),
  }),
);
export type LenderChatBotResponse = z.infer<typeof LenderChatBotResponse>;
