import { z } from 'zod';

import { BorrowerId, PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { EmailString } from '../validations';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GeneratePayoffStatementSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerNotifications: z.array(zodBrandedUuid<BorrowerId>()).optional(),
  additionalEmailNotifications: z.array(EmailString).optional(),
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const GeneratePayoffStatementSideEffect = GeneratePayoffStatementSideEffectContext.extend({
  type: z.literal('generatePayoffStatement'),
});
export type GeneratePayoffStatementSideEffect = z.infer<typeof GeneratePayoffStatementSideEffect>;
