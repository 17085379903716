import { useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { GetBanksDocument } from '@willow/graphql-iso/src/portal';
import { Loader, NamedMemo, paymentAmountFormat, Sentry } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { LoanId } from '@willow/types-iso';

import { AutopayContext } from '../../contexts/autopayContext';
import { WillowModal } from '../modal/Modal';

import './AutopayModal.scss';

interface ConfirmAutopayModalProps {
  loanId: LoanId;
  pastDueBalance: number | undefined;
}

export const ConfirmAutopayModal = NamedMemo(
  'ConfirmAutopayModalInner',
  ({ loanId, pastDueBalance }: ConfirmAutopayModalProps) => {
    const [selectedBank, setSelectedBank] = useState<string | undefined>();
    const { showConfirmAutopayModal, setShowConfirmAutopayModal, updateAutopaySettings, updateAutopaySettingsLoading } =
      useContext(AutopayContext);

    const { data } = useQuery(GetBanksDocument);
    const autopayEligibleBanks = useMemo(
      () => data?.portalSelf.banks.filter((bank) => bank.isAutopayAuthorized) || [],
      [data?.portalSelf.banks],
    );

    useEffect(() => {
      setSelectedBank(autopayEligibleBanks[0]?.links.self);
    }, [autopayEligibleBanks, setSelectedBank]);

    const resetModal = useCallback(() => {
      setShowConfirmAutopayModal(false);
    }, [setShowConfirmAutopayModal]);

    const onSubmit = useCallback(async () => {
      Sentry.addBreadcrumb({
        category: 'autopay',
        message: 'Adding new autopay details',
      });

      try {
        await updateAutopaySettings(loanId, selectedBank);
        resetModal();
        toast.success('Auto-pay was successfully enabled');
      } catch (err) {
        Sentry.captureException(err);
        toast.error('There was an issue updating auto-pay. Please refresh the page and try again.');
      }
    }, [updateAutopaySettings, selectedBank, loanId, resetModal]);

    return (
      <WillowModal showModal={showConfirmAutopayModal} onClose={resetModal}>
        {autopayEligibleBanks.length ? (
          <div className="modal-autopay">
            <h2>Enable Auto-Pay?</h2>

            <div className="modal-autopay__copy">
              {autopayEligibleBanks.length > 1 && (
                <Form.Group>
                  <Form.Label>Confirm bank account to enroll in auto-pay.</Form.Label>
                  <Form.Select onChange={(e) => setSelectedBank(e.target.value)} value={selectedBank}>
                    {autopayEligibleBanks.map((bank) => (
                      <option key={bank.links.self} value={bank.links.self}>
                        {bank.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {pastDueBalance ? (
                <>
                  <p className="mb-4">
                    Your loan is currently past due and {paymentAmountFormat(pastDueBalance)} is outstanding. You can
                    confirm immediate payment by pressing the “submit payment” button on the payment page.
                  </p>
                  <p>
                    Enabling auto-pay will deduct any remaining unpaid balance (including any fees or past due amount)
                    due on your next due date.
                  </p>
                </>
              ) : (
                <>
                  <p className="mb-4">
                    Enabling auto-pay will deduct any remaining unpaid balance (including any fees or past due amount)
                    due on your due date.
                  </p>
                  <p>
                    You can still submit a payment manually by confirming the amount you wish to pay and pressing the
                    “submit payment” button. Any remaining amount will be auto-deducted on your due date if auto-payment
                    is enabled.
                  </p>
                </>
              )}
            </div>

            <div className="modal-autopay__buttons">
              <button
                type="button"
                onClick={onSubmit}
                className="modal-autopay__submit-btn"
                disabled={updateAutopaySettingsLoading || !selectedBank}
              >
                Enable Auto-Pay
                {updateAutopaySettingsLoading && <Loader />}
              </button>
              <button type="button" onClick={resetModal} className="modal-autopay__cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-autopay">
            <h2>Authorization Required</h2>

            <div className="modal-autopay__copy">
              New authorization is required that was not previously obtained while connecting your bank account(s).{' '}
              <b>Please delete and re-connect your connected bank account(s) to enable auto-pay.</b>
            </div>

            <div className="modal-autopay__buttons">
              <button type="button" onClick={resetModal} className="modal-autopay__submit-btn">
                Close
              </button>
            </div>
          </div>
        )}
      </WillowModal>
    );
  },
);
