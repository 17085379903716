import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RemoveAdditionalBorrowerEvent = BaseLoanEvent.extend({
  type: z.literal('removeAdditionalBorrower'),
  borrower: z.object({
    id: zodBrandedUuid<BorrowerId>(),
    name: z.string().optional(),
  }),
});
export type RemoveAdditionalBorrowerEvent = z.infer<typeof RemoveAdditionalBorrowerEvent>;
