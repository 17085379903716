import { Link } from 'react-router-dom';

import { NamedMemo, paymentAmountFormat, PORTAL_LOAN_PATHS } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { ContactForm } from '../contact-form/ContactForm';
import { PageHeading } from '../page-heading/PageHeading';
import { PillLabel } from '../pill-label/PillLabel';

import './PaidOff.scss';

interface Props {
  loan: PortalSelectedLoan;
}

const PaymentSummary = NamedMemo<Props>('PaymentSummary', ({ loan }) => {
  return (
    <>
      <h2 className="u-fs-4 u-bold mb-4">Payment Summary</h2>
      <section className="paid-off">
        <table>
          <tbody>
            <tr className="paid-off__head">
              <th>First Payment</th>
              <th>Loan Amount</th>
            </tr>

            <tr>
              <td>{loan.configuration.firstDueDate}</td>
              <td>{paymentAmountFormat(loan.originalAmount)}</td>
            </tr>

            <tr>
              <td colSpan={4}>
                <Link to={PORTAL_LOAN_PATHS.transactions({ losId: loan.losId, companyId: loan.company.id })}>
                  View complete payment history
                </Link>
              </td>
            </tr>
          </tbody>
        </table>

        <ul>
          <li>
            <div className="paid-off-item-title">First Payment</div>
            <div className="paid-off-item">{loan.configuration.firstDueDate}</div>
          </li>
          <li>
            <div className="paid-off-item-title">Loan Amount</div>
            <div className="paid-off-item">{paymentAmountFormat(loan.originalAmount)}</div>
          </li>
          <li>
            <Link to={PORTAL_LOAN_PATHS.transactions({ losId: loan.losId, companyId: loan.company.id })}>
              View complete payment history
            </Link>
          </li>
        </ul>
      </section>
    </>
  );
});

export const PaidOff = NamedMemo<Props>('PaidOff', ({ loan }) => {
  const { company } = loan;
  const { name, supportEmail: email, supportPhone: phone, supportHours: hours } = company;
  return (
    <>
      <div className="d-flex justify-content-center mb-3">
        <PillLabel label="Paid Off" />
      </div>
      <PageHeading title="Congratulations!" subtitle="Your loan has been paid in full" />

      <section className="mt-4 u-bg-white p-4 u-border-1 u-border-color-bark1 u-border-r-3">
        <PaymentSummary loan={loan} />

        <ContactForm title={`Contact ${name}`} phone={phone} email={email} hours={hours ?? undefined} />
      </section>
    </>
  );
});
