const SSN_REGEX = /(^\d{3}-\d{2}-\d{4}$)|(^\d{9}$)/;
const EIN_REGEX = /(^\d{2}-\d{7}$)|(^\d{9}$)/;

export const isSSNValid = (ssn: string) => new RegExp(SSN_REGEX).test(ssn);
export const isEINValid = (ein: string) => new RegExp(EIN_REGEX).test(ein);

export const formatSSN = (ssn: string) => {
  // Format to ###-##-####
  if (ssn.length === 9) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5 - 9)}`;
  }
  return ssn;
};

export const formatEIN = (ein: string) => {
  // Format to ##-#######
  if (ein.length === 9) {
    return `${ein.slice(0, 2)}-${ein.slice(2, 9)}`;
  }
  return ein;
};

export const formatMaskedSSN = (ssn: string) => {
  // Format to XXX-XX-####
  ssn = ssn.replace('-', '');
  return `XXX-XX-${ssn.slice(5 - 9)}`;
};
