import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

import './ContactCTA.scss';

interface Props {
  company: CompanyFragment;
}

export const ContactCTA = NamedMemo<Props>('ContactCTA', ({ company }) => {
  const { supportEmail: email, supportPhone: phone, supportHours: hours } = company;

  return (
    <section className="contact-cta">
      <h2 className="contact-cta__header">Questions?</h2>
      {email && (
        <a href={`mailto:${email}`} aria-label={`Email: ${email}`}>
          {email}
        </a>
      )}
      {phone && (
        <a href={`tel:${phone}`} aria-label={`Phone Number: ${phone}`}>
          {phoneNumberFormat(phone)}
        </a>
      )}
      {hours && (
        <div>
          {hours.line1 && <div>{hours.line1}</div>}
          {hours.line2 && <div>{hours.line2}</div>}
          {hours.line3 && <div>{hours.line3}</div>}
        </div>
      )}
    </section>
  );
});
