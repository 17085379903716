/*
  High-level transition for initial page loading
*/
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { NamedMemo } from '../NamedMemo';
import { Loader } from './Loader';

interface Props {
  isReady: boolean;
  children: ReactNode;
}

export const LoaderTransition = NamedMemo<Props>('LoaderTransition', ({ isReady = false, children }) => {
  return (
    <AnimatePresence mode="wait">
      {isReady ? (
        <motion.div
          key="content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {children}
        </motion.div>
      ) : (
        <motion.div
          className="loader-centered"
          key="loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'linear' }}
        >
          <Loader />
        </motion.div>
      )}
    </AnimatePresence>
  );
});
