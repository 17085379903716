import { AppContext } from '@willow/shared-iso';

export const getAppContext = (): AppContext => {
  const UNFORMATTED_APP_CONTEXT = ((window as any).REACT_APP_CONTEXT || 'development') as
    | AppContext
    | '<%= REACT_APP_CONTEXT %>';
  const APP_CONTEXT = UNFORMATTED_APP_CONTEXT === '<%= REACT_APP_CONTEXT %>' ? 'development' : UNFORMATTED_APP_CONTEXT;

  return APP_CONTEXT;
};
