import { z } from 'zod';

import { FeeId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertAdvanceSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  advanceId: zodBrandedUuid<FeeId>(),
});
export const UpsertAdvanceSideEffect = UpsertAdvanceSideEffectContext.extend({
  type: z.literal('upsertAdvance'),
});
export type UpsertAdvanceSideEffect = z.infer<typeof UpsertAdvanceSideEffect>;
