/**
 * PROCESS FIELDS INTO SPECIFIC TYPES
 *
 * Flatfile returns everything as a string,
 * so we have to pre-process each field into
 * the values we expect.
 */

import { DateTime, DurationLike } from 'luxon';
import * as z from 'zod';

import { convertStringToDate } from '../utils';
import { STATE_ABBREVIATIONS } from './StateAbbreviations';
import { formatEIN, formatSSN, isEINValid, isSSNValid } from './taxIdentificationNumbers';

const ZIPCODE_REGEX = '^[0-9]{5}(?:-[0-9]{4})?$';

const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const isValidUrl = (url: string): boolean => URL_REGEX.test(url);

const GIVEN_NAME_ALLOWED_CHARS_REGEX = /^((?!,).)*$/;

// --- STRINGS --- //

export const PersonNameString = z.string().refine(
  (val?: string) => {
    if (val == null) {
      return true;
    }
    const regex = new RegExp(GIVEN_NAME_ALLOWED_CHARS_REGEX);
    return regex.test(val);
  },
  {
    message: 'Invalid name provided (commas not allowed).',
  },
);

// --- DATE STRINGS --- //

const dateTimeFromString = (val: string) => DateTime.fromJSDate(convertStringToDate(val)).toUTC();

export const DateString = z.string().refine(
  (val?: string) => {
    if (val == null) return false;

    let parsedDate = DateTime.fromISO(val);
    if (parsedDate.isValid) return parsedDate.toJSDate();

    const allowedFormats = [
      'M/d/yyyy',
      'M/d/yy',
      'MM/dd/yy',
      'MM/dd/yyyy',
      'MMM d, yyyy',
      'MMM dd, yyyy',
      'MMMM d, yyyy',
      'MMMM dd, yyyy',
      'yyyy-MM-dd',
      'yyyy-MM-d',
      'yyyy-M-dd',
      'yyyy-M-d',
    ];

    for (const format of allowedFormats) {
      parsedDate = DateTime.fromFormat(val, format);
      if (parsedDate.isValid) return parsedDate.toJSDate();
    }

    return false;
  },
  { message: 'Invalid date format' },
);

// DateString modifiers to be used with `.and()`. ie, DateString.and(isValidPaymentDate)

export const dateIsOnFirstOfMonth = z.any().refine(
  (val?: string) => {
    const parsed = DateString.safeParse(val);
    if (val && parsed.success) {
      const date = dateTimeFromString(parsed.data);
      return date.day === 1;
    }
    return true;
  },
  {
    message: 'Date must be on the first of the month.',
  },
);

export const isValidPaymentDate = z.any().refine(
  (val?: string) => {
    const parsed = DateString.safeParse(val);
    if (val && parsed.success) {
      const date = dateTimeFromString(parsed.data);
      return date.day === 1 || date.day === 25;
    }
    return true;
  },
  {
    message: 'Date must be on the first of the month. For HELOC or construction loans the 25th is also valid.',
  },
);

export const dateIsWithinRange = (range: DurationLike) =>
  z.any().refine(
    (val?: string) => {
      const parsed = DateString.safeParse(val);
      if (val && parsed.success) {
        const dateTime = dateTimeFromString(parsed.data);
        return dateTime >= DateTime.now().minus(range) && dateTime <= DateTime.now().plus(range);
      }
      return true;
    },
    {
      message: 'Date is out of acceptable range.',
    },
  );

export const dateIsTodayOrInThePast = z.any().refine(
  (val?: string) => {
    const parsed = DateString.safeParse(val);
    if (val && parsed.success) {
      const dateTime = dateTimeFromString(parsed.data);
      return dateTime <= DateTime.now();
    }
    return true;
  },
  {
    message: 'Date must be today or in the past.',
  },
);

// --- NUMBERS --- //
export const Float = z.preprocess((val: any) => {
  if (typeof val === 'string') {
    const number = Number(val);
    return Number.isNaN(number) ? val : number;
  }
  return val;
}, z.number());

export const PositiveFloat = Float.refine(
  (val?: number) => {
    if (val == null) return true;
    return val >= 0;
  },
  {
    message: 'Value must be a positive number.',
  },
);

export const PositiveInteger = Float.refine(
  (val?: number) => {
    if (val == null) return true;
    const regex = new RegExp('^[0-9]*$');
    return regex.test(`${val}`);
  },
  {
    message: 'Value must be a positive integer (no decimals).',
  },
);

export const InterestRate = Float.refine((val?: number) => {
  if (val == null) return true;
  const regex = new RegExp('^(0+.?[0-9]*|.[0-9]+)$');
  return regex.test(`${val}`);
});

export const MonetaryValue = z.preprocess((val: any) => {
  if (typeof val === 'string') {
    const number = Number(val.replace(/[,$]/g, '').trim()); // Added `$` to characters we strip
    return Number.isNaN(number) ? val : number; // If it parsed to NaN, return original value
  }
  return val;
}, z.number());

export const PositiveMonetaryValue = MonetaryValue.refine(
  (val?: number) => {
    if (val == null) return true;
    return val >= 0;
  },
  {
    message: 'Value must be greater than or equal to 0.',
  },
);

export const PositivePercentage = z.preprocess((val) => {
  if (typeof val === 'string' && val.includes('%')) {
    return parseFloat(val.replace('%', '').trim());
  }
  return val;
}, PositiveFloat);

// --- BOOLEANS --- //

// This follows same rules as Flatfile's boolean parser.
// See Flatfile's boolean parser rules here: https://flatfile.com/docs/2.0/javascript/fields/#type
export const Checkbox = z.preprocess((val: any) => {
  if (typeof val === 'string') {
    val = val.toLowerCase();
    if (['true', 'false'].includes(val)) {
      return val === 'true';
    }
    if (['1', '0'].includes(val)) {
      return val === '1';
    }
    if (['yes', 'no'].includes(val)) {
      return val === 'yes';
    }
    if (['y', 'n'].includes(val)) {
      return val === 'y';
    }
    if (['on', 'off'].includes(val)) {
      return val === 'on';
    }
    if (['enabled', 'disabled'].includes(val)) {
      return val === 'enabled';
    }
  }
  return val;
}, z.boolean());

// --- SPECIALIZED --- //
const InvalidSSNMessage = 'Invalid social security number provided.';
export const SocialSecurityNumber = z
  .string()
  .refine(
    (val?: string) => {
      if (val == null) return true;
      return isSSNValid(val);
    },
    {
      message: InvalidSSNMessage,
    },
  )
  .transform(formatSSN);

export const OptionalSocialSecurityNumber = z
  .string()
  .optional()
  .refine(
    (val?: string) => {
      if (val == null || val.length === 0) return true;
      return isSSNValid(val);
    },
    {
      message: InvalidSSNMessage,
    },
  );

export const EmployerIdentificationNumber = z
  .string()
  .refine(
    (val?: string) => {
      if (val == null) return true;
      return isEINValid(val);
    },
    {
      message: 'Invalid EIN provided.',
    },
  )
  .transform(formatEIN);

export const AddressLine = z.string().refine((val) => !/@/.test(val), { message: 'Invalid character "@" found.' });

export const StateAbbreviation = z
  .string()
  .length(2, 'Value must be a two letter state abbreviation.')
  .toUpperCase()
  .refine((value) => STATE_ABBREVIATIONS.includes(value), 'Value must be a two letter state abbreviation.');
export type StateAbbreviation = z.infer<typeof StateAbbreviation>;

export const Zipcode = z.string().refine(
  (val?: string) => {
    if (val == null) return true;
    const regex = new RegExp(ZIPCODE_REGEX);
    return regex.test(val);
  },
  {
    message: 'Invalid zipcode provided.',
  },
);
