// and validating that lastUpdatedDate is within 1 year before effectiveDate

import { compact, pick } from 'lodash';
import { DateTime, Interval } from 'luxon';
import * as z from 'zod';

import { EscrowAnalysisValidator } from '@willow/types-iso';

import { Sanitizer } from '../shared/sanitizeRequest';

// If this function returns false, the refinement must exit early.
const getParsedFields = <T, K extends keyof EscrowAnalysisValidator>(data: T, fields: K[]) => {
  const zodPickArgs: { [x in K]?: boolean } = {};
  for (const field of fields) {
    zodPickArgs[field] = true;
  }

  const partial = pick(data, fields);

  const sanitized = Sanitizer.safeParse(partial);

  if (!sanitized.success) {
    return false;
  }

  const parsed = EscrowAnalysisValidator.pick(zodPickArgs).safeParse(sanitized.data);

  if (!parsed.success) {
    return false;
  }

  return parsed.data;
};

const escrowPoliciesHaveAmounts: z.SuperRefinement<EscrowAnalysisValidator> = (data, ctx) => {
  const parsed = getParsedFields(data, ['activeEscrowedItems']);

  if (!parsed) return;

  if (parsed.activeEscrowedItems.some((e) => e.amount === 0)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Some escrow lines are missing policy amount',
      path: ['activeEscrowedItems'],
    });
  }
};

const escrowPoliciesRecentlyUpdated: z.SuperRefinement<EscrowAnalysisValidator> = (data, ctx) => {
  const parsed = getParsedFields(data, ['activeEscrowedItems']);

  if (!parsed) return;

  const lastUpdatedDates = compact(parsed.activeEscrowedItems.map((e) => new Date(e.updatedAt)));

  if (
    lastUpdatedDates.some(
      (date) => Interval.fromDateTimes(DateTime.fromJSDate(date), DateTime.now()).count('days') >= 365,
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Some escrow lines were not updated in the last year',
      path: ['activeEscrowedItems'],
    });
  }
};

const missingEscrowSettings: z.SuperRefinement<EscrowAnalysisValidator> = (data, ctx) => {
  const parsed = getParsedFields(data, ['activeEscrowedItems']);

  if (!parsed) return;

  // Escrow payment is > 0 and there are no active escrow items. User must add escrow settings.
  if (!parsed.activeEscrowedItems.length) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Missing escrow data',
      path: ['activeEscrowedItems'],
    });
  }
};

export const EscrowAnalysisCrossFieldParser = z
  .any()
  .superRefine(escrowPoliciesHaveAmounts)
  .superRefine(escrowPoliciesRecentlyUpdated)
  .superRefine(missingEscrowSettings);
