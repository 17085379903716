import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import { Checkbox, DateString } from '../fields';
import { FlatfileMonetaryValue } from '../flatfileFields';

const InvestorSplit = z.enum(['servicer', 'investor', 'fiftyFifty']);

export const RecordDrawDisbursementRow = z.object({
  loanId: zodBrandedString<LosId>(),
  disbursementType: z.enum(['approvedDraw']),
  disbursementDate: DateString,
  disbursementTotal: FlatfileMonetaryValue,
  drawFee: FlatfileMonetaryValue.optional(),
  deferFee: Checkbox.default('yes'),
  investorSplit: InvestorSplit.default('servicer'),
  note: z.string().optional(),
  regenerateStatementIncludingDraw: z.boolean().optional(),
});

export const RECORD_DRAW_DISBURSEMENT_ROW_REQUIRED_FIELDS = Object.keys(RecordDrawDisbursementRow.shape).filter(
  (key) => !RecordDrawDisbursementRow.shape[key as keyof typeof RecordDrawDisbursementRow.shape].isOptional(),
);
