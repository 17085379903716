import { StripNullFromNullOrUndefined, StripObjectNullFromNullOrUndefined } from '@willow/types-iso';

export const stripObjectNullFromNullOrUndefined = <T extends Record<string, any>>(
  obj: T,
): StripObjectNullFromNullOrUndefined<T> =>
  Object.entries(obj).reduce((result: any, [key, value]) => {
    if (value === null) {
      result[key] = undefined;
      return result;
    }

    // Without this arrays end up being turned into objects
    // and we need to make sure to convert all arrays that potentially
    // have null values into undefined
    if (value instanceof Array) {
      result[key] = value.map((item) => {
        if (item instanceof Object && !(item instanceof Date) && !(item instanceof Array)) {
          return stripObjectNullFromNullOrUndefined(item);
        }
        return item;
      });
      return result;
    }

    // Date objects get all wonky if passed into normalizeNilValues
    if (value instanceof Object && !(value instanceof Date)) {
      result[key] = stripObjectNullFromNullOrUndefined(value);
      return result;
    }
    result[key] = value;
    return result;
  }, {}) as StripObjectNullFromNullOrUndefined<T>;

export const stripNullFromNullOrUndefined = <T>(item: T): StripNullFromNullOrUndefined<T> => {
  if (item === null) {
    return undefined as StripNullFromNullOrUndefined<T>;
  }
  return item as StripNullFromNullOrUndefined<T>;
};
