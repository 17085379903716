import { parsePhoneNumber } from 'awesome-phonenumber';

export const phoneNumberFormat = (phoneNum: string): string => {
  // Future possibility: consider interational phone numbers
  const pn = parsePhoneNumber(phoneNum, 'US');
  return pn.getNumber('national');
};

const PHONE_ALLOWED_CHARS_REGEX = /^[0-9()\-+\s.]*$/;
export const isValidPhone = (phone: string): boolean => {
  const REGEX = new RegExp(PHONE_ALLOWED_CHARS_REGEX);
  const validChars = REGEX.test(phone);
  if (!validChars) return false;

  const trimmed = phone.replace(/[(|)|\-|+|\s+|.]/g, '');
  return trimmed.length === 10 || trimmed.length === 11;
};
