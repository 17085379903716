import { FetchResult } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import classnames from 'classnames';
import { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';

import { CompanyFragment, LogoutMutation } from '@willow/graphql-iso/src/portal';
import { Dropdown } from '@willow/shared-web/bootstrap';
import { LosId } from '@willow/types-iso';

import { CompanyLogo } from '../../company-logo/CompanyLogo';
import { UserContext } from '../../context/AppContexts';
import { MobileMenu } from '../mobile-menu/MobileMenu';

import './Banner.scss';

interface Props {
  selectedLoanId?: LosId;
  hasActiveLoan: boolean;
  company?: CompanyFragment;
  logout?: () => Promise<FetchResult<LogoutMutation>>;
}

export const Banner = ({ selectedLoanId, company, hasActiveLoan, logout: gqlLogout }: Props) => {
  const { logout } = useAuth0();
  const expireTokenAndLogout = useCallback(async () => {
    try {
      gqlLogout && (await gqlLogout());
    } catch {
      console.error('Error expiring token');
    }
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [gqlLogout, logout]);

  // Form username (first name + first letter of last name)
  let userName;
  const { firstName, lastName } = useContext(UserContext);
  userName = firstName || null;
  if (lastName?.length) {
    userName += ` ${lastName[0]}.`;
  }

  // Base the page slug off of the selected loan id: /:loanId/whatever-page
  const baseSlug = selectedLoanId ? `/${selectedLoanId}/` : '/';

  return (
    <div role="banner" className={classnames(['banner'], { 'banner--no-company': !company })}>
      <div className="wrapper">
        {company && (
          <Link to={`${baseSlug}`} className="logo" aria-label={`${company.name}. Click to return to the home page.`}>
            <CompanyLogo company={company} />
          </Link>
        )}

        {userName && (
          <Dropdown>
            <Dropdown.Toggle aria-label={`${userName}. Click to see additional menu options.`}>
              {userName}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/" onClick={expireTokenAndLogout}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!userName && (
          <a href="/" onClick={expireTokenAndLogout}>
            Logout
          </a>
        )}

        <MobileMenu
          selectedLoanId={selectedLoanId}
          hasActiveLoan={hasActiveLoan}
          company={company}
          baseSlug={baseSlug}
        />
      </div>
    </div>
  );
};
