import * as z from 'zod';

import { BillSettingsId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const BillSettingType = z.enum(['borrower', 'builder']);
export type BillSettingType = z.infer<typeof BillSettingType>;

export const BillSetting = z.object({
  id: zodBrandedUuid<BillSettingsId>(),
  type: BillSettingType,
  startDate: zodDateOrString,
});
export type BillSetting = z.infer<typeof BillSetting>;
