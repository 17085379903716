import * as z from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';

export const AllBorrowerDocumentAccess = z.object({
  type: z.literal('allAccess'),
});
export type AllBorrowerDocumentAccess = z.infer<typeof AllBorrowerDocumentAccess>;

export const WhiteListedBorrowerDocumentAccess = z.object({
  type: z.literal('whiteListedAccess'),
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
});
export type WhiteListedBorrowerDocumentAccess = z.infer<typeof WhiteListedBorrowerDocumentAccess>;

export const BorrowerDocumentAccess = z.discriminatedUnion('type', [
  AllBorrowerDocumentAccess,
  WhiteListedBorrowerDocumentAccess,
]);
export type BorrowerDocumentAccess = z.infer<typeof BorrowerDocumentAccess>;

export const BorrowerDocumentAccessType = z.union([z.literal('allAccess'), z.literal('whiteListedAccess')]);
export type BorrowerDocumentAccessType = z.infer<typeof BorrowerDocumentAccessType>;

export const BorrowerAccessSaveInput = z.object({
  type: BorrowerDocumentAccessType,
  borrowers: z
    .array(
      z.object({
        id: zodBrandedUuid<BorrowerId>(),
        name: z.string(),
      }),
    )
    .optional(),
});
export type BorrowerAccessSaveInput = z.infer<typeof BorrowerAccessSaveInput>;

/* HELPERS */
export function isAllBorrowerAccess(item: BorrowerDocumentAccess): item is AllBorrowerDocumentAccess {
  return item.type === 'allAccess';
}

export function isWhiteListedBorrowerAccess(item: BorrowerDocumentAccess): item is WhiteListedBorrowerDocumentAccess {
  return item.type === 'whiteListedAccess';
}
