import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { DrawRequestDocument } from '@willow/graphql-iso/src/portal';
import { formatCurrency, phoneNumberFormat } from '@willow/shared-iso';
import {
  Button,
  ControlledCurrencyInput,
  ControlledInput,
  ControlledSingleDatePicker,
  NamedMemo,
  useZodHookForm,
  z,
} from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { Alert } from '../alert/Alert';
import { WillowModal } from '../modal/Modal';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

export const DrawRequest = NamedMemo<Props>('DrawRequest', ({ loan }) => {
  const {
    currentSnapshot: { hasOpenDrawRequest },
  } = loan;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const FormShape = z.object({
    amount: z.coerce
      .number()
      .min(1, { message: 'Enter a minimum of $1' })
      .max(loan.currentSnapshot.creditRemaining, { message: 'Exceeds credit available' }),
    date: z.date(),
    message: z.string().min(1, { message: 'Required' }),
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [drawRequest] = useMutation(DrawRequestDocument);
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useZodHookForm(FormShape, {
    defaultValues: {
      amount: undefined,
      date: undefined,
      message: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await drawRequest({
        variables: {
          companyId: loan.company.id,
          loanId: loan.id,
          amount: data.amount,
          date: data.date,
          message: data.message,
        },
      });

      setShowSuccessModal(true);
      reset();
    } catch {
      toast.error(`Something went wrong submitting the draw request form. Please try again.`);
    }
  });

  return (
    <>
      {hasOpenDrawRequest && (
        <div className="mb-4">
          <Alert title="Draw request is pending" subtitle="Contact us for an updated status" level="WARNING" />
        </div>
      )}

      <ManageLoanHeader
        title="Submit a Draw Request"
        subtitle="Fill out the form below to request a draw for funds from your remaining credit available. Once approved, funds will be released and your payment will adjust (if applicable)."
      />
      <Form onSubmit={onSubmit}>
        <Form.Group className="w-100 d-flex gap-3">
          <div className="w-50">
            <ControlledCurrencyInput label="Draw amount" control={control} fieldName="amount" />
            <span className="mt-1 u-fs-2 u-color-bark3">
              Credit available: {formatCurrency(loan.currentSnapshot.creditRemaining)}
            </span>
          </div>
          <div className="w-50">
            <ControlledSingleDatePicker
              label="Funds release date"
              props={{
                minDate: DateTime.now().startOf('day').plus({ days: 1 }).toJSDate(),
              }}
              fieldName="date"
              control={control}
            />
            <span className="mt-1 u-fs-2 u-color-bark3">Date you need funds available</span>
          </div>
        </Form.Group>

        <Form.Group className="w-100 mt-3">
          <ControlledInput
            control={control}
            fieldName="message"
            label="Notes on draw purpose"
            placeholder="Detail about what draw will be used for"
            inputType="textarea"
            textareaRows={4}
          />
        </Form.Group>

        <div className="mt-4 w-100 d-flex justify-content-end">
          <Button variant="primary" size="md" onClick={onSubmit} loading={isSubmitting}>
            Submit Request
          </Button>
        </div>
      </Form>
      <WillowModal showModal={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <h2 className="u-fs-4 u-bold mb-2">Your inquiry has been sent</h2>
        <div>
          Our team is reviewing your note. We will be in touch shortly to discuss options. For immediate assistance
          please call us at{' '}
          <a href={`tel: ${loan.company.supportPhone}`} aria-label={`phone number: ${loan.company.supportPhone}`}>
            {phoneNumberFormat(loan.company.supportPhone)}
          </a>
          .
        </div>
        <div className="mt-4 ms-auto">
          <Button
            variant="primary"
            size="sm"
            className="hover-override hover:u-bg-secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </div>
      </WillowModal>
    </>
  );
});
