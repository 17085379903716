import { z } from 'zod';

export const CompanyLoanControlSettings = z.object({
  loanNoteCategoriesRequired: z.boolean(),
  showTransferDateOnBusinessDay: z.boolean().optional(),
  preActiveWelcomeEnabled: z.boolean(),
  pastGraceEmailEnabled: z.boolean(),
  pastGracePeriodDays: z.number(),
});
export type CompanyLoanControlSettings = z.infer<typeof CompanyLoanControlSettings>;

export const DEFAULT_GRACE_PERIOD = 15;
export const DEFAULT_LOAN_CONTROL_SETTINGS: CompanyLoanControlSettings = {
  loanNoteCategoriesRequired: false,
  showTransferDateOnBusinessDay: false,
  preActiveWelcomeEnabled: false,
  pastGraceEmailEnabled: false,
  pastGracePeriodDays: DEFAULT_GRACE_PERIOD,
};
