import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { PaymentsPaused } from '../payments-paused/PaymentsPaused';

interface Props {
  company: CompanyFragment;
}

export const ArchivedLoan = NamedMemo<Props>('ArchivedLoan', ({ company }) => {
  return (
    <PaymentsPaused company={company} title="This loan is not active" subtitle="You do not have any payments due" />
  );
});
