/*
    Previous Payment Details displays when there is no payment due
*/

import { CheckCircleFilled } from '@ant-design/icons';
import classnames from 'classnames';
import { useCallback, useState } from 'react';

import {
  PaymentMethod,
  PortalPaymentBreakdownFragment,
  PortalPaymentDueFragment,
  PortalPaymentFragment,
  PortalTransferDataFragment,
} from '@willow/graphql-iso/src/portal';
import {
  getLastPaymentDueBeforeTransfer,
  getNextPaymentDue,
  isGqlStatementBreakdown,
  NamedMemo,
  paymentAmountFormat,
  paymentDateFormat,
} from '@willow/shared-web';

import './PreviousPayment.scss';

interface Props {
  paymentsDue: PortalPaymentDueFragment[];
  paymentToPaymentDue: PortalPaymentBreakdownFragment[];
  paymentsMade: PortalPaymentFragment[];
  onAdditionalPayment: () => void;
  transferData: PortalTransferDataFragment | undefined;
}

export const PreviousPayment = NamedMemo<Props>(
  'PreviousPayment',
  ({ paymentsDue, paymentToPaymentDue, paymentsMade, onAdditionalPayment, transferData }) => {
    const [displayMorePayment, setDisplayMorePayment] = useState(false);

    // Get payment due associated with the most recent statement (filter out by transfer date if applicable)
    const associatedPaymentDue = transferData
      ? getLastPaymentDueBeforeTransfer(transferData?.transferEffectiveDate, paymentsDue)
      : getNextPaymentDue(paymentsDue);
    const { id } = associatedPaymentDue || {};

    // Get all payments that were made for this payment due
    const associatedPaymentsMadeIds = paymentToPaymentDue
      ?.filter((breakdown) => isGqlStatementBreakdown(breakdown) && breakdown.paymentDueId === id)
      .map((breakdown) => breakdown.paymentId);
    // It would be confusing to show reserve payments because they didn't actually come from the borrower
    const hiddenMethods = [PaymentMethod.Reserve, PaymentMethod.ReserveReversal, PaymentMethod.Reversal];
    const associatedPaymentsMade = paymentsMade.filter(
      (payment) => associatedPaymentsMadeIds.includes(payment.id) && !hiddenMethods.includes(payment.method),
    );

    const makeAdditionalPayment = useCallback(() => {
      setDisplayMorePayment(true);
      onAdditionalPayment();
    }, [onAdditionalPayment]);

    return (
      <section
        className={classnames(['previous-payment', 'make-payment__container'], {
          'previous-payment__more': displayMorePayment,
        })}
      >
        <h2>Previous Payment Details</h2>
        {associatedPaymentsMade.length > 0 ? (
          <div className="make-payment__container">
            <ul>
              {associatedPaymentsMade?.map((payment, index) => {
                const { total, paymentDate } = payment;

                const formattedAmount = paymentAmountFormat(total);
                const formattedDate = paymentDateFormat(paymentDate);
                const formattedDateMobile = paymentDateFormat(paymentDate, true);

                return (
                  <li key={`previous-payment-item-${index}`} className="previous-payment__row">
                    <div>
                      <div className="previous-payment__amount">{formattedAmount}</div>
                      {formattedDate && <div className="previous-payment__date tablet">{formattedDate}</div>}
                      {formattedDateMobile && (
                        <div className="previous-payment__date mobile">{formattedDateMobile}</div>
                      )}
                    </div>
                    <div className="previous-payment__success d-flex align-items-center">
                      <CheckCircleFilled />
                      Complete
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div>No previous payments have been made for this loan</div>
        )}

        {!displayMorePayment && (
          <button
            className="previous-payment__btn"
            data-ghost="portal-loan--make-a-payment-button"
            onClick={makeAdditionalPayment}
          >
            Make a Payment
          </button>
        )}
      </section>
    );
  },
);
