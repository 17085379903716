import { z } from 'zod';

import { OptionalAddress } from '../Address';
import { AuthorizedContactId } from '../BrandedIds';
import { Phone } from '../Phone';
import { zodBrandedUuid } from '../utils';
import { OptionalEmailString } from '../validations/email';

const BaseAuthorizedContact = z.object({
  id: zodBrandedUuid<AuthorizedContactId>(),
  email: OptionalEmailString,
  address: OptionalAddress,
  phone: Phone, // this is 3 optional phone numbers
  emailConsent: z.boolean(),
});
type BaseAuthorizedContact = z.infer<typeof BaseAuthorizedContact>;

const ThirdPartyAuthorizedContactType = z.enum(['attorney', 'guarantor', 'nonBorrowingSpouse', 'title', 'other']);
type ThirdPartyAuthorizedContactType = z.infer<typeof ThirdPartyAuthorizedContactType>;

const BuilderAuthorizedContactType = z.literal('builder');
type BuilderAuthorizedContactType = z.infer<typeof BuilderAuthorizedContactType>;

export const AuthorizedContactType = z.union([ThirdPartyAuthorizedContactType, BuilderAuthorizedContactType]);
export type AuthorizedContactType = z.infer<typeof AuthorizedContactType>;

export const AuthorizedContactName = z.object({ firstName: z.string(), lastName: z.string() });
export type AuthorizedContactName = z.infer<typeof AuthorizedContactName>;

export const ThirdPartyAuthorizedContact = BaseAuthorizedContact.extend({
  type: ThirdPartyAuthorizedContactType,
  name: AuthorizedContactName,
  companyName: z.string().optional(),
});
export type ThirdPartyAuthorizedContact = z.infer<typeof ThirdPartyAuthorizedContact>;

export const BuilderAuthorizedContact = BaseAuthorizedContact.extend({
  type: z.literal('builder'),
  companyName: z.string(),
});
export type BuilderAuthorizedContact = z.infer<typeof BuilderAuthorizedContact>;

export const AuthorizedContact = z.discriminatedUnion('type', [ThirdPartyAuthorizedContact, BuilderAuthorizedContact]);
export type AuthorizedContact = z.infer<typeof AuthorizedContact>;

/* HELPERS */
export function isThirdPartyContact(item: AuthorizedContact): item is ThirdPartyAuthorizedContact {
  return ['attorney', 'guarantor', 'nonBorrowingSpouse', 'title', 'other'].includes(item.type);
}

export function isBuilderContact(item: AuthorizedContact): item is BuilderAuthorizedContact {
  return item.type === 'builder';
}
