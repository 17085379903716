/**
 * Ensures users input currency correctly
 *
 * Controlled Usage (i.e. via react-hook-form)
 * <CurrencyInput value={VALUE} onChange={(newAmount) => updateAmount(newAmount)} />
 *
 * Please note you'll also need to specify zod resolvers to transform `value` into a number
 * on form submission. See the <RecordMonthlyPaymentForm /> component for a more thourough
 * example, but the gist is:
 *
 * const FORM_SHAPE = z.object({ amount: z.string().transform(Number) })
 *
 * Uncontrolled Usage (non react-hook-form)
 * <CurrencyInput defaultValue={VALUE} onChange={(newAmount) => updateAmount(newAmount)} />
 *
 * If you want to get back the value as a number use `onAmountUpdate` which will take care of
 * parsing the value as a float. This causes issues with controlled components because attempting
 * to parse something like `23.` will return `23` and make it impossible to add cents.
 * <CurrencyInput defaultValue={VALUE} onAmountUpdate={(newAmount) => updateAmount(newAmount)} />
 */

import { forwardRef } from 'react';
import ReactCurrencyInput from 'react-currency-input-field';

export type CurrencyInputProps = {
  value?: number | string;
  defaultValue?: number | string;
  onAmountUpdate?: (amount: number | undefined) => void;
  onChange?: (amount: string | undefined) => void;
  id?: string;
  className?: string;
  placeholder?: string;
  prefix?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  allowNegativeValue?: boolean;
  autoFocus?: boolean;
};

const formatDollars = (dollars: string) => {
  // Format dollars with commas in between every three digits
  let index = dollars.length;
  let chunks: string[] = [];
  while (index > 0) {
    chunks.push(dollars.slice(Math.max(0, index - 3), index));
    index -= 3;
  }
  return chunks.reverse().join(',');
};

const formatNumber = (unformattedNumber: string) => {
  // In the future when iOS Safari and Mac Safari are more update-to-date replace
  // with this:
  // return unformattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const [dollars, cents] = unformattedNumber.split('.');

  if (dollars != null && cents != null) {
    return `${formatDollars(dollars)}.${cents}`;
  }
  if (dollars != null) {
    return `${formatDollars(dollars)}`;
  }
  return unformattedNumber;
};

const formatCurrency = (value: number | undefined, prefix: string): string => {
  if (value == null) {
    return '';
  }
  if (value >= 0) {
    return `${prefix}${formatNumber(value.toFixed(2))}`;
  }
  return `-${prefix}${formatNumber((-1 * value).toFixed(2))}`;
};

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ onAmountUpdate, onChange, placeholder, prefix = '$', allowNegativeValue = false, ...props }, ref) => {
    const formattedPlaceholder = placeholder ? formatCurrency(parseFloat(placeholder), prefix) : '';
    return (
      <ReactCurrencyInput
        {...props}
        ref={ref}
        prefix={prefix}
        placeholder={formattedPlaceholder}
        decimalScale={2}
        allowNegativeValue={allowNegativeValue}
        // Will auto-prepend '0' if a user types '.' first, so .5 becomes 0.5
        // https://github.com/cchanxzy/react-currency-input-field/issues/249#issuecomment-1172789160
        transformRawValue={(value) => {
          if (value.length > 0) {
            if (value[0] === '.') {
              return `0.${value.slice(1)}`;
            }
          }

          return value;
        }}
        onValueChange={(amount) => {
          onAmountUpdate && onAmountUpdate(amount ? parseFloat(amount) : undefined);
          // We don't want amount to be undefined otherwise it will cause issues with react-hook-form
          // that will revert back to a default value
          onChange && onChange(amount ?? '');
        }}
      />
    );
  },
);
