import * as z from 'zod';

import { ApiKeyId, BorrowerId, EventId, LoanId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

const UserActor = z.object({
  type: z.literal('user'),
  id: zodBrandedUuid<UserId>(),
});
type UserActor = z.infer<typeof UserActor>;

const BorrowerActor = z.object({
  type: z.literal('borrower'),
  id: zodBrandedUuid<BorrowerId>(),
});
type BorrowerActor = z.infer<typeof BorrowerActor>;

export const WillowActor = z.object({
  type: z.literal('internal'),
  id: z.literal('WILLOW'),
});
export type WillowActor = z.infer<typeof WillowActor>;

export const ApiActor = z.object({
  type: z.literal('api'),
  id: zodBrandedUuid<ApiKeyId>(),
});
export type ApiActor = z.infer<typeof ApiActor>;

export const Actor = z.discriminatedUnion('type', [UserActor, BorrowerActor, WillowActor, ApiActor]);
export type Actor = z.infer<typeof Actor>;

export const BaseLoanEvent = z.object({
  id: zodBrandedUuid<EventId>().optional(),
  actor: Actor,
  loanId: zodBrandedUuid<LoanId>(),

  // Types are a bit weird on these. They always exist when we pull from the DB, but not on creation
  sequence: z.number().optional(),
  createdAt: zodDateOrString.optional(),
  hidden: z.boolean().default(false).optional(),
});
export type BaseLoanEvent = z.infer<typeof BaseLoanEvent>;
