import * as z from 'zod';

import { EscrowCompanyId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const EscrowCompany = z.object({
  id: zodBrandedUuid<EscrowCompanyId>(),
  escrowType: z.string().optional(),
  escrowAgentName: z.string().optional(),
  escrowAgentEmail: z.string().optional(),
  escrowAgentPhone: z.string().optional(),
  escrowCompanyName: z.string(),
  escrowCompanyAddressLine1: z.string().optional(),
  escrowCompanyAddressLine2: z.string().optional(),
  escrowCompanyAddressCity: z.string().optional(),
  escrowCompanyAddressState: z.string().optional(),
  escrowCompanyAddressZip: z.string().optional(),
  escrowPolicyNumber: z.string(),
  escrowPolicyStart: zodDateOrString.optional(),
  escrowPolicyEnd: zodDateOrString.optional(),
});
export type EscrowCompany = z.infer<typeof EscrowCompany>;
