import { z } from 'zod';

import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const RebuildLoanTransactionDataSideEffectContext = BaseUpsertTransactionSideEffect;

export const RebuildLoanTransactionDataSideEffect = RebuildLoanTransactionDataSideEffectContext.extend({
  type: z.literal('rebuildLoanTransactionData'),
});
export type RebuildLoanTransactionDataSideEffect = z.infer<typeof RebuildLoanTransactionDataSideEffect>;
