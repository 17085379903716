import * as z from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const SaveStatementPdfEvent = BaseLoanEvent.extend({
  type: z.literal('saveStatementPdf'),
  url: z.string(),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  disableMailTask: z.boolean().optional(),
});
export type SaveStatementPdfEvent = z.infer<typeof SaveStatementPdfEvent>;
