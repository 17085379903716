import * as z from 'zod';

import { BorrowerId, PortalUserId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

const DwollaAutopaySettingsInput = z.object({
  bankUrl: z.string(),
  portalUserId: zodBrandedUuid<PortalUserId>(),
  type: z.literal('dwolla'),
});

const UsioAutopaySettingsInput = z.object({
  confirmationId: z.string(),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  type: z.literal('usio'),
  dayOfTheMonth: z.number().optional(),
});
const AutopaySettingsInput = z.discriminatedUnion('type', [DwollaAutopaySettingsInput, UsioAutopaySettingsInput]);

export const UpdateAutopaySettingsEvent = BaseLoanEvent.extend({
  type: z.literal('updateAutopaySettings'),
  autopaySettingsInput: AutopaySettingsInput.optional(),
  isUsio: z.boolean().optional(), // disabled event needs indicator for audit log
});
export type UpdateAutopaySettingsEvent = z.infer<typeof UpdateAutopaySettingsEvent>;
