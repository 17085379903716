import * as z from 'zod';

import { UpdateLoanRow } from '../validations/loan/UpdateLoanRow';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateLoanEvent = BaseLoanEvent.extend({
  type: z.literal('updateLoan'),
  data: UpdateLoanRow,
});
export type UpdateLoanEvent = z.infer<typeof UpdateLoanEvent>;
