import { z } from 'zod';

import { PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertPayoffSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const UpsertPayoffSideEffect = UpsertPayoffSideEffectContext.extend({
  type: z.literal('upsertPayoff'),
});
export type UpsertPayoffSideEffect = z.infer<typeof UpsertPayoffSideEffect>;
