import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UsioMarkAsCancelledEvent = BaseLoanEvent.extend({
  type: z.literal('usioMarkAsCancelled'),
  paymentId: zodBrandedUuid<PaymentId>(),
});
export type UsioMarkAsCancelledEvent = z.infer<typeof UsioMarkAsCancelledEvent>;
