import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { last } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useRef, useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';

import { GetChatMessagesDocument, LoanChatSender, SendChatMessageDocument } from '@willow/graphql-iso/src/portal';
import { Button } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { LoanChatMessageId, LoanId } from '@willow/types-iso';

import { TypingIndicator } from './TypingIndicator';

type ChatMessage = { id?: LoanChatMessageId; type: 'user' | 'bot'; message: string; sentAt?: string };

const formatMessageSentDate = (d: string) => DateTime.fromISO(d).toFormat('cccc LLLL d, yyyy h:mm a');

const AutoScrollContainer = ({
  children,
  enabled = true,
  onScroll,
}: {
  children: ReactNode;
  enabled?: boolean;
  onScroll: () => void;
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  useEffect(() => {
    enabled && scrollToBottom();
  }, [children, enabled]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <div style={{ maxHeight: 300, overflowY: 'scroll' }} onScroll={onScroll}>
      {children}
      <div ref={bottomRef} />
    </div>
  );
};

const CustomComponents: Components = {
  ol: (props) => <ol className="my-3">{props.children}</ol>,
  li: ({ children, ...props }) => (
    <li style={{ listStyle: 'circle' }} {...props}>
      {children}
    </li>
  ),
  a: ({ className, children, ...props }) => (
    <a {...props} className={classNames(className, 'u-color-primary')} target="_blank">
      {children}
    </a>
  ),
};

const MessageBubble = ({ id, message, type, sentAt }: ChatMessage) => {
  return (
    <div
      key={id}
      className={classNames('d-flex', {
        'justify-content-start': type === 'bot',
        'justify-content-end': type === 'user',
      })}
    >
      <div style={{ maxWidth: '75%' }}>
        <div
          className={classNames('p-2 mb-1 rounded-1', {
            'u-border-color-bark1': type === 'bot',
            'u-color-bark4': type === 'bot',
            'u-bg-bark1': type === 'bot',
            'u-border-color-blue1': type === 'user',
            'u-bg-blue1': type === 'user',
            'u-color-white': type === 'user',
          })}
        >
          <ReactMarkdown components={CustomComponents}>{message}</ReactMarkdown>
        </div>
        <div
          className={classNames('u-color-bark3 u-fs-1', {
            'text-end': type === 'user',
          })}
        >
          {sentAt ? (
            formatMessageSentDate(sentAt)
          ) : (
            <div className="d-flex gap-2 align-items-center justify-content-end">
              <TypingIndicator /> Sending
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export function MessageContainer({ loanId }: { loanId: LoanId }) {
  const [showLatestChat, setShowLatestChat] = useState(true);
  const bottomRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [textInput, setTextInput] = useState('');

  const [sendChatMessage] = useMutation(SendChatMessageDocument);
  const { startPolling, stopPolling } = useQuery(GetChatMessagesDocument, {
    variables: { loanId },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      let newMessages: ChatMessage[] = [];
      if (data.getChatMessages?.records) {
        // Exit early if no new messages are present

        if (last(data.getChatMessages.records)?.id === last(messages)?.id) return;
        newMessages = data.getChatMessages.records.reduce((messages, record) => {
          const formattedMessage: ChatMessage = {
            type: record.sender === LoanChatSender.Borrower ? 'user' : 'bot',
            message: record.message,
            sentAt: record.sentAt ?? undefined,
          };

          return [...messages, formattedMessage];
        }, newMessages);
      }
      setMessages(newMessages);
    },
  });

  useEffect(() => {
    startPolling(3_000);
    return () => stopPolling();
  });

  const sendMessage = async (message: string) => {
    const response = await sendChatMessage({
      variables: {
        loanId,
        message,
      },
    });

    return response.data?.sendChatMessage;
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const onSendMessage = async () => {
    setTextInput('');
    const newMessages: ChatMessage[] = [...messages, { type: 'user', message: textInput, id: undefined }];
    setMessages(newMessages);
    setShowLatestChat(true);
    await sendMessage(textInput);
  };

  return (
    <>
      <AutoScrollContainer
        enabled={showLatestChat}
        onScroll={() => {
          if (showLatestChat) setShowLatestChat(false);
        }}
      >
        <ul
          className={classNames('p-2 u-bg-white u-border-color-bark1 u-border-1 u-border-r-3', {
            'd-none': messages.length === 0,
          })}
        >
          {messages.map((m, index) => (
            <li key={m.id ?? index} className="mb-4">
              <MessageBubble {...m} />
            </li>
          ))}
          <li ref={bottomRef}>&nbsp;</li>
        </ul>
      </AutoScrollContainer>

      <div className="position-relative relative u-border-r-3 u-border-1 u-border-color-bark1 mt-3">
        <Form>
          <Form.Group>
            <div className="u-flex">
              <div className="u-flex-1 p-2">
                <textarea
                  ref={inputRef}
                  onChange={(e) => setTextInput(e.target.value ?? '')}
                  onKeyDown={(e) => {
                    // Go ahead and send message if user presses Enter key (without alt key)
                    // which is similar behavior to Slack
                    if (e.key === 'Enter' && !e.altKey) {
                      e.preventDefault();
                      onSendMessage();
                      return;
                    }

                    // Handle cases where `alt` is engaged. Normal behavior is to
                    // skip line break so we have to manually add it in
                    if (e.key === 'Enter' && e.altKey) {
                      setTextInput(`${textInput}\n`);
                    }
                  }}
                  className="u-unset"
                  style={{ width: '85%' }}
                  placeholder="Enter message and press send or return to submit"
                  value={textInput}
                />
              </div>
              <div className="position-absolute end-0 bottom-0 pe-2 pb-2">
                <Button size="sm" onClick={onSendMessage} variant="secondary">
                  Send
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}
