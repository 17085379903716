/*
  Loan is paid to date
*/
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../../../../App';
import { getInOrAfterLastMonthBeforeTransfer } from '../../../../../utils/loanView';
import { PaymentDateWrapper } from '../PaymentDateWrapper';
import { PaymentStatusWrapper } from '../PaymentStatusWrapper';

export interface Props {
  loan: PortalSelectedLoan;
}

export const PaidCurrentHeader = NamedMemo<Props>('PaidCurrentHeader', ({ loan }) => {
  const {
    currentSnapshot: { nextStatementDate, transferData },
  } = loan;

  const isTransferring = transferData && getInOrAfterLastMonthBeforeTransfer(new Date(), transferData);
  const nextDate = isTransferring ? undefined : nextStatementDate;

  return (
    <>
      <PaymentStatusWrapper className="u-bold">No Payment Due</PaymentStatusWrapper>
      <PaymentDateWrapper className="mt-3">
        {nextDate ? <>Next statement available {nextDate}</> : <>Your loan payments are up to date</>}
      </PaymentDateWrapper>
    </>
  );
});
