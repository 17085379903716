import * as z from 'zod';

import { FeeId, FeeLineItemId, PaymentDueId } from '../BrandedIds';
import { AdvanceType, FeeLineItemInput, FeeStatus } from '../events/CreateChargeEvent';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { InvestorRemittanceSplit } from './InvestorRemittanceSplit';

export const FeeLineItem = FeeLineItemInput.extend({
  id: zodBrandedUuid<FeeLineItemId>(),
});

export type FeeLineItem = z.infer<typeof FeeLineItem>;

export const AdvanceConfig = z.object({
  advanceType: AdvanceType,
  interestRate: z.number(),
});

export type AdvanceConfig = z.infer<typeof AdvanceConfig>;

export const ChargeBase = z.object({
  id: zodBrandedUuid<FeeId>(),
  type: z.literal('fee').default('fee'),
  totalAmount: z.number(),
  amountRemaining: z.number(),
  status: FeeStatus,
  chargeDate: zodDateOrString,
  updatedAt: zodDateOrString.optional(),
  comment: z.string().optional(),
  investorRemittanceSplit: InvestorRemittanceSplit.optional(),
  isDeferred: z.boolean(),
});

export const Advance = ChargeBase.extend({
  type: z.literal('advance'),
  advanceConfig: AdvanceConfig.optional(),
  advanceInterestUpdatedAt: zodDateOrString.optional(),
  recipient: z.string().optional(),
  advanceInterest: z.number(),
  advancePrincipal: z.number(),
  originalPrincipal: z.number(),
});

export type Advance = z.infer<typeof Advance>;

export const Fee = ChargeBase.extend({
  type: z.literal('fee'),
  lineItems: z.array(FeeLineItem),
  paymentDueId: zodBrandedUuid<PaymentDueId>().optional(), // used to track automated fees
});

export type Fee = z.infer<typeof Fee>;

export const Charge = z.discriminatedUnion('type', [Advance, Fee]);
export type Charge = z.infer<typeof Charge>;

export function isFee(item: Charge): item is Fee {
  return item.type === 'fee';
}

export function isAdvance(item: Charge): item is Advance {
  return item.type === 'advance';
}
