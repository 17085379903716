import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const PPLink = ({ url, children }: { url: string; children: ReactNode }) => (
  <Link to={{ pathname: url, search: 'privacy' }} target="_blank" rel="noreferrer">
    {children}
  </Link>
);

export const TOSLink = ({ url, children }: { url: string; children: ReactNode }) => (
  <Link to={{ pathname: url, search: 'tos' }} target="_blank" rel="noreferrer">
    {children}
  </Link>
);
