import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

type ButtonAnchorLinkProps = {
  variant?: 'primary' | 'danger' | 'secondary';
  underline?: boolean;
  openInNewTab?: boolean;
} & LinkProps;

export const ButtonAnchorLink = ({
  variant = 'primary',
  underline = false,
  openInNewTab,
  children,
  className,
  ...props
}: ButtonAnchorLinkProps) => {
  return (
    <Link
      className={classNames('u-unset', className, {
        'u-color-current': variant === 'secondary',
      })}
      {...(openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      {...props}
    >
      <span
        className={classNames('d-inline-flex align-items-center gap-2 u-pointer', {
          'u-color-pine3 hover:u-color-willow': variant === 'primary',
          'u-color-red1 hover:u-color-red0': variant === 'danger',
          'text-decoration-underline': underline,
        })}
      >
        {children}
      </span>
    </Link>
  );
};
