/*
  Loan is active and paid to date
*/
import classNames from 'classnames';
import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';

export interface Props {
  className?: string;
  children: ReactNode;
}

export const PaymentDateWrapper = NamedMemo<Props>('PaymentDateWrapper', ({ className, children }) => {
  return (
    <h3
      className={classNames('u-fs-4', className)}
      data-ghost="portal-loan--payment-header-status"
      data-testid="payment-header-date"
    >
      {children}
    </h3>
  );
});
