import { reverse } from 'lodash';

import { PortalChargeFragment } from '@willow/graphql-iso/src/portal';
import { getChargesDue } from '@willow/shared-iso';

export const getFeesDueSorted = (fees: PortalChargeFragment[]): PortalChargeFragment[] => {
  const unfulfilledFees = getChargesDue(fees);

  // The most recent fee is last in the array
  return reverse(unfulfilledFees) || [];
};
