import * as z from 'zod';

import { DrawId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const DrawInput = z.object({
  amount: z.number(),
  date: zodDateOrString,
  comment: z.string().optional(),
  isReversal: z.boolean().optional(),
  transactionId: zodBrandedUuid<DrawId>().optional(), // if this is a reversal, this is the id of the original draw
  externalId: z.string().optional(), // External ID given by lender via API. Used for idempotency.
});
export type DrawInput = z.infer<typeof DrawInput>;

export const Draw = DrawInput.extend({
  id: zodBrandedUuid<DrawId>(),
});
export type Draw = z.infer<typeof Draw>;
