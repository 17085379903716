import { z } from 'zod';

import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateGoodbyeLetterPdfContext = BaseLoanIdSideEffect.extend({
  emailTemplate: z.enum(['goodbye', 'goodbyeBeforeWelcome']),
});

/**
 * @deprecated
 * goodbye letters handled via generateLoanTransferDocuments
 */
export const GenerateGoodbyeLetterPdfSideEffect = GenerateGoodbyeLetterPdfContext.extend({
  type: z.literal('generateGoodbyeLetterPdf'),
});
export type GenerateGoodbyeLetterPdfSideEffect = z.infer<typeof GenerateGoodbyeLetterPdfSideEffect>;
