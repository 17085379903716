import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { DwollaLenderTransferType } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const InitiateSweepToAccountSideEffectContext = BaseLoanIdSideEffect.extend({
  paymentId: zodBrandedUuid<PaymentId>(),
  amount: z.number(),
  transferUrl: z.string(),
  transferType: DwollaLenderTransferType,
});
export const InitiateSweepToAccountSideEffect = InitiateSweepToAccountSideEffectContext.extend({
  type: z.literal('initiateSweepToAccount'),
});
export type InitiateSweepToAccountSideEffect = z.infer<typeof InitiateSweepToAccountSideEffect>;
