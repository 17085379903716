import currency from 'currency.js';

export const NEGATIVE_PARENTHESES_PATTERN = {
  negativePattern: `(!#)`,
};

// Documentation for currency options can be found here: https://currency.js.org/
export function formatCurrency(amount: number, options?: currency.Options): string;
export function formatCurrency(amount: number | undefined | null, options?: currency.Options): string | undefined;
export function formatCurrency(amount: number | undefined | null, options?: currency.Options): string | undefined {
  if (!amount && amount !== 0) return undefined;

  return currency(amount, options).format();
}
