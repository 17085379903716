import { useCallback, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import { useHistory, useLocation } from 'react-router-dom';

import { LoanStatus, PortalSelfQuery } from '@willow/graphql-iso/src/portal';
import { getCdnUrlForFile } from '@willow/shared-iso';
import { NamedMemo, paymentAmountFormat, PORTAL_LOAN_PATHS } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { EnvironmentContext } from '../../components/context/AppContexts';
import { Metadata } from '../../components/metadata/Metadata';
import { isTransferredView } from '../../utils/loanView';

import './SelectLoan.scss';

interface Props {
  loans: PortalSelfQuery['portalSelf']['loans'];
}

export const SelectLoanPage = NamedMemo<Props>('SelectLoanPage', ({ loans }) => {
  const environment = useContext(EnvironmentContext);

  // On loan select, update the browser history with the losId
  const history = useHistory();
  const { search } = useLocation();
  const handleLoanSelection = useCallback(
    (loan: PortalSelectedLoan) => {
      history.push(PORTAL_LOAN_PATHS.root({ losId: loan.losId, companyId: loan.company.id }, { search }));
    },
    [history, search],
  );

  return (
    <main id="main" className="main--full">
      <div className="wrapper">
        <div className="page-select-loan inner">
          <Metadata metadata={{ title: 'Select a loan' }} />

          <section>
            <div className="page-select-loan__header">
              <h1 data-ghost="portal--select-loan-header">Multiple loans identified</h1>
              <h2>Select a loan to review</h2>
            </div>
            <ul className="page-select-loan__list">
              {loans.map((loan, index) => {
                const { company, losId, address, currentSnapshot } = loan;
                const { line1, locality, region, postcode } = address;

                // Determine what to display for the amount due based on the loan status
                const displayTransferredView = isTransferredView(loan);

                const {
                  loanStatus,
                  totalAmountRemainingFromBorrower,
                  expectedPayoffTotal,
                  maturityConfig: { isMaturing },
                } = currentSnapshot;

                let amountDueDisplay;
                switch (loanStatus) {
                  case LoanStatus.PaidOff:
                    amountDueDisplay = 'Paid Off';
                    break;
                  case LoanStatus.PreActive:
                    amountDueDisplay = 'Available soon';
                    break;
                  case LoanStatus.Archived:
                    amountDueDisplay = 'Inactive';
                    break;
                  case LoanStatus.Transferred:
                    amountDueDisplay = 'Transferred';
                    break;
                  case LoanStatus.PendingTransfer:
                    amountDueDisplay = displayTransferredView
                      ? 'Transferring'
                      : paymentAmountFormat(totalAmountRemainingFromBorrower);
                    break;
                  case LoanStatus.Foreclosure:
                    amountDueDisplay = 'Foreclosure';
                    break;
                  default:
                    // TODO made a shared util for this in another PR
                    amountDueDisplay = paymentAmountFormat(
                      isMaturing ? expectedPayoffTotal : totalAmountRemainingFromBorrower,
                    );
                    break;
                }

                const logo = company
                  ? {
                      url: getCdnUrlForFile(
                        company.logos.portalLogo ? 'portalLogo' : 'portalLogoDefault',
                        environment,
                        company.id,
                      ),
                      alt: `${company.name} Logo`,
                    }
                  : undefined;

                return (
                  <li key={`select-loan-${index}`} className="page-select-loan__list__item">
                    <button onClick={() => handleLoanSelection(loan)} data-ghost="portal--select-loan-btn">
                      <div className="page-select-loan__logo">
                        {logo && (
                          <LazyLoad height="100%">
                            <img src={logo.url} alt={logo.alt} />
                          </LazyLoad>
                        )}
                      </div>
                      <div className="page-select-loan__info-wrapper">
                        <div>
                          <div className="page-select-loan__address">
                            {line1}, {locality} {region} {postcode}
                          </div>
                          <div className="page-select-loan__id">#{losId}</div>
                        </div>

                        <div className="page-select-loan__amount">{amountDueDisplay}</div>
                      </div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </div>
    </main>
  );
});
