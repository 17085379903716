import * as z from 'zod';

import { AuthZeroId, CompanyId, UserId } from './BrandedIds';
import { CompanyReportType } from './CompanyReport';
import { zodBrandedUuid, zodDateOrString } from './utils/Zod';

export const UserRoles = z.object({ admin: z.boolean().default(false) }).strict();
export type UserRoles = z.infer<typeof UserRoles>;

export const DEFAULT_PERMISSIONS = { admin: false };
export const UserPermissions = z.object({ admin: z.boolean() });
export type UserPermissions = z.infer<typeof UserPermissions>;

export const UserPreferences = z.object({
  views: z
    .object({
      loanDashboardDefault: z.string().optional(),
      transactionDashboardDefault: z.string().optional(),
    })
    .optional(),
  favoriteReports: z
    .object({
      oneTime: z.array(z.nativeEnum(CompanyReportType)).optional(),
      recurring: z.array(z.nativeEnum(CompanyReportType)).optional(),
    })
    .optional(),
});
export type UserPreferences = z.infer<typeof UserPreferences>;

export const UserLastSeenAt = z.object({ taskPage: zodDateOrString.optional() });
export type UserLastSeenAt = z.infer<typeof UserLastSeenAt>;

const UserByCompany = z.object({
  companyId: zodBrandedUuid<CompanyId>(),
  permissions: UserPermissions,
  preferences: UserPreferences,
  lastSeenAt: UserLastSeenAt,
});
export type UserByCompany = z.infer<typeof UserByCompany>;

export const UserName = z.object({
  firstName: z.string(),
  lastName: z.string(),
});
export type UserName = z.infer<typeof UserName>;

export interface User {
  id: UserId;
  email: string;
  name?: UserName; // Followup: require this after names have been backfilled
  authZeroId: AuthZeroId;
  roles: UserRoles;
  createdAt: Date;
  updatedAt: Date;
}

export interface FullUser extends User {
  contextByCompany: UserByCompany[];
}
