import { z } from 'zod';

import { DrawId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertDrawSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  drawId: zodBrandedUuid<DrawId>(),
});
export const UpsertDrawSideEffect = UpsertDrawSideEffectContext.extend({
  type: z.literal('upsertDraw'),
});
export type UpsertDrawSideEffect = z.infer<typeof UpsertDrawSideEffect>;
