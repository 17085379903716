import { MoreOutlined } from '@ant-design/icons';
import { forwardRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface KebabToggleProps {
  onClick: (ev: unknown) => unknown;
}
export const KebabToggle = forwardRef<HTMLButtonElement, KebabToggleProps>(({ onClick }, ref) => (
  <button
    ref={ref}
    onClick={(ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClick(ev);
    }}
    className="u-btn-reset"
  >
    <OverlayTrigger trigger={['hover', 'focus']} rootClose overlay={<Tooltip>Click for more options</Tooltip>}>
      <div className="u-color-bark4 hover:u-color-pine4 d-flex align-items-center">
        <MoreOutlined rotate={90} />
      </div>
    </OverlayTrigger>
  </button>
));
