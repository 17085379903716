/*
  Payment header displayed when "displayPaymentView" is true
  i.e. active loans that are accepting payments
*/
import { find } from 'lodash';

import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../../../App';
import { PaidCurrentHeader } from './headers/PaidCurrentHeader';
import { PaymentDueHeader } from './headers/PaymentDueHeader';

export interface Props {
  loan: PortalSelectedLoan;
}

export const PaymentHeader = NamedMemo<Props>('PaymentHeader', ({ loan }) => {
  // Determine if there there is anything due on the loan
  const {
    currentSnapshot: {
      paymentDueStatus,
      totalAmountRemainingFromBorrower,
      payoffs,
      maturityConfig: { isMaturing },
    },
  } = loan;

  const hasStatementPaymentDue = paymentDueStatus === 'due';
  const hasBorrowerAmountRemaining = totalAmountRemainingFromBorrower > 0;
  const hasActivePayoff = find(payoffs, ['status', 'pending']);
  const paymentDueOrMaturing = hasStatementPaymentDue || hasBorrowerAmountRemaining || hasActivePayoff || isMaturing;

  return (
    <section id="payment-header" aria-label="Payment Due Details" className="text-center mb-4">
      {paymentDueOrMaturing ? <PaymentDueHeader loan={loan} /> : <PaidCurrentHeader loan={loan} />}
    </section>
  );
});
