import * as z from 'zod';

import { LoanOwnerId, PoolId } from '../BrandedIds';
import { BillSettingType } from '../loan/BillSettings';
import { DrawInput } from '../loan/Draw';
import { EscrowCompany } from '../loan/EscrowCompany';
import { EscrowSettingInput } from '../loan/EscrowSetting';
import { InitialBalances } from '../loan/InitialBalances';
import { LoanInvestorRemittanceSplit } from '../loan/InvestorRemittanceSplit';
import { ConstructionConfig } from '../loan/LoanSnapshot';
import { LoanStatus } from '../loan/LoanStatus';
import { PropertyInput } from '../loan/Property';
import { PaymentInput } from '../PaymentDue';
import { zodBrandedString, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreationEvent = BaseLoanEvent.extend({
  type: z.literal('loanCreation'),
  firstPayment: PaymentInput,
  loanStatus: z.union([LoanStatus, z.literal('onHold')]).optional(),
  initialBalances: InitialBalances.omit({ principal: true }),
  productDescription: z.string(),
  currentOutstandingAmount: z.number().optional(),
  currentPaymentMonth: z.number().optional(), // if there have been 2 normal monthly payments made, this should be 3
  escrowCompanies: z.array(EscrowCompany).optional(),
  maturityDate: zodDateOrString,
  stopEmails: z.boolean().optional(),
  stopPayments: z.boolean().optional(),
  exactPaymentsOnly: z.boolean().optional(),
  properties: z.array(PropertyInput).optional(),
  monthlyMortgageInsurancePayment: z.number().optional(),
  escrowSettings: z.array(EscrowSettingInput).optional(),
  isPendingActivation: z.boolean().optional(),
  constructionConfig: ConstructionConfig.optional(),
  initialDraws: z.array(DrawInput).optional(),
  investorRemittanceSplit: z.array(LoanInvestorRemittanceSplit).optional(), // TODO: remove after migration
  delinquentInterestRate: z.number().optional(),
  delinquentInterestEffectiveDate: zodDateOrString.optional(),
  initialBillSettingType: BillSettingType,
  externalLoanIds: z
    .object({
      startDate: zodDateOrString,
      ownerId: zodBrandedString<LoanOwnerId>().optional(),
      poolId: zodBrandedString<PoolId>().optional(),
      previousServicerId: z.string().optional(),
      capitalPartnerId: z.string().optional(),
      fullId: z.string().optional(),
      otherId1: z.string().optional(),
      otherId2: z.string().optional(),
      otherId3: z.string().optional(),
    })
    .optional(),
});
export type CreationEvent = z.infer<typeof CreationEvent>;
