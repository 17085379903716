import * as z from 'zod';

import { AddressLine, PositiveMonetaryValue, StateAbbreviation, Zipcode } from '../../fields';

export const AddressFields = z.object({
  addressLine1: AddressLine,
  addressLine2: AddressLine.optional(),
  addressLine3: AddressLine.optional(),
  addressLine4: AddressLine.optional(),
  addressLocality: z.string(),
  addressRegion: StateAbbreviation,
  addressPostcode: Zipcode,
  addressCounty: z.string().optional(),
  addressCountry: z.string().optional(),
  addressCoverageAmount: PositiveMonetaryValue.optional(),
  addressReplacementCostValue: PositiveMonetaryValue.optional(),
  addressPropertyType: z.string().optional(),
  addressPropertySubType: z.string().optional(),
  addressFloodZone: z.string().optional(),
  address2Line1: AddressLine.optional(),
  address2Line2: AddressLine.optional(),
  address2Line3: AddressLine.optional(),
  address2Line4: AddressLine.optional(),
  address2Locality: z.string().optional(),
  address2Region: StateAbbreviation.optional().or(z.literal('')),
  address2Postcode: Zipcode.optional(),
  address2County: z.string().optional(),
  address2Country: z.string().optional(),
  address2CoverageAmount: PositiveMonetaryValue.optional(),
  address2ReplacementCostValue: PositiveMonetaryValue.optional(),
  address2PropertyType: z.string().optional(),
  address2PropertySubType: z.string().optional(),
  address2FloodZone: z.string().optional(),
  address3Line1: AddressLine.optional(),
  address3Line2: AddressLine.optional(),
  address3Line3: AddressLine.optional(),
  address3Line4: AddressLine.optional(),
  address3Locality: z.string().optional(),
  address3Region: StateAbbreviation.optional().or(z.literal('')),
  address3Postcode: Zipcode.optional(),
  address3County: z.string().optional(),
  address3Country: z.string().optional(),
  address3CoverageAmount: PositiveMonetaryValue.optional(),
  address3ReplacementCostValue: PositiveMonetaryValue.optional(),
  address3PropertyType: z.string().optional(),
  address3PropertySubType: z.string().optional(),
  address3FloodZone: z.string().optional(),
  address4Line1: AddressLine.optional(),
  address4Line2: AddressLine.optional(),
  address4Line3: AddressLine.optional(),
  address4Line4: AddressLine.optional(),
  address4Locality: z.string().optional(),
  address4Region: StateAbbreviation.optional().or(z.literal('')),
  address4Postcode: Zipcode.optional(),
  address4County: z.string().optional(),
  address4Country: z.string().optional(),
  address4CoverageAmount: PositiveMonetaryValue.optional(),
  address4ReplacementCostValue: PositiveMonetaryValue.optional(),
  address4PropertyType: z.string().optional(),
  address4PropertySubType: z.string().optional(),
  address4FloodZone: z.string().optional(),
  address5Line1: AddressLine.optional(),
  address5Line2: AddressLine.optional(),
  address5Line3: AddressLine.optional(),
  address5Line4: AddressLine.optional(),
  address5Locality: z.string().optional(),
  address5Region: StateAbbreviation.optional().or(z.literal('')),
  address5Postcode: Zipcode.optional(),
  address5County: z.string().optional(),
  address5Country: z.string().optional(),
  address5CoverageAmount: PositiveMonetaryValue.optional(),
  address5ReplacementCostValue: PositiveMonetaryValue.optional(),
  address5PropertyType: z.string().optional(),
  address5PropertySubType: z.string().optional(),
  address5FloodZone: z.string().optional(),
  address6Line1: AddressLine.optional(),
  address6Line2: AddressLine.optional(),
  address6Line3: AddressLine.optional(),
  address6Line4: AddressLine.optional(),
  address6Locality: z.string().optional(),
  address6Region: StateAbbreviation.optional().or(z.literal('')),
  address6Postcode: Zipcode.optional(),
  address6County: z.string().optional(),
  address6Country: z.string().optional(),
  address6CoverageAmount: PositiveMonetaryValue.optional(),
  address6ReplacementCostValue: PositiveMonetaryValue.optional(),
  address6PropertyType: z.string().optional(),
  address6PropertySubType: z.string().optional(),
  address6FloodZone: z.string().optional(),
  address7Line1: AddressLine.optional(),
  address7Line2: AddressLine.optional(),
  address7Line3: AddressLine.optional(),
  address7Line4: AddressLine.optional(),
  address7Locality: z.string().optional(),
  address7Region: StateAbbreviation.optional().or(z.literal('')),
  address7Postcode: Zipcode.optional(),
  address7County: z.string().optional(),
  address7Country: z.string().optional(),
  address7CoverageAmount: PositiveMonetaryValue.optional(),
  address7ReplacementCostValue: PositiveMonetaryValue.optional(),
  address7PropertyType: z.string().optional(),
  address7PropertySubType: z.string().optional(),
  address7FloodZone: z.string().optional(),
  address8Line1: AddressLine.optional(),
  address8Line2: AddressLine.optional(),
  address8Line3: AddressLine.optional(),
  address8Line4: AddressLine.optional(),
  address8Locality: z.string().optional(),
  address8Region: StateAbbreviation.optional().or(z.literal('')),
  address8Postcode: Zipcode.optional(),
  address8County: z.string().optional(),
  address8Country: z.string().optional(),
  address8CoverageAmount: PositiveMonetaryValue.optional(),
  address8ReplacementCostValue: PositiveMonetaryValue.optional(),
  address8PropertyType: z.string().optional(),
  address8PropertySubType: z.string().optional(),
  address8FloodZone: z.string().optional(),
  address9Line1: AddressLine.optional(),
  address9Line2: AddressLine.optional(),
  address9Line3: AddressLine.optional(),
  address9Line4: AddressLine.optional(),
  address9Locality: z.string().optional(),
  address9Region: StateAbbreviation.optional().or(z.literal('')),
  address9Postcode: Zipcode.optional(),
  address9County: z.string().optional(),
  address9Country: z.string().optional(),
  address9CoverageAmount: PositiveMonetaryValue.optional(),
  address9ReplacementCostValue: PositiveMonetaryValue.optional(),
  address9PropertyType: z.string().optional(),
  address9PropertySubType: z.string().optional(),
  address9FloodZone: z.string().optional(),
  address10Line1: AddressLine.optional(),
  address10Line2: AddressLine.optional(),
  address10Line3: AddressLine.optional(),
  address10Line4: AddressLine.optional(),
  address10Locality: z.string().optional(),
  address10Region: StateAbbreviation.optional().or(z.literal('')),
  address10Postcode: Zipcode.optional(),
  address10County: z.string().optional(),
  address10Country: z.string().optional(),
  address10CoverageAmount: PositiveMonetaryValue.optional(),
  address10ReplacementCostValue: PositiveMonetaryValue.optional(),
  address10PropertyType: z.string().optional(),
  address10PropertySubType: z.string().optional(),
  address10FloodZone: z.string().optional(),
});
