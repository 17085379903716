import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  PmiCancellationRequestDocument,
  PmiCancellationRequestPropertyValueType,
} from '@willow/graphql-iso/src/portal';
import { Button, ControlledRadio, NamedMemo, useZodHookForm, z } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { WillowModal } from '../modal/Modal';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

const FormShape = z.object({
  propertyValueType: z.enum([
    PmiCancellationRequestPropertyValueType.Original,
    PmiCancellationRequestPropertyValueType.Current,
  ]),
});

export const RequestPMICancellation = NamedMemo<Props>('RequestPMICancellation', ({ loan }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [pmiCancellationRequest] = useMutation(PmiCancellationRequestDocument);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useZodHookForm(FormShape, {
    defaultValues: {
      propertyValueType: undefined,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await pmiCancellationRequest({
        variables: {
          companyId: loan.company.id,
          loanId: loan.id,
          propertyValueType: data.propertyValueType,
        },
      });

      setShowSuccessModal(true);
      reset();
    } catch {
      toast.error(`Something went wrong submitting the request. Please try again.`);
    }
  });

  return (
    <>
      <ManageLoanHeader
        title="Request Private Mortgage Insurance (PMI) Cancellation"
        subtitle="To initiate the cancellation of your private mortgage insurance, please confirm which property value should be used. Our team will reach out to discuss eligibility and provide required documentation to you."
      />

      <Form onSubmit={onSubmit}>
        <Form.Group className="w-100">
          <ControlledRadio
            control={control}
            fieldName="propertyValueType"
            label="Choose one:"
            options={[
              {
                value: PmiCancellationRequestPropertyValueType.Original,
                label: (
                  <>
                    I am requesting that the <b>Original Property Value</b> be used
                  </>
                ),
              },
              {
                value: PmiCancellationRequestPropertyValueType.Current,
                label: (
                  <>
                    I am requesting that the <b>Current Property Value</b> be used
                  </>
                ),
              },
            ]}
          />
        </Form.Group>

        <div className="fst-italic">
          I understand that in order to determine the <u>current value</u> of my property, an appraisal could be ordered
          at my expense. I will be notified of the cost and required to pay upfront if I wish to move forward.
        </div>
        <div className="mt-4 w-100 d-flex justify-content-end">
          <Button variant="primary" size="md" onClick={onSubmit} loading={isSubmitting}>
            Submit Request
          </Button>
        </div>
      </Form>

      <WillowModal showModal={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <h2 className="u-fs-4 u-bold mb-2">PMI Cancellation Requested</h2>
        <div>{loan.company.name} is reviewing your note. We will be in touch shortly to discuss options.</div>
        <div className="mt-4 ms-auto">
          <Button
            variant="primary"
            size="sm"
            className="hover-override hover:u-bg-secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </div>
      </WillowModal>
    </>
  );
});
