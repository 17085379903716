/*
  This page is displayed only via server-side. It should only be displayed in severe cases
*/
import { SyncOutlined } from '@ant-design/icons';
import React from 'react';

import { getCdnUrlForFile } from '@willow/shared-iso';

import { NamedMemo } from '../NamedMemo';

import './ErrorPage.scss';

interface Props {
  resetErrorBoundary: () => void;
}

export const ErrorPage = NamedMemo('ErrorPage', ({ resetErrorBoundary }: Props) => {
  return (
    <div className="page-error">
      <div className="wrapper">
        <div className="inner">
          <img src={getCdnUrlForFile('houseDark')} alt="House Dark" />

          <h1 className="page-error__title">Something went wrong.</h1>
          <div className="page-error__subtitle">We are working on getting the lights back on.</div>
          <button onClick={resetErrorBoundary} className="page-error__btn">
            <SyncOutlined />
            Refresh
          </button>
        </div>
      </div>
    </div>
  );
});
