import { z } from 'zod';

import { IndexRateId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

const Sofr30DayIndexType = z.literal('sofr30Day');
const Sofr90DayIndexType = z.literal('sofr90Day');
const Sofr180DayIndexType = z.literal('sofr180Day');
const US1Month = z.literal('us1Month');
const US3Month = z.literal('us3Month');
const US6Month = z.literal('us6Month');
const US1Year = z.literal('us1Year');
const US3Year = z.literal('us3Year');
const US5Year = z.literal('us5Year');
const US10Year = z.literal('us10Year');
const wsjPrime = z.literal('wsjPrime');

export const IndexRateType = z.union([
  Sofr30DayIndexType,
  Sofr90DayIndexType,
  Sofr180DayIndexType,
  US1Month,
  US3Month,
  US6Month,
  US1Year,
  US3Year,
  US5Year,
  US10Year,
  wsjPrime,
]);
export type IndexRateType = z.infer<typeof IndexRateType>;

export const IndexRate = z.object({
  id: zodBrandedUuid<IndexRateId>(),
  type: IndexRateType,
  date: zodDateOrString,
  rate: z.number().positive(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type IndexRate = z.infer<typeof IndexRate>;
