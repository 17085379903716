import { z } from 'zod';

import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertBoardingSideEffectContext = BaseUpsertTransactionSideEffect;

export const UpsertBoardingSideEffect = UpsertBoardingSideEffectContext.extend({
  type: z.literal('upsertBoarding'),
});
export type UpsertBoardingSideEffect = z.infer<typeof UpsertBoardingSideEffect>;
