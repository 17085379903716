import classNames from 'classnames';
import { ReactNode } from 'react';

import { Modal as BootstrapModal, ModalProps } from '../../../bootstrap';
import { LoaderOverlay } from '../Loader/LoaderOverlay';

import s from './Modal.module.scss';

interface Props extends ModalProps {
  children: ReactNode;
  showModal: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  showLoader?: boolean;
  onClose: () => void;
}

export const Modal = ({ children, className, header, footer, showModal, showLoader, onClose, ...props }: Props) => {
  return (
    <BootstrapModal
      show={showModal}
      onHide={onClose}
      className={classNames(s.modal, 'd-flex', className)}
      backdropClassName={s.modalBackdrop}
      dialogClassName="m-auto"
      contentClassName="p-4 border-0"
      {...props}
    >
      {header ? <BootstrapModal.Header className="p-0 mb-3 border-0">{header}</BootstrapModal.Header> : undefined}
      <BootstrapModal.Body className="p-0">{children}</BootstrapModal.Body>
      {footer ? <BootstrapModal.Footer className="p-0 m-0 border-0">{footer}</BootstrapModal.Footer> : undefined}
      {showLoader && <LoaderOverlay isLoading />}
    </BootstrapModal>
  );
};
