/*
  Loan has one or more payments due
*/
import { LoanBillSettingsType } from '@willow/graphql-iso/src/portal';
import { getNextPaymentDueDate, getUnfulfilledPaymentsDue, NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../../../../App';
import { getTotalAmountDue } from '../../../../../pages/make-payment/utils';
import { PillLabel } from '../../../../pill-label/PillLabel';
import { PaymentAmountWrapper } from '../PaymentAmountWrapper';
import { PaymentDateWrapper } from '../PaymentDateWrapper';
import { PaymentStatusWrapper } from '../PaymentStatusWrapper';
import { MaturingHeader } from './MaturingHeader';

export interface Props {
  loan: PortalSelectedLoan;
}

export const PaymentDueHeader = NamedMemo<Props>('PaymentDueHeader', ({ loan }) => {
  const {
    currentSnapshot: {
      paymentDueSubStatus: { isPastDue },
      paymentsDue,
      maturityConfig: { isMaturing },
      payoffs,
      expectedPayoffTotal,
      totalAmountRemainingFromBorrower,
      currentBillSettingsType,
    },
    configuration: { hasInterestReserve },
  } = loan;

  if (isMaturing && totalAmountRemainingFromBorrower === 0) {
    return <MaturingHeader payoffs={payoffs} payoffTotal={expectedPayoffTotal} />;
  }

  // Determine status copy
  let status;
  if (hasInterestReserve) {
    status = 'Statement Balance';
  } else {
    status = 'Payment due';
  }

  // Determine amount due
  const amountDue = getTotalAmountDue(loan);

  // Determine due date
  const unfulfilledPayments = getUnfulfilledPaymentsDue(paymentsDue);
  const dueDate = getNextPaymentDueDate(unfulfilledPayments);

  const isBuilderInCharge = currentBillSettingsType === LoanBillSettingsType.Builder;

  return (
    <>
      <PaymentStatusWrapper>
        {isPastDue && !isBuilderInCharge ? <PillLabel label="Past Due" isError /> : <>{status}</>}
      </PaymentStatusWrapper>
      <PaymentAmountWrapper amount={amountDue} />
      {/* Won't have nextPaymentDue if only fees are remaining */}
      {dueDate && <PaymentDateWrapper>{dueDate}</PaymentDateWrapper>}
    </>
  );
});
