import { z } from 'zod';

import { StrictAddress } from '../Address';
import { PropertyId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ModifyPropertyAddressEvent = BaseLoanEvent.extend({
  type: z.literal('modifyPropertyAddress'),
  propertyId: zodBrandedUuid<PropertyId>(),
  originalAddress: StrictAddress,
  newAddress: StrictAddress,
});
export type ModifyPropertyAddressEvent = z.infer<typeof ModifyPropertyAddressEvent>;
