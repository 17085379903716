import { Spinner } from 'react-bootstrap';

import { NamedMemo } from '../NamedMemo';

import './Loader.scss';

export const Loader = NamedMemo('Loader', () => {
  return (
    <div className="loader">
      <Spinner animation="border" role="status" />
    </div>
  );
});
