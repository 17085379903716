/*
    No payments are collected when the Payments Paused View is set to display.

    The Payments Paused view is presented when:
    1. Payments are paused for a temporary backstop situation
*/

import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { ContactForm } from '../contact-form/ContactForm';
import { PageHeading } from '../page-heading/PageHeading';

interface Props {
  company: CompanyFragment;
  title: string;
  subtitle?: string;
  contactFormTitle?: string;
}

export const PaymentsPaused = NamedMemo<Props>('PaymentsPaused', ({ company, title, subtitle, contactFormTitle }) => {
  const { name, supportEmail: email, supportPhone: phone, supportHours: hours } = company;

  return (
    <div className="">
      <PageHeading title={title} subtitle={subtitle} />

      {company && (
        <section className="u-border-1 u-border-color-bark1 u-bg-white py-5 px-4 u-border-r-3 mt-5">
          <ContactForm
            title={contactFormTitle || `If you believe this is an error, contact ${name} by phone or email.`}
            phone={phone}
            email={email}
            hours={hours ?? undefined}
          />
        </section>
      )}
    </div>
  );
});
