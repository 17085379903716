import * as z from 'zod';

import { QcDocumentStatus, QcDocumentType } from './QcDocument';

export const QcDocumentStatusFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('status'),
  filter: z.object({
    values: z.array(QcDocumentStatus),
  }),
});
export type QcDocumentStatusFilter = z.infer<typeof QcDocumentStatusFilter>;

export const QcDocumentTypeFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('type'),
  filter: z.object({
    values: z.array(QcDocumentType),
  }),
});
export type QcDocumentTypeFilter = z.infer<typeof QcDocumentTypeFilter>;

export const QcDocumentYearFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('year'),
  filter: z.object({
    values: z.array(z.number().or(z.literal(null))),
  }),
});
export type QcDocumentYearFilter = z.infer<typeof QcDocumentYearFilter>;

export const QcDocumentWarningsFilter = z.object({
  type: z.literal('enum'),
  key: z.literal('hasWarnings'),
  filter: z.object({
    values: z.array(z.union([z.literal('yes'), z.literal('no')])),
  }),
});
export type QcDocumentWarningsFilter = z.infer<typeof QcDocumentWarningsFilter>;

export const QcDocumentFilter = z.discriminatedUnion('key', [
  QcDocumentStatusFilter,
  QcDocumentTypeFilter,
  QcDocumentYearFilter,
  QcDocumentWarningsFilter,
]);
export type QcDocumentFilter = z.infer<typeof QcDocumentFilter>;
