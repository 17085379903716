import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const WaiveDelinquentInterestEvent = BaseLoanEvent.extend({
  type: z.literal('waiveDelinquentInterest'),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
});

export type WaiveDelinquentInterestEvent = z.infer<typeof WaiveDelinquentInterestEvent>;
