// Future update: this file should really live in shared-iso utils. It's also used in email-node
import { isUndefined, last } from 'lodash';
import { DateTime } from 'luxon';

import { AppPaymentDueFragment } from '@willow/graphql-iso/src/app';
import { PortalPaymentDueFragment } from '@willow/graphql-iso/src/portal';
import { LoanStatus } from '@willow/types-iso';

import { isTransferringLoan } from './loanStatus';

export function getPaymentsDueBeforeTransfer(
  transferEffectiveDate: string | undefined,
  paymentsDue: PortalPaymentDueFragment[],
): PortalPaymentDueFragment[];
export function getPaymentsDueBeforeTransfer(
  transferEffectiveDate: string | undefined,
  paymentsDue: AppPaymentDueFragment[],
): AppPaymentDueFragment[];
export function getPaymentsDueBeforeTransfer(
  transferEffectiveDate: string | undefined,
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment[] | AppPaymentDueFragment[] {
  if (!transferEffectiveDate) {
    return paymentsDue;
  }

  // Find the last payment due that is before the transfer effective date
  const transferDate = DateTime.fromJSDate(new Date(transferEffectiveDate));

  // Filter out any payment dates that occur on or after the transfer date
  const paymentsDueBeforeTransfer = paymentsDue?.filter(
    (paymentDue) => transferDate > DateTime.fromJSDate(new Date(paymentDue.paymentDate)),
  );

  // The last payment due will be the last item in this filtered list
  return paymentsDueBeforeTransfer;
}

export function getLastPaymentDueBeforeTransfer(
  transferEffectiveDate: string,
  paymentsDue: PortalPaymentDueFragment[],
): PortalPaymentDueFragment | undefined;
export function getLastPaymentDueBeforeTransfer(
  transferEffectiveDate: string,
  paymentsDue: AppPaymentDueFragment[],
): AppPaymentDueFragment | undefined;
export function getLastPaymentDueBeforeTransfer(
  transferEffectiveDate: string,
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): PortalPaymentDueFragment | AppPaymentDueFragment | undefined {
  // Find the last payment due that is before the transfer effective date
  const paymentsDueBeforeTransfer = getPaymentsDueBeforeTransfer(transferEffectiveDate, paymentsDue);
  return last(paymentsDueBeforeTransfer);
}

export const isTransferringBeforeCollection = (
  loanStatus: LoanStatus,
  transferEffectiveDate: string | undefined,
  paymentsDue: PortalPaymentDueFragment[] | AppPaymentDueFragment[],
): boolean => {
  if (!transferEffectiveDate) {
    return false;
  }

  const transferring = isTransferringLoan(loanStatus);
  const paymentsDueBeforeTransfer = getLastPaymentDueBeforeTransfer(transferEffectiveDate, paymentsDue);
  return transferring && isUndefined(paymentsDueBeforeTransfer);
};
