import { z } from 'zod';

import { DrawId } from '../BrandedIds';
import { DrawInput } from '../loan/Draw';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateDrawEvent = BaseLoanEvent.extend({
  type: z.literal('createDraw'),
  draw: DrawInput,
  comment: z.string().optional(),
  drawId: zodBrandedUuid<DrawId>().optional(),
  includeAllDrawsInMonth: z.boolean().optional(),
});
export type CreateDrawEvent = z.infer<typeof CreateDrawEvent>;
