import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { PaymentsPaused } from '../payments-paused/PaymentsPaused';

interface Props {
  company: CompanyFragment;
}

// Future enhancement: rename component
export const LoanPaymentsPaused = NamedMemo<Props>('LoanPaymentsPaused', ({ company }) => {
  return <PaymentsPaused company={company} title="Payments temporarily paused" subtitle={undefined} />;
});
