import { DateTime } from 'luxon';

import { GqlIsoDate, parseGqlIsoDate } from '@willow/types-iso';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_SHORT = 'M/d/yy';
export const DATE_FORMAT_SHORT_FULL_YEAR = 'M/d/yyyy';
export const DATE_TIME_FORMAT = `MM/dd/yyyy 'at' hh:mm a ZZZZ`;
export const DATE_FORMAT_MONTH_AND_YEAR_ONLY = 'MMMM yyyy';
export const DATE_FORMAT_MONTH_AND_SHORT_YEAR_ONLY = 'LLL yy';

export const dateTimeFormat = (date: string | Date, format?: string): string => {
  const luxonDate = DateTime.fromJSDate(new Date(date));
  return luxonDate.toFormat(format || DATE_TIME_FORMAT);
};

export const dateFormat = (date: string | Date, format?: string): string => {
  return dateTimeFormat(date, format || DATE_FORMAT);
};

export const gqlIsoDateFormat = (date: GqlIsoDate, format?: string): string => {
  return dateFormat(parseGqlIsoDate(date), format);
};

export const formatDateTime = (date: DateTime, format?: string): string => {
  return date.toFormat(format || DATE_TIME_FORMAT);
};

export const isReactDatePickerValid = (date: Date): boolean => {
  // in react-datepicker you select a date from the calendar picker, but then can type into the input field
  // the library is validating the month + day, but for year something like "20224" is technically valid (so .isValid method will pass)
  // we've had some lender types, so adding a check here that the year is 4 numbers
  const { year } = DateTime.fromJSDate(date);
  return year.toString().length === 4;
};
