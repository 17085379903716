import * as z from 'zod';

import { TaskAuditLogId, TaskId } from '../BrandedIds';
import { Actor } from '../events';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { Task } from './Task';

export const TaskAuditLogEventType = z.union([
  z.literal('create'),
  z.literal('reopen'),
  z.literal('complete'),
  z.literal('archive'),
  z.literal('skipped'),
  z.literal('assign'),
  z.literal('unassign'),
]);
export type TaskAuditLogEventType = z.infer<typeof TaskAuditLogEventType>;

export const TaskAuditLogEvent = z.object({
  id: zodBrandedUuid<TaskAuditLogId>(),
  taskId: zodBrandedUuid<TaskId>(),
  actor: Actor,
  type: TaskAuditLogEventType,
  comment: z.string().optional(),
  task: Task,
  hidden: z.boolean(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type TaskAuditLogEvent = z.infer<typeof TaskAuditLogEvent>;
