import { useLocation, useRouteMatch } from 'react-router-dom';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';
import { Accordion } from '@willow/shared-web/bootstrap';

import { Paperless } from './Paperless';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfService } from './TermsOfService';

import s from './Disclosures.module.scss';

interface Props {
  selectedCompany: CompanyFragment;
}

export const Disclosures = NamedMemo<Props>('Disclosures', ({ selectedCompany }) => {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const PAGES = [Paperless(selectedCompany), TermsOfService(url), PrivacyPolicy(url)];

  return (
    <div>
      <Accordion defaultActiveKey={search}>
        {PAGES.map((page) => (
          <Accordion.Item key={page.searchKey} eventKey={page.searchKey}>
            <Accordion.Header>
              <div>
                <p>{page.title}</p>
                {page.lastModified && <p className="u-color-bark3 mt-1">Last modified: {page.lastModified}</p>}
              </div>
            </Accordion.Header>
            <Accordion.Body className={s.disclosures}>{page.content}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
});
