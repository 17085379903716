/* 
    DESKTOP VIEW for the portal documents page
*/

import { DownloadOutlined } from '@ant-design/icons';

import { dateFormat, DocumentDisplayItem, NamedMemo } from '@willow/shared-web';

import './ViewDocumentsTable.scss';

interface Props {
  documents: DocumentDisplayItem[];
  handleDocumentDownload: (document: DocumentDisplayItem) => void;
}

export const ViewDocumentsTable = NamedMemo<Props>('ViewDocumentsTable', ({ documents, handleDocumentDownload }) => {
  return (
    <table className="view-documents-table" data-testid="view-documents-table">
      <thead className="view-documents-table__header">
        <tr>
          <th>Document Name</th>
          <th style={{ width: '140px' }}>Created Date</th>
          <th style={{ width: '160px' }}>Document</th>
        </tr>
      </thead>

      <tbody className="view-documents-table__body">
        {documents.map((document, index) => {
          const { id, title, subtitle, callouts, date } = document;

          return (
            <tr key={`view-documents-table-item-${id}-${index}`}>
              <td className="pe-4">
                {title}
                {subtitle && <span className="d-block u-fs-1 u-color-bark3">{subtitle}</span>}
                {callouts.map((callout, index) => (
                  <span key={index} className="d-block u-fs-1 u-color-maple4">
                    {callout}
                  </span>
                ))}
              </td>
              <td>{dateFormat(date)}</td>
              <td>
                <button onClick={() => handleDocumentDownload(document)} className="view-documents-table__download">
                  <DownloadOutlined />
                  Download
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
