import { DateTime } from 'luxon';
import * as z from 'zod';

import { AllBrandedIds } from '../BrandedIds';

export const transformDateOrStringToDate = (value: string | Date) =>
  typeof value === 'string' ? convertStringToDate(value) : value;

export const convertStringToDate = (value: string) => {
  const date = new Date(value);
  // JS date will parse 1/1/50 as 1/1/1950 so adjusting accordingly
  if (date < new Date('1/1/1990')) {
    return DateTime.fromJSDate(date).plus({ years: 100 }).toJSDate();
  }
  return date;
};

export const zodDateOrString = z.date().or(z.string()).transform(transformDateOrStringToDate);

export function zodBrandedUuid<T extends AllBrandedIds>() {
  return z
    .string()
    .uuid()
    .transform((id) => id as T);
}

export function zodBrandedString<T extends AllBrandedIds>() {
  return z
    .string()
    .min(1)
    .transform((id) => id as T);
}

export const zodOptionalString = z
  .string()
  .nullable()
  .optional()
  .transform((item) => (item == null ? undefined : item));

export const trimAndStripString = (val: any) => {
  // Trim strings
  if (typeof val === 'string') {
    val = val.trim();
  }
  // Turn "empty" values into undefined
  if (val === null || val === '') {
    return undefined;
  }
  return val;
};

export const DueMonthDay = z.string().refine(
  (val) => {
    const result = DateTime.fromFormat(val, 'MM/d');
    const result2 = DateTime.fromFormat(val, 'M/d');
    return result.isValid || result2.isValid;
  },
  (val) => ({
    message: `${val} is not a valid month day`,
  }),
);

export type DueMonthDay = z.infer<typeof DueMonthDay>;

export const transformNullOrUndefinedToUndefined = (value: any | null | undefined) =>
  value === null ? undefined : value;

/**
 * This is a utility function that will attempt to the validate the input
 * type BEFORE calling `.parse` on it. It should help to catch simple syntax
 * errors so we can avoid runtime errors when possible. If you didn't want
 * to use this function to handle this kind of stuff you could also do:
 *
 * const CustomEvent = z.object({ type: z.literal('custom'), name: z.string() })
 * type TCustomEvent = z.infer<typeof CustomEvent>;
 *
 * DUMB PARSING STRATEGY:
 * CustomEvent.parse({ type: 'wow', name: 123 }); // This won't throw TS errors, BAD!
 *
 * SMARTER PARSING STRATEGY:
 * const myEvent: TCustomEvent = { type: 'wow', name: 123 }; // This will throw a TS error, GOOD!
 * CustomEvent.parse(myEvent);
 *
 * WITH `typedParse`
 * typedParse(CustomEvent, { type: 'wow', name: 123 }); // This will throw a TS error, GOOD!
 *
 */
export const typedParse = <TInput, TDef extends z.ZodTypeDef, TOutput>(
  schema: z.ZodType<TOutput, TDef, TInput>,
  input: TInput,
) => schema.parse(input);

/**
 * Like typedParse, but uses `.safeParse` under the hood
 */
export const typedSafeParse = <TInput, TDef extends z.ZodTypeDef, TOutput>(
  schema: z.ZodType<TOutput, TDef, TInput>,
  input: TInput,
) => schema.safeParse(input);
