import { z } from 'zod';

import { zodDateOrString } from '../utils';

export const PortfolioMetrics = z.object({
  totalAmountOutstandingBilled: z.number().nonnegative(),
  pastDueWithin30Days: z.number().nonnegative(),
  currentLoansAmountOutstanding: z.number().nonnegative(),
  pastDueLoansAmountOutstanding: z.number().nonnegative(),
  current: z.number().nonnegative(),
  pastDue30Days: z.number().nonnegative(),
  pastDue60Days: z.number().nonnegative(),
  pastDue90Days: z.number().nonnegative(),
});

export type PortfolioMetrics = z.infer<typeof PortfolioMetrics>;

export const BillingMetric = z.object({
  date: zodDateOrString,
  totalAmountOutstandingBilled: z.number().nonnegative(),
  totalAmountCollected: z.number().nonnegative(),
  totalAmountRemaining: z.number().nonnegative(),
  loanCount: z.number().nonnegative(),
});

export type BillingMetric = z.infer<typeof BillingMetric>;
