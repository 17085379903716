/*
    This component is responsible for defining the transitions between pages as the route changes
    (the wrapper needed for this to work is in App.tsx).

    See framer motion docs for available styling options

    Note: the function responsible for scrolling the page up on route transitions happens in App.tsx
*/

import { motion } from 'framer-motion';
import React from 'react';

import { NamedMemo } from '@willow/shared-web';

import './PageTransition.scss';

export interface Props {
  children: React.ReactNode;
}

export const PageTransition = NamedMemo<Props>('PageTransition', ({ children }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const transition = {
    duration: 0.2,
    ease: 'easeInOut',
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={transition}
      className="portal-page-transition-wrapper"
    >
      {children}
    </motion.div>
  );
});
