import { z } from 'zod';

import { DocumentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const DeleteDocumentEvent = BaseLoanEvent.extend({
  type: z.literal('deleteDocument'),
  documentId: zodBrandedUuid<DocumentId>(),
  documentTitle: z.string(),
});

export type DeleteDocumentEvent = z.infer<typeof DeleteDocumentEvent>;
