import { z } from 'zod';

import { DocumentId } from '../BrandedIds';
import { BorrowerAccessSaveInput } from '../loan/DocumentAccess';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateDocumentBorrowerAccessEvent = BaseLoanEvent.extend({
  type: z.literal('updateDocumentBorrowerAccess'),
  documentId: zodBrandedUuid<DocumentId>(),
  documentTitle: z.string(),
  originalBorrowerAccess: BorrowerAccessSaveInput,
  newBorrowerAccess: BorrowerAccessSaveInput,
});

export type UpdateDocumentBorrowerAccessEvent = z.infer<typeof UpdateDocumentBorrowerAccessEvent>;
