import { useContext } from 'react';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';

import { PortalSelectedLoan } from '../../App';
import { EmptyPortalUser, PortalUser } from '../../components/context/AppContexts';
import { NavSection } from '../../components/layout/nav-section/NavSection';
import { NavSectionItem } from '../../components/layout/nav-section/NavSectionItem';
import { NavSectionItemHeader } from '../../components/layout/nav-section/NavSectionItemHeader';
import { Metadata } from '../../components/metadata/Metadata';
import { AccountInformation } from '../../components/my-account/AccountInformation';
import { Disclosures } from '../../components/my-account/Disclosures/Disclosures';
import { NotificationSettings } from '../../components/my-account/NotificationSettings';
import { PageHeading } from '../../components/page-heading/PageHeading';
import { FeatureFlagContext } from '../../contexts/featureFlagContext';

export interface Props {
  loan: PortalSelectedLoan;
  user: PortalUser | EmptyPortalUser;
  company: CompanyFragment;
}

export const MyAccount = (props: Props) => {
  const { loan, user, company } = props;
  const { ff_econsentEnabled } = useContext(FeatureFlagContext);

  return (
    <div>
      <Metadata metadata={{ title: 'My Account', description: 'View and manage your account preferences' }} />

      <PageHeading title="My Account" subtitle="View and manage your account preferences" />

      <NavSection>
        <NavSectionItem key="information" label="Account Information">
          <NavSectionItemHeader title="Account Information" subtitle="Login and contact details" />
          <AccountInformation user={user} loan={loan} companyId={company.id} />
        </NavSectionItem>

        {ff_econsentEnabled && (
          <NavSectionItem key="notifications" label="Notification Preferences">
            <NavSectionItemHeader
              title="Notification Preferences"
              subtitle="Manage how you prefer to receive loan communications"
            />
            <NotificationSettings {...props} />
          </NavSectionItem>
        )}

        <NavSectionItem key="disclosures" label="Disclosures">
          <NavSectionItemHeader title="Disclosures" subtitle="View legal disclosures" />
          <Disclosures selectedCompany={loan.company} />
        </NavSectionItem>
      </NavSection>
    </div>
  );
};
