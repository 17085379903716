import { Link } from 'react-router-dom';

import { PPLink } from './Links';

export const TermsOfService = (url: string) => ({
  searchKey: '?tos',
  title: 'Terms of Service',
  lastModified: 'February 3, 2022',
  content: <Content url={url} />,
});

const Content = ({ url }: { url: string }) => (
  <div>
    <p>
      Welcome to Willow Servicing. Please read on to learn the rules and restrictions that govern your use of our
      website(s), products, services and applications (the “Services”). If you have any questions, comments, or concerns
      regarding these terms or the Services, please contact us at:
    </p>

    <p>
      Email:{' '}
      <a href="mailto:support@willowservicing.com?subject=Terms Inquiry" target="_self">
        support@willowservicing.com
      </a>
    </p>

    <p>
      These Terms of Use (the “Terms”) are a binding contract between you and <b>LMCA, INC. DBA WILLOW SERVICING</b>{' '}
      (“Willow Servicing,” “we” and “us”). Your use of the Services in any way means that you agree to all of these
      Terms, and these Terms will remain in effect while you use the Services. These Terms include the provisions in
      this document as well as those in the <PPLink url={url}>Privacy Policy</PPLink>.{' '}
      <b>
        Your use of or participation in certain Services may also be subject to additional policies, rules and/or
        conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that
        by using or participating in any such Services, you agree to also comply with these Additional Terms.&nbsp;
      </b>
    </p>

    <p>
      <b>Please read these Terms carefully.</b>They cover important information about Services provided to you.{' '}
      <b>These Terms include information about</b>{' '}
      <Link to={{ pathname: url, search: 'tos' }} target="_blank" rel="noreferrer">
        future changes to these Terms,
      </Link>{' '}
      <Link to={{ pathname: url, search: 'tos' }} target="_blank" rel="noreferrer">
        limitations of liability
      </Link>
      ,{' '}
      <Link to={{ pathname: url, search: 'tos' }} target="_blank" rel="noreferrer">
        a class action waiver and resolution of disputes by arbitration instead of in court
      </Link>
      .{' '}
      <b>
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE
        TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
      </b>
    </p>

    <p>
      <b>ARBITRATION NOTICE AND CLASS ACTION WAIVER:</b>EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE{' '}
      <Link to={{ pathname: url, search: 'tos' }} target="_blank" rel="noreferrer">
        ARBITRATION AGREEMENT SECTION BELOW
      </Link>
      , YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE
      YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
    </p>

    <p>
      If you are or are a representative of a mortgage lender (“Lender”) and your company has executed an agreement with
      Willow Servicing where by Willow Servicing provides services to your company, your company’s use of the Services
      is governed by the Software-as-a-Service Agreement and any related terms thereunder.
    </p>

    <h1>Will these Terms ever change?</h1>

    <p>
      We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We
      reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at{' '}
      <a href="https://www.willowservicing.com" target="_blank" rel="noreferrer">
        www.willowservicing.com
      </a>
      , send you an email, and/or notify you by some other means.
    </p>

    <p>
      If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer
      be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that
      means you agree to all of the changes.
    </p>

    <p>
      Except for changes by us as described here, no other amendment or modification of these Terms will be effective
      unless in writing and signed by both you and us.
    </p>

    <h1>What about my privacy?</h1>

    <p>
      Willow Servicing takes the privacy of its users very seriously. For the current Willow Servicing Privacy Policy,
      please click <PPLink url={url}>here</PPLink>.
    </p>

    <h2>
      <u>Children’s Online Privacy Protection Act</u>
    </h2>

    <p>
      The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
      consent before they knowingly collect personally identifiable information online from children who are under 13
      years of age. We do not knowingly collect or solicit personally identifiable information from children under 13
      years of age; if you are a child under 13 years of age, please do not attempt to register for or otherwise use the
      Services or send us any personal information. If we learn we have collected personal information from a child
      under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under
      13 years of age may have provided us personal information, please contact us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Terms Inquiry" target="_self">
        support@willowservicing.com
      </a>
      .
    </p>

    <h1>What are the basics of using Willow Servicing?</h1>

    <p>
      To access your account, you may be required to provide your email address, phone number, select a password, and
      provide us with certain information or data, such as your contact information. You promise to provide us with
      accurate, complete, and updated registration information about yourself. You may not use an email address that you
      do not have the right to use, or another person’s name with the intent to impersonate that person. You may not
      transfer your account to anyone else without our prior written permission.
    </p>

    <p>
      In some cases, Lenders may require or implement their own additional requirements on users in connection with
      mortgage or escrow payments facilitated through the Services. Such requirements are imposed solely by the Lenders
      and are the exclusive and sole responsibility of the Lenders imposing them, these requirements are wholly
      unrelated to any Additional Terms requirements. Willow Servicing shall have no responsibility for such
      requirements imposed by Lenders.
    </p>

    <p>
      IN THE EVENT THAT YOU HAVE A DISPUTE WITH A LENDER, YOU RELEASE WILLOW SERVICING FROM ANY CLAIMS, DEMANDS, AND
      DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED,
      DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
    </p>

    <p>
      WILLOW SERVICING IS NOT A LICENSED FINANCIAL ADVISORY FIRM, AND IS NOT ACTING AS YOUR FINANCIAL OR TAX ADVISORS.
      THE SERVICES ARE NOT, AND SHOULD NEVER BE, A SUBSTITUTE FOR THE ADVICE OF A LICENSED FINANCIAL ADVISOR OR TAX
      ADVISOR.&nbsp; WILLOW SERVICING CANNOT AND DOES NOT PROVIDE FINANCIAL OR TAX ADVICE. WILLOW SERVICING IS
      PROHIBITED FROM PROVIDING ANY KIND OF ADVICE, EXPLANATION, OPINION OR RECOMMENDATION TO YOU ABOUT ANY KIND OF
      ADVICE, EXPLANATION, OPINION OR RECOMMENDATION ABOUT POSSIBLE FINANCIAL INVESTMENTS OR STRATEGIES. WILLOW
      SERVICING DOES NOT PROVIDE TAX ADVICE AND NOTHING ON THE SITE SHOULD BE CONSTRUED AS TAX ADVICE.
    </p>

    <h1>What about messaging?</h1>

    <p>
      As part of the Services, you may receive communications through the Services, including messages that Willow
      Servicing sends you (for example, via email or SMS). When signing up for the Services, you will receive a welcome
      message and instructions on how to stop receiving messages.{' '}
      <b>
        By signing up for the Services and providing us with your wireless number, you confirm that you want Willow
        Servicing to send you information regarding your account or transactions with us, which may include Willow
        Servicing using automated dialing technology to text you at the wireless number you provided, and you agree to
        receive communications from Willow Servicing, and you represent and warrant that each person you register for
        the Services or for whom you provide a wireless phone number has consented to receive communications from Willow
        Servicing.
      </b>{' '}
      You agree to indemnify and hold Willow Servicing harmless from and against any and all claims, liabilities,
      damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way
      related to your breach of the foregoing.
    </p>

    <h1>Are there restrictions in how I can use the Services?</h1>

    <p>
      You represent, warrant, and agree that you will not provide or contribute anything, including any Content (as that
      term is defined below), to the Services, or otherwise use or interact with the Services, in a manner that:
    </p>

    <ol>
      <li>
        <p>
          infringes or violates the intellectual property rights or any other rights of anyone else (including Willow
          Servicing);
        </p>
      </li>
      <li>
        <p>
          violates any law or regulation, including, without limitation, any applicable export control laws, privacy
          laws or any other purpose not reasonably intended by Willow Servicing;
        </p>
      </li>
      <li>
        <p>
          is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
          objectionable;
        </p>
      </li>
      <li>
        <p>
          jeopardizes the security of your Willow Servicing account or anyone else’s (such as allowing someone else to
          log in to the Services as you);
        </p>
      </li>
      <li>
        <p>
          attempts, in any manner, to obtain the password, account, or other security information from any other user;
        </p>
      </li>
      <li>
        <p>violates the security of any computer network, or cracks any passwords or security encryption codes;</p>
      </li>
      <li>
        <p>
          runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or
          are activated while you are not logged into the Services, or that otherwise interfere with the proper working
          of the Services (including by placing an unreasonable load on the Services’ infrastructure);
        </p>
      </li>
      <li>
        <p>
          “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content
          (through use of manual or automated means);
        </p>
      </li>
      <li>
        <p>copies or stores any significant portion of the Content; or</p>
      </li>
      <li>
        <p>
          decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
          information of or relating to the Services.
        </p>
      </li>
    </ol>

    <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>

    <h1>What are my rights in the Services?</h1>

    <p>
      The materials displayed or performed or available on or through the Services, including, but not limited to, text,
      graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the “Content”) are
      protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices,
      trademark rules, information, and restrictions contained in any Content you access through the Services, and you
      won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display,
      license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the
      prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Willow
      Servicing's) rights.
    </p>

    <p>
      Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and
      non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the
      Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using
      the Services is expressly prohibited without prior written permission from us. You understand that Willow
      Servicing owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of,
      reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit
      any of the Services. The Services may allow you to copy or download certain Content, but please remember that even
      where these functionalities exist, all the restrictions in this section still apply.
    </p>

    <h1>Who is responsible for what I see and do on the Services?</h1>

    <p>
      Any information or Content publicly posted or privately transmitted through the Services is the sole
      responsibility of the person from whom such Content originated, and you access all such information and Content at
      your own risk, and we aren’t liable for any errors or omissions in that information or Content or for any damages
      or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding
      how you may interpret and use the Content or what actions you may take as a result of having been exposed to the
      Content, and you hereby release us from all liability for you having acquired or not acquired Content through the
      Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not
      responsible for which users gain access to the Services.
    </p>

    <p>
      You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant
      you have all rights necessary to do so, in the manner in which you contribute it. Willow Servicing is not
      responsible for any information supplied by users or Lenders, and is not responsible for any decisions made by
      Lenders, whether or not based on such information.
    </p>

    <p>
      The Services may contain links or connections to third-party websites or services that are not owned or controlled
      by Willow Servicing. When you access third-party websites or use third-party services, you accept that there are
      risks in doing so, and that Willow Servicing is not responsible for such risks.
    </p>

    <p>
      Willow Servicing has no control over, and assumes no responsibility for, the content, accuracy, privacy policies,
      or practices of or opinions expressed in any third-party websites or by any third party that you interact with
      through the Services. In addition, Willow Servicing will not and cannot monitor, verify, censor or edit the
      content of any third-party site or service. We encourage you to be aware when you leave the Services and to read
      the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By
      using the Services, you release and hold us harmless from any and all liability arising from your use of any
      third-party website or service.
    </p>

    <p>
      Your interactions with organizations and/or individuals found on or through the Services, including payment and
      delivery of goods or services, and any other terms, conditions, warranties or representations associated with such
      dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation
      you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third
      parties. You agree that Willow Servicing shall not be responsible or liable for any loss or damage of any sort
      incurred as the result of any such dealings.
    </p>

    <p>
      If there is a dispute between participants on this site or Services, or between users and any third party, you
      agree that Willow Servicing is under no obligation to become involved. In the event that you have a dispute with
      one or more other users, you release Willow Servicing, its directors, officers, employees, agents, and successors
      from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed
      or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do
      waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A
      general release does not extend to claims that the creditor or releasing party does not know or suspect to exist
      in his or her favor at the time of executing the release and that, if known by him or her, would have materially
      affected his or her settlement with the debtor or released party.”
    </p>

    <h1>Will Willow Servicing ever change the Services?</h1>

    <p>
      We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part
      of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts
      or all of the Services. We’ll try to give you notice when we make a material change to the Services that would
      adversely affect you, but this isn’t always practical. We reserve the right to remove any Content from the
      Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that
      Content in violation of these Terms), in our sole discretion, and without notice.
    </p>

    <h1>Do the Services cost anything?</h1>

    <p>
      The Services are currently free, but we reserve the right to charge for certain or all Services in the future. We
      will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using
      such Services, you must pay all applicable fees for such Services.
    </p>

    <p>
      Willow Servicing provides online payment solution services for its Lender clients. The Services provided by Willow
      Servicing on behalf of the Lender to facilitate your payment obligations to the Lender are different than, and
      separate from, the services provided to you by the Lender directly. You acknowledge and understand that Willow
      Servicing acts as the limited agent of the Lender for the purpose of accepting payments from you on behalf of the
      Lender. Willow Servicing is not a mortgage lender or related entity. You further acknowledge and agree that Willow
      Servicing is not a party to any agreement between you and the Lender. Willow Servicing does not have control of,
      or liability for, the services that are provided by the Lender. If the Lender terminates its agreement with us,
      you will no longer be able to use the Services.
    </p>

    <p>
      You may have the option to make a payment to a merchant with an electric check from your bank account (Automated
      Clearing House (“ACH”) or e-check). The Lender can choose to offer you options for one-time payments and/or
      automatic recurring payments. Willow Servicing will facilitate your transaction according to the information you
      provide on the date you authorize Willow Servicing to process the transaction, and your payment will be credited
      to the Lender.
    </p>

    <p>
      You hereby authorize Willow Servicing to debit your account from your bank account (via ACH) for any of the
      transactions you submit, and you agree that your submission of a transaction constitutes your authorization to
      Willow Servicing to debit your account. For accounts that have been set up with the fixed recurring payment option
      (“Autopay”), Willow Servicing will debit your designated account for the authorized payment amount on the
      designated day each month until the Autopay is cancelled.
    </p>

    <h1>What if I want to stop using the Services?</h1>

    <p>
      You’re free to do that at any time by contacting us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Terms Inquiry" target="_self">
        support@willowservicing.com
      </a>
      ; please refer to our <PPLink url={url}>Privacy Policy</PPLink>, as well as the licenses above, to understand how
      we treat information you provide to us after you have stopped using our Services.
    </p>

    <p>
      Willow Servicing is also free to terminate (or suspend access to) your use of the Services or your account for any
      reason in our discretion, including your breach of these Terms. Willow Servicing has the sole right to decide
      whether you are in violation of any of the restrictions set forth in these Terms.
    </p>

    <p>
      Account termination may result in destruction of any Content associated with your account, so keep that in mind
      before you decide to terminate your account.&nbsp;
    </p>

    <p>
      If you have deleted your account by mistake, contact us immediately at{' '}
      <a href="mailto:support@willowservicing.com?subject=Terms Inquiry" target="_self">
        support@willowservicing.com
      </a>{' '}
      – we will try to help, but unfortunately, we can’t promise that we can recover or restore anything.
    </p>

    <p>
      Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of
      example, all of the following will survive termination: any obligation you have to indemnify us, any limitations
      on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes
      between us, including without limitation the arbitration agreement.&nbsp;
    </p>

    <h1>What else do I need to know?</h1>

    <p>
      <b>Warranty Disclaimer.</b> Willow Servicing and its licensors, suppliers, partners, parent, subsidiaries or
      affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract
      employees, representatives and agents, and each of their respective successors and assigns (Willow Servicing and
      all such parties together, the “Willow Servicing Parties”) make no representations or warranties concerning the
      Services, including without limitation regarding any Content contained in or accessed through the Services, and
      the Willow Servicing Parties will not be responsible or liable for the accuracy, copyright compliance, legality,
      or decency of material contained in or accessed through the Services or any claims, actions, suits procedures,
      costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the
      Services. The Willow Servicing Parties make no representations or warranties regarding suggestions or
      recommendations of services or products offered or purchased through or in connection with the Services. THE
      SERVICES AND CONTENT ARE PROVIDED BY WILLOW SERVICING (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS,
      WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
      UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
      ABOVE LIMITATIONS MAY NOT APPLY TO YOU.&nbsp;
    </p>

    <p>
      <b>Limitation of Liability.</b> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER
      NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE
      WILLOW SERVICING PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL,
      PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF
      DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
      GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D)
      ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
      CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
    </p>

    <p>
      <b>Indemnity.</b> You agree to indemnify and hold the Willow Servicing Parties harmless from and against any and
      all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees)
      arising from or in any way related to any claims relating to (a) your use of the Services (including any actions
      taken by a third party using your account), and (b) your violation of these Terms. In the event of such a claim,
      suit, or action (“Claim”), we will attempt to provide notice of the Claim to the contact information we have for
      your account (provided that failure to deliver such notice shall not eliminate or reduce your indemnification
      obligations hereunder).
    </p>

    <p>
      <b>Assignment.</b>You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or
      your Services account, in any way (by operation of law or otherwise) without Willow Servicing's prior written
      consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.
    </p>

    <p>
      <b>Choice of Law.</b> These Terms are governed by and will be construed under the Federal Arbitration Act,
      applicable federal law, and the laws of the State of California, without regard to the conflicts of laws
      provisions thereof.
    </p>

    <p>
      <b>Arbitration Agreement.</b> Please read the following ARBITRATION AGREEMENT carefully because it requires you to
      arbitrate certain disputes and claims with Willow Servicing and limits the manner in which you can seek relief
      from Willow Servicing. Both you and Willow Servicing acknowledge and agree that for the purposes of any dispute
      arising out of or relating to the subject matter of these Terms, Willow Servicing's officers, directors, employees
      and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your
      acceptance of these Terms, Personnel will have the right (and will be deemed to have accepted the right) to
      enforce these Terms against you as the third-party beneficiary hereof.
    </p>

    <p>
      (a) <i>Arbitration Rules; Applicability of Arbitration Agreement. </i>The parties shall use their best efforts to
      settle any dispute, claim, question, or disagreement arising out of or relating to the subject matter of these
      Terms directly through good-faith negotiations, which shall be a precondition to either party initiating
      arbitration. If such negotiations do not resolve the dispute, it shall be finally settled by binding arbitration
      in Alameda County, California. The arbitration will proceed in the English language, in accordance with the JAMS
      Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with
      substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator shall
      be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award
      rendered by such arbitrator may be entered in any court of competent jurisdiction.&nbsp;
    </p>

    <p>
      (b) <i>Costs of Arbitration. </i>The Rules will govern payment of all arbitration fees. Willow Servicing will pay
      all arbitration fees for claims less than seventy-five thousand ($75,000) dollars. Willow Servicing will not seek
      its attorneys’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous.&nbsp;
    </p>

    <p>
      (c) <i>Small Claims Court; Infringement</i>. Either you or Willow Servicing may assert claims, if they qualify, in
      small claims court in Alameda County, California or any United States county where you live or work. Furthermore,
      notwithstanding the foregoing obligation to arbitrate disputes, each party shall have the right to pursue
      injunctive or other equitable relief at any time, from any court of competent jurisdiction, to prevent the actual
      or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets,
      patents or other intellectual property rights.
    </p>

    <p>
      (d) <i>Waiver of Jury Trial</i>. YOU AND WILLOW SERVICING WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO
      COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR JURY. You and Willow Servicing are instead choosing to have claims
      and disputes resolved by arbitration. Arbitration procedures are typically more limited, more efficient, and less
      costly than rules applicable in court and are subject to very limited review by a court. In any litigation between
      you and Willow Servicing over whether to vacate or enforce an arbitration award, YOU AND WILLOW SERVICING WAIVE
      ALL RIGHTS TO A JURY TRIAL, and elect instead to have the dispute be resolved by a judge.
    </p>

    <p>
      (e) <i>Waiver of Class or Consolidated Actions.</i> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
      AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE
      CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
      USER. If however, this waiver of class or consolidated actions is deemed invalid or unenforceable, neither you nor
      Willow Servicing is entitled to arbitration; instead all claims and disputes will be resolved in a court as set
      forth in (g) below.
    </p>

    <p>
      (f) <i>Opt-out</i>. You have the right to opt out of the provisions of this Section by sending written notice of
      your decision to opt out to the following address: 3161 College Ave. Berkeley, CA 94705 postmarked within thirty
      (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii) the email
      address and/or telephone number associated with your account, and (iii) a clear statement that you want to opt out
      of these Terms’ arbitration agreement.
    </p>

    <p>
      (g) <i>Exclusive Venue</i>. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing
      arbitration agreement permits either you or Willow Servicing to litigate any dispute arising out of or relating to
      the subject matter of these Terms in court, then the foregoing arbitration agreement will not apply to either
      party, and both you and Willow Servicing agree that any judicial proceeding (other than small claims actions) will
      be brought in the state or federal courts located in, respectively, Alameda County, California, or the federal
      district in which that county falls.
    </p>

    <p>
      (h) <i>Severability</i>. If the prohibition against class actions and other claims brought on behalf of third
      parties contained above is found to be unenforceable, then all of the preceding language in this Arbitration
      Agreement section will be null and void. This arbitration agreement will survive the termination of your
      relationship with Willow Servicing.
    </p>

    <p>
      <u>Miscellaneous</u>. You will be responsible for paying, withholding, filing, and reporting all taxes, duties,
      and other governmental assessments associated with your activity in connection with the Services, provided that
      the Willow Servicing may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees
      fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any
      further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision
      will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full
      force and effect and enforceable. You and Willow Servicing agree that these Terms are the complete and exclusive
      statement of the mutual understanding between you and Willow Servicing, and that these Terms supersede and cancel
      all previous written and oral agreements, communications and other understandings relating to the subject matter
      of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of
      Willow Servicing, and you do not have any authority of any kind to bind Willow Servicing in any respect
      whatsoever.
    </p>

    <p>
      Except as expressly set forth in the section above regarding the arbitration agreement, you and Willow Servicing
      agree there are no third-party beneficiaries intended under these Terms.
    </p>
  </div>
);
