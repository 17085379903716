import { LoanStatus } from '@willow/graphql-iso/src/portal';
import { assertNever } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { isTransferredView } from '../../utils/loanView';

type LoanStatusView = 'preActive' | 'paidOff' | 'transferred' | 'archived';
type PageViewType = LoanStatusView | 'maturing' | 'pausedPayments' | 'payment';

export const getPageViewType = (loan: PortalSelectedLoan): PageViewType => {
  const {
    company: { paymentsPaused: companyPaymentsPaused },
    currentSnapshot: {
      loanStatus,
      stopSettings: { portalPayments },
      maturityConfig: { isMaturing },
      totalAmountRemainingFromBorrower,
    },
  } = loan;

  // Loan status-specific views
  switch (loanStatus) {
    case LoanStatus.Active:
    case LoanStatus.Foreclosure:
      break;
    case LoanStatus.Archived:
      return 'archived';
    case LoanStatus.PaidOff:
      return 'paidOff';
    case LoanStatus.PendingActivation:
    case LoanStatus.PreActive:
      return 'preActive';
    case LoanStatus.PendingTransfer:
    case LoanStatus.Transferred:
      if (isTransferredView(loan)) {
        return 'transferred';
      }
      break;
    default:
      assertNever(loanStatus);
  }

  // Paused payments
  if (companyPaymentsPaused || portalPayments === 'none') {
    return 'pausedPayments';
  }

  // Maturing loan
  if (isMaturing && totalAmountRemainingFromBorrower === 0) {
    return 'maturing';
  }

  return 'payment';
};
