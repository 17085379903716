import { isArray, mapValues, mergeWith, uniq } from 'lodash';
import * as z from 'zod';

import { trimAndStrip } from '@willow/types-iso';

import { Sanitizer } from './sanitizeRequest';

export interface ValidatorReturnType<T> {
  data: T;
  errors?: z.typeToFlattenedError<T>;
  warnings?: z.typeToFlattenedError<T>;
}

// Updated this to follow Zod's guide to writing generic validator
// functions, see:
// https://zod.dev/?id=writing-generic-functions
export const makeValidator =
  <TOutput, TDef extends z.ZodTypeDef, TInput>({
    FieldParser,
    CrossFieldParser,
    WarningParser,
  }: {
    FieldParser: z.ZodType<TOutput, TDef, TInput>;
    CrossFieldParser?: z.ZodTypeAny;
    WarningParser?: z.ZodTypeAny;
  }) =>
  (data: any, errorMap?: z.ZodErrorMap): ValidatorReturnType<TOutput> => {
    // Collect errors as we go through each parser
    const errors: z.ZodError<any>[] = [];
    const warnings: z.ZodError<any>[] = [];

    /* 1. PREPROCESS ALL VALUES
     * Clean up the data to get it ready to parse.
     * Trim whitespaces, check for illegal characters.
     */
    const sanitizedObject = Sanitizer.safeParse(data, { errorMap });
    if (!sanitizedObject.success) {
      errors.push(sanitizedObject.error);
      // Trim the data since the sanitizer was unable to
      data = mapValues(data, trimAndStrip);
    } else {
      data = sanitizedObject.data; // Set data to newest parsed data for next step
    }

    /* 2. PARSE INDIVIDUAL FIELDS
     * Now that the data has been generically parsed,
     * validate it against our specific object type.
     */
    const parsedFields = FieldParser.safeParse(data, { errorMap });

    if (!parsedFields.success) {
      errors.push(parsedFields.error);
    } else {
      data = parsedFields.data;
    }

    /* 3. RUN CROSS-FIELD VALIDATION
     * Now that the individual fields have been cleaned up,
     * Make sure all necessary fields are present.
     * ie, if `foo = true`, make sure `bar = true`
     */
    if (CrossFieldParser) {
      const parsedObject = CrossFieldParser.safeParse(data, { errorMap });
      if (!parsedObject.success) {
        errors.push(parsedObject.error);
      } else {
        data = parsedObject.data;
      }
    }

    /* 4. RUN WARNINGS
     * Warnings don't prevent the data from being uploaded.
     */
    if (WarningParser) {
      const parsedWarnings = WarningParser.safeParse(data, { errorMap });
      if (!parsedWarnings.success) {
        warnings.push(parsedWarnings.error);
      } else {
        data = parsedWarnings.data;
      }
    }

    const mergeCustomizer = (
      objValue: z.typeToFlattenedError<TOutput, string>,
      srcValue: z.typeToFlattenedError<TOutput, string>,
    ) => {
      if (isArray(objValue)) {
        return uniq(objValue.concat(srcValue));
      }
    };

    // Return collected data, errors, and warnings
    return {
      data,
      errors: errors.length
        ? errors.reduce<z.typeToFlattenedError<TOutput, string>>(
            (allErrors, theseErrors) => mergeWith(allErrors, theseErrors.flatten(), mergeCustomizer),
            { formErrors: [], fieldErrors: {} },
          )
        : undefined,
      warnings: warnings.length
        ? warnings.reduce<z.typeToFlattenedError<TOutput, string>>(
            (allWarnings, theseWarnings) => mergeWith(allWarnings, theseWarnings.flatten(), mergeCustomizer),
            { formErrors: [], fieldErrors: {} },
          )
        : undefined,
    };
  };
