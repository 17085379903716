import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import { fileTypeFromStream } from 'file-type';
import { produce } from 'immer';
import { compact } from 'lodash';
import { Accept, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { getFileBasename, MAX_BORROWER_UPLOAD_FILE_SIZE } from '@willow/shared-iso';

import { Form } from '../../../../bootstrap';
import { useZodHookForm } from '../../../hooks';
import { Button } from '../../Button/Button';
import { Modal } from '../../Modal/Modal';
import { NamedMemo } from '../../NamedMemo';
import { DragDropUploader } from './DragDropUploader';

const FileUpload = z.object({
  file: z.instanceof(File),
  name: z.string(),
});
type TFileUpload = z.infer<typeof FileUpload>;

const FORM_SHAPE = z.object({
  files: z.array(FileUpload).min(1, { message: 'Select document to upload' }),
});
export type DocumentUploadFormShape = z.infer<typeof FORM_SHAPE>;

export interface Props {
  modalSubtitle: string;
  fileAccept: Accept;
  isSubmitting: boolean;
  onSubmit: (data: DocumentUploadFormShape) => void;
  onCloseModal: () => void;
}

// Future: move lender side to this modal + add optional borrower sharing
export const UploadDocumentModal = NamedMemo<Props>(
  'UploadDocumentModal',
  ({ modalSubtitle, fileAccept, isSubmitting, onSubmit, onCloseModal }) => {
    const {
      watch,
      setValue,
      handleSubmit,
      formState: { errors },
    } = useZodHookForm(FORM_SHAPE, {
      defaultValues: {
        files: [],
      },
    });
    const files = watch('files');

    const isFileValid = async (file: File) => {
      const fileType = await fileTypeFromStream(file.stream());
      if (fileType) {
        const match = Object.keys(fileAccept).find((allowedMimeType) => {
          const mimeTypeRegex = new RegExp(allowedMimeType);
          return mimeTypeRegex.test(fileType.mime);
        });

        if (!match) {
          return `Unsupported file type for ${file.name}`;
        }
      }
    };

    const handleOnSubmit = handleSubmit(async (data) => {
      onSubmit(data);
    });

    const dropzone = useDropzone({
      accept: fileAccept,
      multiple: true,
      maxSize: MAX_BORROWER_UPLOAD_FILE_SIZE, // 5mb limit (note: bucketAV has 2gb limit)
      async onDropAccepted(acceptedFiles) {
        const newFiles: TFileUpload[] = compact(
          await Promise.all(
            acceptedFiles.map(async (file) => {
              const error = await isFileValid(file);
              if (error) {
                toast.error(error);
                return null;
              }

              return {
                file,
                name: getFileBasename(file.name),
              };
            }),
          ),
        );

        setValue('files', [...files, ...newFiles]);
      },
      onDropRejected(rejectedFiles) {
        for (const file of rejectedFiles) {
          toast.error(`Error uploading ${file.file.name}. ${file.errors.flatMap((e) => e.message)}`);
        }
      },
    });

    const updateFileName = (file: File, name: string) => {
      const index = files.findIndex((f) => f.file === file);

      setValue(
        'files',
        produce(files, (draft) => {
          draft[index].name = name;
        }),
      );
    };

    const removeFile = (file: File) => {
      setValue(
        'files',
        files.filter((f) => f.file !== file),
      );
    };

    return (
      <Modal
        showModal
        onClose={onCloseModal}
        header={
          <div>
            <h2>Upload document</h2>
            <div className="mt-2">{modalSubtitle}</div>
          </div>
        }
        showLoader={isSubmitting}
      >
        <Form onSubmit={handleOnSubmit}>
          <DragDropUploader dropzone={dropzone} fileCount={files.length} />

          <div>
            {files.map((file) => (
              <div key={file.file.name} className="py-2">
                <div className="d-flex align-items-center gap-2">
                  <PaperClipOutlined />
                  <Form.Control
                    type="text"
                    value={file.name}
                    placeholder={file.file.name}
                    onChange={(e) => updateFileName(file.file, e.target.value)}
                  />
                  <button className="u-unset d-flex" onClick={() => removeFile(file.file)}>
                    <CloseOutlined />
                  </button>
                </div>
              </div>
            ))}
          </div>

          {files.length > 0 && (
            <div className="mt-2 ms-4 d-flex justify-content-start">
              <Button size="sm" variant="tertiary" onClick={() => dropzone.open()}>
                + additional files
              </Button>
            </div>
          )}

          <ErrorMessage
            errors={errors}
            name="files"
            render={({ message }) => <div className="mt-3 u-color-red1">{message}</div>}
          />

          <div className="mt-4 d-flex gap-2 justify-content-end">
            <Button size="sm" variant="secondary" disabled={isSubmitting} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button size="sm" type="submit" disabled={isSubmitting}>
              Confirm Upload
            </Button>
          </div>
        </Form>
      </Modal>
    );
  },
);
