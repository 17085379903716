import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UsioTransactionBatchedEvent = BaseLoanEvent.extend({
  type: z.literal('usioTransactionBatched'),
  paymentId: zodBrandedUuid<PaymentId>(),
  timestamp: zodDateOrString,
  batch: z.string().optional(),
});
export type UsioTransactionBatchedEvent = z.infer<typeof UsioTransactionBatchedEvent>;
