import { z } from 'zod';

import { BillSettingType } from '../loan/BillSettings';
import { zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateBillSettingsEvent = BaseLoanEvent.extend({
  type: z.literal('updateBillSettings'),
  billSettingType: BillSettingType,
  startDate: zodDateOrString,
  comment: z.string().optional(),
});

export type UpdateBillSettingsEvent = z.infer<typeof UpdateBillSettingsEvent>;
