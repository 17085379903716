import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateInCareOfStatementPdfSideEffectContext = BaseLoanIdSideEffect.extend({
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  careOfType: z.literal('builder'), // could expand in the future
  disableEmail: z.boolean().optional(),
});
export const GenerateInCareOfStatementPdfSideEffect = GenerateInCareOfStatementPdfSideEffectContext.extend({
  type: z.literal('generateInCareOfStatementPDF'),
});
export type GenerateInCareOfStatementPdfSideEffect = z.infer<typeof GenerateInCareOfStatementPdfSideEffect>;
