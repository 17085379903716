import { z } from 'zod';

import { StateAbbreviation } from '../validations/fields';

export const DefaultAllStates = 'ALL_STATES';
export const MonthlyStatementFooterCustomization = z.object({
  state: z.union([z.literal(DefaultAllStates), StateAbbreviation]),
  customizedCopy: z.string(),
});
export type MonthlyStatementFooterCustomization = z.infer<typeof MonthlyStatementFooterCustomization>;

export const MonthlyStatementCustomization = z.object({
  footer: z.array(MonthlyStatementFooterCustomization),
});
export type MonthlyStatementCustomization = z.infer<typeof MonthlyStatementCustomization>;

export const DocumentSettings = z
  .object({
    monthlyStatement: z
      .object({
        footer: z.array(MonthlyStatementFooterCustomization),
      })
      .optional(),
  })
  .optional();
export type DocumentSettings = z.infer<typeof DocumentSettings>;
