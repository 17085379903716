import { z } from 'zod';

import { Disbursement } from '../loan/Disbursement';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateDisbursementEvent = BaseLoanEvent.extend({
  type: z.literal('createDisbursement'),
  disbursement: Disbursement.omit({ id: true }),
  comment: z.string().optional(),
  updateNextPaymentAmount: z.boolean().optional(),
});
export type CreateDisbursementEvent = z.infer<typeof CreateDisbursementEvent>;
