import { z } from 'zod';

import { DisbursementId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ReverseDisbursementEvent = BaseLoanEvent.extend({
  type: z.literal('reverseDisbursement'),
  disbursementId: zodBrandedUuid<DisbursementId>(),
  comment: z.string().optional(),
  todayDate: z.date().optional(),
});
export type ReverseDisbursementEvent = z.infer<typeof ReverseDisbursementEvent>;
