import * as z from 'zod';

import { PositiveFloat, PositivePercentage } from '../../fields';

export const RemittanceFields = z.object({
  investorRemittanceRate: PositiveFloat.optional(),
  investor2RemittanceRate: PositiveFloat.optional(),
  servicerRemittanceRate: PositiveFloat.optional(),
  investorOwnershipPercent: PositivePercentage.optional(),
  investor2OwnershipPercent: PositivePercentage.optional(),
});
