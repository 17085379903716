import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';

import { PaymentConfigId } from '../BrandedIds';
import { type PaymentConfig } from '../loan/PaymentConfig';

const DEFAULT_DATE = DateTime.now().minus({ months: 1 }).startOf('month').toJSDate();

export const paymentConfig = (overrides?: Partial<PaymentConfig>): PaymentConfig => ({
  id: faker.string.uuid() as PaymentConfigId,
  source: 'initial',
  createdAt: DEFAULT_DATE,
  effectiveDate: DEFAULT_DATE,
  type: 'fixed',
  isInterestOnly: false,
  monthlyPrincipalAndInterestTotal: faker.number.float({ precision: 0.01, min: 200, max: 2000 }),
  monthlyInterestRate: faker.number.float({ precision: 0.001, min: 0.01, max: 0.11 }),
  monthlyMortgageInsurancePayment: faker.number.float({ precision: 0.01, min: 100, max: 300 }),
  ...overrides,
});
