import { z } from 'zod';

import { EmailTemplateType } from '../emails/Templates';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateSentEmailEvent = BaseLoanEvent.extend({
  type: z.literal('updateSentEmail'),
  emailTemplate: EmailTemplateType,
});

export type UpdateSentEmailEvent = z.infer<typeof UpdateSentEmailEvent>;
