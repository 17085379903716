import { z } from 'zod';

import { FeeId } from '../BrandedIds';
import { Payment } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateFeePaymentEvent = BaseLoanEvent.extend({
  type: z.literal('createFeePayment'),
  payment: Payment,
  feeId: zodBrandedUuid<FeeId>(),
  disableEmail: z.boolean().optional(),
});
export type CreateFeePaymentEvent = z.infer<typeof CreateFeePaymentEvent>;
