import { AppPaymentBreakdownFragment, FeeBreakdown, StatementBreakdown } from '@willow/graphql-iso/src/app';
import { PortalPaymentBreakdownFragment } from '@willow/graphql-iso/src/portal';

export function isGqlStatementBreakdown(
  item: AppPaymentBreakdownFragment | PortalPaymentBreakdownFragment,
): item is StatementBreakdown {
  return item.__typename === 'StatementBreakdown';
}
export function isGqlFeeBreakdown(
  item: AppPaymentBreakdownFragment | PortalPaymentBreakdownFragment,
): item is FeeBreakdown {
  return item.__typename === 'FeeBreakdown';
}
