import { TOSLink } from './Links';

export const PrivacyPolicy = (url: string) => ({
  searchKey: '?privacy',
  title: 'Privacy Policy',
  lastModified: 'March 4, 2022',
  content: <Content url={url} />,
});

const Content = ({ url }: { url: string }) => (
  <div>
    <p>
      At Willow Servicing, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your
      personal data.{' '}
      <b>
        By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies
        outlined below, and you hereby consent that we will collect, use and share your information as described in this
        Privacy Policy.
      </b>
    </p>
    <p>
      Remember that your use of Willow Servicing's Services is at all times subject to our{' '}
      <TOSLink url={url}> Terms of Use</TOSLink>, which incorporates this Privacy Policy. Any terms we use in this
      Policy without defining them have the definitions given to them in the Terms of Use.
    </p>
    <p>
      <b>THIS PRIVACY POLICY DOES NOT APPLY TO LENDER PROVIDED DATA.</b>
    </p>
    <p>
      Our business customers are typically mortgage lenders (“Lenders”). This Privacy Policy does not apply to the
      Services provided to Lenders or to the data provided by Lenders to us. Willow Servicing’s confidentiality,
      privacy, use, and other obligations with respect to such Services and Lender provided data are governed solely by
      the agreements under which the Services are provided to our Lenders. If you have any questions about the data
      practices of a Lender using our Services to process your personal information, please contact the Lender directly.
    </p>
    <p>
      You may print a copy of this Privacy Policy by clicking{' '}
      <a
        href="https://www.willowservicing.com/_files/ugd/a481fd_ba19521b84ae42649c148dc24e901ded.pdf"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </p>
    <h1>Privacy Policy Table of Contents</h1>
    <ul>
      <li>
        <a href="/">What this Privacy Policy Covers</a>
      </li>

      <li>
        <a href="/">Personal Data</a>
        <ul>
          <li>Categories of Personal Data We Collect</li>
          <li>Categories of Sources of Personal Data</li>
          <li>Our Commercial or Business Purposes for Collecting Personal Data</li>
        </ul>
      </li>

      <li>
        <a href="/">How We Share Your Personal Data</a>
      </li>

      <li>
        <a href="/">Tracking Tools and Opt-Out</a>
      </li>

      <li>
        <a href="/">Data Security and Retention</a>
      </li>

      <li>
        <a href="/">Personal Data of Children</a>
      </li>

      <li>
        <a href="/">California Resident Rights</a>
      </li>

      <li>
        <a href="/">Other State Law Privacy Rights</a>
      </li>

      <li>
        <a href="/">Changes to this Privacy Policy</a>
      </li>

      <li>
        <a href="/">Contact Information</a>
      </li>
    </ul>

    <h1>What this Privacy Policy Covers</h1>

    <p>
      This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services.
      “Personal Data” means any information that identifies or relates to a particular individual and also includes
      information referred to as “personally identifiable information” or “personal information” under applicable data
      privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don’t own or
      control or people we don’t manage.
    </p>

    <h1>Personal Data</h1>

    <h2>
      <i>Categories of Personal Data We Collect</i>
    </h2>

    <p>
      This chart details the categories of Personal Data that we collect and have collected over the past 12 months:
    </p>
    <table className="table table-bordered table-sm table-hover" style={{ lineHeight: 1.25 }}>
      <thead style={{ backgroundColor: '#0077b8', color: '#fff' }}>
        <tr>
          <th>Category of Personal Data</th>
          <th>Examples of Personal Data We Collect</th>
          <th>Categories of Third Parties With Whom We Share this Personal Data:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Profile or Contact Data</td>
          <td>First and last name, Email, Phone Number, Mailing Address, Unique identifiers such as passwords </td>
          <td>Service Providers, Business Partners, Parties Users Authorize, Access or Authenticate </td>
        </tr>
        <tr>
          <td>Identifiers</td>
          <td>Social security number, Driver’s license number, Passport number </td>
          <td>Service Providers, Business Partners, Parties Users Authorize, Access or Authenticate</td>
        </tr>
        <tr>
          <td>Payment Data </td>
          <td>Bank account information, Billing address, phone number, and email </td>
          <td>Service Providers (specifically our ACH payments provider, currently Dwolla, Inc.), Business Partners</td>
        </tr>
        <tr>
          <td>Device/IP Data </td>
          <td>
            IP address, Device ID, Domain server, Type of device/operating system/browser used to access the Services{' '}
          </td>
          <td>Service Providers, Business Partners, Analytics Partners</td>
        </tr>
        <tr>
          <td>Web Analytics </td>
          <td>Statistics associated with the interaction between device or browser and the Services </td>
          <td>Service Providers</td>
        </tr>
        <tr>
          <td>Professional or Employment-Related Data </td>
          <td>Resume, Job title, Job history </td>
          <td>Service Providers, Parties Users Authorize, Access or Authenticate</td>
        </tr>
        <tr>
          <td>Other Identifying Information that You Voluntarily Choose to Provide </td>
          <td>Identifying information in emails or letters you send us </td>
          <td>Service Providers, Business Partners </td>
        </tr>
      </tbody>
    </table>

    <h2>
      <i>Categories of Sources of Personal Data</i>
    </h2>

    <p>We collect Personal Data about you from the following categories of sources:</p>

    <ul>
      <li>
        <b>You</b>
        <ul>
          <li>
            <u>When you provide such information directly to us.</u>
            <ul>
              <li>When you create an account or use our interactive tools and Services.</li>
              <li>
                When you voluntarily provide information in free-form text boxes through the Services or through
                responses to surveys or questionnaires.
              </li>
              <li>When you send us an email or otherwise contact us.</li>
            </ul>
          </li>
          <li>
            <u>When you use the Services and such information is collected automatically.</u>
            <ul>
              <li>Through Cookies (defined in the “Tracking Tools and Opt-Out” section below).</li>
              <li>If you use a location-enabled browser, we may receive information about your location.</li>
              <li>
                If you download and install certain applications and software we make available, we may receive and
                collect information transmitted from your computing device for the purpose of providing you the relevant
                Services, such as information regarding when you are logged on and available to receive updates or alert
                notices.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <b>Third Parties</b>
        <ul>
          <li>
            <u>Mortgage Lender</u>
            <ul>
              <li>
                When you provide your information to your mortgage lender, we may receive that information for the
                purpose of providing you the Services.
              </li>
            </ul>
          </li>
          <li>
            <u>Vendors</u>
            <ul>
              <li>
                We may use analytics providers to analyze how you interact and engage with the Services, or third
                parties may help us provide you with customer support.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    <h2>
      <i>Our Commercial or Business Purposes for Collecting Personal Data</i>
    </h2>

    <ul>
      <li>
        <b>Providing, Customizing and Improving the Services</b>
        <ul>
          <li>Creating and managing your account or other user profiles.</li>
          <li>Providing you with the products, services or information you request.</li>
          <li>Meeting or fulfilling the reason you provided the information to us.</li>
          <li>Providing support and assistance for the Services.</li>
          <li>Improving the Services, including testing, research, internal analytics and product development.</li>
          <li>Personalizing the Services, website content and communications based on your preferences.</li>
          <li>Doing fraud protection, security and debugging.</li>
          <li>
            Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in
            applicable data privacy laws, such as the California Consumer Privacy Act (the “CCPA”).
          </li>
        </ul>
      </li>
      <li>
        <b>Marketing the Services</b>
        <ul>
          <li>Marketing and selling the Services.</li>
        </ul>
      </li>
      <li>
        <b>Corresponding with You</b>
        <ul>
          <li>
            Responding to correspondence that we receive from you, contacting you when necessary or requested, and
            sending you information about Willow Servicing or the Services.
          </li>
          <li>
            Sending emails and other communications according to your preferences or that display content that we think
            will interest you.
          </li>
        </ul>
      </li>
      <li>
        <b>Meeting Legal Requirements and Enforcing Legal Terms</b>
        <ul>
          <li>
            Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such
            as preventing, detecting and investigating security incidents and potentially illegal or prohibited
            activities.
          </li>
          <li>Protecting the rights, property or safety of you, Willow Servicing or another party.</li>
          <li>Enforcing any agreements with you.</li>
          <li>Responding to claims that any posting or other content violates third-party rights.</li>
          <li>Resolving disputes.</li>
        </ul>
      </li>
    </ul>

    <p>
      We will not collect additional categories of Personal Data or use the Personal Data we collected for materially
      different, unrelated or incompatible purposes without providing you notice.
    </p>

    <h1>How We Share Your Personal Data</h1>

    <p>
      We disclose your Personal Data to the categories of service providers and other parties listed in this section.
      Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your
      Personal Data. For more information, please refer to the state-specific sections below.
    </p>

    <ul>
      <li>
        <b>Service Providers. </b>These parties help us provide the Services or perform business functions on our
        behalf. They include:
        <ul>
          <li>Hosting, technology and communication providers.</li>
          <li>Support and customer service vendors.</li>
          <li>Payment services, specifically our ACH payments provider, currently Dwolla, Inc.</li>
          <li>
            Identify verification providers, currently Plaid,&nbsp; to connect and verify&nbsp; your account with your
            bank account. Information shared with Plaid is treated by Plaid in accordance with its Privacy Policy,
            available at{' '}
            <a href="https://plaid.com/legal/#end-user-privacy-policy" target="_blank" rel="noreferrer noopener">
              https://plaid.com/legal/#end-user-privacy-policy
            </a>
            .
          </li>
        </ul>
      </li>
      <li>
        <b>Analytics Partners. </b>These parties provide analytics on web traffic or usage of the Services. They
        include:
        <ul>
          <li>Companies that track how users found or were referred to the Services.</li>
          <li>Companies that track how users interact with the Services.</li>
        </ul>
      </li>
      <li>
        <b>Business Partners. </b>These parties partner with us in offering various services. They include:
        <ul>
          <li>Businesses that you have a relationship with.</li>
          <li>Businesses that your Lender has a relationship with.</li>
        </ul>
      </li>
      <li>
        <b>Parties You Authorize, Access or Authenticate</b>

        <ul>
          <li>Third parties you access through the services.</li>
          <li>Other users, such as your Lender.</li>
        </ul>
      </li>
    </ul>

    <h2>
      <i>Legal Obligations</i>
    </h2>

    <p>
      We may share any Personal Data that we collect with third parties in conjunction with any of the activities set
      forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for
      Collecting Personal Data” section above.&nbsp;
    </p>

    <h2>
      <i>Business Transfers</i>
    </h2>

    <p>
      All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition,
      bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part).
      Should one of these events occur, we will make reasonable efforts to notify you before your information becomes
      subject to different privacy and security policies and practices.
    </p>

    <h2>
      <i>Data that is Not Personal Data</i>
    </h2>

    <p>
      We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by
      removing information that makes the data personally identifiable to a particular user. We may use such aggregated,
      de-identified or anonymized data and share it with third parties for our lawful business purposes, including to
      analyze, build and improve the Services and promote our business, provided that we will not share such data in a
      manner that could identify you.
    </p>

    <h1>Tracking Tools and Opt-Out</h1>

    <p>
      The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript
      (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and
      use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are
      small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use
      that device to access our Services. We may also supplement the information we collect from you with information
      received from third parties, including third parties that have placed their own Cookies on your device(s). Please
      note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser
      at this time.
    </p>

    <p>We use the following types of Cookies:</p>

    <ul>
      <li>
        <b>Essential Cookies.</b> Essential Cookies are required for providing you with features or services that you
        have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling
        these Cookies may make certain features and services unavailable.
      </li>
      <li>
        <b>Performance/Analytical Cookies.</b> Performance/Analytical Cookies allow us to understand how visitors use
        our Services. They do this by collecting information about the number of visitors to the Services, what pages
        visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical
        Cookies also help us measure the performance of our advertising campaigns in order to help us improve our
        campaigns and the Services’ content for those who engage with our advertising. For example, Google LLC
        (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share
        information collected by Google Analytics about your visits to the Services is subject to the Google Analytics
        Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by
        visiting the Google advertising opt-out page at{' '}
        <a href="http://www.google.com/privacy_ads.html" target="_blank" rel="noreferrer">
          www.google.com/privacy_ads.html
        </a>{' '}
        or the Google Analytics Opt-out Browser Add-on at{' '}
        <a href="https://tools.google.com/dlpage/gaoptout/." target="_blank" rel="noreferrer">
          https://tools.google.com/dlpage/gaoptout/.
        </a>
      </li>
    </ul>

    <p>
      You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an
      option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as
      (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in
      a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you
      may have to manually adjust some preferences every time you visit our website and some of the Services and
      functionalities may not work.
    </p>

    <p>
      To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your
      browser’s menu. To find out more information about Cookies, including information about how to manage and delete
      Cookies, please visit{' '}
      <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer noopener">
        http://www.allaboutcookies.org/
      </a>
    </p>

    <h1>Data Security and Retention</h1>

    <p>
      We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical,
      technical, organizational and administrative security measures based on the type of Personal Data and how we are
      processing that data. You should also help protect your data by appropriately selecting and protecting your
      password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off
      after you have finished accessing your account. Although we work to protect the security of your account and other
      data that we hold in our records, please be aware that no method of transmitting data over the internet or storing
      data is completely secure.
    </p>

    <p>
      We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to
      provide you with our Services. In some cases we retain Personal Data for longer, if doing so is necessary to
      comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by
      applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where
      that information would not identify you personally.
    </p>

    <h1>Personal Data of Children</h1>

    <p>
      As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 13 years
      of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the
      Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of
      age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may
      have provided Personal Data to us, please contact us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Privacy Policy inquiry" target="_self">
        support@willowservicing.com
      </a>
      .
    </p>

    <h1>California Resident Rights</h1>

    <p>
      If you are a California resident, you have the rights set forth in this section. Please see the “Exercising Your
      Rights” section below for instructions regarding how to exercise these rights. Please note that we may process
      Personal Data of our customers’ end users or employees in connection with our provision of certain services to our
      customers. If we are processing your Personal Data as a service provider, you should contact the entity that
      collected your Personal Data in the first instance to address your rights with respect to such data.
    </p>

    <p>
      If there are any conflicts between this section and any other provision of this Privacy Policy and you are a
      California resident, the portion that is more protective of Personal Data shall control to the extent of such
      conflict. If you have any questions about this section or whether any of the following rights apply to you, please
      contact us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Privacy Policy Inquiry" target="_self">
        support@willowservicing.com
      </a>
      .
    </p>

    <h2>
      <u>Access</u>
    </h2>

    <p>
      You have the right to request certain information about our collection and use of your Personal Data over the past
      12 months. In response, we will provide you with the following information:
    </p>

    <ul>
      <li>The categories of Personal Data that we have collected about you.</li>
      <li>The categories of sources from which that Personal Data was collected.</li>
      <li>The business or commercial purpose for collecting or selling your Personal Data.</li>
      <li>The categories of third parties with whom we have shared your Personal Data.</li>
      <li>The specific pieces of Personal Data that we have collected about you.</li>
    </ul>

    <p>
      If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we
      will identify the categories of Personal Data shared with each category of third party recipient. If we have sold
      your Personal Data over the past 12 months, we will identify the categories of Personal Data sold to each category
      of third party recipient.
    </p>

    <h2>
      <u>Deletion</u>
    </h2>

    <p>
      You have the right to request that we delete the Personal Data that we have collected about you. Under the CCPA,
      this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you
      with the Services or complete a transaction or other action you have requested. If your deletion request is
      subject to one of these exceptions, we may deny your deletion request.
    </p>

    <h2>
      <u>Exercising Your Rights</u>
    </h2>

    <p>
      To exercise the rights described above, you or your Authorized Agent (defined below) must send us a request that
      (1) provides sufficient information to allow us to verify that you are the person about whom we have collected
      Personal Data, and (2) describes your request in sufficient detail to allow us to understand, evaluate and respond
      to it. Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to
      requests that do not meet these criteria. We will only use Personal Data provided in a Valid Request to verify
      your identity and complete your request. You do not need an account to submit a Valid Request.
    </p>

    <p>
      We will work to respond to your Valid Request within 45 days of receipt. We will not charge you a fee for making a
      Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that
      your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your
      request.
    </p>

    <p>You may submit a Valid Request using the following methods:</p>

    <ul>
      <li>
        Email us at: <a href="mailto:support@willowservicing.com">support@willowservicing.com</a>
      </li>
      <li>Call us at: (510) 603-3550</li>
    </ul>

    <p>
      You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this, you
      must provide your Authorized Agent with written permission to exercise your rights on your behalf, and we may
      request a copy of this written permission from your Authorized Agent when they make a request on your behalf.
    </p>

    <h2>
      <u>Personal Data Sales Opt-Out and Opt-In</u>
    </h2>

    <p>
      We will not sell your Personal Data, and have not done so over the last 12 months. To our knowledge, we do not
      sell the Personal Data of minors under 16 years of age.
    </p>

    <h2>
      <u>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</u>
    </h2>

    <p>
      We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or
      services, charge you different prices or rates, or provide you a lower quality of goods and services if you
      exercise your rights under the CCPA. However, we may offer different tiers of our Services as allowed by
      applicable data privacy laws (including the CCPA) with varying prices, rates or levels of quality of the goods or
      services you receive related to the value of Personal Data that we receive from you.
    </p>

    <h1>Other State Law Privacy Rights</h1>

    <h2>
      <u>California Resident Rights</u>
    </h2>

    <p>
      Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent
      disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit
      such a request, please contact us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Privacy Policy Inquiry" target="_self">
        support@willowservicing.com
      </a>
      .
    </p>

    <h2>
      <u>Nevada Resident Rights</u>
    </h2>

    <p>
      If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third
      parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at{' '}
      <a href="mailto:support@willowservicing.com?subject=Nevada Do Not Sell Request">support@willowservicing.com</a>{' '}
      with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address
      associated with your account.
    </p>

    <h1>Changes to this Privacy Policy</h1>

    <p>
      We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time,
      but we will alert you to any such changes by placing a notice on the Willow Servicing website, by sending you an
      email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or
      you haven’t provided us with your email address), those legal notices will still govern your use of the Services,
      and you are still responsible for reading and understanding them. If you use the Services after any changes to the
      Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is
      subject to the Privacy Policy in effect at the time such information is collected.
    </p>

    <h1>Contact Information</h1>

    <p>
      If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your
      Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us
      at:
    </p>

    <ul>
      <li>
        <a href="https://www.willowservicing.com">www.willowservicing.com</a>
      </li>
      <li>
        <a href="mailto:support@willowservicing.com">support@willowservicing.com</a>
      </li>
    </ul>
  </div>
);
