import { z } from 'zod';

import { PropertyId } from '../BrandedIds';
import { EscrowSettingInput } from '../loan/EscrowSetting';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateEscrowSettingEvent = BaseLoanEvent.extend({
  type: z.literal('createEscrowSetting'),
  propertyId: zodBrandedUuid<PropertyId>(),
  newEscrowSetting: EscrowSettingInput,
});

export type CreateEscrowSettingEvent = z.infer<typeof CreateEscrowSettingEvent>;
