import * as z from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';
import { DateString } from '../fields';
import { FlatfileMonetaryValue } from '../flatfileFields';

export const RecordEscrowDisbursementRow = z.object({
  loanId: zodBrandedString<LosId>(),
  disbursementType: z.enum(['escrow']),
  disbursementDate: DateString,
  disbursementTotal: FlatfileMonetaryValue,
  disbursementRecipient: z.string(),
  note: z.string().optional(),
});
export type RecordEscrowDisbursementRow = z.infer<typeof RecordEscrowDisbursementRow>;

export const RECORD_ESCROW_DISBURSEMENT_ROW_REQUIRED_FIELDS = Object.keys(RecordEscrowDisbursementRow.shape).filter(
  (key) => !RecordEscrowDisbursementRow.shape[key as keyof typeof RecordEscrowDisbursementRow.shape].isOptional(),
);
