import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { PortalUserId } from '@willow/types-iso';

export const useSentryContext = <T extends { id?: PortalUserId | string; email?: string }>(user?: T) => {
  useEffect(() => {
    if (user?.id || user?.email) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        ip_address: '{{auto}}',
      });
    }
  }, [user]);
};
