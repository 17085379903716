/*
    Only present on non-performing loans
*/

import { isUndefined } from 'lodash';
import * as z from 'zod';

import { zodDateOrString } from '../utils/Zod';
import { LoanStatusActive, LoanStatusPendingTransfer, LoanStatusPreActive } from './LoanStatus';

/* Eligible loan statuses for having delinquent or default non-performance status */
export const AllowedLoanStatusForNonPerformance = z.union([
  LoanStatusPreActive,
  LoanStatusActive,
  LoanStatusPendingTransfer,
]);
export type AllowedLoanStatusForNonPerformance = z.infer<typeof AllowedLoanStatusForNonPerformance>;

/* Status */
export const DelinquentNonPerformanceStatus = z.literal('delinquent');
export const DefaultedNonPerformanceStatus = z.literal('defaulted');
export const ForeclosureNonPerformanceStatus = z.literal('foreclosure');

export const NonPerformanceStatus = z.union([
  DelinquentNonPerformanceStatus,
  DefaultedNonPerformanceStatus,
  ForeclosureNonPerformanceStatus,
]);
export type NonPerformanceStatus = z.infer<typeof NonPerformanceStatus>;

/* Non-Performance Types */
export const NonPerformanceDeliquent = z.object({
  status: DelinquentNonPerformanceStatus,
});
export type NonPerformanceDeliquent = z.infer<typeof NonPerformanceDeliquent>;

export const NonPerformanceDefaulted = z.object({
  status: DefaultedNonPerformanceStatus,
});
export type NonPerformanceDefaulted = z.infer<typeof NonPerformanceDefaulted>;

export const NonPerformanceForeclosure = z.object({
  status: ForeclosureNonPerformanceStatus,
  foreclosureDate: zodDateOrString,
});
export type NonPerformanceForeclosure = z.infer<typeof NonPerformanceForeclosure>;

/* -------------------- */
export const NonPerformanceData = z.discriminatedUnion('status', [
  NonPerformanceDeliquent,
  NonPerformanceDefaulted,
  NonPerformanceForeclosure,
]);
export type NonPerformanceData = z.infer<typeof NonPerformanceData>;

/* -------------------- */
/* Reporting filters */
export const NonPerformingReportStatus = z.enum(['NONE', 'DELINQUENT', 'DEFAULTED']);
export type NonPerformingReportStatus = z.infer<typeof NonPerformingReportStatus>;

export function isNonPerformingStatus(
  nonPerformanceData: NonPerformanceData | undefined,
): nonPerformanceData is NonPerformanceDeliquent | NonPerformanceDefaulted {
  return !isUndefined(nonPerformanceData) && ['delinquent', 'defaulted'].includes(nonPerformanceData.status);
}
