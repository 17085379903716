import { useMutation } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { EscrowWaiverRequestDocument, EscrowWaiverRequestType } from '@willow/graphql-iso/src/portal';
import { Button, ControlledInput, NamedMemo, useZodHookForm, z } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { WillowModal } from '../modal/Modal';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

const FormShape = z.object({
  waivedItems: z.array(z.enum([EscrowWaiverRequestType.Tax, EscrowWaiverRequestType.Insurance, EscrowWaiverRequestType.Other])).min(1, { message: "Select at least one escrow item" }),
  message: z.string().optional()
});

export const EscrowWaiverRequest = NamedMemo<Props>('EscrowWaiverRequest', ({ loan }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [escrowWaiverRequest] = useMutation(EscrowWaiverRequestDocument);

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
  } = useZodHookForm(FormShape, {
    defaultValues: {
      waivedItems: [],
      message: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await escrowWaiverRequest({
        variables: {
          companyId: loan.company.id,
          loanId: loan.id,
          waivedItems: data.waivedItems,
          message: data.message,
        },
      });

      setShowSuccessModal(true);
      reset();
    } catch {
      toast.error(`Something went wrong submitting the request form. Please try again.`);
    }
  });

  return (
    <>
      <ManageLoanHeader
        title="Request an Escrow Waiver"
        subtitle={
          <>
            Provide more details below to request the removal of escrow from your loan. Please note, your request may not be approved if your escrow balance is negative. If eligible, our team will reach out to provide required documentation to you.
          </>
        }
      />

      <Form onSubmit={onSubmit}>
        <Form.Group>
        <Form.Label className="u-fs-2 fw-bold">Indicate escrowed item(s) you want to waive:</Form.Label>
          <Form.Check
              id="escrow-waiver-tax"
              type="checkbox"
              label="City or County Tax"
              value={EscrowWaiverRequestType.Tax}
              {...register('waivedItems')}
            />
             <Form.Check
              id="escrow-waiver-tax"
              type="checkbox"
              label="Insurance"
              value={EscrowWaiverRequestType.Insurance}
              {...register('waivedItems')}
            />
             <Form.Check
              id="escrow-waiver-tax"
              type="checkbox"
              label="Other"
              value={EscrowWaiverRequestType.Other}
              {...register('waivedItems')}
            />
            <ErrorMessage
              errors={errors}
              name="waivedItems"
              render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
            />
        </Form.Group>
        <Form.Group className="w-100 mt-4">
          <ControlledInput
            control={control}
            fieldName="message"
            label="Tell us more about why escrow should be waived"
            placeholder="Details on why you would like escrow to be waived"
            inputType="textarea"
            textareaRows={8}
          />
        </Form.Group>

        <div className="mt-4 w-100 d-flex justify-content-end">
          <Button variant="primary" size="md" onClick={onSubmit} loading={isSubmitting}>
            Submit Request
          </Button>
        </div>
      </Form>

      <WillowModal showModal={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <h2 className="u-fs-4 u-bold mb-2">Escrow Waiver Requested</h2>
        <div>
        {loan.company.name} is reviewing your request. We will contact you shortly.
        </div>
        <div className="mt-4 ms-auto">
          <Button
            variant="primary"
            size="sm"
            className="hover-override hover:u-bg-secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </div>
      </WillowModal>
    </>
  );
});
