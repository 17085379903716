import { z } from 'zod';

import { Payment } from '../loan/Payments';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreatePaymentEvent = BaseLoanEvent.extend({
  type: z.literal('createPayment'),
  payment: Payment,
  disableEmail: z.boolean().optional(),
});
export type CreatePaymentEvent = z.infer<typeof CreatePaymentEvent>;
