/*
    Each page template should call this metadata component
*/
import { Helmet } from 'react-helmet-async';

import { NamedMemo } from '@willow/shared-web';

import { META_DEFAULTS } from './Defaults';

interface Props {
  metadata?: {
    title?: string;
    description?: string; // 50 – 155 characters
  };
}

export const Metadata = NamedMemo<Props>('Metadata', ({ metadata }) => {
  // Determine page-specific metadata
  const pageTitle = metadata?.title;
  const title = pageTitle
    ? pageTitle + (META_DEFAULTS.TITLE ? ` ${META_DEFAULTS.DIVIDER} ${META_DEFAULTS.TITLE}` : '')
    : META_DEFAULTS.TITLE;
  const description = metadata?.description || META_DEFAULTS.DESCRIPTION;

  return (
    <Helmet>
      <meta name="description" content={description} />
      <title>{title}</title>
    </Helmet>
  );
});
