import * as z from 'zod';

import { PaymentConfigSource } from '../loan/PaymentConfig';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateMonthlyInterestRateEvent = BaseLoanEvent.extend({
  type: z.literal('updateMonthlyInterestRate'),
  oldMonthlyInterestRate: z.number(),
  newMonthlyInterestRate: z.number(),
  source: PaymentConfigSource,
});

export type UpdateMonthlyInterestRateEvent = z.infer<typeof UpdateMonthlyInterestRateEvent>;
