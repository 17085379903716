import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateForwardPaymentDisbursementEvent = BaseLoanEvent.extend({
  type: z.literal('createForwardPaymentDisbursement'),
  paymentId: zodBrandedUuid<PaymentId>(),
  date: zodDateOrString,
  recipient: z.string(),
  comment: z.string().optional(),
});
export type CreateForwardPaymentDisbursementEvent = z.infer<typeof CreateForwardPaymentDisbursementEvent>;
