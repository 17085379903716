/*
  The page numbers + sizes are passed into this control component,
  but there is a page size dropdown that reports back to the parent to update the page size.
  On page updates (size or index), the query params are updated and then results re-gathered
*/
import React from 'react';

import { Pagination as BsPagination } from '../../../bootstrap';
import { usePagination } from './usePagination';

import s from './Pagination.module.scss';

interface Props {
  pageIndex: number;
  totalResults: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  gotoPage: (page: number) => void; // index0 pagination
}

export const Pagination = ({ pageIndex, pageSize, totalResults, gotoPage, setPageSize }: Props) => {
  const startIndex = pageIndex * pageSize + 1;
  const endIndex = startIndex + pageSize < totalResults ? startIndex + pageSize - 1 : totalResults;
  const paginationRange = usePagination({
    totalCount: totalResults,
    siblingCount: 1,
    currentPage: pageIndex,
    pageSize,
  });
  const lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0;

  // CSS Variable Reference
  // https://getbootstrap.com/docs/5.3/components/pagination/#variables
  const paginationStyles = {
    '--bs-pagination-border-color': 'var(--color-bark2)',
    '--bs-pagination-hover-border-color': 'var(--color-bark2)',
    '--bs-pagination-disabled-border-color': 'var(--color-bark2)',
    '--bs-pagination-active-bg': 'var(--primary-color)',
    '--bs-pagination-active-border-color': 'var(--primary-color)',
    '--bs-link-color': 'var(--primary-color)',
    '--bs-link-hover-color': 'var(--secondary-color)',
    '--bs-pagination-focus-box-shadow': '0',
  } as React.CSSProperties;

  return (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div className="u-color-bark3">
        {totalResults === 0 ? (
          <span>Showing 0 of 0</span>
        ) : (
          <span>
            Showing {startIndex} - {endIndex} of {totalResults}
          </span>
        )}
      </div>
      {!paginationRange || paginationRange.length === 0 ? undefined : (
        <div className="d-flex gap-3">
          <BsPagination style={paginationStyles} className="mb-0">
            <BsPagination.Prev disabled={pageIndex === 0} onClick={() => gotoPage(pageIndex - 1)} />

            {paginationRange.map((page, idx) =>
              typeof page === 'string' ? (
                <BsPagination.Ellipsis key={`${idx}-${page}`} />
              ) : (
                <BsPagination.Item
                  key={`${idx}-${page}`}
                  onClick={() => gotoPage(page - 1)}
                  active={page - 1 === pageIndex}
                >
                  {page}
                </BsPagination.Item>
              ),
            )}
            <BsPagination.Next disabled={pageIndex + 1 === lastPage} onClick={() => gotoPage(pageIndex + 1)} />
          </BsPagination>
          <div className={s.select}>
            <select
              className="form-select"
              value={pageSize}
              onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
            >
              <option value="10">10 / page</option>
              <option value="20">20 / page</option>
              <option value="50">50 / page</option>
              <option value="100">100 / page</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};
