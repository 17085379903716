import { z } from 'zod';

import { PaymentCadenceEnum } from '../../../loan/EscrowSetting';
import { Checkbox, DateString, PositiveMonetaryValue } from '../../fields';

export const TaxFields = z.object({
  // COUNTY
  taxApn: z.string().optional(),
  taxCountyIsEscrowed: Checkbox.optional(),
  taxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  taxCountyPayeeName: z.string().optional(),

  // CITY
  taxCityIsEscrowed: Checkbox.optional(),
  taxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  taxCityPayeeName: z.string().optional(),
  taxCityNextDueDate: DateString.optional(),
  taxCityPaymentCadence: PaymentCadenceEnum.optional(),

  // SCHOOL
  taxSchoolIsEscrowed: Checkbox.optional(),
  taxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  taxSchoolPayeeName: z.string().optional(),
  taxSchoolNextDueDate: DateString.optional(),
  taxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 1
  taxOther1IsEscrowed: Checkbox.optional(),
  taxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  taxOther1PayeeName: z.string().optional(),
  taxOther1NextDueDate: DateString.optional(),
  taxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 2
  taxOther2IsEscrowed: Checkbox.optional(),
  taxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  taxOther2PayeeName: z.string().optional(),
  taxOther2NextDueDate: DateString.optional(),
  taxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 3
  taxOther3IsEscrowed: Checkbox.optional(),
  taxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  taxOther3PayeeName: z.string().optional(),
  taxOther3NextDueDate: DateString.optional(),
  taxOther3PaymentCadence: PaymentCadenceEnum.optional(),
});

export const MultipleAddressesTaxFields = z.object({
  // Tax county
  address2TaxApn: z.string().optional(),
  address2TaxCountyIsEscrowed: Checkbox.optional(),
  address2TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxCountyPayeeName: z.string().optional(),

  address3TaxApn: z.string().optional(),
  address3TaxCountyIsEscrowed: Checkbox.optional(),
  address3TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxCountyPayeeName: z.string().optional(),

  address4TaxApn: z.string().optional(),
  address4TaxCountyIsEscrowed: Checkbox.optional(),
  address4TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxCountyPayeeName: z.string().optional(),

  address5TaxApn: z.string().optional(),
  address5TaxCountyIsEscrowed: Checkbox.optional(),
  address5TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxCountyPayeeName: z.string().optional(),

  address6TaxApn: z.string().optional(),
  address6TaxCountyIsEscrowed: Checkbox.optional(),
  address6TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxCountyPayeeName: z.string().optional(),

  address7TaxApn: z.string().optional(),
  address7TaxCountyIsEscrowed: Checkbox.optional(),
  address7TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxCountyPayeeName: z.string().optional(),

  address8TaxApn: z.string().optional(),
  address8TaxCountyIsEscrowed: Checkbox.optional(),
  address8TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxCountyPayeeName: z.string().optional(),

  address9TaxApn: z.string().optional(),
  address9TaxCountyIsEscrowed: Checkbox.optional(),
  address9TaxCountyNextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxCountyPayeeName: z.string().optional(),

  address10TaxApn: z.string().optional(),
  address10TaxCounty0IsEscrowed: Checkbox.optional(),
  address10TaxCounty0NextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxCounty0PayeeName: z.string().optional(),

  // Tax city
  address2TaxCityIsEscrowed: Checkbox.optional(),
  address2TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxCityPayeeName: z.string().optional(),

  address3TaxCityIsEscrowed: Checkbox.optional(),
  address3TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxCityPayeeName: z.string().optional(),

  address4TaxCityIsEscrowed: Checkbox.optional(),
  address4TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxCityPayeeName: z.string().optional(),

  address5TaxCityIsEscrowed: Checkbox.optional(),
  address5TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxCityPayeeName: z.string().optional(),

  address6TaxCityIsEscrowed: Checkbox.optional(),
  address6TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxCityPayeeName: z.string().optional(),

  address7TaxCityIsEscrowed: Checkbox.optional(),
  address7TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxCityPayeeName: z.string().optional(),

  address8TaxCityIsEscrowed: Checkbox.optional(),
  address8TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxCityPayeeName: z.string().optional(),

  address9TaxCityIsEscrowed: Checkbox.optional(),
  address9TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxCityPayeeName: z.string().optional(),

  address10TaxCityIsEscrowed: Checkbox.optional(),
  address10TaxCityNextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxCityPayeeName: z.string().optional(),

  // Tax school
  address2TaxSchoolIsEscrowed: Checkbox.optional(),
  address2TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxSchoolPayeeName: z.string().optional(),
  address2TaxSchoolNextDueDate: DateString.optional(),
  address2TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address3TaxSchoolIsEscrowed: Checkbox.optional(),
  address3TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxSchoolPayeeName: z.string().optional(),
  address3TaxSchoolNextDueDate: DateString.optional(),
  address3TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address4TaxSchoolIsEscrowed: Checkbox.optional(),
  address4TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxSchoolPayeeName: z.string().optional(),
  address4TaxSchoolNextDueDate: DateString.optional(),
  address4TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address5TaxSchoolIsEscrowed: Checkbox.optional(),
  address5TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxSchoolPayeeName: z.string().optional(),
  address5TaxSchoolNextDueDate: DateString.optional(),
  address5TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address6TaxSchoolIsEscrowed: Checkbox.optional(),
  address6TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxSchoolPayeeName: z.string().optional(),
  address6TaxSchoolNextDueDate: DateString.optional(),
  address6TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address7TaxSchoolIsEscrowed: Checkbox.optional(),
  address7TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxSchoolPayeeName: z.string().optional(),
  address7TaxSchoolNextDueDate: DateString.optional(),
  address7TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address8TaxSchoolIsEscrowed: Checkbox.optional(),
  address8TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxSchoolPayeeName: z.string().optional(),
  address8TaxSchoolNextDueDate: DateString.optional(),
  address8TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address9TaxSchoolIsEscrowed: Checkbox.optional(),
  address9TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxSchoolPayeeName: z.string().optional(),
  address9TaxSchoolNextDueDate: DateString.optional(),
  address9TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  address10TaxSchoolIsEscrowed: Checkbox.optional(),
  address10TaxSchoolNextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxSchoolPayeeName: z.string().optional(),
  address10TaxSchoolNextDueDate: DateString.optional(),
  address10TaxSchoolPaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 1
  address2TaxOther1IsEscrowed: Checkbox.optional(),
  address2TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxOther1PayeeName: z.string().optional(),
  address2TaxOther1NextDueDate: DateString.optional(),
  address2TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address3TaxOther1IsEscrowed: Checkbox.optional(),
  address3TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxOther1PayeeName: z.string().optional(),
  address3TaxOther1NextDueDate: DateString.optional(),
  address3TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address4TaxOther1IsEscrowed: Checkbox.optional(),
  address4TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxOther1PayeeName: z.string().optional(),
  address4TaxOther1NextDueDate: DateString.optional(),
  address4TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address5TaxOther1IsEscrowed: Checkbox.optional(),
  address5TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxOther1PayeeName: z.string().optional(),
  address5TaxOther1NextDueDate: DateString.optional(),
  address5TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address6TaxOther1IsEscrowed: Checkbox.optional(),
  address6TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxOther1PayeeName: z.string().optional(),
  address6TaxOther1NextDueDate: DateString.optional(),
  address6TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address7TaxOther1IsEscrowed: Checkbox.optional(),
  address7TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxOther1PayeeName: z.string().optional(),
  address7TaxOther1NextDueDate: DateString.optional(),
  address7TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address8TaxOther1IsEscrowed: Checkbox.optional(),
  address8TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxOther1PayeeName: z.string().optional(),
  address8TaxOther1NextDueDate: DateString.optional(),
  address8TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address9TaxOther1IsEscrowed: Checkbox.optional(),
  address9TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxOther1PayeeName: z.string().optional(),
  address9TaxOther1NextDueDate: DateString.optional(),
  address9TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  address10TaxOther1IsEscrowed: Checkbox.optional(),
  address10TaxOther1NextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxOther1PayeeName: z.string().optional(),
  address10TaxOther1NextDueDate: DateString.optional(),
  address10TaxOther1PaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 2
  address2TaxOther2IsEscrowed: Checkbox.optional(),
  address2TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxOther2PayeeName: z.string().optional(),
  address2TaxOther2NextDueDate: DateString.optional(),
  address2TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address3TaxOther2IsEscrowed: Checkbox.optional(),
  address3TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxOther2PayeeName: z.string().optional(),
  address3TaxOther2NextDueDate: DateString.optional(),
  address3TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address4TaxOther2IsEscrowed: Checkbox.optional(),
  address4TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxOther2PayeeName: z.string().optional(),
  address4TaxOther2NextDueDate: DateString.optional(),
  address4TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address5TaxOther2IsEscrowed: Checkbox.optional(),
  address5TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxOther2PayeeName: z.string().optional(),
  address5TaxOther2NextDueDate: DateString.optional(),
  address5TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address6TaxOther2IsEscrowed: Checkbox.optional(),
  address6TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxOther2PayeeName: z.string().optional(),
  address6TaxOther2NextDueDate: DateString.optional(),
  address6TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address7TaxOther2IsEscrowed: Checkbox.optional(),
  address7TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxOther2PayeeName: z.string().optional(),
  address7TaxOther2NextDueDate: DateString.optional(),
  address7TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address8TaxOther2IsEscrowed: Checkbox.optional(),
  address8TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxOther2PayeeName: z.string().optional(),
  address8TaxOther2NextDueDate: DateString.optional(),
  address8TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address9TaxOther2IsEscrowed: Checkbox.optional(),
  address9TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxOther2PayeeName: z.string().optional(),
  address9TaxOther2NextDueDate: DateString.optional(),
  address9TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  address10TaxOther2IsEscrowed: Checkbox.optional(),
  address10TaxOther2NextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxOther2PayeeName: z.string().optional(),
  address10TaxOther2NextDueDate: DateString.optional(),
  address10TaxOther2PaymentCadence: PaymentCadenceEnum.optional(),

  // Other tax 3
  address2TaxOther3IsEscrowed: Checkbox.optional(),
  address2TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address2TaxOther3PayeeName: z.string().optional(),
  address2TaxOther3NextDueDate: DateString.optional(),
  address2TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address3TaxOther3IsEscrowed: Checkbox.optional(),
  address3TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address3TaxOther3PayeeName: z.string().optional(),
  address3TaxOther3NextDueDate: DateString.optional(),
  address3TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address4TaxOther3IsEscrowed: Checkbox.optional(),
  address4TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address4TaxOther3PayeeName: z.string().optional(),
  address4TaxOther3NextDueDate: DateString.optional(),
  address4TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address5TaxOther3IsEscrowed: Checkbox.optional(),
  address5TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address5TaxOther3PayeeName: z.string().optional(),
  address5TaxOther3NextDueDate: DateString.optional(),
  address5TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address6TaxOther3IsEscrowed: Checkbox.optional(),
  address6TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address6TaxOther3PayeeName: z.string().optional(),
  address6TaxOther3NextDueDate: DateString.optional(),
  address6TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address7TaxOther3IsEscrowed: Checkbox.optional(),
  address7TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address7TaxOther3PayeeName: z.string().optional(),
  address7TaxOther3NextDueDate: DateString.optional(),
  address7TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address8TaxOther3IsEscrowed: Checkbox.optional(),
  address8TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address8TaxOther3PayeeName: z.string().optional(),
  address8TaxOther3NextDueDate: DateString.optional(),
  address8TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address9TaxOther3IsEscrowed: Checkbox.optional(),
  address9TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address9TaxOther3PayeeName: z.string().optional(),
  address9TaxOther3NextDueDate: DateString.optional(),
  address9TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),

  address10TaxOther3IsEscrowed: Checkbox.optional(),
  address10TaxOther3NextPaymentAmount: PositiveMonetaryValue.optional(),
  address10TaxOther3PayeeName: z.string().optional(),
  address10TaxOther3NextDueDate: DateString.optional(),
  address10TaxOther3PaymentCadence: PaymentCadenceEnum.optional(),
});
