import * as z from 'zod';

import { ConstructionToPermanentRow } from '../validations/loan/ConstructionToPermanentRow';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ConstructionToPermanentEvent = BaseLoanEvent.extend({
  type: z.literal('constructionToPermanent'),
  data: ConstructionToPermanentRow,
});
export type ConstructionToPermanentEvent = z.infer<typeof ConstructionToPermanentEvent>;
