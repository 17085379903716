/**
 * Generic Loader
 *
 * USAGE
 *
 * Step 1: Wrap the content you want to load. FYI the content will actually be rendered, but will
 * be set to `display:none`. This will allow that component to make any network calls it needs to.
 *
 * <UniversalLoader loader={(isLoading) => isLoading ? <div>LOADING!!!</div> : undefined}>
 *   <SomeFancyComponent />
 * </UniversalLoader>
 *
 * Step 2: Inside `<SomeFancyComponent />` (or another nested component) you'll need to report when
 * loading can safely be considered complete. This is accomplished via the `ussUniversalLoader` hook
 * that needs to be imported.
 *
 * import { useUniversalLoader } from './path/to/UniversalLoader';
 *
 * Step 3: Use the hook to report when loading is complete
 *
 * const loader = useUniversalLoader();
 *
 * loader.setLoading(false); // Loading is considered complete
 * loader.setLoading(true); // If you wanted to re-enable the loading state
 * loader.loading // If you want to know the current loading status
 */

import classNames from 'classnames';
import { noop } from 'lodash';
import { createContext, useEffect, useState } from 'react';

import { NamedMemo } from '../NamedMemo';
import { useUniversalLoader } from './hooks/useUniversalLoader';

interface UniversalLoaderProps {
  children: JSX.Element;
  loader: (isLoading: boolean) => JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UniversalLoaderContext = createContext<{ loading: boolean; setLoading: (isLoading: boolean) => void }>({
  loading: false,
  setLoading: noop,
});

export const UniversalLoader = NamedMemo<UniversalLoaderProps>('UniversalLoader', ({ children, loader }) => {
  const [loading, setLoading] = useState(true);
  const [minLoadingTimePassed, setMinLoadingTimePassed] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);

  useEffect(() => {
    // Track minimum amount of time to display the initial loader screen
    const timeout = setTimeout(() => setMinLoadingTimePassed(true), 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!loading && minLoadingTimePassed) setDisplayLoader(false);
  }, [minLoadingTimePassed, loading]);

  return (
    <UniversalLoaderContext.Provider value={{ loading, setLoading }}>
      {loader(displayLoader)}
      <div
        className={classNames({
          'd-none': displayLoader,
        })}
      >
        {children}
      </div>
    </UniversalLoaderContext.Provider>
  );
});

export { useUniversalLoader };
