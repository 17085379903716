import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreatePaymentAfterPayoffDisbursementEvent = BaseLoanEvent.extend({
  type: z.literal('createPaymentAfterPayoffDisbursement'),
  paymentId: zodBrandedUuid<PaymentId>(),
  date: zodDateOrString,
  recipient: z.string(),
  comment: z.string().optional(),
});
export type CreatePaymentAfterPayoffDisbursementEvent = z.infer<typeof CreatePaymentAfterPayoffDisbursementEvent>;
