import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import React from 'react';

import { SupportHours } from '@willow/graphql-iso/src/portal';
import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

interface Props {
  title?: string;
  phone: string;
  email: string;
  hours?: SupportHours;
}

type ContactItemProps = { icon: React.ReactNode; label: string; children: React.ReactNode };
const ContactItem = ({ icon, label, children }: ContactItemProps) => (
  <div className="u-border-1 u-border-color-bark1 u-border-r-3 px-4 py-4 d-flex align-items-center justify-content-between">
    <div className="d-flex align-items-center u-fs-4 fw-bold" aria-hidden="true">
      {icon}
      <h3 className="ms-3">{label}</h3>
    </div>
    {children}
  </div>
);

export const ContactForm = NamedMemo<Props>('ContactForm', ({ title, phone, email, hours }) => {
  return (
    <>
      {title && <h2 className="u-fs-4 u-bold mb-4">{title}</h2>}

      {phone && (
        <div className="mb-2">
          <ContactItem icon={<PhoneOutlined />} label="Phone">
            <a href={`tel: ${phone}`} aria-label={`phone number: ${phone}`} className="u-color-primary u-fs-4">
              {phoneNumberFormat(phone)}
            </a>
          </ContactItem>
        </div>
      )}

      {email && (
        <ContactItem icon={<MailOutlined />} label="Email">
          <a href={`mailto: ${email}`} aria-label={`email: ${email}`} className="u-color-primary u-fs-4">
            {email}
          </a>
        </ContactItem>
      )}

      {hours && hours.line1 && (
        <div className="mt-4 u-fs-3 ">
          Customer service hours are {hours?.line1 && <>{hours.line1}</>}
          {hours?.line2 && <>, {hours.line2}</>}
          {hours?.line3 && <>, {hours.line3}</>}.
        </div>
      )}
    </>
  );
});
