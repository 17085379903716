/*
  The email template types specified here will be copied as a PDF
  and display only the documents page.export 
*/
import { z } from 'zod';

import {
  FinalPaymentEmail,
  GracePastDueEmail,
  PaymentCancelledEmail,
  PaymentFailedAutopayEmail,
  PaymentFailedEmail,
  RescindGoodbyeEmail,
  WelcomeAutoPayEmail,
  WelcomeEmail,
  WelcomeInterestReserveEmail,
  WelcomePreActiveEmail,
} from './Templates';

/*
    Email templates that will be copied into PDF versions
*/
export const DocumentFromEmailToEmailCopyType = z.union([
  // Direct copy of the email template
  WelcomeEmail,
  WelcomeInterestReserveEmail,
  WelcomePreActiveEmail,
  WelcomeAutoPayEmail,
  RescindGoodbyeEmail,
  PaymentCancelledEmail,
  PaymentFailedEmail,
  FinalPaymentEmail,
  PaymentFailedAutopayEmail,
  GracePastDueEmail,
]);
export type DocumentFromEmailToEmailCopyType = z.infer<typeof DocumentFromEmailToEmailCopyType>;

export const DocumentFromEmailType = DocumentFromEmailToEmailCopyType;
export type DocumentFromEmailType = z.infer<typeof DocumentFromEmailType>;
