import { Modal } from '../Modal/Modal';
import { NamedMemo } from '../NamedMemo';

interface Props {
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const BankDeleteConfirmModal = NamedMemo<Props>(
  'BankDeleteConfirmModal',
  ({ showModal, onCancel, onConfirm }) => {
    return (
      <Modal showModal={showModal} onClose={onCancel}>
        <h2 className="willow-modal__title">Delete bank account?</h2>

        <div className="willow-modal__copy">
          <p>
            Deleting this account will remove the ability to make payments from this account and disable all
            auto-payments scheduled from this account. If you change your mind, you can connect the account again.
          </p>
        </div>
        <div className="willow-modal__buttons">
          <button type="button" onClick={onCancel} className="willow-modal__buttons__close">
            Cancel
          </button>

          <button type="button" onClick={onConfirm} className="willow-modal__buttons__submit">
            Delete account
          </button>
        </div>
      </Modal>
    );
  },
);
