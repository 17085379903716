import { z } from '@willow/shared-web';

const DocumentTypeOption = z.union([z.literal('current'), z.literal('archived')]);
export type TDocumentTypeOption = z.infer<typeof DocumentTypeOption>;

export const DocumentTableShape = z.object({
  type: DocumentTypeOption,
});

export const getDocumentTypeOptions = (): { value: TDocumentTypeOption; label: string }[] => [
  { value: 'current', label: 'Current documents' },
  { value: 'archived', label: 'Archived documents' },
];
