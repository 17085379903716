import * as z from 'zod';

import { BaseBorrowerAndPortalUser } from './Borrower';
import { AuthZeroId, BorrowerId, PortalUserId } from './BrandedIds';

export const PortalUserPermissions = z.object({
  isAdmin: z.boolean().optional(),
});
export type PortalUserPermissions = z.infer<typeof PortalUserPermissions>;

export interface PortalUser extends BaseBorrowerAndPortalUser {
  id: PortalUserId;
  borrowerId: BorrowerId;
  email: string;
  authZeroId: AuthZeroId;
  acceptedTermsDate?: Date;
  acceptedTermsVersion?: number;
  permissions: PortalUserPermissions;
  createdAt: Date;
  updatedAt: Date;
}

export interface DwollaUser {
  id: string;
  portalUserId: PortalUserId;
  customerUrl: string;
  plaidToken?: string;
  plaidAccountId?: string;
  plaidMicrodepositPostedEmailSent: boolean;
}
