import * as z from 'zod';

import { trimAndStrip } from '@willow/types-iso';

/**
 * SANITIZE ALL FIELDS ON AN INCOMING REQUEST
 *
 * Anytime we receive values from a CSV or the API, we begin by sanitizing the data.
 * This includes trimming whitespaces, turning empty strings and null into undefined,
 * and throwing an error if a field begins with an illegal character.
 */

export const refineIllegalChars = (val: any) => {
  const noIllegalChars = new RegExp(/^([^\\=+@\t\n\r])(.*)/);
  if (!!val && typeof val === 'string') {
    return noIllegalChars.test(val);
  }
  return true;
};

export const Sanitizer = z.object({}).catchall(
  z.any().transform(trimAndStrip).optional().refine(refineIllegalChars, {
    message: 'Field begins with an illegal character.',
  }),
);
