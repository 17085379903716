import { z } from 'zod';

import { PaymentDueId } from '../BrandedIds';
import { FeeLineItemInput } from '../events';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const CreateFeeSideEffectContext = BaseLoanIdSideEffect.extend({
  paymentDueId: zodBrandedUuid<PaymentDueId>().optional(),
  lineItems: z.array(FeeLineItemInput),
  isDeferred: z.boolean(),
  comment: z.string(),
});
export const CreateFeeSideEffect = CreateFeeSideEffectContext.extend({
  type: z.literal('createFee'),
});
export type CreateFeeSideEffect = z.infer<typeof CreateFeeSideEffect>;
