import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  CompanyFragment,
  GetS3PresignedUrlDocumentUploadDocument,
  SaveBorrowerDocumentUploadDocument,
} from '@willow/graphql-iso/src/portal';
import { getFileBasename, getFileExtension } from '@willow/shared-iso';
import {
  Button,
  DocumentUploadFormShape,
  NamedMemo,
  Sentry,
  UploadDocumentModal,
  useS3Uploader,
} from '@willow/shared-web';
import { LoanId } from '@willow/types-iso';

export interface Props {
  company: CompanyFragment;
  loanId: LoanId;
}

export const UploadDocument = NamedMemo<Props>('UploadDocument', ({ company, loanId }) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [getS3Url] = useMutation(GetS3PresignedUrlDocumentUploadDocument);
  const [saveBorrowerUploadDocument] = useMutation(SaveBorrowerDocumentUploadDocument);

  const getPresignedUrl = async (file: File) => {
    const { data, errors } = await getS3Url({
      variables: {
        companyId: company.id,
        loanId,
        fileName: getFileBasename(file.name),
        fileType: file.type,
        fileExtension: getFileExtension(file.name),
        fileSizeInBytes: file.size,
      },
    });

    if (errors || !data) {
      Sentry.captureException(`Unable to get presigned url for ${file.name}, companyId ${company.id}}`);
      throw new Error('Unable to get presigned url', { cause: errors });
    }
    const { presignedUploadUrl, destination } = data.getS3PresignedUrlDocumentUpload;

    return { signedUrl: presignedUploadUrl, destination };
  };

  const s3 = useS3Uploader({ getPresignedUrl });

  const handleUpload = async (data: DocumentUploadFormShape) => {
    setIsSubmitting(true);

    try {
      let hasErrors = false;
      await Promise.all(
        data.files.map(async (file) => {
          const { destination, error } = await s3.upload(file.file);
          if (error || !destination) {
            toast.error(`There was an issue uploading ${file.name}`);
            return;
          }
          const { errors } = await saveBorrowerUploadDocument({
            variables: {
              loanId,
              url: destination,
              title: file.name,
            },
          });
          if (errors) {
            toast.error(`Unable to save ${file.name}, please try uploading it again`);
            hasErrors = true;
          }
        }),
      );

      if (!hasErrors) {
        toast.success(`Your documents were successfully uploaded and shared with ${company.name}`);
        setShowModal(false);
      }
    } catch (err) {
      toast.error('There was an issue uploading one or more files. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button size="sm" onClick={() => setShowModal(true)}>
          + Add document
        </Button>
      </div>

      {showModal && (
        <UploadDocumentModal
          modalSubtitle={`Uploaded documents will be shared with ${company.name} for review. Co-borrowers will not have access to this document.`}
          fileAccept={{
            'application/pdf': ['.pdf'],
            'image/*': [],
          }}
          isSubmitting={isSubmitting}
          onSubmit={handleUpload}
          onCloseModal={() => setShowModal(false)}
        />
      )}
    </>
  );
});
