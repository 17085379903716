import { z } from 'zod';

import { Address } from '../Address';
import { LoanConfiguration } from '../loan/Loan';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const UpdateLoanSideEffectContext = BaseLoanIdSideEffect.extend({
  opts: z.object({
    configuration: LoanConfiguration.optional(),
    address: Address.optional(),
    originalAmount: z.number().optional(),
    additionalIds: z
      .object({
        fullId: z.string().nullable(),
        poolId: z.string().nullable(),
        capitalPartnerId: z.string().nullable(),
        previousServicerId: z.string().nullable(),
      })
      .optional(),
  }),
});
export type UpdateLoanSideEffectContext = z.infer<typeof UpdateLoanSideEffectContext>;

export const UpdateLoanSideEffect = UpdateLoanSideEffectContext.extend({
  type: z.literal('updateLoan'),
});
export type UpdateLoanSideEffect = z.infer<typeof UpdateLoanSideEffect>;
