import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { phoneNumberFormat } from '@willow/shared-iso';

const Content = ({ selectedCompany }: { selectedCompany: CompanyFragment }) => (
  <>
    <p>
      By registering for electronic delivery (paperless), you agree to receive all account communications, including but
      not limited to billing statements and other loan servicing disclosures, electronically. If you have multiple
      accounts with {selectedCompany.name}, your consent will apply to all of them.
    </p>

    <p>
      You can revoke your consent to receive disclosures electronically and receive hard copy disclosures via the U.S.
      Postal Service by adjusting your notification preferences on your My Account page. There is no fee to cancel this
      service. It may take up to one billing cycle for changes to your delivery preferences to become effective.
    </p>

    <p>
      Regardless of your notification preferences, your communications will be available for download and review from
      the Documents tab in your payment portal. To request a paper copy of a specific communication, please call us at{' '}
      {phoneNumberFormat(selectedCompany.supportPhone)}. There is no charge for this service.
    </p>

    <p>
      Should your contact information change, please call us at {phoneNumberFormat(selectedCompany.supportPhone)} to
      update it.
    </p>

    <p>
      Willow Servicing supports the latest stable release (except where noted) of these browsers: Chrome, Firefox,
      Microsoft Edge, and Safari. Note: Prerelease (such as Beta) versions of operating systems and browsers are not
      supported.
    </p>
  </>
);

export const Paperless = (selectedCompany: CompanyFragment) => ({
  searchKey: '?paperless',
  title: 'Paperless Disclosures',
  lastModified: undefined,
  content: <Content selectedCompany={selectedCompany} />,
});
