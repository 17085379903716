import { isUndefined, omit } from 'lodash';
import * as z from 'zod';

import { StateAbbreviation, Zipcode } from './validations/fields';

export const DEFAULT_COUNTRY = 'USA';

// Allows required fields to be ''
export const Address = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  line3: z.string().optional(),
  line4: z.string().optional(),
  locality: z.string(),
  region: z.string(),
  postcode: z.string(),
  county: z.string().optional(),
  country: z.string(),
});

export type Address = z.infer<typeof Address>;

// Coerces addresses with '' in required fields to undefined so that invalid addresses aren't saved.
export const PaymentAddress = Address.transform((data) => {
  if (data.line1.length === 0 || data.locality.length === 0 || data.region.length === 0 || data.postcode.length === 0) {
    return undefined;
  }
  return data;
});

export type PaymentAddress = z.infer<typeof PaymentAddress>;

// Fails addresses with '' in required fields, fails invalid Zip and State abbreviations
export const StrictAddress = z.object({
  line1: z.string().trim().min(1, { message: 'Required' }),
  line2: z.string().optional(),
  line3: z.string().optional(),
  line4: z.string().optional(),
  locality: z.string().trim().min(1, { message: 'Required' }),
  region: StateAbbreviation,
  postcode: Zipcode,
  county: z.string().optional(),
  country: z.string().trim().min(1, { message: 'Required' }),
});
export type StrictAddress = z.infer<typeof StrictAddress>;

// Optional address that passes when empty, but fails if partially filled in with missing required fields
export const OptionalAddress = Address.optional().refine(
  (address) => {
    if (address == null) return true;

    if (!Object.values(omit(address, 'country')).some((val) => !(isUndefined(val) || val.trim() === ''))) return true; // all fields are blank (ignore country which forms are hardcoding)
    return StrictAddress.safeParse(address).success;
  },
  { message: 'Invalid address' },
);
export type OptionalAddress = z.infer<typeof StrictAddress>;
