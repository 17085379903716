import { CloudUploadOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DropzoneState } from 'react-dropzone';

interface DragDropUploaderProps {
  dropzone: DropzoneState;
  fileCount: number;
}
export const DragDropUploader = ({ dropzone, fileCount }: DragDropUploaderProps) => {
  const { getRootProps, getInputProps, isDragActive } = dropzone;
  return (
    <>
      {fileCount === 0 && (
        <div
          {...getRootProps({
            className: classNames('d-flex align-items-center justify-content-center u-border-1 u-pointer', {
              'u-border-color-bark1 u-bg-bark00 u-color-bark3': !isDragActive,
              'u-border-color-bark2 u-bg-bark0 u-color-bark3': isDragActive,
            }),
          })}
        >
          <div className="text-center py-5">
            <CloudUploadOutlined className="fs-2" />

            <div className="mt-2">Drag and drop or click here to upload a file</div>
          </div>
        </div>
      )}

      <input {...getInputProps()} />
    </>
  );
};
