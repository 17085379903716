import { ceil, floor, round } from 'lodash';

export function subtract(...nums: number[]): number {
  return subtractPrecision(2, ...nums);
}
export function subtractPrecision(precision: number = 2, ...nums: number[]): number {
  if (!nums.length) return 0;

  let difference = nums.shift() as number;
  for (const num of nums) {
    difference -= num;
  }
  return round(difference, precision);
}

export function add(...nums: number[]): number {
  return addPrecision(2, ...nums);
}
export function addPrecision(precision: number = 2, ...nums: number[]): number {
  let sum = 0;
  for (const num of nums) {
    sum += num;
  }
  return round(sum, precision);
}

export function multiply(...nums: number[]): number {
  return multiplyPrecision(2, ...nums);
}
export function multiplyPrecision(precision: number = 2, ...nums: number[]): number {
  const total = nums.reduce((acc, num) => acc * num, 1);
  return round(total, precision);
}

export function divide(numerator: number, denominator: number, roundingDigits: number = 2): number {
  return round(numerator / denominator, roundingDigits);
}

export function roundTo(
  method: 'up' | 'down' | 'nearest',
  numToRound: number,
  numToRoundTo: number,
  precision: number = 2,
): number {
  const percentage = numToRoundTo === 0 ? numToRound : numToRound / numToRoundTo;
  let roundedNum: number = 0;
  switch (method) {
    case 'up':
      const ceiling = ceil(percentage);
      roundedNum = numToRoundTo === 0 ? ceiling : ceiling * numToRoundTo;
      break;
    case 'down':
      const floored = floor(percentage);
      roundedNum = numToRoundTo === 0 ? floored : floored * numToRoundTo;
      break;
    case 'nearest':
      const nearest = round(percentage);
      roundedNum = numToRoundTo === 0 ? nearest : nearest * numToRoundTo;
      break;
    default:
      break;
  }

  return round(roundedNum, precision);
}
