import { z } from 'zod';

export const CompanyThirdPartyIds = z.object({
  fnmaServicerId: z.string().optional(),
  fhlmServicerId: z.string().optional(),
  fhaLenderId: z.string().optional(),
  gnmaServicerId: z.string().optional(),
  vaServicerId: z.string().optional(),
  experianId: z.string().optional(),
  transunionId: z.string().optional(),
  equifaxId: z.string().optional(),
});
export type CompanyThirdPartyIds = z.infer<typeof CompanyThirdPartyIds>;
