import { join } from 'lodash';
import { generatePath } from 'react-router-dom';

export const PORTAL_LOAN_BASE_ROUTE = '/:companyId/loan/:losId' as const;
export type PortalLoanParams = { losId: string; companyId: string };

const getLoanPath =
  (pageSlug?: string) =>
  (params: PortalLoanParams, opts: { search?: string } = {}) => {
    const rootPath = generatePath(PORTAL_LOAN_BASE_ROUTE, params);
    const path = pageSlug ? join([rootPath, pageSlug], '/') : rootPath;

    let { search } = opts;
    if (search) {
      search = search.startsWith('?') ? search : `?${search}`;
    }

    return join([path, search], '');
  };

const getLoanRoute = (pageSlug?: string) => join([PORTAL_LOAN_BASE_ROUTE, pageSlug], '/');

const PORTAL_LOAN_SLUGS = {
  root: undefined,
  transactions: 'transactions',
  documents: 'documents',
  faq: 'faq',
  loanOverview: 'loan-overview',
  manageLoan: {
    root: 'manage-loan',
    contactUs: 'manage-loan/contact-us',
  },
  applications: 'applications',
  myAccount: {
    root: 'my-account',
    disclosures: 'my-account/disclosures',
  },
};

// TODO: Assert deep matching key structure of PORTAL_LOAN_SLUGS
export const PORTAL_LOAN_PATHS = {
  root: getLoanPath(PORTAL_LOAN_SLUGS.root),
  transactions: getLoanPath(PORTAL_LOAN_SLUGS.transactions),
  documents: getLoanPath(PORTAL_LOAN_SLUGS.documents),
  faq: getLoanPath(PORTAL_LOAN_SLUGS.faq),
  loanOverview: getLoanPath(PORTAL_LOAN_SLUGS.loanOverview),
  manageLoan: {
    root: getLoanPath(PORTAL_LOAN_SLUGS.manageLoan.root),
    contactUs: getLoanPath(PORTAL_LOAN_SLUGS.manageLoan.contactUs),
  },
  applications: getLoanPath(PORTAL_LOAN_SLUGS.applications),
  myAccount: {
    root: getLoanPath(PORTAL_LOAN_SLUGS.myAccount.root),
    disclosures: getLoanPath(PORTAL_LOAN_SLUGS.myAccount.disclosures),
  },
};

export const PORTAL_LOAN_ROUTES = {
  root: getLoanRoute(PORTAL_LOAN_SLUGS.root),
  transactions: getLoanRoute(PORTAL_LOAN_SLUGS.transactions),
  documents: getLoanRoute(PORTAL_LOAN_SLUGS.documents),
  faq: getLoanRoute(PORTAL_LOAN_SLUGS.faq),
  loanOverview: getLoanRoute(PORTAL_LOAN_SLUGS.loanOverview),
  manageLoan: getLoanRoute(PORTAL_LOAN_SLUGS.manageLoan.root),
  applications: getLoanRoute(PORTAL_LOAN_SLUGS.applications),
  myAccount: getLoanRoute(PORTAL_LOAN_SLUGS.myAccount.root),
};
