import { z } from 'zod';

import { CreateLoanRow } from '@willow/types-iso';

import { getParsedFields } from '../CreateLoanCrossFieldParser';

export const validateAuthorizedContactFields: z.SuperRefinement<CreateLoanRow> = (data, ctx) => {
  const parsed = getParsedFields(data, [
    'constructionLoanType',
    'builderCompanyName',
    'builderAddressLine1',
    'builderAddressLine2',
    'builderAddressCity',
    'builderAddressState',
    'builderAddressZip',
    'builderEmail',
    'builderPhone',
    'builderEmailConsent',
    'builderIsResponsibleForPayment',
  ]);

  if (!parsed) return;

  const {
    constructionLoanType,
    builderCompanyName,
    builderAddressLine1,
    builderAddressLine2,
    builderAddressCity,
    builderAddressState,
    builderAddressZip,
    builderPhone,
    builderIsResponsibleForPayment,
  } = parsed;

  const isConstructionLoan = constructionLoanType != null;

  if (!isConstructionLoan && builderIsResponsibleForPayment) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Loan is not valid for sending builder statements',
      path: ['builderIsResponsibleForPayment'],
    });
  }

  if (
    !builderCompanyName &&
    [
      builderAddressLine1,
      builderAddressLine2,
      builderAddressCity,
      builderAddressState,
      builderAddressZip,
      builderPhone,
    ].some((f) => f != null)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Builder must have company name to be created',
      path: ['builderCompanyName'],
    });
  }

  const REQUIRED_ADDRESS_FIELDS: (keyof CreateLoanRow)[] = [
    'builderAddressLine1',
    'builderAddressCity',
    'builderAddressState',
    'builderAddressZip',
  ];
  const hasMailingAddress = REQUIRED_ADDRESS_FIELDS.some((key) => parsed[key] != null);
  if (hasMailingAddress) {
    const missingFields: (keyof CreateLoanRow)[] = [];
    for (const key of REQUIRED_ADDRESS_FIELDS) {
      // @ts-ignore
      if (parsed[key] == null) {
        missingFields.push(key);
      }
    }
    if (missingFields.length) {
      const missingFieldsString = missingFields.map((key) => `\`${String(key)}\``).join(', ');
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Builder mailing address is missing required ${
          missingFields.length > 1 ? 'fields' : 'field'
        } ${missingFieldsString}.`,
        path: ['builderAddressLine1'],
      });
    }
  }
};
