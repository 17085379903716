import { z } from 'zod';

import { BorrowerId, LoanId, LoanNoteId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

/* TOPICS */
const PaymentResolutionTopicType = z.union([
  z.literal('promise'),
  z.literal('noPromise'),
  z.literal('repayment'),
  z.literal('modification'),
  z.literal('paymentMailed'),
  z.literal('stoppedPayment'),
  z.literal('billing'),
  z.literal('lossMitigation'),
  z.literal('paymentProcessed'),
  z.literal('generalPaymentResolution'),
]);
type PaymentResolutionTopicType = z.infer<typeof PaymentResolutionTopicType>;

const PaymentPortalTopicType = z.union([
  z.literal('autopay'),
  z.literal('login'),
  z.literal('connectBank'),
  z.literal('emailUndelivered'),
  z.literal('generalPaymentPortal'),
]);
type PaymentPortalTopicType = z.infer<typeof PaymentPortalTopicType>;

const LoanTransferTopicType = z.union([
  z.literal('paymentWrongServicer'),
  z.literal('goodbyeLetter'),
  z.literal('generalLoanTransfer'),
]);
type LoanTransferTopicType = z.infer<typeof LoanTransferTopicType>;

const TaxEscrowTopicType = z.union([
  z.literal('tax'),
  z.literal('insurance'),
  z.literal('damage'),
  z.literal('escrowPayment'),
  z.literal('escrowAnalysisTaxEscrow'),
  z.literal('generalTaxEscrow'),
]);
type TaxEscrowTopicType = z.infer<typeof TaxEscrowTopicType>;

const ContactTopicType = z.union([
  z.literal('editContact'),
  z.literal('econsentPreference'),
  z.literal('addContact'),
  z.literal('removeContact'),
  z.literal('welcomeInformation'),
  z.literal('generalContact'),
]);
type ContactTopicType = z.infer<typeof ContactTopicType>;

const DocumentTopicType = z.union([
  z.literal('doc1098'),
  z.literal('monthlyStatement'),
  z.literal('escrowAnalysisDocuments'),
  z.literal('payoff'),
  z.literal('generalDocuments'),
]);
type DocumentTopicType = z.infer<typeof DocumentTopicType>;

const ComplaintTopicType = z.union([z.literal('qualifiedWrittenRequest'), z.literal('generalComplaint')]);
type ComplaintTopicType = z.infer<typeof ComplaintTopicType>;

const OtherTopicType = z.literal('other');
type OtherTopicType = z.infer<typeof OtherTopicType>;

export const LoanNoteTopicType = z.union([
  PaymentResolutionTopicType,
  PaymentPortalTopicType,
  LoanTransferTopicType,
  TaxEscrowTopicType,
  ContactTopicType,
  DocumentTopicType,
  ComplaintTopicType,
  OtherTopicType,
]);
export type LoanNoteTopicType = z.infer<typeof LoanNoteTopicType>;

/* REASONS */
const reasonDeath = z.literal('death');
const reasonMarital = z.literal('marital');
const reasonChangeIncome = z.literal('changeIncome');
const reasonFinancialObligation = z.literal('financialObligation');
const reasonFraud = z.literal('fraud');
const reasonLackRental = z.literal('lackRental');
const reasonUnableSell = z.literal('unableSell');
const reasonDefault = z.literal('default');
const reasonTaxEscrow = z.literal('taxEscrow');
const reasonMilitary = z.literal('military');
const reasonIllness = z.literal('illness');
const reasonPaymentMisapplication = z.literal('paymentMisapplication');
const reasonNotificationNotReceieved = z.literal('notificationNotReceived');
const reasonOther = z.literal('other');
const reasonDispute = z.literal('dispute');
const reasonConstruction = z.literal('construction');
const reasonProperty = z.literal('property');
const reasonOrigination = z.literal('origination');
const reasonLoanDetails = z.literal('loanDetails');
const reasonPaymentHandling = z.literal('paymentHandling');
const reasonSubservicer = z.literal('subservicer');
const reasonNewServicer = z.literal('newServicer');
const reasonNoContact = z.literal('noContact');
const reasonDeathBorrowerFamily = z.literal('deathBorrowerFamily');
const reasonIllnessBorrowerFamily = z.literal('illnessBorrowerFamily');
const reasonAbandonmentOfProperty = z.literal('abandonmentOfProperty');
const reasonDistanceEmploymentTransfer = z.literal('distanceEmploymentTransfer');
const reasonUnemployment = z.literal('unemployment');
const reasonBusinessFailure = z.literal('businessFailure');
const reasonCasualtyLoss = z.literal('casualtyLoss');
const reasonEnergyEnvironmentCost = z.literal('energyEnvironmentCost');
const reasonServicingProblems = z.literal('servicingProblems');
const reasonPaymentAdjustment = z.literal('paymentAdjustment');
const reasonPaymentDispute = z.literal('paymentDispute');
const reasonTransferOwnership = z.literal('transferOwnership');
const reasonNationalEmergency = z.literal('nationalEmergency');
const reasonNationalEmergencyRelated = z.literal('nationalEmergencyRelated'); // FannieFreddie & HUD have different codes for this reason
const reasonEligibleDisaster = z.literal('eligibleDisaster');
const reasonIncarceration = z.literal('incarceration');
const reasonNeighborhoodProblem = z.literal('neighborhoodProblem');

export const LoanNoteReason = z.union([
  reasonDeath,
  reasonMarital,
  reasonChangeIncome,
  reasonFinancialObligation,
  reasonFraud,
  reasonLackRental,
  reasonUnableSell,
  reasonDefault,
  reasonTaxEscrow,
  reasonMilitary,
  reasonIllness,
  reasonPaymentMisapplication,
  reasonNotificationNotReceieved,
  reasonOther,
  reasonDispute,
  reasonConstruction,
  reasonProperty,
  reasonOrigination,
  reasonLoanDetails,
  reasonPaymentHandling,
  reasonSubservicer,
  reasonNewServicer,
  reasonNoContact,
  reasonDeathBorrowerFamily,
  reasonIllnessBorrowerFamily,
  reasonAbandonmentOfProperty,
  reasonDistanceEmploymentTransfer,
  reasonUnemployment,
  reasonBusinessFailure,
  reasonCasualtyLoss,
  reasonEnergyEnvironmentCost,
  reasonServicingProblems,
  reasonPaymentAdjustment,
  reasonPaymentDispute,
  reasonTransferOwnership,
  reasonNationalEmergency,
  reasonNationalEmergencyRelated,
  reasonEligibleDisaster,
  reasonIncarceration,
  reasonNeighborhoodProblem,
]);
export type LoanNoteReason = z.infer<typeof LoanNoteReason>;

export const LoanNoteReasonCodes: Record<LoanNoteReason, string | undefined> = {
  death: '001',
  illness: '002',
  illnessBorrowerFamily: '003',
  deathBorrowerFamily: '004',
  marital: '005',
  changeIncome: '006',
  financialObligation: '007',
  abandonmentOfProperty: '008',
  distanceEmploymentTransfer: '009',
  neighborhoodProblem: '010',
  property: '011',
  unableSell: '012',
  lackRental: '013',
  military: '014',
  other: '015',
  unemployment: '016',
  businessFailure: '017',
  casualtyLoss: '019',
  energyEnvironmentCost: '022',
  servicingProblems: '023',
  paymentAdjustment: '026',
  paymentDispute: '027',
  transferOwnership: '029',
  fraud: '030',
  noContact: '031',
  nationalEmergency: '032',
  nationalEmergencyRelated: '055',
  eligibleDisaster: '034',
  incarceration: 'INC',
  default: undefined,
  taxEscrow: undefined,
  paymentMisapplication: undefined,
  notificationNotReceived: undefined,
  dispute: undefined,
  construction: undefined,
  origination: undefined,
  loanDetails: undefined,
  paymentHandling: undefined,
  subservicer: undefined,
  newServicer: undefined,
};

/* TOPICS + REASONS */
const PaymentResolutionTopic = z.object({
  topic: PaymentResolutionTopicType,
  reason: z.union([
    reasonDeath,
    reasonMarital,
    reasonChangeIncome,
    reasonFinancialObligation,
    reasonFraud,
    reasonLackRental,
    reasonUnableSell,
    reasonDefault,
    reasonMilitary,
    reasonIllness,
    reasonPaymentMisapplication,
    reasonNotificationNotReceieved,
    reasonDispute,
    reasonConstruction,
    reasonProperty,
    reasonNoContact,
    reasonOther,
    reasonDeathBorrowerFamily,
    reasonIllnessBorrowerFamily,
    reasonAbandonmentOfProperty,
    reasonDistanceEmploymentTransfer,
    reasonUnemployment,
    reasonBusinessFailure,
    reasonCasualtyLoss,
    reasonEnergyEnvironmentCost,
    reasonServicingProblems,
    reasonPaymentAdjustment,
    reasonPaymentDispute,
    reasonTransferOwnership,
    reasonNationalEmergency,
    reasonNationalEmergencyRelated,
    reasonEligibleDisaster,
    reasonIncarceration,
    reasonNeighborhoodProblem,
  ]),
});

const PaymentPortalTopic = z.object({
  topic: PaymentPortalTopicType,
  reason: z.union([
    reasonDeath,
    reasonMarital,
    reasonFraud,
    reasonDefault,
    reasonMilitary,
    reasonPaymentMisapplication,
    reasonNotificationNotReceieved,
    reasonNoContact,
    reasonOther,
  ]),
});

const LoanTransferTopic = z.object({
  topic: LoanTransferTopicType,
  reason: z.union([reasonPaymentMisapplication, reasonNotificationNotReceieved, reasonNoContact, reasonOther]),
});

const TaxEscrowTopic = z.object({
  topic: TaxEscrowTopicType,
  reason: z.union([reasonTaxEscrow, reasonDispute, reasonConstruction, reasonProperty, reasonNoContact, reasonOther]),
});

const ContactTopic = z.object({
  topic: ContactTopicType,
  reason: z.union([reasonDeath, reasonMarital, reasonNotificationNotReceieved, reasonNoContact, reasonOther]),
});

const DocumentTopic = z.object({
  topic: DocumentTopicType,
  reason: z.union([
    reasonDefault,
    reasonTaxEscrow,
    reasonPaymentMisapplication,
    reasonNotificationNotReceieved,
    reasonDispute,
    reasonNoContact,
    reasonOther,
  ]),
});

const ComplaintTopic = z.object({
  topic: ComplaintTopicType,
  reason: z.union([
    reasonProperty,
    reasonOrigination,
    reasonLoanDetails,
    reasonPaymentHandling,
    reasonSubservicer,
    reasonNewServicer,
    reasonNoContact,
    reasonOther,
  ]),
});

const OtherTopic = z.object({
  topic: z.literal('other'),
  reason: z.union([
    reasonDeath,
    reasonMarital,
    reasonChangeIncome,
    reasonFinancialObligation,
    reasonFraud,
    reasonLackRental,
    reasonUnableSell,
    reasonDefault,
    reasonMilitary,
    reasonIllness,
    reasonPaymentMisapplication,
    reasonNotificationNotReceieved,
    reasonDispute,
    reasonConstruction,
    reasonProperty,
    reasonNoContact,
    reasonOther,
    reasonDeathBorrowerFamily,
    reasonIllnessBorrowerFamily,
    reasonAbandonmentOfProperty,
    reasonDistanceEmploymentTransfer,
    reasonUnemployment,
    reasonBusinessFailure,
    reasonCasualtyLoss,
    reasonEnergyEnvironmentCost,
    reasonServicingProblems,
    reasonPaymentAdjustment,
    reasonPaymentDispute,
    reasonTransferOwnership,
    reasonNationalEmergency,
    reasonNationalEmergencyRelated,
    reasonEligibleDisaster,
    reasonIncarceration,
    reasonNeighborhoodProblem,
  ]),
});

export const LoanNoteTopic = z.union([
  PaymentResolutionTopic,
  PaymentPortalTopic,
  LoanTransferTopic,
  TaxEscrowTopic,
  ContactTopic,
  DocumentTopic,
  ComplaintTopic,
  OtherTopic,
]);
export type LoanNoteTopic = z.infer<typeof LoanNoteTopic>;

/* CONTACT TYPE */
export const LoanNoteContactType = z.union([
  z.literal('inboundCall'),
  z.literal('outboundCall'),
  z.literal('welcomeCall'),
  z.literal('voicemail'),
  z.literal('inboundEmail'),
  z.literal('outboundEmail'),
  z.literal('inboundText'),
  z.literal('noContact'),
  z.literal('inPerson'),
  z.literal('paperMail'),
  z.literal('other'),
]);
export type LoanNoteContactType = z.infer<typeof LoanNoteContactType>;

/* CONTACT ROLE */
const LoanNoteContactRoleBorrower = z.union([z.literal('primaryBorrower'), z.literal('coBorrower')]);
type LoanNoteContactRoleBorrower = z.infer<typeof LoanNoteContactRoleBorrower>;

const LoanNoteContactRoleNonBorrower = z.union([
  z.literal('authorizedThirdParty'),
  z.literal('attorneyRepresentingBorrower'),
  z.literal('stateAgency'),
  z.literal('cfpb'),
  z.literal('nonCfpbRegulatory'),
  z.literal('noContact'),
  z.literal('other'),
]);
type LoanNoteContactRoleNonBorrower = z.infer<typeof LoanNoteContactRoleNonBorrower>;

export const LoanNoteContactRole = z.union([LoanNoteContactRoleBorrower, LoanNoteContactRoleNonBorrower]);
export type LoanNoteContactRole = z.infer<typeof LoanNoteContactRole>;

export const LoanNotePin = z.object({
  userId: zodBrandedUuid<UserId>(),
  pinnedAt: zodDateOrString,
});
export type LoanNotePin = z.infer<typeof LoanNotePin>;

/* NOTE */
const LoanNoteBase = z.object({
  id: zodBrandedUuid<LoanNoteId>(),
  loanId: zodBrandedUuid<LoanId>(),

  // These fields will be required if the company enables the loanNoteCategoriesRequired setting
  topics: z.array(LoanNoteTopic).optional(),
  contactType: LoanNoteContactType.optional(),
  contactRole: LoanNoteContactRoleNonBorrower.optional(),

  comment: z.string(),

  pin: LoanNotePin.optional(),

  userId: zodBrandedUuid<UserId>(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
type LoanNoteBase = z.infer<typeof LoanNoteBase>;

export const LoanNoteBorrowerContact = LoanNoteBase.extend({
  contactRole: LoanNoteContactRoleBorrower,
  borrowerId: zodBrandedUuid<BorrowerId>(),
});
export type LoanNoteBorrowerContact = z.infer<typeof LoanNoteBorrowerContact>;

export const LoanNote = z.union([LoanNoteBase, LoanNoteBorrowerContact]);
export type LoanNote = z.infer<typeof LoanNote>;

/* HELPERS */
export function isBorrowerContactLoanNote(note: LoanNote): note is LoanNoteBorrowerContact {
  return Boolean(note.contactRole && ['primaryBorrower', 'coBorrower'].includes(note.contactRole));
}
