import * as z from 'zod';

import { CompanyId } from './BrandedIds';
import { CompanyChatSettings } from './company/Company';
import { CustomizableEmailTemplateType, EmailTemplateSettings } from './emails/Templates';

export const EmailSettings = z.object({
  recipients: z.array(z.string()),
  bccRecipients: z.array(z.string()).optional(),
  limitedBccRecipients: z.array(z.string()).optional(),
  mortgageeBccRecipients: z.array(z.string()).optional(),
  emails: z.object({
    paymentSubmitted: z.boolean(),
    paymentCancelled: z.boolean(),
    paymentFailed: z.boolean(),
    paymentSuccessful: z.boolean(),
    sweepFailed: z.boolean().default(true),
    sweepSuccessful: z.boolean().default(true),
    bankConnected: z.boolean(),
    bankRemoved: z.boolean(),
  }),
});
export type EmailSettings = z.infer<typeof EmailSettings>;

export interface CompanyEmailTemplate {
  type: CustomizableEmailTemplateType;
  companyId: CompanyId;
  settings: EmailTemplateSettings;
  createdAt: Date;
  updatedAt: Date;
}

export const DEFAULT_EMAIL_SETTINGS: EmailSettings = {
  recipients: [],
  bccRecipients: [],
  limitedBccRecipients: [],
  mortgageeBccRecipients: [],
  emails: {
    paymentSubmitted: true,
    paymentCancelled: true,
    paymentFailed: true,
    paymentSuccessful: true,
    sweepFailed: true,
    sweepSuccessful: true,
    bankConnected: true,
    bankRemoved: true,
  },
};

export const DEFAULT_CHAT_SETTINGS: CompanyChatSettings = {};
