import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { PaymentAssistanceInquiryDocument } from '@willow/graphql-iso/src/portal';
import { formatCurrency, phoneNumberFormat } from '@willow/shared-iso';
import { Button, ControlledInput, NamedMemo, useZodHookForm, z } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { WillowModal } from '../modal/Modal';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

const FormShape = z.object({
  message: z.string().min(10, { message: 'Enter a minimum of 10 characters' }),
});

export const PaymentAssistanceInquiry = NamedMemo<Props>('PaymentAssistanceInquiry', ({ loan }) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [paymentAssistanceInquiry] = useMutation(PaymentAssistanceInquiryDocument);
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useZodHookForm(FormShape, {
    defaultValues: {
      message: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await paymentAssistanceInquiry({
        variables: {
          companyId: loan.company.id,
          loanId: loan.id,
          message: data.message,
          quotedPastDueTotal: loan.currentSnapshot.totalPastDueAmountRemainingFromBorrower,
        },
      });

      setShowSuccessModal(true);
      reset();
    } catch {
      toast.error(`Something went wrong submitting the inquiry form. Please try again.`);
    }
  });

  return (
    <>
      <ManageLoanHeader
        title="Submit a Payment Assistance Inquiry"
        subtitle="If you are experiencing a financial hardship, please work with us to help get your loan back on track."
      />

      <Form onSubmit={onSubmit}>
        <div className="u-color-bark3 my-4">
          <b>Past Due Total: </b>
          {formatCurrency(loan.currentSnapshot.totalPastDueAmountRemainingFromBorrower)}
        </div>

        <Form.Group className="w-100">
          <ControlledInput
            control={control}
            fieldName="message"
            label="Tell us more about your financial hardship"
            placeholder="Provide details"
            inputType="textarea"
            textareaRows={4}
          />
        </Form.Group>

        <div className="mt-3">
          Once submitted, a member from our team will be in contact to discuss options available to you.
        </div>
        <div className="mt-4 w-100 d-flex justify-content-end">
          <Button variant="primary" size="md" onClick={onSubmit} loading={isSubmitting}>
            Send
          </Button>
        </div>
      </Form>

      <WillowModal showModal={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        <h2 className="u-fs-4 u-bold mb-2">Your inquiry has been sent</h2>
        <div>
          Our team is reviewing your note. We will be in touch shortly to discuss options. For immediate assistance
          please call us at{' '}
          <a href={`tel: ${loan.company.supportPhone}`} aria-label={`phone number: ${loan.company.supportPhone}`}>
            {phoneNumberFormat(loan.company.supportPhone)}
          </a>
          .
        </div>
        <div className="mt-4 ms-auto">
          <Button
            variant="primary"
            size="sm"
            className="hover-override hover:u-bg-secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </div>
      </WillowModal>
    </>
  );
});
