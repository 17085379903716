import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import {
  DwollaBank,
  GetDwollaUserDocument,
  PortalLoanSnapshot,
  RemoveBankDocument,
} from '@willow/graphql-iso/src/portal';
import { Loader, NamedMemo } from '@willow/shared-web';

import { BankContainer } from './BankContainer';

import './PaymentMethod.scss';

interface Props {
  companyName: string;
  autopaySettings: PortalLoanSnapshot['autopaySettings'];
  onBankUpdate: (bank: DwollaBank | undefined) => void;
  onIsBusy?: (isBusy: boolean) => void;
}

export const PaymentMethod = NamedMemo<Props>(
  'PaymentMethod',
  ({ companyName, autopaySettings, onBankUpdate, onIsBusy }) => {
    const { loading, error, data, refetch } = useQuery(GetDwollaUserDocument, {
      fetchPolicy: 'no-cache',
    });

    const [removeBank] = useMutation(RemoveBankDocument);

    const banks = useMemo<DwollaBank[]>(() => {
      if (!loading && !error && data) {
        return data.portalSelf.banks;
      }
      if (!loading && error) {
        toast.error('Error loading bank accounts. Please wait a few moments and try refreshing the page.');
      }
      return [];
    }, [loading, error, data]);

    useEffect(() => {
      // If there are banks, the first one will be selected by default
      // Let the parent form know this is the selected payment method
      onBankUpdate(banks?.length ? banks[0] : undefined);
    }, [banks, onBankUpdate]);

    return (
      <div className="payment-method make-payment__container0">
        {loading ? (
          <Loader />
        ) : (
          data?.portalSelf?.linkToken && (
            <BankContainer
              banks={banks}
              companyName={companyName}
              linkToken={data.portalSelf.linkToken}
              microDepositVerification={data.portalSelf.microDepositVerification}
              autopaySettings={autopaySettings}
              refetch={refetch}
              removeBank={removeBank}
              onIsBusy={onIsBusy}
              onSelectedBank={onBankUpdate}
            />
          )
        )}
      </div>
    );
  },
);
