import { NamedMemo, paymentAmountFormat } from '@willow/shared-web';

export interface Props {
  amount: number;
}

export const PaymentAmountWrapper = NamedMemo<Props>('PaymentStatusWrapper', ({ amount }) => {
  return (
    <div
      className="py-2 u-color-primary u-fs-9 u-bold"
      data-ghost="portal-loan--payment-header-amount"
      data-testid="payment-header-amount"
    >
      {paymentAmountFormat(amount)}
    </div>
  );
});
