import { z } from 'zod';

import { PaymentConfigId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateDelinquentInterestAppliedSideEffectContext = BaseLoanIdSideEffect.extend({
  paymentConfigId: zodBrandedUuid<PaymentConfigId>(),
});

export const GenerateDelinquentInterestAppliedSideEffect = GenerateDelinquentInterestAppliedSideEffectContext.extend({
  type: z.literal('generateDelinquentInterestApplied'),
});
export type GenerateDelinquentInterestAppliedSideEffect = z.infer<typeof GenerateDelinquentInterestAppliedSideEffect>;
