import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { Transfer } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertTransferSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  transfer: Transfer,
  paymentId: zodBrandedUuid<PaymentId>(),
  isSweep: z.boolean(),
});
export const UpsertTransferSideEffect = UpsertTransferSideEffectContext.extend({
  type: z.literal('upsertTransfer'),
});
export type UpsertTransferSideEffect = z.infer<typeof UpsertTransferSideEffect>;
