import { z } from 'zod';

import { zodDateOrString } from '../../utils';

const AccpetedOrRejected = z.enum(['accepted', 'rejected']);

/**
 * REO Collected Data
 */
export const ReoStepEvictionOfOccupiedPropertyCollectedData = z.object({
  type: z.literal('reoStepEvictionOfOccupiedPropertyCollectedData'),
  propertyOccupied: z.boolean(),
  sentDate: zodDateOrString,
  mailService: z.string(),
  trackingNumber: z.string(),
});
export type ReoStepEvictionOfOccupiedPropertyCollectedData = z.infer<
  typeof ReoStepEvictionOfOccupiedPropertyCollectedData
>;

export const ReoStepOrderInspectionAndValuationCollectedData = z.object({
  type: z.literal('reoStepOrderInspectionAndValuationCollectedData'),
  inspectionCompany: z.string(),
  inspectionCompanyPhone: z.string(),
  inspectionSentDate: zodDateOrString,
  valuationCompany: z.string(),
  valuationCompanyPhone: z.string(),
  valuationSentDate: zodDateOrString,
});
export type ReoStepOrderInspectionAndValuationCollectedData = z.infer<
  typeof ReoStepOrderInspectionAndValuationCollectedData
>;

export const ReoStepOrderRepairsAndPreservationCollectedData = z.object({
  type: z.literal('reoStepOrderRepairsAndPreservationCollectedData'),
  areRepairsRequired: z.boolean(),
  estimatedRepairCost: z.number(),
  repairDetails: z.string(),
  isPropertyPreservationRequired: z.boolean(),
  estimatedPreservationCost: z.number(),
  preservationDetails: z.string(),
});
export type ReoStepOrderRepairsAndPreservationCollectedData = z.infer<
  typeof ReoStepOrderRepairsAndPreservationCollectedData
>;

export const ReoStepPropertyListingCollectedData = z.object({
  type: z.literal('reoStepPropertyListingCollectedData'),
  propertyValuation: z.number(),
  listingAgentName: z.string(),
  listingAgentEmail: z.string(),
  listingAgentPhone: z.string(),
  listingAgentLicenseNumber: z.string(),
  listingDate: zodDateOrString,
  listingPrice: z.number(),
});
export type ReoStepPropertyListingCollectedData = z.infer<typeof ReoStepPropertyListingCollectedData>;

export const ReoStepPropertyReductionCollectedData = z.object({
  type: z.literal('reoStepPropertyReductionCollectedData'),
  newListingPrice: z.number(),
  reductionDate: zodDateOrString,
});
export type ReoStepPropertyReductionCollectedData = z.infer<typeof ReoStepPropertyReductionCollectedData>;

export const ReoStepOfferEvaluationsCollectedData = z.object({
  type: z.literal('reoStepOfferEvaluationsCollectedData'),
  offerPrice: z.number(),
  offerDate: zodDateOrString,
  offerStatus: AccpetedOrRejected,
  offerStatusDate: zodDateOrString,
});
export type ReoStepOfferEvaluationsCollectedData = z.infer<typeof ReoStepOfferEvaluationsCollectedData>;

export const ReoStepCounterOfferDetailsCollectedData = z.object({
  type: z.literal('reoStepCounterOfferDetailsCollectedData'),
  offerPrice: z.number(),
  offerDate: zodDateOrString,
  offerStatus: AccpetedOrRejected,
  offerStatusDate: zodDateOrString,
});
export type ReoStepCounterOfferDetailsCollectedData = z.infer<typeof ReoStepCounterOfferDetailsCollectedData>;

export const ReoStepContractStatusCollectedData = z.object({
  type: z.literal('reoStepContractStatusCollectedData'),
  contractDate: zodDateOrString,
  contractPrice: z.number(),
  netProceeds: z.number(),
  contractStatus: z.enum(['open', 'closed', 'walked']),
});
export type ReoStepContractStatusCollectedData = z.infer<typeof ReoStepContractStatusCollectedData>;

export const ReoStepCollectedData = z.discriminatedUnion('type', [
  ReoStepEvictionOfOccupiedPropertyCollectedData,
  ReoStepOrderInspectionAndValuationCollectedData,
  ReoStepOrderRepairsAndPreservationCollectedData,
  ReoStepPropertyListingCollectedData,
  ReoStepPropertyReductionCollectedData,
  ReoStepOfferEvaluationsCollectedData,
  ReoStepCounterOfferDetailsCollectedData,
  ReoStepContractStatusCollectedData,
]);
export type ReoStepCollectedData = z.infer<typeof ReoStepCollectedData>;
