import { z } from 'zod';

import { PayoffId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GeneratePaidInFullStatementSideEffectContext = BaseLoanIdSideEffect.extend({
  payoffId: zodBrandedUuid<PayoffId>(),
});
export const GeneratePaidInFullStatementSideEffect = GeneratePaidInFullStatementSideEffectContext.extend({
  type: z.literal('generatePaidInFullStatement'),
});
export type GeneratePaidInFullStatementSideEffect = z.infer<typeof GeneratePaidInFullStatementSideEffect>;
