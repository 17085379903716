import { StylesConfig, ThemeConfig } from 'react-select';

export const WILLOW_SELECT_THEME: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--primary-color)',
    primary75: 'var(--primary-color-75)',
    primary50: 'var(--primary-color-50)',
    primary25: 'var(--primary-color-25)',
    danger: 'var(--color-red1)',
    dangerLight: 'var(--color-red0)',
    neutral5: 'var(--color-bark0)',
    neutral10: 'var(--color-bark1)',
    neutral90: 'var(--color-bark4)',
  },
});

export const WILLOW_SELECT_STYLES: StylesConfig<any> = {
  control: (styles, { isFocused, theme }) => ({
    ...styles,
    borderColor: isFocused ? theme.colors.primary50 : 'var(--bs-border-color)',
    boxShadow: isFocused ? `0 0 0 0.25rem ${theme.colors.primary25}` : `0 0 0 0 ${theme.colors.primary25}`,
    borderRadius: 'var(--bs-border-radius)',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    cursor: 'pointer',
    ':hover': {
      borderColor: isFocused ? theme.colors.primary50 : 'var(--bs-border-color)',
    },
  }),
  option: (styles, { isDisabled, isSelected, isFocused, theme }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? theme.colors.neutral10 : isSelected ? theme.colors.neutral5 : styles.backgroundColor,
      ':hover': {
        backgroundColor: theme.colors.neutral10,
      },
      ':active': {
        backgroundColor: '',
      },
      color: theme.colors.neutral90,
      opacity: isDisabled ? 0.4 : 1,
      cursor: isDisabled ? 'none' : 'pointer',
    };
  },
};
