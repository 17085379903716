import * as z from 'zod';

import { StopSettings } from '../loan/StopSettings';
import { BaseLoanEvent } from './BaseLoanEvent';

export const FlagAccountEvent = BaseLoanEvent.extend({
  type: z.literal('flagAccount'),
  stopSettings: StopSettings,
  isFlagged: z.boolean(),
  comment: z.string().optional(),
});
export type FlagAccountEvent = z.infer<typeof FlagAccountEvent>;
