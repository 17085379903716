import { mapValues } from 'lodash';
import { z } from 'zod';

import { ImportRow } from '../loan/LoanImport';
import { CreateTransactionRowData } from '../loan/LoanTransaction';
import { TransferLoanRowData } from '../loan/LoanTransfer';
import { LoanImportRowData } from '../loanImport';
import { trimAndStrip } from '../validations/utils';
import { ConstructionToPermanentRowsPlatformData } from './FlatFilePlatformResponse';

export const FlatfileV1Data = <T extends z.ZodTypeAny>(schema: T) =>
  z
    .object({
      getRows: z.object({
        data: z.array(LoanImportRowData(schema)),
      }),
    })
    .strict()
    .or(FlatFileErrors);

// TODO Could eventually be updated to
// ImportRowsData = FlatfileV1Data(ImportRow);
export const ImportRowsData = z
  .object({
    getRows: z.object({
      data: z.array(
        z.object({
          id: z.string(),
          // Use preprocessor to trim/strip nullish values into undefined
          mapped: z.preprocess((data: any) => mapValues(data, trimAndStrip), ImportRow),
          sequence: z.number(),
        }),
      ),
    }),
  })
  .strict();
export type ImportRowsData = z.infer<typeof ImportRowsData>;

// TODO Could eventually be updated to
// TransferRowsData = FlatfileV1Data(TransferRow);
export const TransferRowsData = z
  .object({
    getRows: z.object({
      data: z.array(
        z.object({
          id: z.string(),
          // Use preprocessor to trim/strip nullish values into undefined
          mapped: z.preprocess((data: any) => mapValues(data, trimAndStrip), TransferLoanRowData),
          sequence: z.number(),
        }),
      ),
    }),
  })
  .strict();
export type TransferRowsData = z.infer<typeof TransferRowsData>;

export const ConstructionToPermanentRowsData = z
  .object({
    getRows: z.object({
      data: ConstructionToPermanentRowsPlatformData,
    }),
  })
  .strict();
export type ConstructionToPermanentRowsData = z.infer<typeof ConstructionToPermanentRowsData>;

// TODO
export const CreateTransactionRowsData = z
  .object({
    getRows: z.object({
      data: z.array(
        z.object({
          id: z.string(),
          // Use preprocessor to trim/strip nullish values into undefined
          mapped: z.preprocess((data: any) => mapValues(data, trimAndStrip), CreateTransactionRowData),
          sequence: z.number(),
        }),
      ),
    }),
  })
  .strict();
export type CreateTransactionRowsData = z.infer<typeof CreateTransactionRowsData>;

export const FlatFileErrors = z.object({
  errors: z
    .object({
      message: z.string(),
    })
    .array(),
});
export type FlatFileErrors = z.infer<typeof FlatFileErrors>;

export const ImportResponse = ImportRowsData.or(FlatFileErrors);
export type ImportResponse = z.infer<typeof ImportResponse>;

export const TransferResponse = TransferRowsData.or(FlatFileErrors);
export type TransferResponse = z.infer<typeof TransferResponse>;

export const ConstructionToPermanentResponse = ConstructionToPermanentRowsData.or(FlatFileErrors);
export type ConstructionToPermanentResponse = z.infer<typeof ConstructionToPermanentResponse>;
