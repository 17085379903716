import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { ContactCTA } from '../contact-cta/ContactCTA';

import './Layout.scss';

export interface LayoutProps {
  loan?: PortalSelectedLoan;
  hasMultipleLoans?: boolean; // allow users with mutliple loans to switch views
  onLoanSwitch?: () => void;
  children: React.ReactNode;
}

export const Layout = NamedMemo<LayoutProps>('Layout', ({ loan, hasMultipleLoans, onLoanSwitch, children }) => {
  // On loan select, update the browser history to remove the losId
  const history = useHistory();
  const { search } = useLocation();
  const handleLoanSwitch = useCallback(() => {
    // preserve impersonation when switching loans
    history.push(`/${search}`);

    if (onLoanSwitch) onLoanSwitch();
  }, [history, onLoanSwitch, search]);

  const { company, losId } = loan || {};

  return (
    <main id="main">
      <div className="wrapper">
        {hasMultipleLoans && loan && (
          <section className="loan-switch-header">
            <div>Loan #{losId}</div>
            <button onClick={handleLoanSwitch}>Switch Loan</button>
          </section>
        )}

        <div className="inner">{children}</div>

        {company && (
          <div className="contact-cta-wrapper">
            <ContactCTA company={company} />
          </div>
        )}
      </div>
    </main>
  );
});
