import * as z from 'zod';

import { zodDateOrString } from '../utils';

/** For fees, charges, advances etc */
export const InvestorRemittanceSplit = z.object({
  splitEnabled: z.boolean(),
  investor: z.number().nonnegative(),
  servicer: z.number().nonnegative(),
});
export type InvestorRemittanceSplit = z.infer<typeof InvestorRemittanceSplit>;

export const DEFAULT_IR_SPLIT: InvestorRemittanceSplit = {
  splitEnabled: false,
  investor: 0,
  servicer: 100,
};

/** Same as above, but contains additional ownership information for the loan level only */
export const LoanInvestorRemittanceSplit = InvestorRemittanceSplit.extend({
  investor2: z.number().nonnegative().optional(),
  investorOwnership: z.number().nonnegative().optional(),
  investor2Ownership: z.number().nonnegative().optional(),
  startDate: zodDateOrString.optional(), // TODO: require after migration
});
export type LoanInvestorRemittanceSplit = z.infer<typeof LoanInvestorRemittanceSplit>;
