/*
  For now, the FAQ items are hardcoded. In the future, we will add the ability to customize this page from lender to lender.
*/

import { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { FAQItem, FAQSection, getFAQData, NamedMemo } from '@willow/shared-web';
import { Accordion } from '@willow/shared-web/bootstrap';

import { PortalSelectedLoan } from '../../App';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';
import { FeatureFlagContext } from '../../contexts/featureFlagContext';

import './FAQ.scss';

interface Props {
  loan: PortalSelectedLoan;
}

export const FAQPage = NamedMemo<Props>('FAQPage', ({ loan }) => {
  const { losId, company } = loan;
  const { name: lenderName, supportEmail, supportPhone, customFAQ } = company;
  const { ff_autopaySettings: autoPayFeatureEnabled } = useContext(FeatureFlagContext);

  const faqData = getFAQData({
    lenderName,
    supportEmail,
    supportPhone,
    losId,
    companyId: company.id,
    autoPayFeatureEnabled,
  });

  const customFAQData: FAQSection[] = customFAQ.map((section) => {
    const items: FAQItem[] = section.items.map((item) => {
      return { question: item.question, answer: ReactHtmlParser(item.answer) };
    });

    return { title: section.title, items };
  });

  return (
    <div className="page-faq">
      <Metadata metadata={{ title: 'Frequently Asked Questions' }} />

      <PageHeading title="Frequently asked questions" />

      <section className="faq-container">
        {faqData.concat(customFAQData).map((data, index) => {
          const { title, items } = data;
          return (
            <div key={`faq-section-${index}`} className="faq-section">
              {title && <h2 className="faq-section__title">{title}</h2>}
              {/* Default to first section + first item open */}
              <Accordion defaultActiveKey={index === 0 ? '0' : undefined}>
                {items.map((item, itemIndex) => {
                  if (!item) return null;
                  const { question, answer } = item;

                  return (
                    <Accordion.Item key={`faq-item-${index}-${itemIndex}`} eventKey={itemIndex.toString()}>
                      <Accordion.Header as="h3">{question}</Accordion.Header>
                      <Accordion.Body>{answer}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          );
        })}
      </section>
    </div>
  );
});
