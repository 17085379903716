import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';

import { Loader, NamedMemo } from '@willow/shared-web';
import { LoanId } from '@willow/types-iso';

import { AutopayContext } from '../../contexts/autopayContext';
import { WillowModal } from '../modal/Modal';

import './AutopayModal.scss';

interface DisableAutopayModalProps {
  loanId: LoanId;
}

export const DisableAutopayModal = NamedMemo('DisableAutopayModalInner', ({ loanId }: DisableAutopayModalProps) => {
  const { showDisableAutopayModal, setShowDisableAutopayModal, updateAutopaySettings, updateAutopaySettingsLoading } =
    useContext(AutopayContext);

  const resetModal = useCallback(() => {
    setShowDisableAutopayModal(false);
  }, [setShowDisableAutopayModal]);

  const onSubmit = useCallback(async () => {
    try {
      await updateAutopaySettings(loanId);
      resetModal();

      toast.success('Auto-pay was disabled');
    } catch (err) {
      toast.error('There was an issue updating auto-pay. Please refresh the page and try again.');
    }
  }, [loanId, resetModal, updateAutopaySettings]);

  return (
    <WillowModal showModal={showDisableAutopayModal} onClose={resetModal}>
      <div className="modal-autopay">
        <h2>Disable Auto-Pay?</h2>

        <div className="modal-autopay__copy">
          Disabling auto-pay will stop all future payments being paid automatically from your connected bank account.
          You can enable auto-pay again at anytime.
        </div>

        <div className="modal-autopay__buttons">
          <button
            type="button"
            onClick={onSubmit}
            className="modal-autopay__submit-btn"
            disabled={updateAutopaySettingsLoading}
          >
            Disable Auto-Pay
            {updateAutopaySettingsLoading && <Loader />}
          </button>
          <button type="button" onClick={resetModal} className="modal-autopay__cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </WillowModal>
  );
});
