/*
  We get here when loan is maturing AND has a payoff statement
*/
import { find } from 'lodash';

import { PortalPayoffFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo, paymentDateFormat } from '@willow/shared-web';

import { PaymentAmountWrapper } from '../PaymentAmountWrapper';
import { PaymentDateWrapper } from '../PaymentDateWrapper';
import { PaymentStatusWrapper } from '../PaymentStatusWrapper';

export interface Props {
  payoffs: PortalPayoffFragment[];
  payoffTotal: number;
}

export const MaturingHeader = NamedMemo<Props>('MaturingHeader', ({ payoffs, payoffTotal }) => {
  const activePayoffStatement = find(payoffs, ['status', 'pending']);
  if (!activePayoffStatement) return <></>;

  return (
    <>
      <PaymentStatusWrapper>Final payment due</PaymentStatusWrapper>
      <PaymentAmountWrapper amount={payoffTotal} />
      <PaymentDateWrapper>{paymentDateFormat(activePayoffStatement.expirationDate)}</PaymentDateWrapper>
    </>
  );
});
