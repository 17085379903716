import * as z from 'zod';

export const SlackAction = z.object({
  action_id: z.string(),
  block_id: z.string(),
  type: z.string(),
  text: z
    .object({
      type: z.string(),
      text: z.string(),
      emoji: z.boolean(),
    })
    .optional(),
  value: z.string(),
  style: z.string().optional(),
  action_ts: z.string(),
});

export type SlackAction = z.infer<typeof SlackAction>;

export const SlackInteractivityPayload = z.object({
  type: z.string(),
  response_url: z.string().optional(),
  actions: z.array(SlackAction),
});

export type SlackInteractivityPayload = z.infer<typeof SlackInteractivityPayload>;

export const SlackEvent = z.object({
  client_msg_id: z.string().optional(),
  type: z.string().optional(),
  text: z.string().optional(),
  user: z.string().optional(),
  ts: z.string(),
  team: z.string().optional(),
  thread_ts: z.string().optional(),
  parent_user_id: z.string().optional(),
  channel: z.string(),
  event_ts: z.string(),
  channel_type: z.string(),
  bot_id: z.string().optional(),
});

export type SlackEvent = z.infer<typeof SlackEvent>;
