import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { DwollaTransfer } from '../Dwolla';
import { DwollaBaseTransfer } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateTransferEvent = BaseLoanEvent.extend({
  type: z.literal('updateTransfer'),
  transfer: DwollaBaseTransfer,
  paymentId: zodBrandedUuid<PaymentId>(),
  isSweep: z.boolean(),
  dwollaTransfer: DwollaTransfer,
  multiBankAccountsEnabled: z.boolean(),
});
export type UpdateTransferEvent = z.infer<typeof UpdateTransferEvent>;
