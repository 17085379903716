import * as z from 'zod';

import { trimAndStripString } from './utils/Zod';

export const BorrowerRequestSettings = z.object({
  payoffRequestEnabled: z.boolean(),
  drawRequestEnabled: z.boolean(),
  generalInquiryEnabled: z.boolean(),
  paymentAssistanceInquiryEnabled: z.boolean(),
  escrowWaiverRequestEnabled: z.boolean(),
  pmiCancellationRequestEnabled: z.boolean(),
});
export type BorrowerRequestSettings = z.infer<typeof BorrowerRequestSettings>;

export const DEFAULT_BORROWER_REQUEST_SETTINGS: BorrowerRequestSettings = {
  payoffRequestEnabled: true,
  drawRequestEnabled: false,
  generalInquiryEnabled: true,
  paymentAssistanceInquiryEnabled: false,
  escrowWaiverRequestEnabled: false,
  pmiCancellationRequestEnabled: false,
};

export const PortalSettings = z.object({
  primaryColor: z
    .string()
    .transform((s) => s || undefined)
    .refine((val) => (val ? val.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/) : true), {
      message: "Hex value must start with '#' followed by 6 alpha-numeric characters. (ie #123ABC)",
    })
    .optional(),
  emailGreeting: z.string().transform(trimAndStripString).optional(),
  statementPdf: z
    .object({
      footer: z
        .string()
        .transform((s) => s || undefined)
        .optional(),
    })
    .optional(), // TODO migration followup delete
  borrowerRequests: BorrowerRequestSettings,
});
export type PortalSettings = z.infer<typeof PortalSettings>;

export const DEFAULT_COMPANY_PORTAL_SETTINGS: PortalSettings = {
  borrowerRequests: DEFAULT_BORROWER_REQUEST_SETTINGS,
};
