import { z } from 'zod';

import { zodDateOrString } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateDelinquentInterestRateEvent = BaseLoanEvent.extend({
  type: z.literal('updateDelinquentInterestRate'),
  delinquentInterestRate: z.number(),
  effectiveDate: zodDateOrString,
  delinquentInterestDeferred: z.boolean().optional(),
  comment: z.string().optional(),
});

export type UpdateDelinquentInterestRateEvent = z.infer<typeof UpdateDelinquentInterestRateEvent>;
