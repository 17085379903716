import * as z from 'zod';

import { UpdateIdsAndRemittanceDataRow } from '../validations/loan/UpdateIdsAndRemittanceDataRow';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateIdsAndRemittanceDataEvent = BaseLoanEvent.extend({
  type: z.literal('updateIdsAndRemittanceData'),
  data: UpdateIdsAndRemittanceDataRow,
});
export type UpdateIdsAndRemittanceDataEvent = z.infer<typeof UpdateIdsAndRemittanceDataEvent>;
