import * as z from 'zod';

import { SftpSiteId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';

export const SftpSite = z.object({
  id: zodBrandedUuid<SftpSiteId>(),
  name: z.string(),
  hostname: z.string(),
  port: z.number(),
  path: z.string(),
  username: z.string(),
  password: z.string(),
});

export type SftpSite = z.infer<typeof SftpSite>;

export type SftpSiteInput = Omit<SftpSite, 'id'>;

export type UpdateSftpSiteInput = SftpSite;
