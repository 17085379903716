import { FC, memo, NamedExoticComponent, PropsWithChildren } from 'react';

// https://github.com/facebook/react/issues/18026
export function NamedMemo<Props extends object>(
  name: string,
  Component: FC<Props>,
  propsAreEqual?: (
    prevProps: Readonly<PropsWithChildren<Props>>,
    nextProps: Readonly<PropsWithChildren<Props>>,
  ) => boolean,
): NamedExoticComponent<Props> {
  Component.displayName = name;
  return memo<Props>(Component, propsAreEqual);
}
