import { z } from 'zod';

import { OptionalPhoneNumber } from '../../../Phone';
import { OptionalEmailString } from '../../email';
import { AddressLine, Checkbox } from '../../fields';

export const AuthorizedContactFields = z.object({
  builderCompanyName: z.string().optional(), // required to create contact
  builderAddressLine1: AddressLine.optional(),
  builderAddressLine2: AddressLine.optional(),
  builderAddressCity: z.string().optional(),
  builderAddressState: z.string().optional(),
  builderAddressZip: z.string().optional(),
  builderEmail: OptionalEmailString,
  builderPhone: OptionalPhoneNumber,
  builderEmailConsent: Checkbox.optional(),
  builderIsResponsibleForPayment: Checkbox.optional(), // loan type validation. defaults false
});
