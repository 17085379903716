import { z } from 'zod';

import { BorrowerEmailConsent } from '../Borrower';
import { AuthorizedContactId, BorrowerId, LoanNoteId, LoanWorkflowId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

const UpdateLoanIdsContext = z.object({
  type: z.literal('updateLoanIds'),
  updatedIds: z.array(z.string()),
});

const RemoveBankContext = z.object({
  type: z.literal('removeBank'),
});

const AddBankContext = z.object({
  type: z.literal('addBank'),
});

const InitiateMicrodepositsContext = z.object({
  type: z.literal('initiateMicrodeposits'),
});

const FirstLoginContext = z.object({
  type: z.literal('firstLogin'),
});

const MicrodepositEmailSentContext = z.object({
  type: z.literal('sentMicrodepositEmail'),
});

const ExpirePayoffContext = z.object({
  type: z.literal('expirePayoff'),
});

const CreateLoanNoteContext = z.object({
  type: z.literal('createLoanNote'),
  loanNoteId: zodBrandedUuid<LoanNoteId>(),
});

const PinLoanNoteContext = z.object({
  type: z.literal('pinLoanNote'),
  loanNoteId: zodBrandedUuid<LoanNoteId>(),
  isPinned: z.boolean(),
});

const CreateLoanWorkflowContext = z.object({
  type: z.literal('createLoanWorkflow'),
  loanWorkflowId: zodBrandedUuid<LoanWorkflowId>(),
  workflowType: z.enum(['foreclosure', 'realEstateOwned', 'chapter7', 'chapter13', 'delinquency']),
});

const CloseLoanWorkflowContext = z.object({
  type: z.literal('closeLoanWorkflow'),
  loanWorkflowId: zodBrandedUuid<LoanWorkflowId>(),
  reason: z.string(),
  comment: z.string().optional(),
});

export const AddUsioBankContext = z.object({
  type: z.literal('addUsioBank'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  accountName: z.string(),
});
export type AddUsioBankContext = z.infer<typeof AddUsioBankContext>;

const DeleteUsioBankContext = z.object({
  type: z.literal('deleteUsioBank'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  accountName: z.string(),
});

const EnableUsioBankContext = z.object({
  type: z.literal('enableUsioBank'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  accountName: z.string(),
});

export const UpdateBorrowerEconsentContext = z.object({
  type: z.literal('updateBorrowerEconsent'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  econsentInput: BorrowerEmailConsent,
});
export type UpdateBorrowerEconsentContext = z.infer<typeof UpdateBorrowerEconsentContext>;

/* Authorized Contacts */
export const AddThirdPartyContactContext = z.object({
  type: z.literal('addThirdPartyContact'),
  authorizedContactId: zodBrandedUuid<AuthorizedContactId>(),
  name: z.string(),
});
export type AddThirdPartyContactContext = z.infer<typeof AddThirdPartyContactContext>;

export const EditThirdPartyContactContext = z.object({
  type: z.literal('editThirdPartyContact'),
  authorizedContactId: zodBrandedUuid<AuthorizedContactId>(),
  name: z.string(),
});
export type EditThirdPartyContactContext = z.infer<typeof EditThirdPartyContactContext>;

export const DeleteThirdPartyContactContext = z.object({
  type: z.literal('deleteThirdPartyContact'),
  name: z.string(),
});
export type DeleteThirdPartyContactContext = z.infer<typeof DeleteThirdPartyContactContext>;

export const AddLoanEntityContext = z.object({
  type: z.literal('addLoanEntity'),
});
export type AddLoanEntityContext = z.infer<typeof AddLoanEntityContext>;

export const EditLoanEntityContext = z.object({
  type: z.literal('editLoanEntity'),
});
export type EditLoanEntityContext = z.infer<typeof EditLoanEntityContext>;

export const EditBorrowerDetailsContext = z.object({
  type: z.literal('editBorrowerDetails'),
  comment: z.string(),
});
export type EditBorrowerDetailsContext = z.infer<typeof EditBorrowerDetailsContext>;
/* ------- */

export const AddAuditLogItemEvent = BaseLoanEvent.extend({
  type: z.literal('addAuditLogItem'),
  context: z.discriminatedUnion('type', [
    UpdateLoanIdsContext,
    RemoveBankContext,
    AddBankContext,
    InitiateMicrodepositsContext,
    MicrodepositEmailSentContext,
    FirstLoginContext,
    ExpirePayoffContext,
    AddUsioBankContext,
    DeleteUsioBankContext,
    EnableUsioBankContext,
    UpdateBorrowerEconsentContext,
    CreateLoanNoteContext,
    PinLoanNoteContext,
    CreateLoanWorkflowContext,
    CloseLoanWorkflowContext,
    AddThirdPartyContactContext,
    EditThirdPartyContactContext,
    DeleteThirdPartyContactContext,
    AddLoanEntityContext,
    EditLoanEntityContext,
    EditBorrowerDetailsContext,
  ]),
  comment: z.string().optional(),
});
export type AddAuditLogItemEvent = z.infer<typeof AddAuditLogItemEvent>;
