import { PortalSelectedLoan } from '../../App';

export const getTotalAmountDue = (loan: PortalSelectedLoan): number => {
  const {
    currentSnapshot: {
      totalAmountRemainingFromBorrower,
      maturityConfig: { isMaturing },
      expectedPayoffTotal,
    },
  } = loan;

  return isMaturing && totalAmountRemainingFromBorrower === 0 ? expectedPayoffTotal : totalAmountRemainingFromBorrower;
};
