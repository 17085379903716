import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ReversePaymentEvent = BaseLoanEvent.extend({
  type: z.literal('reversePayment'),
  paymentId: zodBrandedUuid<PaymentId>(),
  reverseToSuspense: z.boolean().optional(), // TODO: migrate to required
  comment: z.string().optional(),
});
export type ReversePaymentEvent = z.infer<typeof ReversePaymentEvent>;
