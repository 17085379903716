import * as z from 'zod';

import { LoanId, PaymentDueId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const RegenerateStatementPdfEvent = BaseLoanEvent.extend({
  type: z.literal('regenerateStatementPdf'),
  loanId: zodBrandedUuid<LoanId>(),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  disableEmail: z.boolean().optional(),
});
export type RegenerateStatementPdfEvent = z.infer<typeof RegenerateStatementPdfEvent>;
