import { last } from 'lodash';
import * as z from 'zod';

import { EscrowAnalysisValidator } from '@willow/types-iso';

import { getNewMonthlyEscrowPlusShortage } from '../../utils/escrowAnalysis';
import { formatCurrency } from '../../utils/formatCurrency';
import { subtract } from '../../utils/math';

const checkDifference: z.SuperRefinement<EscrowAnalysisValidator> = (data, ctx) => {
  const { newMonthlyEscrowAmount, oldMonthlyEscrowAmount, activeEscrowedItems, shortageOrOverageAmount } = data;

  if (!activeEscrowedItems.length) {
    // The loan is missing escrow data. There's no use comparing the difference.
    // This error is caught by missingEscrowSettings
    return;
  }

  const newMonthlyEscrowPlusShortage = getNewMonthlyEscrowPlusShortage(newMonthlyEscrowAmount, shortageOrOverageAmount);

  let pctChange = 0;
  if (oldMonthlyEscrowAmount < newMonthlyEscrowPlusShortage) {
    pctChange = subtract(newMonthlyEscrowPlusShortage / oldMonthlyEscrowAmount, 1);
  } else if (oldMonthlyEscrowAmount > newMonthlyEscrowPlusShortage) {
    pctChange = subtract(oldMonthlyEscrowAmount / newMonthlyEscrowPlusShortage, 1);
  }

  if (Math.abs(pctChange) > 0.05) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Monthly escrow difference is over 5% (Was: ${formatCurrency(
        oldMonthlyEscrowAmount,
      )}, Now: ${formatCurrency(newMonthlyEscrowPlusShortage)})`,
    });
  }
};

const checkOverage: z.SuperRefinement<EscrowAnalysisValidator> = (data, ctx) => {
  const { historyEscrowBalanceItems, activeEscrowedItems, shortageOrOverageAmount, disbursementDueAmount } = data;

  const endingEscrowBalance = last(historyEscrowBalanceItems)?.actualBalance;

  if (!activeEscrowedItems.length || !disbursementDueAmount || endingEscrowBalance == null) {
    // Don't bother validating if:
    // - The loan is missing escrow data.
    // - The lender doesn't owe the borrower any money as a result of an overage.
    // - Ending escrow balance is null.
    return;
  }

  if (shortageOrOverageAmount > 0 && shortageOrOverageAmount / endingEscrowBalance > 0.05) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Overage greater than 5% of escrow balance (Overage: ${formatCurrency(
        shortageOrOverageAmount,
      )}, Ending escrow balance: ${formatCurrency(endingEscrowBalance)})`,
    });
  }
};

export const EscrowAnalysisWarningParser = z.any().superRefine(checkDifference).superRefine(checkOverage);
