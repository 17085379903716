// This email template is sent 3 days before a payment due IF the loan status is CURRENT
import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

export const SendPaymentReminderEmailEvent = BaseLoanEvent.extend({
  type: z.literal('sendPaymentReminderEmail'),
  ignoreDateCheck: z.boolean().optional(),
});
export type SendPaymentReminderEmailEvent = z.infer<typeof SendPaymentReminderEmailEvent>;
