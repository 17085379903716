import { ExclamationCircleFilled } from '@ant-design/icons';

import { NamedMemo, paymentAmountFormat } from '@willow/shared-web';

import { WillowModal } from '../../modal/Modal';

import './UnderPaymentModal.scss';

interface Props {
  showModal: boolean;
  onClose: () => void;
  minPayment: number;
  submissionAmount: number;
  bankName: string;
  autopayEnabled: boolean;
  onSubmit: () => void;
  disabled: boolean;
}

export const UnderPaymentModal = NamedMemo<Props>(
  'UnderPaymentModal',
  ({ showModal, onClose, minPayment, submissionAmount, bankName, autopayEnabled, onSubmit, disabled }) => {
    return (
      <WillowModal showModal={showModal} onClose={onClose}>
        <div className="modal-underpayment">
          <h2>
            <ExclamationCircleFilled />
            Underpayment Detected
          </h2>

          <div className="modal-underpayment__copy">
            <p>
              Your minimum payment due is <b>{paymentAmountFormat(minPayment)}</b>.
            </p>
            <p>
              A partial payment will not be counted towards your loan until made in full.
              {autopayEnabled && <> The remaining balance is scheduled for auto-payment on your payment's due date.</>}
            </p>
            <p>
              Are you sure you would like to submit {paymentAmountFormat(submissionAmount)} from your {bankName} account
              for payment?
            </p>
          </div>

          <div className="modal-underpayment__buttons">
            <button onClick={onSubmit} className="modal-underpayment__submit-btn" disabled={disabled}>
              Yes, Submit Payment
            </button>
            <button onClick={onClose} className="modal-underpayment__cancel-btn">
              No, Do Not Submit
            </button>
          </div>
        </div>
      </WillowModal>
    );
  },
);
