/*
    The overpayment modal appears when a user attempts to submit a payment for more than the Remaining Amount value.
    The user has the option to decide where the excess payment amount is applied (principal, escrow, or fees)
*/
import { InfoCircleFilled } from '@ant-design/icons';
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';

import { add, subtract } from '@willow/shared-iso';
import { CurrencyInput, NamedMemo, paymentAmountFormat } from '@willow/shared-web';

import { FeatureFlagContext } from '../../../contexts/featureFlagContext';
import { WillowModal } from '../../modal/Modal';
import { AdditionalPayment } from '../PaymentForm';

import './OverPaymentModal.scss';

interface Props {
  showModal: boolean;
  onClose: () => void;
  paymentAmount: number;
  amountDue: number;
  onSubmit: (additionalPayment: AdditionalPayment) => void;
  autopayEnabled: boolean;
  bankName: string;
  disabled: boolean;
}

export const OverPaymentModal = NamedMemo<Props>(
  'OverPaymentModal',
  ({ showModal, onClose, paymentAmount, amountDue, onSubmit, autopayEnabled, bankName, disabled }) => {
    const { ff_portalBalanceForward } = useContext(FeatureFlagContext);
    const [overPaymentAmount, setOverPaymentAmount] = useState<number>(subtract(paymentAmount, amountDue));
    const [additionalPayment, setAdditionalPayment] = useState<AdditionalPayment>({
      principal: subtract(paymentAmount, amountDue),
      escrow: 0,
      suspense: 0,
    });
    const [paymentTotal, setPaymentTotal] = useState<number>(paymentAmount);

    const paymentSubmit = useCallback(
      async (e?: MouseEvent) => {
        if (e) {
          e.preventDefault();
        }

        onSubmit(additionalPayment);
      },
      [onSubmit, additionalPayment],
    );

    const onFormUpdate = useCallback(
      (amount: number | undefined, paymentType: keyof AdditionalPayment) => {
        // Update the amounts based on the input type
        const amounts = { ...additionalPayment };
        amounts[paymentType] = amount || 0;
        setAdditionalPayment(amounts);

        // Determine the new overpayment amount + total
        const newOverPaymentAmount = add(amounts.principal, amounts.escrow, amounts.suspense);
        setOverPaymentAmount(newOverPaymentAmount);

        const newTotal = add(newOverPaymentAmount, amountDue);
        setPaymentTotal(newTotal);
      },
      [additionalPayment, amountDue],
    );

    useEffect(() => {
      // Init form values
      const newOverPaymentAmount = subtract(paymentAmount, amountDue);
      setOverPaymentAmount(newOverPaymentAmount);
      setAdditionalPayment({ principal: newOverPaymentAmount, escrow: 0, suspense: 0 });
      setPaymentTotal(paymentAmount);
    }, [paymentAmount, amountDue, showModal]);

    return (
      <WillowModal showModal={showModal} onClose={onClose}>
        <div className="modal-overpayment">
          <h2>
            <InfoCircleFilled />
            Overpayment Confirmation
          </h2>

          <div className="modal-overpayment__copy">
            You have elected to pay <b>{paymentAmountFormat(overPaymentAmount)}</b> more than the minimum amount due.
            Please confirm where your overpayment should be applied.
          </div>

          <form className="modal-overpayment__form" onSubmit={() => onSubmit(additionalPayment)}>
            <div className="modal-overpayment__form__inner">
              <div className="modal-overpayment__input modal-overpayment__due">
                <div>Due</div>
                <div>{paymentAmountFormat(amountDue)}</div>
              </div>

              <div className="modal-overpayment__input">
                <label htmlFor="principal">Principal</label>
                <div className="modal-overpayment__input__wrapper">
                  <CurrencyInput
                    id="principal"
                    prefix=""
                    defaultValue={additionalPayment.principal || undefined}
                    onAmountUpdate={(amount) => onFormUpdate(amount, 'principal')}
                  />
                </div>
              </div>

              <div className="modal-overpayment__input">
                <label htmlFor="escrow">Escrow</label>
                <div className="modal-overpayment__input__wrapper">
                  <CurrencyInput
                    id="escrow"
                    prefix=""
                    defaultValue={additionalPayment.escrow || undefined}
                    onAmountUpdate={(amount) => onFormUpdate(amount, 'escrow')}
                  />
                </div>
              </div>

              {ff_portalBalanceForward && (
                <div className="modal-overpayment__input">
                  <label htmlFor="suspense">Next Statement</label>
                  <div className="modal-overpayment__input__wrapper">
                    <CurrencyInput
                      id="suspense"
                      prefix=""
                      defaultValue={additionalPayment.suspense || undefined}
                      onAmountUpdate={(amount) => onFormUpdate(amount, 'suspense')}
                    />
                  </div>
                </div>
              )}

              <div className="modal-overpayment__total">
                <div className="modal-overpayment__total__label">Payment total:</div>
                <div className="modal-overpayment__total__amount">{paymentAmountFormat(paymentTotal)}</div>
              </div>
            </div>

            <div className="modal-overpayment__copy">
              {autopayEnabled ? (
                <>
                  Once confirmed, payment will begin processing from your {bankName} account, overriding your scheduled
                  auto-payment.
                </>
              ) : (
                <>
                  Confirming this payment will begin processing from your {bankName} account for immediate withdraw
                  today.
                </>
              )}
            </div>

            <div className="modal-overpayment__buttons">
              <button type="button" onClick={onClose} className="modal-overpayment__cancel-btn">
                Cancel
              </button>

              <button onClick={paymentSubmit} className="modal-overpayment__submit-btn" disabled={disabled}>
                Confirm Payment
              </button>
            </div>
          </form>
        </div>
      </WillowModal>
    );
  },
);
