/*
  One-off emails sent to external parties (ie not the lender or borrowers)
*/
import { z } from 'zod';

import { PaymentDueId, PayoffId } from '../BrandedIds';
import { EmailAttachment } from '../EmailAttachment';
import { MortgageeLetterEmail } from '../emails';
import { ExternalPaymentDueEmail, ExternalPayoffQuoteEmail } from '../emails/Templates';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

/* CONTEXT */

const SendExternalBaseEmailSideEffectContext = BaseLoanIdSideEffect.extend({
  emailRecipientName: z.string().optional(),
  emailRecipient: z.string(),
  attachments: z.array(EmailAttachment).optional(),
});

const SendExternalNoDataEmailSideEffectContext = SendExternalBaseEmailSideEffectContext.extend({
  emailTemplate: MortgageeLetterEmail,
});

const SendExternalPayoffEmailSideEffectContext = SendExternalBaseEmailSideEffectContext.extend({
  emailTemplate: ExternalPayoffQuoteEmail,
  payoffId: zodBrandedUuid<PayoffId>(),
  attachments: z.array(EmailAttachment),
});

const SendExternalPaymentDueEmailSideEffectContext = SendExternalBaseEmailSideEffectContext.extend({
  emailTemplate: ExternalPaymentDueEmail,
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  attachments: z.array(EmailAttachment),
});

export const SendExternalEmailSideEffectContext = z.discriminatedUnion('emailTemplate', [
  SendExternalNoDataEmailSideEffectContext,
  SendExternalPayoffEmailSideEffectContext,
  SendExternalPaymentDueEmailSideEffectContext,
]);
export type SendExternalEmailSideEffectContext = z.infer<typeof SendExternalEmailSideEffectContext>;

/* ------- */
const SendExternalEmailBaseSideEffect = SendExternalNoDataEmailSideEffectContext.extend({
  type: z.literal('sendExternalEmail'),
});
const SendExternalPayoffEmailSideEffect = SendExternalPayoffEmailSideEffectContext.extend({
  type: z.literal('sendExternalEmail'),
});
const SendExternalPaymentDueEmailSideEffect = SendExternalPaymentDueEmailSideEffectContext.extend({
  type: z.literal('sendExternalEmail'),
});
const SendExternalEmailSideEffect = z.union([
  SendExternalEmailBaseSideEffect,
  SendExternalPayoffEmailSideEffect,
  SendExternalPaymentDueEmailSideEffect,
]);
export type SendExternalEmailSideEffect = z.infer<typeof SendExternalEmailSideEffect>;
