import * as z from 'zod';

import {
  LoanStatusActive,
  LoanStatusArchived,
  LoanStatusForeclosure,
  LoanStatusPaidOff,
  LoanStatusPreActive,
} from '../loan/LoanStatus';
import { StopSettings } from '../loan/StopSettings';
import { zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const SetLoanStatusAllowedStatuses = z.union([
  LoanStatusPreActive,
  LoanStatusActive,
  LoanStatusArchived,
  LoanStatusPaidOff,
  LoanStatusForeclosure,
  z.literal('onHold'),
]);
export type SetLoanStatusAllowedStatuses = z.infer<typeof SetLoanStatusAllowedStatuses>;

export const SetLoanStatusEvent = BaseLoanEvent.extend({
  type: z.literal('setStatus'),
  loanStatus: SetLoanStatusAllowedStatuses,
  stopSettings: StopSettings.optional(),
  today: zodDateOrString.optional(),
  comment: z.string().optional(),
});

export type SetLoanStatusEvent = z.infer<typeof SetLoanStatusEvent>;
