import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
  AcceptTermsDocument,
  PortalSelfDocument,
  UpdateNotificationSettingsForAllCompaniesDocument,
} from '@willow/graphql-iso/src/portal';
import { Loader, NamedMemo, Sentry } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { PortalUserId } from '@willow/types-iso';

import { FeatureFlagContext } from '../../contexts/featureFlagContext';
import { WillowModal } from '../modal/Modal';

import './AcceptTermsModal.scss';

interface Props {
  userId: PortalUserId | undefined;
  acceptedTerms: boolean;
}

export const AcceptTermsModal = NamedMemo<Props>('AcceptTermsModal', ({ userId, acceptedTerms }) => {
  const { ff_econsentEnabled } = useContext(FeatureFlagContext);

  const [showAcceptTerms, setShowAcceptTerms] = useState(!acceptedTerms);
  const [showPaperlessDisclosure, setShowPaperlessDisclosure] = useState(false);

  const onAcceptTerms = useCallback(
    (emailConsentProvided?: boolean) => {
      setShowAcceptTerms(false);
      if (ff_econsentEnabled && emailConsentProvided) {
        setShowPaperlessDisclosure(true);
      }
    },
    [ff_econsentEnabled],
  );

  const onAcceptPaperlessDisclosure = () => setShowPaperlessDisclosure(false);

  return (
    <>
      <AcceptTerms
        userId={userId}
        showModal={showAcceptTerms}
        onAcceptTerms={onAcceptTerms}
        includeEmailConsent={ff_econsentEnabled}
      />
      <PaperlessDisclosure
        showModal={showPaperlessDisclosure}
        onAcceptPaperlessDisclosure={onAcceptPaperlessDisclosure}
      />
    </>
  );
});

interface AcceptTermsProps {
  userId: PortalUserId | undefined;
  showModal: boolean;
  onAcceptTerms: (emailConsentProvided?: boolean) => void;
  includeEmailConsent: boolean;
}

const AcceptTerms = NamedMemo<AcceptTermsProps>(
  'AcceptTerms',
  ({ userId, showModal, onAcceptTerms, includeEmailConsent }) => {
    const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);
    const [econsentChecked, setEconsentChecked] = useState(true);

    const [acceptTerms] = useMutation(AcceptTermsDocument);
    const [hasError, setHasError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleAcceptTermsChecked = useCallback(() => {
      setHasError(false);
      setAcceptTermsChecked(!acceptTermsChecked);
    }, [acceptTermsChecked]);

    const onSubmit = useCallback(async () => {
      if (!acceptTermsChecked) {
        setIsSubmitting(false);
        setHasError(true);
        return;
      }

      setIsSubmitting(true);

      if (!userId) {
        // We can get into this state if we have an email address registered in auth0, but doesn't exist in the db
        // If we don't have a user id, don't try to save the acceptance to the db, just close the modal after they click accept
        setIsSubmitting(false);
        onAcceptTerms();
        return;
      }

      try {
        if (includeEmailConsent) {
          // Pass econsent if the feature flag is enabled
          // And refetch the loans if the user turns off econsent
          await acceptTerms({
            variables: {
              emailConsent: econsentChecked,
            },
            refetchQueries: !econsentChecked ? [PortalSelfDocument] : undefined,
          });

          onAcceptTerms(econsentChecked);
        } else {
          await acceptTerms();
          onAcceptTerms();
        }
      } catch (err) {
        toast.error('Something went wrong! Please try again.');
        Sentry.captureException(`Accept terms modal: Error ${err}`);
      } finally {
        setIsSubmitting(false);
      }
    }, [includeEmailConsent, econsentChecked, acceptTermsChecked, onAcceptTerms, acceptTerms, userId]);

    return (
      <WillowModal showModal={showModal} onClose={onSubmit}>
        <div className="accept-terms-modal">
          {hasError && (
            <div role="alert" className="accept-terms-modal__error">
              <ExclamationCircleOutlined /> Please check box below to continue
            </div>
          )}

          <h2 className="accept-terms-modal__header">Welcome to your mortgage payment portal</h2>
          <h3 className="accept-terms-modal__description">
            Please review our{' '}
            <a href="https://www.willowservicing.com/tos" target="_blank" rel="noopener noreferrer">
              Terms
            </a>{' '}
            and{' '}
            <a href="https://www.willowservicing.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://www.willowservicing.com/paperless-disclosures" target="_blank" rel="noopener noreferrer">
              Paperless Disclosures
            </a>
            , as well as our partner Dwolla’s{' '}
            <a href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">
              Terms
            </a>{' '}
            and{' '}
            <a href="https://www.dwolla.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </h3>

          <Form className="accept-terms-modal__form">
            <Form.Check
              className={classNames({
                'accept-terms-modal__form__checked': acceptTermsChecked,
                'form-check__error': hasError,
              })}
            >
              <Form.Check.Input
                id="accept-terms-modal"
                type="checkbox"
                checked={acceptTermsChecked}
                onChange={toggleAcceptTermsChecked}
                className={classNames({ 'accept-terms-modal__form__error': hasError })}
              />
              <Form.Check.Label htmlFor="accept-terms-modal" className="accept-terms-modal__form__label">
                I agree to the provided terms and privacy policy
              </Form.Check.Label>
            </Form.Check>

            {includeEmailConsent && (
              <Form.Check className={classNames({ 'accept-terms-modal__form__checked': econsentChecked })}>
                <Form.Check.Input
                  id="confirm-econsent"
                  type="checkbox"
                  checked={econsentChecked}
                  onChange={(e) => setEconsentChecked(e.target.checked)}
                />
                <Form.Check.Label htmlFor="confirm-econsent" className="accept-terms-modal__form__label">
                  I agree to receive disclosures electronically
                </Form.Check.Label>
              </Form.Check>
            )}

            <div className="accept-terms-modal__form__btn-wrapper">
              <button
                disabled={isSubmitting}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                Agree and Continue
                {isSubmitting && <Loader />}
              </button>
            </div>
          </Form>
        </div>
      </WillowModal>
    );
  },
);

interface PaperlessDisclosureProps {
  showModal: boolean;
  onAcceptPaperlessDisclosure: () => void;
}

const PaperlessDisclosure = NamedMemo<PaperlessDisclosureProps>(
  'PaperlessDisclosure',
  ({ showModal, onAcceptPaperlessDisclosure }) => {
    const [updateNotificationSettingsForAllCompanies, { loading }] = useMutation(
      UpdateNotificationSettingsForAllCompaniesDocument,
    );

    const [notifications, setNotifications] = useState<'email' | 'emailAndPaper'>('email');
    const [showDisclosure, setShowDisclosure] = useState(false);

    const onSubmit = useCallback(async () => {
      try {
        const { data, errors } = await updateNotificationSettingsForAllCompanies({
          variables: {
            input: {
              emailNotificationsEnabled: true,
              paperNotificationsEnabled: notifications === 'emailAndPaper',
            },
          },
          refetchQueries: [PortalSelfDocument],
        });

        if (!data || errors) {
          toast.error(
            'Your paperless settings could not be saved at this time. You can edit them anytime from the "My Account" tab.',
          );
        }

        // If there are errors, let's allow the customer to proceed anyway. We don't want to block them from making payments
        // just because we couldn't save their paperless settings. We've already gained emailConsent on the previous modal which is the most important.
        onAcceptPaperlessDisclosure();
      } catch (err) {
        toast.error('There was a problem saving your paperless settings. Please try again.');
      }
    }, [updateNotificationSettingsForAllCompanies, notifications, onAcceptPaperlessDisclosure]);

    return (
      <WillowModal showModal={showModal} onClose={() => {}}>
        <div className="accept-terms-modal">
          <h2 className="accept-terms-modal__header">Enroll in paperless notifications</h2>
          <p className="accept-terms-modal__description">
            By enrolling to receive electronic notifications, you acknowledge that you agree to receive communications
            electronically and have read the{' '}
            {showDisclosure ? (
              'Paperless Disclosures'
            ) : (
              <button className="u-unset" onClick={() => setShowDisclosure(true)}>
                Paperless Disclosures
              </button>
            )}
            .
          </p>

          {showDisclosure && (
            <p className="accept-terms-modal__description mt-3">
              By registering for electronic delivery (paperless), you agree to receive all account communications, including but not limited to billing statements and other loan servicing disclosures, electronically. If you have multiple accounts, your consent will apply to all of them.
              <br/><br/>
              You can revoke your consent to receive disclosures electronically and receive hard copy disclosures via the U.S. Postal Service by adjusting your notification preferences on your My Account page. There is no fee to cancel this service. It may take up to one billing cycle for changes to your delivery preferences to become effective.
              <br/><br/>
              Regardless of your notification preferences, your communications will be available for download and review from the Documents tab in your payment portal. To request a paper copy of a specific communication, please call your lender. There is no charge for this service.
              <br/><br/>
              Should your contact information change, please call your lender to update it.
              <br/><br/>
              Willow Servicing supports the latest stable release (except where noted) of these browsers: Chrome, Firefox, Microsoft Edge, and Safari. Note: Prerelease (such as Beta) versions of operating systems and browsers are not supported.
            </p>
          )}

          <Form className="accept-terms-modal__form">
            <Form.Check>
              <Form.Check.Input
                name="notifications"
                id="notifications-email"
                type="radio"
                defaultChecked={notifications === 'email'}
                onChange={(e) => e.target.checked && setNotifications('email')}
              />
              <Form.Check.Label htmlFor="notifications-email" className="accept-terms-modal__form__label">
                I want to receive just emails
              </Form.Check.Label>
            </Form.Check>

            <Form.Check>
              <Form.Check.Input
                name="notifications"
                id="notifications-email-and-paper"
                type="radio"
                defaultChecked={notifications === 'emailAndPaper'}
                onChange={(e) => e.target.checked && setNotifications('emailAndPaper')}
              />
              <Form.Check.Label htmlFor="notifications-email-and-paper" className="accept-terms-modal__form__label">
                I want to receive both emails and paper mail
              </Form.Check.Label>
            </Form.Check>

            <div className="accept-terms-modal__form__btn-wrapper">
              <button
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              >
                Confirm Preference
                {loading && <Loader />}
              </button>
            </div>
          </Form>
        </div>
      </WillowModal>
    );
  },
);
