import { useState } from 'react';

import { NamedMemo, paymentAmountFormat } from '@willow/shared-web';

import { WillowModal } from '../../modal/Modal';

import './ConfirmPaymentModal.scss';

interface Props {
  showModal: boolean;
  onClose: () => void;
  paymentAmount: number;
  bankName: string;
  autopayEnabled: boolean;
  paymentSubmit: () => Promise<void>;
}

export const ConfirmPaymentModal = NamedMemo<Props>(
  'ConfirmPaymentModal',
  ({ showModal, onClose, paymentAmount, bankName, autopayEnabled, paymentSubmit }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
      <WillowModal showModal={showModal} onClose={onClose}>
        <div className="modal-confirm-payment">
          <h2>Submit Payment Immediately?</h2>

          <div className="modal-confirm-payment__copy">
            {autopayEnabled ? (
              <p>
                You currently have auto-payment enabled, submitting payment today will override auto-payment and begin
                processing for immediate withdraw today.
              </p>
            ) : (
              <p>Confirming this payment will begin processing for immediate withdraw today.</p>
            )}
          </div>

          <ul className="modal-confirm-payment__details">
            <li>
              <div className="modal-confirm-payment__details__title">Amount</div>
              <div className="modal-confirm-payment__details__desc">{paymentAmountFormat(paymentAmount)}</div>
            </li>
            <li>
              <div className="modal-confirm-payment__details__title">From account</div>
              <div className="modal-confirm-payment__details__desc">{bankName}</div>
            </li>
          </ul>

          <div className="modal-confirm-payment__buttons">
            <button type="button" onClick={onClose} className="modal-confirm-payment__cancel-btn">
              Cancel
            </button>

            <button
              disabled={isSubmitting}
              onClick={async () => {
                try {
                  setIsSubmitting(true);
                  await paymentSubmit();
                } finally {
                  setIsSubmitting(false);
                }
              }}
              className="modal-confirm-payment__submit-btn"
              data-ghost="portal-loan--modal-submit-payment-button"
            >
              Confirm Payment
            </button>
          </div>
        </div>
      </WillowModal>
    );
  },
);
