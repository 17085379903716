import { z } from 'zod';

import { PaymentDueId, PayoffId } from '../BrandedIds';
import { EmailAttachment } from '../EmailAttachment';
import { ExternalPayoffQuoteEmail, MortgageeLetterEmail } from '../emails';
import { ExternalPaymentDueEmail } from '../emails/Templates';
import { zodBrandedUuid } from '../utils';
import { EmailString } from '../validations';
import { BaseLoanEvent } from './BaseLoanEvent';

const SendExternalEmailBaseEvent = BaseLoanEvent.extend({
  type: z.literal('sendExternalEmail'),
  emailRecipientName: z.string().optional(),
  emailRecipient: EmailString,
  attachments: z.array(EmailAttachment).optional(),
});
type SendExternalEmailBaseEvent = z.infer<typeof SendExternalEmailBaseEvent>;

const SendNoContextExternalEmailEvent = SendExternalEmailBaseEvent.extend({
  emailTemplate: MortgageeLetterEmail,
});
type SendNoContextExternalEmailEvent = z.infer<typeof SendNoContextExternalEmailEvent>;

const SendPayoffExternalEmailEvent = SendExternalEmailBaseEvent.extend({
  emailTemplate: ExternalPayoffQuoteEmail,
  payoffId: zodBrandedUuid<PayoffId>(),
  attachments: z.array(EmailAttachment),
});
type SendPayoffExternalEmailEvent = z.infer<typeof SendPayoffExternalEmailEvent>;

const SendPaymentDueExternalEmailEvent = SendExternalEmailBaseEvent.extend({
  emailTemplate: ExternalPaymentDueEmail,
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  attachments: z.array(EmailAttachment),
});
type SendPaymentDueExternalEmailEvent = z.infer<typeof SendPaymentDueExternalEmailEvent>;

export const SendExternalEmailEvent = z.discriminatedUnion('emailTemplate', [
  SendNoContextExternalEmailEvent,
  SendPayoffExternalEmailEvent,
  SendPaymentDueExternalEmailEvent,
]);
export type SendExternalEmailEvent = z.infer<typeof SendExternalEmailEvent>;
