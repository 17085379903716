import { PlusOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { CompanyLogo } from '../../components/company-logo/CompanyLogo';
import { Metadata } from '../../components/metadata/Metadata';
import { PageHeading } from '../../components/page-heading/PageHeading';
import { LadderIcon } from './LadderIcon';

import s from './Applications.module.scss';

interface Props {
  loan: PortalSelectedLoan;
}

const LifeInsurance = NamedMemo<{ company: CompanyFragment }>('LifeInsurance', ({ company }) => (
  <>
    <Helmet>
      <script src="https://www.ladderlife.com/widget/embed.js" />
    </Helmet>

    <div>
      <div className={s.header}>
        <div className={s.header__title}>
          <div className={s.header__title__logos}>
            <CompanyLogo company={company} />
            <PlusOutlined className={s.header__title__logos__plus} />
            <LadderIcon />
          </div>

          <h2>
            <span className="fw-bold">Life Insurance</span> by Ladder Life
          </h2>
        </div>

        <p className="u-fs-2 u-maxw-4">
          There are no hidden fees or doctor visits. Everything is handled online, so you can get coverage in as little
          as five minutes.
        </p>
      </div>

      <div className="my-4 d-flex justify-content-center">
        <div className="ladder-widget-flex-portrait d-md-none" style={{ height: 700 }} />
        <div className="ladder-widget-portrait d-none d-md-flex d-lg-none" style={{ height: 700, width: 420 }} />
        <div className="ladder-widget-landscape d-none d-lg-flex" style={{ height: 377, width: 720 }} />
      </div>
    </div>
  </>
));

export const ApplicationsPage = NamedMemo<Props>('ApplicationsPage', ({ loan }) => (
  <div>
    <Metadata metadata={{ title: 'Applications', description: 'Trusted options to protect your home and family' }} />

    <PageHeading title="Applications" subtitle="Trusted options to protect your home and family" />

    <section className={s.page}>
      <LifeInsurance company={loan.company} />
    </section>
  </div>
));
