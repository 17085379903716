import { z } from 'zod';

import { CompanyId, CustomFAQItemId, CustomFAQSectionId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';

export const CustomFAQItem = z.object({
  id: zodBrandedUuid<CustomFAQItemId>(),
  question: z.string(),
  answer: z.string(),
  orderNum: z.number().positive(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type CustomFAQItem = z.infer<typeof CustomFAQItem>;

export const CustomFAQSection = z.object({
  id: zodBrandedUuid<CustomFAQSectionId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  title: z.string(),
  items: z.array(CustomFAQItem),
  orderNum: z.number().positive(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type CustomFAQSection = z.infer<typeof CustomFAQSection>;
