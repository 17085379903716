import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import classnames from 'classnames';
import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';

import { DayAndTimeRange, Timezone, Weekday } from '@willow/graphql-iso/src/app';

import { TDayAndTimeRange } from '../../utils/time';
import { Button } from '../Button/Button';
import { ControlledTimeofDaySelect } from './Select/ControlledTimeofDaySelect';
import { ControlledTimezoneSelect } from './Select/ControlledTimezoneSelect';
import { ControlledWeekdaySelect } from './Select/ControlledWeekdaySelect';

type FormContactTime = Omit<DayAndTimeRange, '__typename'>;
type Props<T extends { preferredContactTimes?: FormContactTime[] }> = {
  control: Control<T>;
  preferredContactTimes?: FormContactTime[];
  setValue: UseFormSetValue<T>;
  errors?: FieldErrors<T>;
};

const DEFAULT_TIME: TDayAndTimeRange = {
  startTime: 9,
  endTime: 17,
  timezone: Timezone.AmericaNewYork,
  startDay: Weekday.Monday,
  endDay: Weekday.Friday,
};

export function ControlledPreferredContactTimes({
  control,
  preferredContactTimes,
  setValue,
  errors,
}: Props<{ preferredContactTimes?: FormContactTime[] }>) {
  return (
    <>
      {preferredContactTimes?.map((_, index) => (
        <div key={`pct-${index}`} className={classnames({ 'mt-4': index !== 0 })}>
          <div className="d-flex gap-3">
            <div className="d-flex gap-2 align-items-center">
              <ControlledTimeofDaySelect
                control={control}
                fieldName={`preferredContactTimes.${index}.startTime`}
                timeIncrements="15min"
              />
              –
              <ControlledTimeofDaySelect
                control={control}
                fieldName={`preferredContactTimes.${index}.endTime`}
                timeIncrements="15min"
                hideErrorMessage
              />
            </div>
            <ControlledTimezoneSelect control={control} fieldName={`preferredContactTimes.${index}.timezone`} />
            <Button
              variant="tertiary"
              size="sm"
              onClick={() =>
                setValue(
                  'preferredContactTimes',
                  preferredContactTimes?.filter((_: any, i: number) => i !== index),
                )
              }
            >
              <DeleteOutlined />
            </Button>
          </div>
          <div className="d-flex align-items-center gap-2 mt-2">
            <ControlledWeekdaySelect control={control} fieldName={`preferredContactTimes.${index}.startDay`} />
            –
            <ControlledWeekdaySelect control={control} fieldName={`preferredContactTimes.${index}.endDay`} />
          </div>
          <ErrorMessage
            errors={errors}
            name={`preferredContactTimes.${index}.endTime`}
            render={({ message }) => <div className="u-fs-2 u-color-red1 mt-1">{message}</div>}
          />
        </div>
      ))}

      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="tertiary"
          size="sm"
          onClick={() =>
            setValue(
              'preferredContactTimes',
              preferredContactTimes ? [...preferredContactTimes, DEFAULT_TIME] : [DEFAULT_TIME],
            )
          }
        >
          <PlusOutlined className="me-1" /> Add additional time
        </Button>
      </div>
    </>
  );
}
