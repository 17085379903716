import { z } from 'zod';

import { zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ModifyMaturityDateEvent = BaseLoanEvent.extend({
  type: z.literal('modifyMaturityDate'),
  maturityDate: zodDateOrString,
  newInterestRate: z.number().optional(),
});
export type ModifyMaturityDateEvent = z.infer<typeof ModifyMaturityDateEvent>;
