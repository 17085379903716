import * as z from 'zod';

import { TransferLoanRowData } from '../loan/LoanTransfer';
import { BaseLoanEvent } from './BaseLoanEvent';

export const TransferAccountEvent = BaseLoanEvent.extend({
  type: z.literal('transferAccount'),
  data: TransferLoanRowData,
});
export type TransferAccountEvent = z.infer<typeof TransferAccountEvent>;
