import { z } from 'zod';

import { AddressLine, StateAbbreviation, Zipcode } from '../../fields';

export const MailingAddress = z.object({
  primaryBorrowerMailingAddressLine1: AddressLine.optional(),
  primaryBorrowerMailingAddressLine2: AddressLine.optional(),
  primaryBorrowerMailingAddressLine3: AddressLine.optional(),
  primaryBorrowerMailingAddressLine4: AddressLine.optional(),
  primaryBorrowerMailingAddressLocality: AddressLine.optional(),
  primaryBorrowerMailingAddressRegion: StateAbbreviation.optional().or(z.literal('')),
  primaryBorrowerMailingAddressPostcode: Zipcode.optional(),
  primaryBorrowerMailingAddressCountry: z.string().optional(),
});
