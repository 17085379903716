import { z } from 'zod';

import {
  MortgageInsuranceFHAProgramTypeEnum,
  MortgageInsuranceRenewalTypeEnum,
  MortgageInsuranceTypeEnum,
  PaymentCadenceEnum,
} from '../../../loan/EscrowSetting';
import { AddressLine, Checkbox, DateString, PositiveMonetaryValue, PositivePercentage } from '../../fields';

export const MortgageInsuranceFields = z.object({
  miCompanyName: z.string().optional(),
  miCompanyAddressLine1: AddressLine.optional(),
  miCompanyAddressLine2: AddressLine.optional(),
  miCompanyAddressCity: z.string().optional(),
  miCompanyAddressState: z.string().optional(),
  miCompanyAddressZip: z.string().optional(),
  miPaymentCadence: PaymentCadenceEnum.optional(),
  miType: MortgageInsuranceTypeEnum.optional(),
  miFhaProgramType: MortgageInsuranceFHAProgramTypeEnum.optional(),
  miIsEscrowed: Checkbox.optional(),
  miAmount: PositiveMonetaryValue.optional(),
  miStartDate: DateString.optional(),
  miMidpointDate: DateString.optional(),
  miCertificateId: z.string().optional(),
  miPoolCertificateNumber: z.string().optional(),
  miCoveragePercent: PositivePercentage.optional(),
  miIsRefundable: Checkbox.optional(),
  miIsFinanced: Checkbox.optional(),
  miRenewalType: MortgageInsuranceRenewalTypeEnum.optional(),
});
