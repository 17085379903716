import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { PaymentStatus } from '../loan/Payments';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdatePaymentEvent = BaseLoanEvent.extend({
  type: z.literal('updatePayment'),
  paymentId: zodBrandedUuid<PaymentId>(),
  transferStatus: PaymentStatus,
  // this is for compatibility with old payment flow
  transferUrl: z.string().optional(),
});
export type UpdatePaymentEvent = z.infer<typeof UpdatePaymentEvent>;
