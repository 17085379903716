export const LadderIcon = () => (
  <svg viewBox="0 0 561 170" fill="none" role="img">
    <title>Ladder logo</title>
    <path d="M187.826 53.4091H205.502V115.591H243.811V130.046H187.826V53.4091Z" fill="#1A1A1A" />
    <path
      d="M297.76 76.7779C302.638 81.1177 305.066 87.6699 305.066 96.4239V130.036H289.129V122.707C285.927 128.174 279.958 130.919 271.231 130.919C266.714 130.919 262.812 130.153 259.504 128.621C256.196 127.089 253.662 124.973 251.923 122.271C250.173 119.569 249.304 116.506 249.304 113.07C249.304 107.592 251.361 103.295 255.464 100.157C259.578 97.0195 265.919 95.4453 274.508 95.4453H288.037C288.037 91.7225 286.903 88.8612 284.655 86.8509C282.396 84.8405 279.014 83.8407 274.508 83.8407C271.38 83.8407 268.305 84.33 265.283 85.3192C262.261 86.3084 259.695 87.638 257.585 89.3186L251.477 77.3842C254.679 75.1186 258.518 73.3742 262.992 72.1297C267.467 70.8852 272.069 70.2683 276.798 70.2683C285.906 70.2683 292.883 72.4382 297.76 76.7779ZM283.245 117.399C285.492 116.049 287.094 114.06 288.048 111.432V105.412H276.374C269.386 105.412 265.898 107.709 265.898 112.304C265.898 114.496 266.746 116.229 268.464 117.506C270.171 118.782 272.514 119.42 275.504 119.42C278.409 119.42 280.997 118.75 283.245 117.399Z"
      fill="#1A1A1A"
    />
    <path
      d="M376.553 48.8141V130.046H360.288V123.26C356.068 128.366 349.96 130.919 341.955 130.919C336.43 130.919 331.426 129.674 326.951 127.196C322.477 124.718 318.967 121.175 316.422 116.58C313.877 111.985 312.605 106.656 312.605 100.593C312.605 94.5306 313.877 89.2016 316.422 84.6065C318.967 80.0115 322.477 76.4695 326.951 73.9911C331.426 71.5128 336.43 70.2683 341.955 70.2683C349.451 70.2683 355.304 72.6402 359.524 77.3842V48.8141H376.553ZM355.601 112.475C358.443 109.518 359.853 105.561 359.853 100.593C359.853 95.6261 358.432 91.6693 355.601 88.7123C352.77 85.7553 349.197 84.2768 344.902 84.2768C340.534 84.2768 336.939 85.7553 334.098 88.7123C331.267 91.6693 329.846 95.6261 329.846 100.593C329.846 105.561 331.267 109.518 334.098 112.475C336.939 115.432 340.534 116.91 344.902 116.91C349.197 116.91 352.759 115.432 355.601 112.475Z"
      fill="#1A1A1A"
    />
    <path
      d="M447.701 48.8141V130.046H431.446V123.26C427.226 128.366 421.119 130.919 413.113 130.919C407.589 130.919 402.584 129.674 398.11 127.196C393.635 124.718 390.125 121.175 387.581 116.58C385.036 111.985 383.763 106.656 383.763 100.593C383.763 94.5306 385.036 89.2016 387.581 84.6065C390.125 80.0115 393.635 76.4695 398.11 73.9911C402.584 71.5128 407.589 70.2683 413.113 70.2683C420.61 70.2683 426.463 72.6402 430.683 77.3842V48.8141H447.701ZM426.749 112.475C429.591 109.518 431.001 105.561 431.001 100.593C431.001 95.6261 429.58 91.6693 426.749 88.7123C423.918 85.7553 420.345 84.2768 416.05 84.2768C411.682 84.2768 408.087 85.7553 405.246 88.7123C402.414 91.6693 400.994 95.6261 400.994 100.593C400.994 105.561 402.414 109.518 405.246 112.475C408.087 115.432 411.682 116.91 416.05 116.91C420.345 116.91 423.918 115.432 426.749 112.475Z"
      fill="#1A1A1A"
    />
    <path
      d="M516.24 105.412H471.823C472.619 109.06 474.517 111.943 477.496 114.06C480.476 116.176 484.187 117.24 488.629 117.24C491.683 117.24 494.398 116.783 496.762 115.868C499.127 114.953 501.332 113.517 503.368 111.539L512.423 121.388C506.888 127.738 498.819 130.908 488.195 130.908C481.578 130.908 475.715 129.61 470.625 127.026C465.525 124.43 461.602 120.835 458.834 116.24C456.067 111.645 454.688 106.422 454.688 100.583C454.688 94.8178 456.056 89.6164 458.781 84.9788C461.506 80.3412 465.26 76.7354 470.021 74.14C474.782 71.5447 480.115 70.2576 486.01 70.2576C491.757 70.2576 496.964 71.5021 501.618 73.9805C506.273 76.4588 509.931 80.0221 512.582 84.6491C515.233 89.2867 516.569 94.6688 516.569 100.796C516.569 101.03 516.452 102.561 516.24 105.412ZM476.404 86.468C473.785 88.6591 472.184 91.648 471.601 95.4453H500.516C499.933 91.7225 498.331 88.7548 495.712 86.5211C493.093 84.2981 489.891 83.1812 486.106 83.1812C482.257 83.1918 479.023 84.2768 476.404 86.468Z"
      fill="#1A1A1A"
    />
    <path
      d="M548.05 72.4594C551.507 71.0022 555.483 70.2683 560 70.2683V86.0318C558.102 85.8829 556.84 85.8085 556.183 85.8085C551.305 85.8085 547.488 87.1806 544.721 89.9142C541.953 92.6479 540.575 96.7536 540.575 102.232V130.036H523.546V71.1405H539.811V78.9159C541.847 76.0653 544.593 73.9167 548.05 72.4594Z"
      fill="#1A1A1A"
    />
    <path
      d="M89.8413 72.7147L93.966 68.577C96.2351 66.3008 96.2351 62.5886 93.966 60.3123C92.8315 59.1742 91.3364 58.5998 89.8413 58.5998C88.3463 58.5998 86.8618 59.1742 85.7167 60.3123C84.6139 61.4185 84.0096 62.8864 84.0096 64.45C84.0096 66.0136 84.6139 67.4814 85.7167 68.577L89.8413 72.7147Z"
      fill="#1A1A1A"
    />
    <path
      d="M70.4056 105.742L66.2809 109.879C64.0118 112.156 64.0118 115.868 66.2809 118.144C68.55 120.42 72.2505 120.42 74.5196 118.144C76.7887 115.868 76.7887 112.156 74.5196 109.879L70.4056 105.742Z"
      fill="#1A1A1A"
    />
    <path
      d="M55.0945 60.3123C53.9599 59.1742 52.4649 58.5998 50.9698 58.5998C49.4748 58.5998 47.9903 59.1742 46.8452 60.3123C45.7424 61.4185 45.138 62.8864 45.138 64.45C45.138 66.0136 45.7424 67.4814 46.8452 68.577L50.9698 72.7147L55.0945 68.577C57.3742 66.3008 57.3742 62.5886 55.0945 60.3123Z"
      fill="#1A1A1A"
    />
    <path
      d="M70.2783 15C31.4598 15 0 46.5591 0 85.5C0 124.441 31.4598 156 70.2783 156C109.097 156 140.557 124.441 140.557 85.5C140.557 46.5591 109.086 15 70.2783 15ZM105.895 102.359L89.8307 86.2552L77.1492 98.9767L81.2739 103.114C87.2647 109.124 87.2647 118.91 81.2739 124.92C78.2732 127.93 74.3394 129.43 70.4056 129.43C66.4718 129.43 62.538 127.93 59.5372 124.92C53.5464 118.91 53.5464 109.124 59.5372 103.114L63.6619 98.9767L50.9804 86.2552L34.9165 102.359L28.1728 95.5942L44.2262 79.4903L40.1015 75.3526C37.1962 72.4381 35.5951 68.5664 35.5951 64.45C35.5951 60.3336 37.1962 56.4618 40.1015 53.5474C46.0923 47.5376 55.8473 47.5376 61.8381 53.5474C67.829 59.5571 67.829 69.3429 61.8381 75.3526L57.7135 79.4903L70.4056 92.2011L83.0871 79.4796L78.9624 75.342C76.0571 72.4275 74.456 68.5557 74.456 64.4393C74.456 60.3229 76.0571 56.4512 78.9624 53.5367C84.9532 47.527 94.7082 47.527 100.699 53.5367C106.69 59.5465 106.69 69.3322 100.699 75.342L96.5744 79.4796L112.628 95.5836L105.895 102.359Z"
      fill="#1A1A1A"
    />
  </svg>
);
