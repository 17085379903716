import { ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';

import './Alert.scss';

export interface Props {
  title: string;
  subtitle?: ReactNode;
  level: 'ERROR' | 'WARNING' | 'INFO';
  variant?: 'BOLD';
}

export const Alert = NamedMemo<Props>('Alert', ({ title, subtitle, level, variant }) => {
  return (
    <section
      className={classNames('payment-alert', {
        'u-color-red1': level === 'ERROR',
        'u-color-maple4': level === 'WARNING',
        'u-border-color-current': variant === 'BOLD',
      })}
    >
      <div className="payment-alert__icon">
        {level === 'ERROR' ? <ExclamationCircleFilled /> : <InfoCircleFilled />}
      </div>
      <div className="payment-alert__content">
        <h2 className="payment-alert__title">{title}</h2>
        {subtitle && (
          <div className={classNames('payment-alert__subtitle', { 'u-color-bark4': variant === 'BOLD' })}>
            {subtitle}
          </div>
        )}
      </div>
    </section>
  );
});
