/* 
    TABLET + MOBILE VIEW for the portal documents page
*/

import { DownloadOutlined } from '@ant-design/icons';

import { dateFormat, DocumentDisplayItem, NamedMemo } from '@willow/shared-web';
import { Accordion } from '@willow/shared-web/bootstrap';

import './ViewDocumentsAccordion.scss';

interface Props {
  documents: DocumentDisplayItem[];
  handleDocumentDownload: (document: DocumentDisplayItem) => void;
}

export const ViewDocumentsAccordion = NamedMemo<Props>(
  'ViewDocumentsAccordion',
  ({ documents, handleDocumentDownload }) => {
    return (
      <Accordion className="view-documents-accordion" defaultActiveKey="0">
        {documents.map((document, index) => {
          const { id, title, subtitle, callouts, date } = document;

          return (
            <Accordion.Item key={`view-documents-accordion-item-${id}-${index}`} eventKey={index.toString()}>
              <Accordion.Header>
                <div>
                  {title}
                  {subtitle && <span className="view-documents-accordion__callout">{subtitle}</span>}
                  {callouts.map((callout, index) => (
                    <span key={index} className="d-block u-fs-1 u-color-maple4">
                      {callout}
                    </span>
                  ))}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ul className="view-documents-accordion__list">
                  <li>
                    <h2 className="view-documents-accordion__list__title">Created Date</h2>
                    <div className="view-documents-accordion__list__description">{dateFormat(date)}</div>
                  </li>
                  <li>
                    <h2 className="view-documents-accordion__list__title">Document</h2>
                    <div className="view-documents-accordion__list__description">
                      <button
                        onClick={() => handleDocumentDownload(document)}
                        className="view-documents-accordion__download"
                      >
                        <DownloadOutlined />
                        Download
                      </button>
                    </div>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    );
  },
);
