import React from 'react';

import { NamedMemo } from '@willow/shared-web';

import './PillLabel.scss';

interface Props {
  label: string;
  isError?: boolean;
}

export const PillLabel = NamedMemo<Props>('PillLabel', ({ label, isError = false }) => {
  return <div className={`pill-label ${isError ? 'pill-label--error' : ''}`}>{label}</div>;
});
