import { ReactNode } from 'react';
import ReactSelect, { GroupBase, OptionsOrGroups, Props as SelectProps } from 'react-select';

import { WILLOW_SELECT_STYLES, WILLOW_SELECT_THEME } from './styles';

export type Options =
  | OptionsOrGroups<any, GroupBase<any>>
  | { label: ReactNode | JSX.Element; value: string | number }[];
export function WillowSelect<TOptions extends Options, TIsMulti extends boolean>(
  props: SelectProps<TOptions, TIsMulti>,
) {
  return <ReactSelect styles={WILLOW_SELECT_STYLES} theme={WILLOW_SELECT_THEME} {...props} />;
}
