import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const AddAdditionalBorrowerEvent = BaseLoanEvent.extend({
  type: z.literal('addAdditionalBorrower'),
  borrower: z.object({
    id: zodBrandedUuid<BorrowerId>(),
    name: z.string(),
    email: z.string().optional(),
    settings: z
      .object({
        emailConsent: z.boolean().optional(),
      })
      .optional(),
  }),
});
export type AddAdditionalBorrowerEvent = z.infer<typeof AddAdditionalBorrowerEvent>;
