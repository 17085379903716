import chroma from 'chroma-js';

export const getLightenedColors = (color: string) => {
  const chromaColor = chroma(color);
  return {
    color100: chromaColor,
    color90: chromaColor.mix('white', 0.1),
    color75: chromaColor.mix('white', 0.25),
    color50: chromaColor.mix('white', 0.5),
    color25: chromaColor.mix('white', 0.75),
    color10: chromaColor.mix('white', 0.9),
  };
};

export const getPortalCss = (primaryColor: string, secondaryColor: string) => {
  const primaryColors = getLightenedColors(primaryColor);
  return (
    <style type="text/css">
      {`
        :root {
          --primary-color: ${primaryColors.color100};
          --primary-color-90: ${primaryColors.color90};
          --primary-color-75: ${primaryColors.color75};
          --primary-color-50: ${primaryColors.color50};
          --primary-color-25: ${primaryColors.color25};
          --primary-color-10: ${primaryColors.color10};
          --secondary-color: ${secondaryColor};
        }
      `}
    </style>
  );
};
