import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { ContactForm } from '../contact-form/ContactForm';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

export const ManageLoanContact = NamedMemo<Props>('ManageLoanContact', ({ loan }) => {
  const { name, supportPhone, supportEmail, supportHours } = loan.company;

  return (
    <>
      <ManageLoanHeader
        title={`Contact ${name}`}
        subtitle={`Contact ${name} directly with any requests or questions.`}
      />

      <ContactForm phone={supportPhone} email={supportEmail} hours={supportHours ?? undefined} />
    </>
  );
});
