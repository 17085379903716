import { z } from 'zod';

import { DocumentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateDocumentTitleEvent = BaseLoanEvent.extend({
  type: z.literal('updateDocumentTitle'),
  documentId: zodBrandedUuid<DocumentId>(),
  originalTitle: z.string(),
  newTitle: z.string(),
});

export type UpdateDocumentTitleEvent = z.infer<typeof UpdateDocumentTitleEvent>;
