import { z } from 'zod';

import { Payment } from '../loan/Payments';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreatePrincipalPrepaymentEvent = BaseLoanEvent.extend({
  type: z.literal('createPrincipalPrepayment'),
  payment: Payment,
  disableEmail: z.boolean().optional(),
});
export type CreatePrincipalPrepaymentEvent = z.infer<typeof CreatePrincipalPrepaymentEvent>;
