import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertPaymentSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  paymentId: zodBrandedUuid<PaymentId>(),
});
export const UpsertPaymentSideEffect = UpsertPaymentSideEffectContext.extend({
  type: z.literal('upsertPayment'),
});
export type UpsertPaymentSideEffect = z.infer<typeof UpsertPaymentSideEffect>;
