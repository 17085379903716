import { ErrorMessage } from '@hookform/error-message';
import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Control, Controller, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { GroupBase, OptionsOrGroups } from 'react-select';

import { WillowSelect } from './WillowSelect';

type Props<T extends FieldValues> = {
  options: OptionsOrGroups<any, GroupBase<any>>;
  fieldName: FieldPathByValue<T, boolean | string | number | undefined | null>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  displayValue?: boolean;
  classNamePrefix?: string;
  maxMenuHeight?: number;
};

export function ControlledSelect<T extends FieldValues>({
  options,
  control,
  fieldName,
  hideErrorMessage = false,
  label,
  placeholder,
  required,
  disabled,
  isClearable = true,
  displayValue = false,
  classNamePrefix,
  maxMenuHeight,
}: Props<T>) {
  const {
    formState: { errors },
  } = useController({ control, name: fieldName });

  const FLAT_OPTIONS = options.flatMap((o) => ('options' in o ? o.options : o)); // In case groups are present

  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}

      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) =>
          displayValue ? (
            <div className="u-fs-2">{FLAT_OPTIONS.find((o) => o.value === value)?.label ?? value}</div>
          ) : (
            <WillowSelect
              placeholder={placeholder}
              options={options}
              value={value != null ? FLAT_OPTIONS.filter((r) => value === r.value) : null}
              isClearable={isClearable}
              isDisabled={disabled}
              onChange={(e) => onChange(e?.value)}
              classNamePrefix={classNamePrefix}
              maxMenuHeight={maxMenuHeight}
            />
          )
        }
      />

      {!hideErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldName as any}
          render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
        />
      )}
    </>
  );
}
