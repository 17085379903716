import { useMutation } from '@apollo/client';
import { noop } from 'lodash';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { UpdateAutopaySettingsForLoanDocument } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';
import { LoanId } from '@willow/types-iso';

import { UserContext } from '../components/context/AppContexts';

interface AutopayContext {
  showConfirmAutopayModal: boolean;
  setShowConfirmAutopayModal: (val: boolean) => void;
  showDisableAutopayModal: boolean;
  setShowDisableAutopayModal: (val: boolean) => void;
  updateAutopaySettings: (loanId: LoanId, bankUrl?: string) => Promise<any>;
  updateAutopaySettingsLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AutopayContext = createContext<AutopayContext>({
  showConfirmAutopayModal: false,
  showDisableAutopayModal: false,
  setShowConfirmAutopayModal: noop,
  setShowDisableAutopayModal: noop,
  updateAutopaySettings: async () => {},
  updateAutopaySettingsLoading: false,
});

export const AutopayContextProvider = NamedMemo<{ children: ReactNode }>('AutopayContextProvider', ({ children }) => {
  const { id: portalUserId } = useContext(UserContext);

  const [showConfirmAutopayModal, setShowConfirmAutopayModal] = useState(false);
  const [showDisableAutopayModal, setShowDisableAutopayModal] = useState(false);

  const [updateMutation, { loading: updateAutopaySettingsLoading }] = useMutation(UpdateAutopaySettingsForLoanDocument);

  const updateAutopaySettings = useCallback(
    async (loanId: LoanId, bankUrl?: string) => {
      const variables = {
        loanId,
        autopaySettings:
          bankUrl && portalUserId
            ? {
                portalUserId,
                bankUrl,
              }
            : undefined,
      };
      return updateMutation({ variables });
    },
    [portalUserId, updateMutation],
  );

  return (
    <AutopayContext.Provider
      value={{
        showConfirmAutopayModal,
        setShowConfirmAutopayModal,
        showDisableAutopayModal,
        setShowDisableAutopayModal,
        updateAutopaySettings,
        updateAutopaySettingsLoading,
      }}
    >
      {children}
    </AutopayContext.Provider>
  );
});
