/*
  Loader with opaque background to lay on top of content during loading scenario
*/
import { AnimatePresence, motion } from 'framer-motion';

import { NamedMemo } from '../NamedMemo';
import { Loader } from './Loader';

interface Props {
  isLoading: boolean;
}

export const LoaderOverlay = NamedMemo<Props>('LoaderOverlay', ({ isLoading = false }) => {
  // Enhancement: create hook to have minimum visible time
  // Enhancement: add this to the shared table component
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const transition = {
    duration: 0.4,
    ease: 'easeInOut',
  };

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          key="overlay-loader"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={transition}
          className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ zIndex: 100, top: 0, left: 0 }}
        >
          <div className="position-absolute w-100 h-100 u-bg-white" style={{ opacity: 0.8 }} />
          <Loader />
        </motion.div>
      )}
    </AnimatePresence>
  );
});
