import { useContext } from 'react';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { getCdnUrlForFile } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

import { EnvironmentContext } from '../context/AppContexts';

export const CompanyLogo = NamedMemo<{
  company?: CompanyFragment;
}>('CompanyLogo', ({ company }) => {
  const environment = useContext(EnvironmentContext);

  return company ? (
    <img
      src={getCdnUrlForFile(company.logos.portalLogo ? 'portalLogo' : 'portalLogoDefault', environment, company.id)}
      alt={`${company.name} Logo`}
    />
  ) : (
    <></>
  );
});
