import { z } from 'zod';

import { MaturityHistoryItemId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';

export const MaturityHistoryItem = z.object({
  id: zodBrandedUuid<MaturityHistoryItemId>(),
  type: z.enum(['initial', 'manualUpdate', 'constructionToPermanent']),
  createdAt: zodDateOrString,
  maturityDate: zodDateOrString,
});
export type MaturityHistoryItem = z.infer<typeof MaturityHistoryItem>;

export const MaturityConfig = z.object({
  dIsMaturing: z.boolean(),
  history: z.array(MaturityHistoryItem),
});
