import { z } from 'zod';

import { EscrowDueInput } from '../loan/EscrowDue';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateEscrowDueEvent = BaseLoanEvent.extend({
  type: z.literal('updateEscrowDue'),
  newEscrowDue: EscrowDueInput,
});

export type UpdateEscrowDueEvent = z.infer<typeof UpdateEscrowDueEvent>;
