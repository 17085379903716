/*
    One-off email template sent to escrow company
    Optionally bcc'd to lender (admin setting)
*/

import { z } from 'zod';

export const MortgageeLetterEmail = z.literal('mortgageeLetter');
export type MortgageeLetterType = z.infer<typeof MortgageeLetterEmail>;

export interface MortgageeLetterEmailData {
  policyNumber: string;
  propertyAddress: string;
  borrowerNames: string;
  lenderName: string;
  lenderAddress?: string;
  newLoanNumber: string;
  mortgageeClauseName: string;
  mortgageeClauseAddress: string;
}
