import { compact } from 'lodash';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { phoneNumberFormat } from '@willow/shared-iso';
import { CompanyId, LosId } from '@willow/types-iso';

import { PORTAL_LOAN_PATHS } from '../routes/portal';

export type FAQSection = { title: string; items: FAQItem[] };
export type FAQItem = { question: string; answer: ReactNode };

interface Props {
  lenderName: string;
  supportEmail: string;
  supportPhone: string;
  losId: LosId | string; // will come through as string in app for non-specific loan copy
  companyId: CompanyId | string;
  autoPayFeatureEnabled: boolean;
}
export const getFAQData = ({
  lenderName,
  supportEmail,
  supportPhone,
  losId,
  companyId,
  autoPayFeatureEnabled,
}: Props): FAQSection[] => {
  const faqData = [
    {
      title: 'Contact Information',
      items: [
        {
          question: `How do I contact ${lenderName}?`,
          answer: (
            <p>
              If you have any questions about your mortgage payment, you can send an email to {lenderName} at{' '}
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a> or call us at{' '}
              <a href={`tel:${supportPhone}`}>{phoneNumberFormat(supportPhone)}</a> during our standard business hours.
            </p>
          ),
        },
        {
          question: 'How do I change my contact information?',
          answer: (
            <p>
              If your mailing address, phone number, or email has changed, please call {lenderName} at{' '}
              <a href={`tel:${supportPhone}`}>{phoneNumberFormat(supportPhone)}</a> during our standard business hours
              to update your information.
            </p>
          ),
        },
      ],
    },
    {
      title: 'Online Payments',
      items: compact([
        {
          question: 'How do I submit a payment online?',
          answer: (
            <p>
              When a payment is due, you will receive a statement sent to you via email. In order to successfully submit
              your payment, you will need to provide information about which bank account you would pay your mortgage
              from. You can connect your bank account under the "Payment Method" section of your mortgage portal.
            </p>
          ),
        },
        {
          question: 'What if I don’t want to provide my banking credentials?',
          answer: (
            <p>
              {lenderName} uses Plaid to securely connect to your preferred bank account. If you do not feel comfortable
              providing your banking credentials, you can instead provide your bank account and routing numbers to make
              a payment.
              <br />
              <br />
              The easiest way to use this option is to scroll to the bottom of the “select your bank” page. You will see
              a button called “link with account numbers”, allowing you to enter your bank and routing information.
              Follow the instructions to complete the set-up.
              <br />
              <br />
              Learn more about bank partnerships with Plaid{' '}
              <a
                href="https://support-my.plaid.com/hc/en-us/articles/4410327486615-Who-is-Plaid-"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          ),
        },
        {
          question: 'Can I use my routing number to make an online payment?',
          answer: (
            <p>
              If your bank is not partnered with our payment partner, Plaid, there is an option to use your bank account
              and routing numbers to make a payment.
              <br />
              <br />
              The easiest way to use this option is to scroll to the bottom of the “select your bank” page. You will see
              a button called “link with account numbers”, allowing you to enter your bank and routing information.
              Follow the instructions to complete the set-up.
              <br />
              <br />
              Learn more about bank partnerships with Plaid{' '}
              <a
                href="https://support-my.plaid.com/hc/en-us/articles/4410328283287-Why-can-t-I-find-my-financial-institution-when-attempting-to-connect-it-to-an-app-or-service-using-Plaid-"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
          ),
        },
        {
          question: 'What does a processing payment mean?',
          answer: (
            <p>
              When a payment has been submitted, it is first moved into the “processing” state until the funds have
              cleared. This process can take up to 3-4 business days. There is no additional action required from you
              during the processing time.
            </p>
          ),
        },
        {
          question: 'Where can I view my payment history?',
          answer: (
            <p>
              You can view all of your previous payments made in your online mortgage payment portal. Select{' '}
              <Link to={PORTAL_LOAN_PATHS.transactions({ losId, companyId })}>“Transactions”</Link> in the navigation
              menu to view all of your past payment details.
            </p>
          ),
        },
        {
          question: 'Can I make a payment if it is past due?',
          answer: (
            <p>
              Yes, you are encouraged to make a payment through the online mortgage payment portal even if it is past
              due. Once your payment is collected, it will be applied to your mortgage balance.
            </p>
          ),
        },
        {
          question: 'What should I do if my payment fails?',
          answer: (
            <p>
              You will be notified via email if your payment fails to clear. If your payment fails, you should check to
              make sure you have sufficient funds in your account to make a full payment. You can go to your mortgage
              payment portal to submit your payment again or you can send a paper check directly to {lenderName}.
            </p>
          ),
        },
        autoPayFeatureEnabled
          ? {
              question: 'Can I enroll in auto-payments?',
              answer: (
                <p>
                  Yes, if you have a bank account connected to your payment portal, turn on the toggle in the “enable
                  auto-pay” section located on the “make a payment” page. Once you have enabled auto-pay, payments will
                  be scheduled to automatically deduct from your bank account in full on the due date of each month.
                </p>
              ),
            }
          : undefined,
        autoPayFeatureEnabled
          ? {
              question: 'Can I disable my auto-payments?',
              answer: (
                <p>
                  Yes, if you have auto-payments enabled, turn off the toggle in the “enable auto-pay” section located
                  on the “make a payment” page. Once you have confirmed that you would like to disable auto-payments, no
                  future payment will be scheduled to automatically deduct from your bank account.
                </p>
              ),
            }
          : undefined,
        autoPayFeatureEnabled
          ? {
              question: 'My loan transferred, what happens to my auto-payments?',
              answer: (
                <p>
                  Auto-payments were only authorized for {lenderName}. Once your loan has transferred, your
                  auto-payments will automatically disable with and you will need to turn it on with your new servicer
                  if they offer this.
                </p>
              ),
            }
          : undefined,
      ]),
    },
    {
      title: 'Payments Partner',
      items: [
        {
          question: `Who is Plaid?`,
          answer: (
            <p>
              Plaid is a trusted service used to process online payments. Plaid works with thousands of reputable
              companies like Venmo, Betterment, and Chime to connect businesses to users’ bank accounts. You can learn
              more about Plaid{' '}
              <a href="https://www.plaid.com/" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          ),
        },
        {
          question: `Can I remove my account that I’ve linked to Plaid?`,
          answer: (
            <p>
              Yes, you can disconnect your account from Plaid anytime by pressing the trash can to the right of your
              bank account. Note, that when a payment is processing, you will not be able to disconnect your account
              until the payment has successfully completed.
            </p>
          ),
        },
        {
          question: `Can ${lenderName} see my banking credentials?`,
          answer: <p>No, we will never have access to any of your banking credentials.</p>,
        },
        {
          question: `How do I know my information is secure?`,
          answer: (
            <p>
              You can learn more about how Plaid protects your information{' '}
              <a href="https://plaid.com/legal/#end-user-privacy-policy" target="_blank" rel="noopener noreferrer">
                here
              </a>
              . We do not store your login credentials in our database, nor are your login credentials ever shared with
              us.
            </p>
          ),
        },
      ],
    },
    {
      title: 'Statements',
      items: [
        {
          question: `Where can I view my monthly statement?`,
          answer: (
            <p>
              Your monthly statement is available for download from the “Documents” page. Click the “download” button of
              the statement you wish to view to automatically download a PDF version to your computer.
            </p>
          ),
        },
        {
          question: `How do I request a payoff statement?`,
          answer: (
            <p>
              To request a payoff, call us at or send us an email inquiry at{' '}
              <a href={`tel:${supportPhone}`}>{phoneNumberFormat(supportPhone)}</a>. Once your inquiry has been
              received, you should expect an email letting you know a payoff quote is available for download from the
              “Documents” tab in your payment portal.
            </p>
          ),
        },
      ],
    },
  ];

  return faqData;
};
