import { z } from 'zod';

import { Address } from '../Address';
import { PropertyId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { PositiveMonetaryValue } from '../validations/fields';

export const Property = z.object({
  id: zodBrandedUuid<PropertyId>(),
  address: Address,
  isReleased: z.boolean(),
  // Note: these fields are being added before we understand how they're going to be used or what kind of values to expect
  // When we do have that info, we should consider migrating these props off of being directly on the Property
  coverageAmount: PositiveMonetaryValue.optional(),
  replacementCostValue: PositiveMonetaryValue.optional(),
  propertyType: z.string().optional(),
  propertySubType: z.string().optional(),
  floodZone: z.string().optional(),
});

export type Property = z.infer<typeof Property>;

export const PropertyInput = Property.omit({ id: true, isReleased: true });
export type PropertyInput = Omit<Property, 'id' | 'isReleased'>;
