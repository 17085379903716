import { CheckCircleFilled, ExclamationCircleFilled, SyncOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { startCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { PortalPaymentFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo, paymentAmountFormat, paymentDateFormat } from '@willow/shared-web';
import { PortalPaymentDueStatus, PortalPaymentDueSubStatus } from '@willow/types-iso';

import './PaymentDetails.scss';

interface Props {
  payments: PortalPaymentFragment[];
  displayMoreButton?: boolean;
  status: PortalPaymentDueStatus;
  subStatus?: PortalPaymentDueSubStatus;
  onAdditionalPayment?: () => void;
}

export const PaymentDetails = NamedMemo<Props>(
  'PaymentDetails',
  ({ payments, displayMoreButton = false, onAdditionalPayment }) => {
    const [displayMorePayment, setDisplayMorePayment] = useState(false);

    const makeAdditionalPayment = useCallback(() => {
      setDisplayMorePayment(false);
      onAdditionalPayment && onAdditionalPayment();
    }, [onAdditionalPayment]);

    useEffect(() => {
      setDisplayMorePayment(displayMoreButton);
    }, [payments, displayMoreButton]);

    return (
      <section
        className={classnames(['payment-details', 'payment-details__container'], {
          'payment-details__more': displayMorePayment,
        })}
      >
        <h2>Payment Details</h2>
        <h3>Amount Processing</h3>

        <ul>
          {payments.map((payment) => {
            let { id, total, paymentDate, status } = payment || {};
            const totalFormatted = paymentAmountFormat(total);
            const dateFormatted = paymentDateFormat(paymentDate);
            const dateFormattedMobile = paymentDateFormat(paymentDate, true);

            let formattedStatus = startCase(status.toLowerCase());
            if (status === 'PENDING') {
              formattedStatus = 'Processing';
            }

            return (
              <li key={`payment-details-${id}`} className="payment-details__item payment-details__container">
                <div className="payment-details__item__info">
                  {totalFormatted && <div className="payment-details__item__info__total">{totalFormatted}</div>}
                  {dateFormatted && (
                    <div className="payment-details__item__info__date tablet">Submitted on {dateFormatted}</div>
                  )}
                  {dateFormattedMobile && (
                    <div className="payment-details__item__info__date mobile">{dateFormattedMobile}</div>
                  )}
                </div>
                <div className="payment-details__item__processing d-flex align-items-center">
                  <div className="payment-details__item__processing__icon">
                    {status === 'PENDING' && <SyncOutlined />}
                    {status === 'PROCESSED' && <CheckCircleFilled />}
                    {status === 'FAILED' && <ExclamationCircleFilled />}
                    {status === 'CANCELLED' && <ExclamationCircleFilled />}
                  </div>
                  {formattedStatus}
                </div>
              </li>
            );
          })}
        </ul>

        {displayMorePayment && (
          <div className="payment-details__addition">
            <button
              className="payment-details__addition__btn"
              data-ghost="portal-loan--make-a-payment-button"
              onClick={makeAdditionalPayment}
            >
              Make Another Payment
            </button>
          </div>
        )}
      </section>
    );
  },
);
