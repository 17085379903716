import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { Hint } from '../Hint';
import { CurrencyInput, CurrencyInputProps } from './CurrencyInput';

type Props<T extends FieldValues> = {
  fieldName: FieldPathByValue<T, number | undefined>;
  control: Control<T>;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  required?: boolean;
  displayValue?: boolean;
  className?: string;
  onAmountUpdate?: (amount: number | undefined) => void;
} & Pick<CurrencyInputProps, 'id' | 'disabled' | 'placeholder' | 'allowNegativeValue'>;

export function ControlledCurrencyInput<T extends FieldValues>({
  control,
  fieldName,
  hideErrorMessage = false,
  label,
  hint,
  required,
  displayValue,
  ...props
}: Props<T>) {
  const {
    field,
    formState: { errors },
  } = useController({ control, name: fieldName });
  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}
      {displayValue ? (
        <div className="u-fs-2">{field.value ? `$${field.value}` : 'N/A'}</div>
      ) : (
        <>
          <CurrencyInput
            {...field}
            className={classNames('form-control', {
              'u-border-color-red1': !!get(errors, fieldName),
            })}
            placeholder="0"
            onFocus={(ev) => {
              ev.currentTarget.select();
            }}
            {...props}
          />
          {hint && <Hint>{hint}</Hint>}
          {!hideErrorMessage && (
            <ErrorMessage
              errors={errors}
              name={fieldName as any}
              render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
            />
          )}
        </>
      )}
    </>
  );
}
