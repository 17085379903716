import { z } from 'zod';

import { DEFAULT_IR_SPLIT, InvestorRemittanceSplit } from '../loan/InvestorRemittanceSplit';

/*
    ----------------------
    Fee setting definitions
    ----------------------
*/
export const BaseCompanyFee = z.object({
  enabled: z.boolean().default(false),
  investorRemittanceSplit: InvestorRemittanceSplit.default({
    splitEnabled: false,
    servicer: 100,
    investor: 0,
  }),
});
export type BaseCompanyFee = z.infer<typeof BaseCompanyFee>;

// LATE
export const CompanyLateFeeSettings = BaseCompanyFee.extend({
  appliedToAllLoans: z.boolean(), // If false, fee will only be applied on loans with individual late fee settings
  percentCharge: z.number().nonnegative().default(5), // applies to billed principal + interest
  minAmount: z.number().nonnegative().default(0.01), // min & max ignored if loans have override late fee specified
  maxAmount: z.number().positive().default(10_000),
});
export type CompanyLateFeeSettings = z.infer<typeof CompanyLateFeeSettings>;

// DRAW
export const CompanyDrawFeeSettings = BaseCompanyFee.extend({
  amountCharge: z.number().nonnegative().default(150), // per draw
  deferredToPayoff: z.boolean().default(true),
});
export type CompanyDrawFeeSettings = z.infer<typeof CompanyDrawFeeSettings>;

// EXTENSION
export const CompanyExtensionFeeSettings = BaseCompanyFee.extend({
  percentCharge: z.number().nonnegative().default(0.5), // applies to original loan balance
  maxAmount: z.number().positive().default(10_000),
});
export type CompanyExtensionFeeSettings = z.infer<typeof CompanyExtensionFeeSettings>;

// PAYOFF STATEMENT
export const CompanyPrePenaltyFeeType = z.enum(['outstandingPrincipal', 'interest', 'fixed']);
export type CompanyPrePenaltyFeeType = z.infer<typeof CompanyPrePenaltyFeeType>;

export const CompanyPrePenaltyFeeSettings = BaseCompanyFee.extend({
  type: CompanyPrePenaltyFeeType.default('outstandingPrincipal'),
  percentageFirstYear: z.number().nonnegative().default(2), // used for 'OutstandingPricipal' type
  percentageSecondYear: z.number().nonnegative().default(2),
  percentageThirdYear: z.number().nonnegative().default(2),
  monthsInterest: z.number().positive().int().default(6), // used for interest type
  fixedAmount: z.number().nonnegative().default(3_000), // used for fixed type
  fixedMonths: z.number().positive().int().max(36).default(12),
});
export type CompanyPrePenaltyFeeSettings = z.infer<typeof CompanyPrePenaltyFeeSettings>;

export const CompanyPayoffAmountFeeSettings = BaseCompanyFee.extend({
  amountCharge: z.number().nonnegative().default(400),
  perProperty: z.boolean().default(false),
});
export type CompanyPayoffAmountFeeSettings = z.infer<typeof CompanyPayoffAmountFeeSettings>;

/*
    ----------------------
    COMPANY FEES
    ----------------------
*/
export const CompanyFeeSettings = z.object({
  late: CompanyLateFeeSettings,
  draw: CompanyDrawFeeSettings,
  extension: CompanyExtensionFeeSettings,
  prePenalty: CompanyPrePenaltyFeeSettings,
  lienRelease: CompanyPayoffAmountFeeSettings,
  recording: CompanyPayoffAmountFeeSettings.optional(), // TODO migration followup
});
export type CompanyFeeSettings = z.infer<typeof CompanyFeeSettings>;

/*
    ----------------------
    Defaults
    ----------------------
*/
export const DEFAULT_COMPANY_FEE_SETTINGS: CompanyFeeSettings = {
  late: {
    enabled: false,
    appliedToAllLoans: false,
    percentCharge: 5, // applies to billed principal + interest
    minAmount: 0.01,
    maxAmount: 10_000,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
  draw: {
    enabled: false,
    amountCharge: 150, // per draw
    deferredToPayoff: true,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
  extension: {
    enabled: false,
    percentCharge: 0.5, // applies to original loan balance
    maxAmount: 10_000,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
  prePenalty: {
    enabled: false,
    type: 'outstandingPrincipal',
    percentageFirstYear: 2, // used for 'OutstandingPricipal' type
    percentageSecondYear: 2,
    percentageThirdYear: 2,
    monthsInterest: 6, // used for interest type
    fixedAmount: 3_000, // used for fixed type
    fixedMonths: 12,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
  lienRelease: {
    enabled: false,
    amountCharge: 400,
    perProperty: false,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
  recording: {
    enabled: false,
    amountCharge: 100,
    perProperty: false,
    investorRemittanceSplit: DEFAULT_IR_SPLIT,
  },
};
