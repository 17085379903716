import * as z from 'zod';

import { OptionalPhoneNumber } from '../../../Phone';
import { OptionalEmailString } from '../../email';
import { Checkbox, PersonNameString, SocialSecurityNumber } from '../../fields';

export const CoborrowerFields = z.object({
  coBorrower1FirstName: PersonNameString.optional(),
  coBorrower1MiddleName: PersonNameString.optional(),
  coBorrower1LastName: PersonNameString.optional(),
  coBorrower1Phone: OptionalPhoneNumber,
  coBorrower1Email: OptionalEmailString,
  coBorrower1EmailConsent: Checkbox.optional(),
  coBorrower1SSN: SocialSecurityNumber.optional(),
  coBorrower2FirstName: PersonNameString.optional(),
  coBorrower2MiddleName: PersonNameString.optional(),
  coBorrower2LastName: PersonNameString.optional(),
  coBorrower2Phone: OptionalPhoneNumber,
  coBorrower2Email: OptionalEmailString,
  coBorrower2EmailConsent: Checkbox.optional(),
  coBorrower2SSN: SocialSecurityNumber.optional(),
  coBorrower3FirstName: PersonNameString.optional(),
  coBorrower3MiddleName: PersonNameString.optional(),
  coBorrower3LastName: PersonNameString.optional(),
  coBorrower3Phone: OptionalPhoneNumber,
  coBorrower3Email: OptionalEmailString,
  coBorrower3EmailConsent: Checkbox.optional(),
  coBorrower3SSN: SocialSecurityNumber.optional(),
  coBorrower4FirstName: PersonNameString.optional(),
  coBorrower4MiddleName: PersonNameString.optional(),
  coBorrower4LastName: PersonNameString.optional(),
  coBorrower4Phone: OptionalPhoneNumber,
  coBorrower4Email: OptionalEmailString,
  coBorrower4EmailConsent: Checkbox.optional(),
  coBorrower4SSN: SocialSecurityNumber.optional(),
  coBorrower5FirstName: PersonNameString.optional(),
  coBorrower5MiddleName: PersonNameString.optional(),
  coBorrower5LastName: PersonNameString.optional(),
  coBorrower5Phone: OptionalPhoneNumber,
  coBorrower5Email: OptionalEmailString,
  coBorrower5EmailConsent: Checkbox.optional(),
  coBorrower5SSN: SocialSecurityNumber.optional(),
  coBorrower6FirstName: PersonNameString.optional(),
  coBorrower6MiddleName: PersonNameString.optional(),
  coBorrower6LastName: PersonNameString.optional(),
  coBorrower6Phone: OptionalPhoneNumber,
  coBorrower6Email: OptionalEmailString,
  coBorrower6EmailConsent: Checkbox.optional(),
  coBorrower6SSN: SocialSecurityNumber.optional(),
  coBorrower7FirstName: PersonNameString.optional(),
  coBorrower7MiddleName: PersonNameString.optional(),
  coBorrower7LastName: PersonNameString.optional(),
  coBorrower7Phone: OptionalPhoneNumber,
  coBorrower7Email: OptionalEmailString,
  coBorrower7EmailConsent: Checkbox.optional(),
  coBorrower7SSN: SocialSecurityNumber.optional(),
  coBorrower8FirstName: PersonNameString.optional(),
  coBorrower8MiddleName: PersonNameString.optional(),
  coBorrower8LastName: PersonNameString.optional(),
  coBorrower8Phone: OptionalPhoneNumber,
  coBorrower8Email: OptionalEmailString,
  coBorrower8EmailConsent: Checkbox.optional(),
  coBorrower8SSN: SocialSecurityNumber.optional(),
  coBorrower9FirstName: PersonNameString.optional(),
  coBorrower9MiddleName: PersonNameString.optional(),
  coBorrower9LastName: PersonNameString.optional(),
  coBorrower9Phone: OptionalPhoneNumber,
  coBorrower9Email: OptionalEmailString,
  coBorrower9EmailConsent: Checkbox.optional(),
  coBorrower9SSN: SocialSecurityNumber.optional(),
});
