import { Opaque } from 'type-fest';
import * as z from 'zod';

export type FormattedFlatfileMonetaryValue = Opaque<string, 'FormattedFlatfileMonetaryValue'>;
export type FormattedFlatfileDate = Opaque<string, 'FormattedFlatfileDate'>;
export type IsoDate = Opaque<string, 'IsoDate'>;

export const FlatfileMonetaryValue = z
  .union([
    z
      .string()
      .trim()
      .transform((val) => val.replace(/[,$]/g, '')),
    z.number(),
  ])
  .pipe(z.coerce.number().nonnegative().multipleOf(0.01));

export const FlatfileMonetaryValueTransformer = FlatfileMonetaryValue.transform(
  (value) => value.toFixed(2) as FormattedFlatfileMonetaryValue,
);
