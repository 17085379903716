import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps } from 'react-hook-form';
import { z } from 'zod';

// This hook provides a nice way to work with Zod and React Hook Form
// in your components. It gets rid of some boilerplate and ensures we
// use these 2 libraries in a consistent way.
//
// Here's how we currently use the 2 libraries together:
//
// const MyFormShape = z.object({ email: z.string(), name: z.string() });
// type TMyFormShape = z.infer<typeof MyFormShape>;

// const MyFormComponent = () => {
//   const { register, handleSubmit } = useForm<TMyFormShape>({
//     resolver: zodResolver(MyFormShape),
//     defaultValues: { name: '', email: '' },
//   });

//   return (
//     <form onSubmit={handleSubmit((data) => console.log('ready to submit', data))}>
//       <input {...register('email')} />
//     </form>
//   );
// };
//
//
// And here's the new way:
//
// const MyFormShapeNew = z.object({ email: z.string(), name: z.string() });

// const MyFormComponentNew = () => {
//   const { register, handleSubmit } = useZodHookForm(MyFormShapeNew, {
//     defaultValues: { email: '', name: '' },
//   });

//   return (
//     <form onSubmit={handleSubmit((data) => console.log('ready to submit', data))}>
//       <input {...register('email')} />
//     </form>
//   );
// };

export function useZodHookForm<T extends z.ZodTypeAny>(schema: T, props?: UseFormProps<z.infer<T>>) {
  return useForm<z.infer<T>>({ ...props, resolver: zodResolver(schema) });
}
