import { decimalToPercentageNumber } from './decimalToPercentage';
import { add, subtract } from './math';

export const getNewMonthlyEscrowPlusShortage = (newMonthlyEscrowAmount: number, shortageOrOverageAmount: number) =>
  shortageOrOverageAmount < 0
    ? add(newMonthlyEscrowAmount, (shortageOrOverageAmount * -1) / 12)
    : newMonthlyEscrowAmount;

export const getMonthlyEscrowDifferenceNumber = (
  oldMonthlyEscrowAmount: number,
  newMonthlyEscrowAmount: number,
  shortageOrOverageAmount: number,
  decimalPlaces?: number,
) => {
  const newMonthlyEscrowWithShortage = getNewMonthlyEscrowPlusShortage(newMonthlyEscrowAmount, shortageOrOverageAmount);

  return decimalToPercentageNumber(
    subtract(newMonthlyEscrowWithShortage, oldMonthlyEscrowAmount) / oldMonthlyEscrowAmount,
    decimalPlaces,
  );
};

export const getMonthlyEscrowDifference = (
  oldMonthlyEscrowAmount: number,
  newMonthlyEscrowAmount: number,
  shortageOrOverageAmount: number,
  decimalPlaces?: number,
) => {
  const difference = getMonthlyEscrowDifferenceNumber(
    oldMonthlyEscrowAmount,
    newMonthlyEscrowAmount,
    shortageOrOverageAmount,
    decimalPlaces,
  );

  if (difference === Infinity) return 'new';
  if (difference > 0) return `+ ${Math.abs(difference)}%`;
  if (difference < 0) return `- ${Math.abs(difference)}%`;
  return '0%';
};
