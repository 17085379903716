import * as z from 'zod';

import { BorrowerId, DocumentId, EscrowDueId, EventId, PaymentDueId, PaymentId, PayoffId } from '../BrandedIds';
import { exhaustiveStringTuple } from '../utils';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseTask } from './BaseTask';
import { EmptyContext } from './EmptyContext';
import { Chapter7Step, Chapter7StepType } from './workflowTasks/Chapter7WorkflowTask';
import { Chapter13Step, Chapter13StepType } from './workflowTasks/Chapter13WorkflowTask';
import { DelinquencyStep, DelinquencyStepType } from './workflowTasks/DelinquencyTask';
import { ForeclosureStep, ForeclosureStepType } from './workflowTasks/ForeclosureWorkflowTask';
import { ReoStep, ReoStepType } from './workflowTasks/RealEstateOwnedWorkflowTask';

/* 
  TASK TYPES 
*/
const CustomTaskType = z.literal('custom');
const PastGraceTaskType = z.literal('pastGrace');
const LoanFlaggedTooLongTaskType = z.literal('loanFlaggedTooLong');
const ForwardMiscollectedFundsTaskType = z.literal('forwardMiscollectedFunds');
const DisburseEscrowFundsTaskType = z.literal('disburseEscrowFunds');
const DisburseEscrowFundsToBorrowerTaskType = z.literal('disburseEscrowFundsToBorrower');
// Payoff Tasks
const GenerateRequestedPayoffTaskType = z.literal('generateRequestedPayoff'); // portal request
const GeneratePayoffStatementTaskType = z.literal('generatePayoffStatement'); // maturing loans
const PayoffReimbursementTaskType = z.literal('payoffReimbursement');
const PendingPaymentAtPayoffReimbursementTaskType = z.literal('pendingPaymentAtPayoffReimbursement');
// Mail tasks
const MailBundleTaskType = z.literal('mailBundle');
const MailPreActiveWelcomeLetterTaskType = z.literal('mailPreActiveWelcomeLetter');
const MailMonthlyStatementTaskType = z.literal('mailMonthlyStatement');
const MailGoodbyeLetterTaskType = z.literal('mailGoodbyeLetter');
const MailMortgageeLetterTaskType = z.literal('mailMortgageeLetter');
const Mail1098FormTaskType = z.literal('mail1098Form');
const MailEscrowAnalysisTaskType = z.literal('mailEscrowAnalysis');
// Portal Requests Tasks
const GeneralInquiryTaskType = z.literal('generalInquiry');
const DrawRequestTaskType = z.literal('drawRequest');
const EscrowWaiverRequestTaskType = z.literal('escrowWaiverRequest');
const PaymentAssistanceInquiryTaskType = z.literal('paymentAssistanceInquiry');
const PMICancellationRequestTaskType = z.literal('pmiCancellationRequest');
// Usio Task
const DisableUsioAccountTaskType = z.literal('disableUsioAccount');

export const DashboardTaskType = z.union([
  CustomTaskType,
  PastGraceTaskType,
  LoanFlaggedTooLongTaskType,
  ForwardMiscollectedFundsTaskType,
  DisburseEscrowFundsTaskType,
  DisburseEscrowFundsToBorrowerTaskType,
  // Payoff Tasks
  GenerateRequestedPayoffTaskType,
  GeneratePayoffStatementTaskType,
  PayoffReimbursementTaskType,
  PendingPaymentAtPayoffReimbursementTaskType,
  // Mail Tasks
  MailBundleTaskType,
  MailPreActiveWelcomeLetterTaskType,
  MailMonthlyStatementTaskType,
  MailGoodbyeLetterTaskType,
  MailMortgageeLetterTaskType,
  Mail1098FormTaskType,
  MailEscrowAnalysisTaskType,
  // Portal Requests
  GeneralInquiryTaskType,
  DrawRequestTaskType,
  EscrowWaiverRequestTaskType,
  PaymentAssistanceInquiryTaskType,
  PMICancellationRequestTaskType,
  // Usio task
  DisableUsioAccountTaskType,
]);
export type DashboardTaskType = z.infer<typeof DashboardTaskType>;

export const DASHBOARD_TASKS: DashboardTaskType[] = [
  'custom',
  'pastGrace',
  'loanFlaggedTooLong',
  'forwardMiscollectedFunds',
  'disburseEscrowFunds',
  'disburseEscrowFundsToBorrower',
  'generateRequestedPayoff',
  'generatePayoffStatement',
  'payoffReimbursement',
  'pendingPaymentAtPayoffReimbursement',
  // mail
  'mailBundle',
  'mailPreActiveWelcomeLetter',
  'mailMonthlyStatement',
  'mailGoodbyeLetter',
  'mailMortgageeLetter',
  'mail1098Form',
  'mailEscrowAnalysis',
  // portal requests
  'generalInquiry',
  'drawRequest',
  'escrowWaiverRequest',
  'paymentAssistanceInquiry',
  'pmiCancellationRequest',
  // usio
  'disableUsioAccount',
];

export const WorkflowTaskType = z.union([
  ForeclosureStepType,
  ReoStepType,
  Chapter7StepType,
  Chapter13StepType,
  DelinquencyStepType,
]);
export type WorkflowTaskType = z.infer<typeof WorkflowTaskType>;

export const TaskType = z.union([DashboardTaskType, WorkflowTaskType]);
export type TaskType = z.infer<typeof TaskType>;

/*
  CONTEXT OPTIONS
*/

export const DocumentContext = z.object({
  type: z.literal('document'),
  documentId: zodBrandedUuid<DocumentId>(),
});
export type DocumentContext = z.infer<typeof DocumentContext>;

export const TransferDocumentContext = z.object({
  type: z.literal('transferDocument'),
  documentId: zodBrandedUuid<DocumentId>(),
  transferEffectiveDate: zodDateOrString,
});
export type TransferDocumentContext = z.infer<typeof TransferDocumentContext>;

export const MailBundleContext = z.object({
  type: z.literal('mailBundle'),
  taskBundleType: z.union([
    MailPreActiveWelcomeLetterTaskType,
    MailMonthlyStatementTaskType,
    MailGoodbyeLetterTaskType,
    MailMortgageeLetterTaskType,
  ]),
  documentUrl: z.string(),
  bundleDate: zodDateOrString, // used for forming task title
  bundleCount: z.number(),
});
export type MailBundleContext = z.infer<typeof MailBundleContext>;

export const MonthlyStatementContext = z.object({
  type: z.literal('monthlyStatement'),
  paymentDueId: zodBrandedUuid<PaymentDueId>(),
  paymentDate: zodDateOrString,
  statementVersion: z.number().positive(),
});
export type MonthlyStatementContext = z.infer<typeof MonthlyStatementContext>;

export const EscrowDueContext = z.object({
  type: z.literal('escrowDue'),
  escrowDueId: zodBrandedUuid<EscrowDueId>().optional(),
});
export type EscrowDueContext = z.infer<typeof EscrowDueContext>;

export const EscrowAnalysisContext = z.object({
  type: z.literal('escrowAnalysis'),
  effectiveDate: zodDateOrString,
});
export type EscrowAnalysisContext = z.infer<typeof EscrowAnalysisContext>;

export const PayoffContext = z.object({
  type: z.literal('payoff'),
  payoffId: zodBrandedUuid<PayoffId>(),
});
export type PayoffContext = z.infer<typeof PayoffContext>;

export const PaymentContext = z.object({
  type: z.literal('payment'),
  paymentId: zodBrandedUuid<PaymentId>(),
});
export type PaymentContext = z.infer<typeof PaymentContext>;

export const EventContext = z.object({
  type: z.literal('event'),
  eventId: zodBrandedUuid<EventId>(),
});
export type EventContext = z.infer<typeof EventContext>;

export const PortalRequestContext = z.object({
  type: z.literal('portalRequest'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
});
export type PortalRequestContext = z.infer<typeof PortalRequestContext>;

export const GeneralInquiryContext = PortalRequestContext.extend({
  type: z.literal('generalInquiry'),
  borrowerNote: z.string(),
});
export type GeneralInquiryContext = z.infer<typeof GeneralInquiryContext>;

export const DrawRequestContext = PortalRequestContext.extend({
  type: z.literal('drawRequest'),
  drawAmount: z.number(),
  fundsReleaseDate: zodDateOrString,
  borrowerNote: z.string(),
});
export type DrawRequestContext = z.infer<typeof DrawRequestContext>;

export const EscrowWaiverRequestItem = z.enum(['tax', 'insurance', 'other']);
export type EscrowWaiverRequestItem = z.infer<typeof EscrowWaiverRequestItem>;
export const EscrowWaiverRequestContext = PortalRequestContext.extend({
  type: z.literal('escrowWaiverRequest'),
  waivedItems: z.array(EscrowWaiverRequestItem),
  borrowerNote: z.string().optional(),
});
export type EscrowWaiverRequestContext = z.infer<typeof EscrowWaiverRequestContext>;

export const PaymentAssistanceInquiryContext = PortalRequestContext.extend({
  type: z.literal('paymentAssistanceInquiry'),
  quotedPastDueTotal: z.number(),
  borrowerNote: z.string(),
});
export type PaymentAssistanceInquiryContext = z.infer<typeof PaymentAssistanceInquiryContext>;

export const PMICancellationRequestContext = PortalRequestContext.extend({
  type: z.literal('pmiCancellationRequest'),
  propertyValueType: z.enum(['original', 'current']),
});
export type PMICancellationRequestContext = z.infer<typeof PMICancellationRequestContext>;

// --------
export const TaskContext = z.discriminatedUnion('type', [
  EmptyContext,
  MonthlyStatementContext,
  DocumentContext,
  TransferDocumentContext,
  EscrowDueContext,
  EscrowAnalysisContext,
  PayoffContext,
  PaymentContext,
  MailBundleContext,
  EventContext,
  // Portal Requests
  GeneralInquiryContext,
  DrawRequestContext,
  EscrowWaiverRequestContext,
  PaymentAssistanceInquiryContext,
  PMICancellationRequestContext,
]);
export type TaskContext = z.infer<typeof TaskContext>;

/*
  Resolve context type helpers
*/
export function isEmptyContext(item: TaskContext): item is EmptyContext {
  return item.type === 'empty';
}
export function isDocumentContext(item: TaskContext): item is DocumentContext {
  return item.type === 'document';
}
export function isTransferDocumentContext(item: TaskContext): item is TransferDocumentContext {
  return item.type === 'transferDocument';
}
export function isMonthlyStatementContext(item: TaskContext): item is MonthlyStatementContext {
  return item.type === 'monthlyStatement';
}

export function isEscrowDueContext(item: TaskContext): item is EscrowDueContext {
  return item.type === 'escrowDue';
}

export function isEscrowAnalysisContext(item: TaskContext): item is EscrowAnalysisContext {
  return item.type === 'escrowAnalysis';
}

export function isPayoffContext(item: TaskContext): item is PayoffContext {
  return item.type === 'payoff';
}
export function isPaymentContext(item: TaskContext): item is PaymentContext {
  return item.type === 'payment';
}

export function isMailBundleContext(item: TaskContext): item is MailBundleContext {
  return item.type === 'mailBundle';
}

export function isEventContext(item: EventContext): item is EventContext {
  return item.type === 'event';
}

/*
    Types + Context
*/
export const PastGraceTask = BaseTask.extend({
  type: PastGraceTaskType,
  context: EmptyContext,
});
export type PastGraceTask = z.infer<typeof PastGraceTask>;

export const LoanFlaggedTooLongTask = BaseTask.extend({
  type: LoanFlaggedTooLongTaskType,
  context: EventContext,
});
export type LoanFlaggedTooLongTask = z.infer<typeof LoanFlaggedTooLongTask>;

export const CustomTask = BaseTask.extend({
  type: CustomTaskType,
  context: EmptyContext,
});
export type CustomTask = z.infer<typeof CustomTask>;

export const ForwardMiscollectedFundsTask = BaseTask.extend({
  type: ForwardMiscollectedFundsTaskType,
  context: EmptyContext,
});
export type ForwardMiscollectedFundsTask = z.infer<typeof ForwardMiscollectedFundsTask>;

export const DisburseEscrowFundsTask = BaseTask.extend({
  type: DisburseEscrowFundsTaskType,
  context: EscrowDueContext,
});
export type DisburseEscrowFundsTask = z.infer<typeof DisburseEscrowFundsTask>;

export const DisburseEscrowFundsToBorrowerTask = BaseTask.extend({
  type: DisburseEscrowFundsToBorrowerTaskType,
  context: EscrowAnalysisContext,
});
export type DisburseEscrowFundsToBorrowerTask = z.infer<typeof DisburseEscrowFundsToBorrowerTask>;

// PAYOFF TASKS
export const GenerateRequestedPayoffTask = BaseTask.extend({
  type: GenerateRequestedPayoffTaskType,
  context: EmptyContext,
});
export type GenerateRequestedPayoffTask = z.infer<typeof GenerateRequestedPayoffTask>;

export const GeneratePayoffStatementTask = BaseTask.extend({
  type: GeneratePayoffStatementTaskType,
  context: EmptyContext,
});
export type GeneratePayoffStatementTask = z.infer<typeof GeneratePayoffStatementTask>;

export const PayoffReimbursementTask = BaseTask.extend({
  type: PayoffReimbursementTaskType,
  context: PayoffContext,
});
export type PayoffReimbursementTask = z.infer<typeof PayoffReimbursementTask>;

export const PendingPaymentAtPayoffReimbursementTask = BaseTask.extend({
  type: PendingPaymentAtPayoffReimbursementTaskType,
  context: PaymentContext,
});
export type PendingPaymentAtPayoffReimbursementTask = z.infer<typeof PendingPaymentAtPayoffReimbursementTask>;

// MAIL Tasks
export const MailBundleTask = BaseTask.extend({
  type: MailBundleTaskType,
  context: MailBundleContext,
});
export type MailBundleTask = z.infer<typeof MailBundleTask>;

export const MailPreActiveWelcomeLetterTask = BaseTask.extend({
  type: MailPreActiveWelcomeLetterTaskType,
  context: DocumentContext,
});
export type MailPreActiveWelcomeLetterTask = z.infer<typeof MailPreActiveWelcomeLetterTask>;

export const MailMonthlyStatementTask = BaseTask.extend({
  type: MailMonthlyStatementTaskType,
  context: MonthlyStatementContext,
});
export type MailMonthlyStatementTask = z.infer<typeof MailMonthlyStatementTask>;

export const MailGoodbyeLetterTask = BaseTask.extend({
  type: MailGoodbyeLetterTaskType,
  context: TransferDocumentContext,
});
export type MailGoodbyeLetterTask = z.infer<typeof MailGoodbyeLetterTask>;

export const MailMortgageeLetterTask = BaseTask.extend({
  type: MailMortgageeLetterTaskType,
  context: TransferDocumentContext,
});
export type MailMortgageeLetterTask = z.infer<typeof MailMortgageeLetterTask>;

export const Mail1098FormTask = BaseTask.extend({
  type: Mail1098FormTaskType,
  context: DocumentContext,
});
export type Mail1098FormTask = z.infer<typeof Mail1098FormTask>;

export const MailEscrowAnalysisTask = BaseTask.extend({
  type: MailEscrowAnalysisTaskType,
  context: DocumentContext,
});
export type MailEscrowAnalysisTask = z.infer<typeof MailEscrowAnalysisTask>;

// PORTAL REQUEST TASKS
export const GeneralInquiryTask = BaseTask.extend({
  type: GeneralInquiryTaskType,
  context: GeneralInquiryContext,
});
export type GeneralInquiryTask = z.infer<typeof GeneralInquiryTask>;

export const DrawRequestTask = BaseTask.extend({
  type: DrawRequestTaskType,
  context: DrawRequestContext,
});
export type DrawRequestTask = z.infer<typeof DrawRequestTask>;

export const EscrowWaiverRequestTask = BaseTask.extend({
  type: EscrowWaiverRequestTaskType,
  context: EscrowWaiverRequestContext,
});
export type EscrowWaiverRequestTask = z.infer<typeof EscrowWaiverRequestTask>;

export const PaymentAssistanceInquiryTask = BaseTask.extend({
  type: PaymentAssistanceInquiryTaskType,
  context: PaymentAssistanceInquiryContext,
});
export type PaymentAssistanceInquiryTask = z.infer<typeof PaymentAssistanceInquiryTask>;

export const PMICancellationRequestTask = BaseTask.extend({
  type: PMICancellationRequestTaskType,
  context: PMICancellationRequestContext,
});
export type PMICancellationRequestTask = z.infer<typeof PMICancellationRequestTask>;

export const DisableUsioAccountTask = BaseTask.extend({
  type: DisableUsioAccountTaskType,
  context: EmptyContext,
});
export type DisableUsioAccountTask = z.infer<typeof DisableUsioAccountTask>;

/* 
  ---------------------
*/

export const DashboardTask = z.discriminatedUnion('type', [
  CustomTask,
  ForwardMiscollectedFundsTask,
  LoanFlaggedTooLongTask,
  PastGraceTask,
  DisburseEscrowFundsTask,
  DisburseEscrowFundsToBorrowerTask,
  // Payoffs
  GenerateRequestedPayoffTask,
  GeneratePayoffStatementTask,
  PayoffReimbursementTask,
  PendingPaymentAtPayoffReimbursementTask,
  // Mail
  MailBundleTask,
  MailPreActiveWelcomeLetterTask,
  MailMonthlyStatementTask,
  MailGoodbyeLetterTask,
  MailMortgageeLetterTask,
  Mail1098FormTask,
  MailEscrowAnalysisTask,
  // Portal Requests
  GeneralInquiryTask,
  DrawRequestTask,
  EscrowWaiverRequestTask,
  PaymentAssistanceInquiryTask,
  PMICancellationRequestTask,
  // Usio task
  DisableUsioAccountTask,
]);
export type DashboardTask = z.infer<typeof DashboardTask>;

export const WorkflowStepTask = z.union([ForeclosureStep, ReoStep, Chapter7Step, Chapter13Step, DelinquencyStep]);
export type WorkflowStepTask = z.infer<typeof WorkflowStepTask>;

export const Task = z.union([DashboardTask, WorkflowStepTask]);
export type Task = z.infer<typeof Task>;

export type DBTask = Omit<Task, 'losId' | 'primaryBorrowerLastName' | 'associatedUserName'>; // Fields that are actually stored in the DB

/*
  BUNDLES
  Bundle Task: these show up on the dashboard
  1. Add the task to BundleTaskType, BundleTask and BundleTaskTypes
  2. Followup: frontend filter (we still want to be able to filter by the individual tasks)

  Bundeable Tasks: these are hidden on the dashboard page, as they are part of the bundle
  1. Add the task to BundleableTask and BundleableTaskTypes (it should still be defined in Task)
  2. Update the bundleTask cron
*/
export const BundleTaskType = MailBundleTaskType; // Update to union once there are more bundle task types
export type BundleTaskType = z.infer<typeof BundleTaskType>;

export const BundleTask = MailBundleTask; // Update to union once there are more bundle task types
export type BundleTask = z.infer<typeof BundleTask>;
export const BundleTaskTypes: BundleTaskType[] = ['mailBundle'];

export const BundleableTask = z.discriminatedUnion('type', [
  MailPreActiveWelcomeLetterTask,
  MailMonthlyStatementTask,
  MailGoodbyeLetterTask,
  MailMortgageeLetterTask,
]);
export type BundleableTask = z.infer<typeof BundleableTask>;
export const BundleableTaskTypes: TaskType[] = [
  'mailPreActiveWelcomeLetter',
  'mailMonthlyStatement',
  'mailGoodbyeLetter',
  'mailMortgageeLetter',
];

export const WorkflowStepTaskTypes = exhaustiveStringTuple<WorkflowTaskType>()(
  'foreclosureStepDemandLetters',
  'foreclosureStepDemandLetterExpires',
  'foreclosureStepReferClosureAttorney',
  'foreclosureStepJudicialState',
  'foreclosureStepNonJudicialState',
  'foreclosureStepHomeSale',
  'reoStepContractStatus',
  'reoStepCounterOfferDetails',
  'reoStepEvictionOfOccupiedProperty',
  'reoStepOfferEvaluations',
  'reoStepOrderInspectionAndValuation',
  'reoStepPropertyListing',
  'reoStepPropertyReduction',
  'reoStepRepairsAndPreservation',
  'chapter13StepAttorneyReferral',
  'chapter13StepCreditorMeeting',
  'chapter13StepFilingStay',
  'chapter13StepPlanInPlace',
  'chapter13StepPlanMonitoring',
  'chapter7StepAttorneyRelief',
  'chapter7StepCreditorMeeting',
  'chapter7StepFilingStay',
  'chapter7StepHearingDate',
  'chapter7StepHearingOutcome',
  'delinquencyStepPastGrace',
  'delinquencyStepMortgageAssistanceRequest',
  'delinquencyStepBorrowerResponsePackage',
  'delinquencyStepFlexModificationEligibility',
  'delinquencyStepFlexModificationTerms',
  'delinquencyStepSendBreachTerms',
  'delinquencyStepPreForeclosureReferral',
);
export const isWorkflowStepTaskType = (type: TaskType): type is WorkflowTaskType =>
  WorkflowStepTaskTypes.includes(type as any);

export function isBundleableTask(item: Task): item is BundleableTask {
  return BundleableTask.safeParse(item).success;
}
export const isBundleableTaskType = (taskType: TaskType): boolean => BundleableTaskTypes.includes(taskType);
