import { z } from 'zod';

import { BorrowerId } from '../../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../../utils';

/**
 * Delinquency Collected Data
 */
export const DelinquencyStepPastGraceCollectedData = z.object({
  type: z.literal('delinquencyStepPastGraceCollectedData'),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  reason: z.string(),
  abilityToPay: z.boolean(),
  occupancy: z.string(),
  paymentCommitment: z.string(),
});

export const DelinquencyStepMortgageAssistanceRequestCollectedData = z.object({
  type: z.literal('delinquencyStepMortgageAssistanceRequestCollectedData'),
  recipient: zodBrandedUuid<BorrowerId>(),
  sentDate: zodDateOrString,
});

export const DelinquencyStepBorrowerResponsePackageCollectedData = z.object({
  type: z.literal('delinquencyStepBorrowerResponsePackageCollectedData'),
  packageComplete: z.boolean(),
  missingInformation: z.string(),
  additionalInformation: z.string(),
  deadlineForPackage: zodDateOrString,
});

export const DelinquencyStepFlexModificationEligibilityCollectedData = z.object({
  type: z.literal('delinquencyStepFlexModificationEligibilityCollectedData'),
  completePackage: z.boolean(),
  overTwelveMonthsOld: z.boolean(),
  overSixtyDaysDelinquent: z.boolean(),
  occupiedByOneOrMoreBorrowers: z.boolean(),
  eligibleHardship: z.string(),
});

export const DelinquencyStepFlexModificationTermsCollectedData = z.object({
  type: z.literal('delinquencyStepFlexModificationTermsCollectedData'),
  modificationDate: zodDateOrString,
  modifiedMaturityDate: zodDateOrString,
  recipient: zodBrandedUuid<BorrowerId>(),
});

export const DelinquencyStepSendBreachTermsCollectedData = z.object({
  type: z.literal('delinquencyStepSendBreachTermsCollectedData'),
  recipient: zodBrandedUuid<BorrowerId>(),
  sentDate: zodDateOrString,
});

export const DelinquencyStepCollectedData = z.discriminatedUnion('type', [
  DelinquencyStepPastGraceCollectedData,
  DelinquencyStepMortgageAssistanceRequestCollectedData,
  DelinquencyStepBorrowerResponsePackageCollectedData,
  DelinquencyStepFlexModificationEligibilityCollectedData,
  DelinquencyStepFlexModificationTermsCollectedData,
  DelinquencyStepSendBreachTermsCollectedData,
]);
export type DelinquencyStepCollectedData = z.infer<typeof DelinquencyStepCollectedData>;
