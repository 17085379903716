import { z } from 'zod';

import { StrictAddress } from '../Address';
import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const UpdateBorrowerSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerId: zodBrandedUuid<BorrowerId>(),
  opts: z.object({
    address: StrictAddress.optional(),
  }),
});
export type UpdateBorrowerSideEffectContext = z.infer<typeof UpdateBorrowerSideEffectContext>;

export const UpdateBorrowerSideEffect = UpdateBorrowerSideEffectContext.extend({
  type: z.literal('updateBorrower'),
});
export type UpdateBorrowerSideEffect = z.infer<typeof UpdateBorrowerSideEffect>;
