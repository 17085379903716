import { z } from 'zod';

import { BorrowerId, WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateForbearanceWorkoutPlanSideEffectContext = BaseLoanIdSideEffect.extend({
  borrowerIds: z.array(zodBrandedUuid<BorrowerId>()),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});

export const GenerateForbearanceWorkoutPlanSideEffect = GenerateForbearanceWorkoutPlanSideEffectContext.extend({
  type: z.literal('generateForbearanceWorkoutPlan'),
});
export type GenerateForbearanceWorkoutPlanSideEffect = z.infer<typeof GenerateForbearanceWorkoutPlanSideEffect>;
