import classNames from 'classnames';
import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';

export interface Props {
  className?: string;
  children: ReactNode;
}

export const PaymentStatusWrapper = NamedMemo<Props>('PaymentStatusWrapper', ({ className, children }) => {
  return (
    <h2
      className={classNames('d-inline-flex u-fs-6', className)}
      data-ghost="portal-loan--payment-header-status"
      data-testid="payment-header-status"
    >
      {children}
    </h2>
  );
});
