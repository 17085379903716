import * as z from 'zod';

import { BorrowerId, CompanyId, LoanId, UserId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

// deprecated for CreateLoanNoteEvent
export const AddAccountCommentEvent = BaseLoanEvent.extend({
  type: z.literal('addAccountComment'),
  companyId: zodBrandedUuid<CompanyId>(),
  loanId: zodBrandedUuid<LoanId>(),
  comment: z.string(),
  borrowerId: zodBrandedUuid<BorrowerId>().optional(),
  userId: zodBrandedUuid<UserId>(),
});
export type AddAccountCommentEvent = z.infer<typeof AddAccountCommentEvent>;
