import { z } from 'zod';

import { zodDateOrString } from '../../utils';

/**
 * Chapter 7 Collected Data
 */
export const Chapter7StepFilingStayCollectedData = z.object({
  type: z.literal('chapter7StepFilingStayCollectedData'),
  filingType: z.union([z.literal('joint'), z.literal('individual')]),
  petitionDate: zodDateOrString,
});
export const Chapter7StepCreditorMeetingCollectedData = z.object({
  type: z.literal('chapter7StepCreditorMeetingCollectedData'),
  meetingDate: zodDateOrString,
});
export const Chapter7StepAttorneyReliefCollectedData = z.object({
  type: z.literal('chapter7StepAttorneyReliefCollectedData'),
  contactDate: zodDateOrString,
  lawFirm: z.string(),
  attorneyContactName: z.string(),
  attorneyPhone: z.string(),
  filingDate: zodDateOrString,
});
export const Chapter7StepHearingDateCollectedData = z.object({
  type: z.literal('chapter7StepHearingDateCollectedData'),
  hearingDate: zodDateOrString,
});
export const Chapter7StepHearingOutcomeCollectedData = z.object({
  type: z.literal('chapter7StepHearingOutcomeCollectedData'),
  hearingOutcome: z.union([z.literal('granted'), z.literal('dismissed'), z.literal('discharged')]),
});
export const Chapter7StepCollectedData = z.discriminatedUnion('type', [
  Chapter7StepFilingStayCollectedData,
  Chapter7StepAttorneyReliefCollectedData,
  Chapter7StepCreditorMeetingCollectedData,
  Chapter7StepHearingDateCollectedData,
  Chapter7StepHearingOutcomeCollectedData,
]);
export type Chapter7StepCollectedData = z.infer<typeof Chapter7StepCollectedData>;
