import * as z from 'zod';

import { LoanInvestorRemittanceSplit } from '../loan/InvestorRemittanceSplit';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateRemittanceSplitEvent = BaseLoanEvent.extend({
  type: z.literal('updateInvestorRemittanceSplit'),
  investorRemittanceSplit: LoanInvestorRemittanceSplit,
});
export type UpdateRemittanceSplitEvent = z.infer<typeof UpdateRemittanceSplitEvent>;
