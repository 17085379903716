import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';
import { LosId } from '@willow/types-iso';

import { ContactCTA } from '../../contact-cta/ContactCTA';
import { PortalNavLinks } from '../nav-items/NavItems';

import './Nav.scss';

interface Props {
  selectedLoanId?: LosId;
  company?: CompanyFragment;
  hasActiveLoan: boolean;
}

export const PortalNav = NamedMemo<Props>('PortalNav', ({ selectedLoanId, company, hasActiveLoan }) => {
  return (
    <nav className="portal-nav">
      <PortalNavLinks losId={selectedLoanId} hasActiveLoan={hasActiveLoan} companyId={company?.id} />

      {company && hasActiveLoan && <ContactCTA company={company} />}
    </nav>
  );
});
