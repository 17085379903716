import { InfoCircleFilled } from '@ant-design/icons';
import { useContext } from 'react';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';
import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

import { EnvironmentContext } from '../context/AppContexts';
import { WillowModal } from '../modal/Modal';

import './DwollaLimitModal.scss';

interface Props {
  company: CompanyFragment;
  showModal: boolean;
  onClose: () => void;
}

export const DwollaLimitModal = NamedMemo<Props>('Alert', ({ company, showModal, onClose }) => {
  const { name, supportPhone: phone } = company;

  const environment = useContext(EnvironmentContext);
  const limitAmount = environment === 'production' ? '$50,000' : '$15,000';

  return (
    <WillowModal showModal={showModal} onClose={onClose}>
      <div className="modal-dwolla-limit">
        <h2>
          <InfoCircleFilled />
          Payment exceeds weekly limit
        </h2>

        <div className="modal-dwolla-limit__copy">
          <p>Your weekly payment limit is currently {limitAmount}.</p>
          <p>
            Please adjust your payment amount or contact {company ? `${name} ` : 'your lender '}
            {phone && (
              <>
                at <a href={`tel:${phone}`}>{phoneNumberFormat(phone)} </a>
              </>
            )}
            to learn about additional payment options.
          </p>

          <button className="modal-dwolla-limit__close-btn" onClick={onClose}>
            Edit Payment Amount
          </button>
        </div>
      </div>
    </WillowModal>
  );
});
