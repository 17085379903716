export const META_DEFAULTS = {
  TITLE: '',
  DESCRIPTION: 'Providing the tools for the industry to better serve people like you.',
  DIVIDER: '|',
};

// The metadata defaults are set once in app.tsx and then can be used by all pages
export const setMetaDefaults = (title: string): void => {
  META_DEFAULTS.TITLE = title;
};
