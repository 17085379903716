import { z } from 'zod';

import { LosId } from '../../BrandedIds';
import { zodBrandedString } from '../../utils';

export const ImportInvoiceRow = z.object({
  loanId: zodBrandedString<LosId>(),
  // TODO Just for WHEDA demo purposes, replace with actual fields
});

export type ImportInvoiceRow = z.infer<typeof ImportInvoiceRow>;
