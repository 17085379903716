import { z } from 'zod';

import { WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CancelWorkoutPlanEvent = BaseLoanEvent.extend({
  type: z.literal('cancelWorkoutPlan'),
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
  comment: z.string().optional(),
});
export type CancelWorkoutPlanEvent = z.infer<typeof CancelWorkoutPlanEvent>;
