import { z } from 'zod';

import { DrawId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const ReverseDrawEvent = BaseLoanEvent.extend({
  type: z.literal('reverseDraw'),
  drawId: zodBrandedUuid<DrawId>(),
  comment: z.string().optional(),
});
export type ReverseDrawEvent = z.infer<typeof ReverseDrawEvent>;
