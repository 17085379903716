import { z } from 'zod';

import { BorrowerId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const CreateRequestedPayoffEvent = BaseLoanEvent.extend({
  type: z.literal('createRequestedPayoff'),
  payoffDate: zodDateOrString,
  additionalBorrowers: z.array(zodBrandedUuid<BorrowerId>()),
  message: z.string().optional(),
});
export type CreateRequestedPayoffEvent = z.infer<typeof CreateRequestedPayoffEvent>;
