/*

  This is the highest level loader that appears while we're waiting for the initial application (app + portal) to come in
*/
import { AnimatePresence, motion } from 'framer-motion';
import { gsap } from 'gsap';
import { useEffect } from 'react';

import { NamedMemo } from '../NamedMemo';

import './InitLoader.scss';

interface InitLoaderProps {
  visible: boolean;
}
export const InitLoader = NamedMemo<InitLoaderProps>('InitLoader', ({ visible }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const transition = {
    duration: 0.4,
    ease: 'easeInOut',
  };

  useEffect(() => {
    // Start the gsap animation (animating stroke out -> in -> repeat infinitely)
    const path = document.querySelector('#initial-loader-path') as SVGGeometryElement;
    const pathLength = path.getTotalLength();
    gsap.set(path, { strokeDasharray: pathLength });

    const timeline = gsap.timeline({ repeat: -1 });
    timeline.to(path, { strokeDashoffset: pathLength, duration: 1.5, delay: 0.5, ease: 'easeInOut' });
    timeline.to(path, { strokeDashoffset: 0, duration: 1.5, ease: 'easeInOut' });
  }, []);

  return (
    <AnimatePresence mode="wait">
      {visible && (
        <motion.div
          key="initial-loader"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={transition}
          className="init-loader"
        >
          <svg width="589" height="468" viewBox="0 0 589 468" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="initial-loader-path"
              d="M92.5 167.5V453.5C92.5 455.709 94.2909 457.5 96.5 457.5H230.5C232.709 457.5 234.5 455.709 234.5 453.5V336.423C234.5 331.934 235.002 327.457 236.302 323.161C242.244 303.531 256.49 272.847 294 272.847C320.275 272.847 333.857 286.369 344.992 315.755C347.858 323.318 349 331.414 349 339.502V453.5C349 455.709 350.791 457.5 353 457.5H490.5C492.709 457.5 494.5 455.709 494.5 453.5V167.5L579 232L294 10L10 226.5L119.5 138.5V14C119.5 11.7909 121.291 10 123.5 10H176.5C178.709 10 180.5 11.7909 180.5 14V90.5"
              strokeWidth="20"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
