import { z } from 'zod';

import { WorkoutPlanId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertPaymentDefermentSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  workoutPlanId: zodBrandedUuid<WorkoutPlanId>(),
});
export const UpsertPaymentDefermentSideEffect = UpsertPaymentDefermentSideEffectContext.extend({
  type: z.literal('upsertPaymentDeferment'),
});
export type UpsertPaymentDefermentSideEffect = z.infer<typeof UpsertPaymentDefermentSideEffect>;
