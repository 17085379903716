import { z } from 'zod';

import { PaymentId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UsioMarkAsFailedEvent = BaseLoanEvent.extend({
  type: z.literal('usioMarkAsFailed'),
  paymentId: zodBrandedUuid<PaymentId>(),
  failureCode: z.string(),
});
export type UsioMarkAsFailedEvent = z.infer<typeof UsioMarkAsFailedEvent>;
