import * as z from 'zod';

import { LosId } from '../BrandedIds';
import { zodDateOrString } from '../utils/Zod';
import { CreateTransactionRow } from '../validations/loan/CreateTransactionRow';

// THINGS TO DO WITH THE RAW TRANSACTION ROW
// First, take the initial object parser
// from the spreadsheet uploader or API — ie, CreateTransactionRow.
// Then...
// * Turn date strings into Date

// * Turn ids into branded IDs
export const CreateTransactionRowData = CreateTransactionRow.extend({
  loanId: z.string().transform((id) => id as LosId),
  date: zodDateOrString,
});
export type CreateTransactionRowData = z.infer<typeof CreateTransactionRowData>;
