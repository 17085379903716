import React from 'react';

import { NamedMemo } from '@willow/shared-web';

import './PageHeading.scss';

interface Props {
  title: string;
  subtitle?: string;
}

export const PageHeading = NamedMemo<Props>('PageHeading', ({ title, subtitle }) => {
  return (
    <section className="page-heading">
      <h1 className="page-heading__title" data-testid="page-heading--title">
        {title}
      </h1>
      {subtitle && (
        <div className="page-heading__subtitle" data-testid="page-heading--subtitle">
          {subtitle}
        </div>
      )}
    </section>
  );
});
