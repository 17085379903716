/*
    Handle style customizations based on lender settings
*/
import { useMemo } from 'react';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';

import { hexToRgbA } from './utils';

export const useStyleCustomization = (company: CompanyFragment | undefined) => {
  const primaryColor = useMemo<string | undefined>(() => company?.portalSettings?.primaryColor || undefined, [company]);
  const secondaryColor = useMemo<string | undefined>(
    () => (primaryColor ? hexToRgbA(primaryColor) : undefined),
    [primaryColor],
  );

  // Default primary = $blue1
  // Default secondary = $blue0
  return { primaryColor: primaryColor || '#0070ae', secondaryColor: secondaryColor || '#f2f8fb' };
};
