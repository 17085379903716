/*
    There are instances where a user's email might be valid for login because it exists in autho0,
    but there's not actually a corresponding user in the willow db (ie maybe they were dropped on a loan as a previous co-borrower)

    In this case, display a message rather than just a totally blank screen
*/
import { NamedMemo } from '@willow/shared-web';

import { Layout } from '../layout/Layout';

import './InactiveUser.scss';

export const InactiveUser = NamedMemo('InactiveUser', () => {
  // TODO add user / company info if applicable
  return (
    <Layout>
      <section className="inactive-user__title">
        <h1>No active loan found</h1>
        <h2>You do not have any payments due</h2>
      </section>
    </Layout>
  );
});
