import * as z from 'zod';

import { CompanyId, LoanId, LosId, QcDocumentId } from '../BrandedIds';
import { LoanStatus } from '../loan/LoanStatus';
import { zodBrandedUuid } from '../utils/Zod';
import { EscrowAnalysisPartial } from './documents/EscrowAnalysis';
import { Form1098Partial } from './documents/Form1098';

export const QcDocumentStatus = z.enum(['draft', 'qc', 'queuedToSend', 'sent', 'deleted']);
export type QcDocumentStatus = z.infer<typeof QcDocumentStatus>;

export const QcDocumentType = z.enum(['form1098', 'escrowAnalysis']);
export type QcDocumentType = z.infer<typeof QcDocumentType>;

export const QcDocumentSentError = z.enum([
  'stopEmailsEnabled',
  'primaryBorrowerEconsentDisabled',
  'primaryBorrowerEmailsDisabled',
]);
export type QcDocumentSentError = z.infer<typeof QcDocumentSentError>;

export const QcDocumentSentErrors = z.object({
  errors: QcDocumentSentError.array().optional(),
});

export const BaseQcDocument = z.object({
  id: zodBrandedUuid<QcDocumentId>(),
  loanId: zodBrandedUuid<LoanId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  losId: z.string().transform((id) => id as LosId),

  status: QcDocumentStatus,
  errors: z.object({
    formErrors: z.string().array().optional(),
    fieldErrors: z.record(z.string(), z.string().array()).optional(),
  }),
  warnings: z.object({
    formErrors: z.string().array().optional(),
    fieldErrors: z.record(z.string(), z.string().array()).optional(),
  }),

  sentErrors: QcDocumentSentErrors,
  location: z.string().optional(),

  createdAt: z.date(),
  sentAt: z.date().optional(),
  approvedAt: z.date().optional(),
  approvedBy: z.string().optional(), // user email
});

export const Form1098 = BaseQcDocument.extend({
  type: z.literal('form1098'),
  year: z.number(),
  fields: Form1098Partial,
  overrides: Form1098Partial,
});
export type Form1098 = z.infer<typeof Form1098>;

export const EscrowAnalysis = BaseQcDocument.extend({
  type: z.literal('escrowAnalysis'),
  fields: EscrowAnalysisPartial,
  overrides: EscrowAnalysisPartial,
});
export type EscrowAnalysis = z.infer<typeof EscrowAnalysis>;

export const EscrowAnalysisLoanStatusFilter = z.enum([
  'bankruptcy',
  'foreclosure',
  'scheduledTransfer',
  'activeRepayment',
  'pastDue90',
]);
export type EscrowAnalysisLoanStatusFilter = z.infer<typeof EscrowAnalysisLoanStatusFilter>;

export const QcDocument = z.discriminatedUnion('type', [Form1098, EscrowAnalysis]);
export type QcDocument = z.infer<typeof QcDocument>;

export const CreateForm1098CorrectionTypeInput = z.union([
  z.literal('correctAll'),
  z.literal('correctIfSent'),
  z.literal('correctNone'),
]);
export type CreateForm1098CorrectionTypeInput = z.infer<typeof CreateForm1098CorrectionTypeInput>;

export const Form1098AllowedLoanStatuses: LoanStatus[] = [
  'active',
  'foreclosure',
  'paidOff',
  'pendingTransfer',
  'preActive',
  'transferred',
];

const DefaultEscrowAnalysisAllowedLoanStatuses: LoanStatus[] = ['preActive', 'active'];
export function getEscrowAnalysisAllowedLoanStatuses(filters?: EscrowAnalysisLoanStatusFilter[]): LoanStatus[] {
  const allowedLoanStatuses = DefaultEscrowAnalysisAllowedLoanStatuses;
  for (const filter of filters ?? []) {
    switch (filter) {
      case 'foreclosure':
        allowedLoanStatuses.push('foreclosure');
        break;
      case 'scheduledTransfer':
        allowedLoanStatuses.push('pendingTransfer');
        break;
      default:
        break;
    }
  }

  return allowedLoanStatuses;
}
