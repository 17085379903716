import { z } from 'zod';

import { BaseTask } from '../BaseTask';
import {
  ForeclosureStepDemandLettersCollectedData,
  ForeclosureStepHomeSaleCollectedData,
  ForeclosureStepJudicialStateCollectedData,
  ForeclosureStepNonJudicialStateCollectedData,
  ForeclosureStepReferAttorneyCollectedData,
} from '../collectedData/ForeclosureCollectedData';
import { EmptyCollectedData } from '../collectedData/TaskCollectedData';
import { EmptyContext } from '../EmptyContext';

export const ForeclosureStepDemandLettersType = z.literal('foreclosureStepDemandLetters');
export const ForeclosureStepDemandLetterExpiresType = z.literal('foreclosureStepDemandLetterExpires');
export const ForeclosureStepReferClosureAttorneyType = z.literal('foreclosureStepReferClosureAttorney');
export const ForeclosureStepJudicialStateType = z.literal('foreclosureStepJudicialState');
export const ForeclosureStepNonJudicialStateType = z.literal('foreclosureStepNonJudicialState');
export const ForeclosureStepHomeSaleType = z.literal('foreclosureStepHomeSale');
export const ForeclosureStepType = z.union([
  ForeclosureStepDemandLettersType,
  ForeclosureStepDemandLetterExpiresType,
  ForeclosureStepReferClosureAttorneyType,
  ForeclosureStepJudicialStateType,
  ForeclosureStepNonJudicialStateType,
  ForeclosureStepHomeSaleType,
]);

export const ForeclosureStepDemandLetters = BaseTask.extend({
  type: ForeclosureStepDemandLettersType,
  context: EmptyContext,
  collectedData: z.union([ForeclosureStepDemandLettersCollectedData, EmptyCollectedData]),
});
export type ForeclosureStepDemandLetters = z.infer<typeof ForeclosureStepDemandLetters>;

export const ForeclosureStepDemandLetterExpires = BaseTask.extend({
  type: ForeclosureStepDemandLetterExpiresType,
  context: EmptyContext,
});
export type ForeclosureStepDemandLetterExpires = z.infer<typeof ForeclosureStepDemandLetterExpires>;

export const ForeclosureStepReferClosureAttorney = BaseTask.extend({
  type: ForeclosureStepReferClosureAttorneyType,
  context: EmptyContext,
  collectedData: z.union([ForeclosureStepReferAttorneyCollectedData, EmptyCollectedData]),
});
export type ForeclosureStepReferClosureAttorney = z.infer<typeof ForeclosureStepReferClosureAttorney>;

export const ForeclosureStepJudicialState = BaseTask.extend({
  type: ForeclosureStepJudicialStateType,
  context: EmptyContext,
  collectedData: z.union([ForeclosureStepJudicialStateCollectedData, EmptyCollectedData]),
});
export type ForeclosureStepJudicialState = z.infer<typeof ForeclosureStepJudicialState>;

export const ForeclosureStepNonJudicialState = BaseTask.extend({
  type: ForeclosureStepNonJudicialStateType,
  context: EmptyContext,
  collectedData: z.union([ForeclosureStepNonJudicialStateCollectedData, EmptyCollectedData]),
});
export type ForeclosureStepNonJudicialState = z.infer<typeof ForeclosureStepNonJudicialState>;

export const ForeclosureStepHomeSale = BaseTask.extend({
  type: ForeclosureStepHomeSaleType,
  context: EmptyContext,
  collectedData: z.union([ForeclosureStepHomeSaleCollectedData, EmptyCollectedData]),
});
export type ForeclosureStepHomeSale = z.infer<typeof ForeclosureStepHomeSale>;

export const ForeclosureStep = z.discriminatedUnion('type', [
  ForeclosureStepDemandLetters,
  ForeclosureStepDemandLetterExpires,
  ForeclosureStepReferClosureAttorney,
  ForeclosureStepJudicialState,
  ForeclosureStepNonJudicialState,
  ForeclosureStepHomeSale,
]);
