import { z } from 'zod';

import { BaseTask } from '../BaseTask';
import {
  DelinquencyStepBorrowerResponsePackageCollectedData,
  DelinquencyStepFlexModificationEligibilityCollectedData,
  DelinquencyStepFlexModificationTermsCollectedData,
  DelinquencyStepMortgageAssistanceRequestCollectedData,
  DelinquencyStepPastGraceCollectedData,
  DelinquencyStepSendBreachTermsCollectedData,
} from '../collectedData/DelinquencyCollectedData';
import { EmptyCollectedData } from '../collectedData/TaskCollectedData';
import { EmptyContext } from '../EmptyContext';

export const DelinquencyStepPastGraceType = z.literal('delinquencyStepPastGrace');
export const DelinquencyStepMortgageAssistanceRequestType = z.literal('delinquencyStepMortgageAssistanceRequest');
export const DelinquencyStepBorrowerResponsePackageType = z.literal('delinquencyStepBorrowerResponsePackage');
export const DelinquencyStepFlexModificationEligibilityType = z.literal('delinquencyStepFlexModificationEligibility');
export const DelinquencyStepFlexModificationTermsType = z.literal('delinquencyStepFlexModificationTerms');
export const DelinquencyStepSendBreachTermsType = z.literal('delinquencyStepSendBreachTerms');
export const DelinquencyStepPreForeclosureReferralType = z.literal('delinquencyStepPreForeclosureReferral');
export const DelinquencyStepType = z.union([
  DelinquencyStepPastGraceType,
  DelinquencyStepMortgageAssistanceRequestType,
  DelinquencyStepBorrowerResponsePackageType,
  DelinquencyStepFlexModificationEligibilityType,
  DelinquencyStepFlexModificationTermsType,
  DelinquencyStepSendBreachTermsType,
  DelinquencyStepPreForeclosureReferralType,
]);

export const DelinquencyStepPastGrace = BaseTask.extend({
  type: DelinquencyStepPastGraceType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepPastGraceCollectedData]),
});
export type DelinquencyStepPastGrace = z.infer<typeof DelinquencyStepPastGrace>;

export const DelinquencyStepMortgageAssistanceRequest = BaseTask.extend({
  type: DelinquencyStepMortgageAssistanceRequestType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepMortgageAssistanceRequestCollectedData]),
});
export type DelinquencyStepMortgageAssistanceRequest = z.infer<typeof DelinquencyStepMortgageAssistanceRequest>;

export const DelinquencyStepBorrowerResponsePackage = BaseTask.extend({
  type: DelinquencyStepBorrowerResponsePackageType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepBorrowerResponsePackageCollectedData]),
});
export type DelinquencyStepBorrowerResponsePackage = z.infer<typeof DelinquencyStepBorrowerResponsePackage>;

export const DelinquencyStepFlexModificationEligibility = BaseTask.extend({
  type: DelinquencyStepFlexModificationEligibilityType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepFlexModificationEligibilityCollectedData]),
});
export type DelinquencyStepFlexModificationEligibility = z.infer<typeof DelinquencyStepFlexModificationEligibility>;

export const DelinquencyStepFlexModificationTerms = BaseTask.extend({
  type: DelinquencyStepFlexModificationTermsType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepFlexModificationTermsCollectedData]),
});
export type DelinquencyStepFlexModificationTerms = z.infer<typeof DelinquencyStepFlexModificationTerms>;

export const DelinquencyStepSendBreachTerms = BaseTask.extend({
  type: DelinquencyStepSendBreachTermsType,
  context: EmptyContext,
  collectedData: z.union([EmptyCollectedData, DelinquencyStepSendBreachTermsCollectedData]),
});
export type DelinquencyStepSendBreachTerms = z.infer<typeof DelinquencyStepSendBreachTerms>;

export const DelinquencyStepPreForeclosureReferral = BaseTask.extend({
  type: DelinquencyStepPreForeclosureReferralType,
  context: EmptyContext,
  collectedData: EmptyCollectedData,
});
export type DelinquencyStepPreForeclosureReferral = z.infer<typeof DelinquencyStepPreForeclosureReferral>;

export const DelinquencyStep = z.discriminatedUnion('type', [
  DelinquencyStepPastGrace,
  DelinquencyStepMortgageAssistanceRequest,
  DelinquencyStepBorrowerResponsePackage,
  DelinquencyStepFlexModificationEligibility,
  DelinquencyStepFlexModificationTerms,
  DelinquencyStepSendBreachTerms,
  DelinquencyStepPreForeclosureReferral,
]);
export type DelinquencyStep = z.infer<typeof DelinquencyStep>;
