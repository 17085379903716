import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';

import {
  BorrowersOnLoanFragment,
  PortalSelfDocument,
  UpdateNotificationSettingsDocument,
} from '@willow/graphql-iso/src/portal';
import { ButtonAnchorLink, NamedMemo, PORTAL_LOAN_PATHS, Sentry } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { UnpackArray } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { EmptyPortalUser, PortalUser } from '../context/AppContexts';

import s from './NotificationSettings.module.scss';

interface Props {
  loan: PortalSelectedLoan;
  user: PortalUser | EmptyPortalUser;
}

interface CoBorrowerProps {
  loan: PortalSelectedLoan;
  borrower: UnpackArray<BorrowersOnLoanFragment['additionalBorrowers']>;
}

interface PrimaryBorrowerProps {
  loan: PortalSelectedLoan;
  borrower: BorrowersOnLoanFragment['primaryBorrower'];
}

interface PrimaryBorrowerForm {
  emailNotificationsEnabled: boolean;
  paperNotificationsEnabled: boolean;
  emailConsent?: boolean;
}

export const NotificationSettings = NamedMemo<Props>('NotificationSettings', ({ loan, user }) => {
  // User hasn't loaded yet
  if (!user.lastName) return <></>;

  // User is primary borrower
  if (user.borrowerId === loan.primaryBorrower.id) {
    return <NotificationSettingsPrimaryBorrower loan={loan} borrower={loan.primaryBorrower} />;
  }

  const coBorrower = loan.additionalBorrowers.find((ab) => ab.id === user.borrowerId);

  if (!coBorrower) {
    Sentry.captureException(
      `User's borrowerId does not exist on loan. borrowerId=${user.borrowerId}, loanId=${loan.id}, company=${loan.company.id}`,
    );
    return <></>;
  }

  // User is co-borrower
  return <NotificationSettingsCoBorrower loan={loan} borrower={coBorrower} />;
});

const NotificationSettingsCoBorrower = NamedMemo<CoBorrowerProps>(
  'NotificationSettingsCoBorrower',
  ({ loan, borrower }) => {
    const [updateNotificationSettings] = useMutation(UpdateNotificationSettingsDocument);

    // Co-borrowers can only set emailNotificationsEnabled true/false
    const [checked, setChecked] = useState(borrower.emailNotificationsEnabled);

    const onSubmit = async (emailNotificationsEnabled: boolean) => {
      try {
        const { data, errors } = await updateNotificationSettings({
          variables: {
            loanId: loan.id,
            input: {
              companyId: loan.company.id,
              emailNotificationsEnabled,
              // If email notifications are enabled, the borrower consents
              emailConsent: emailNotificationsEnabled ? true : undefined,
            },
          },
          refetchQueries: [PortalSelfDocument],
        });

        if (!data || errors) {
          throw new Error();
        }

        toast.success('Notification settings updated');
      } catch (err) {
        toast.error('Notification settings could not be saved. Please try again.');
      }
    };

    return (
      <>
        <div
          className={classNames(
            'u-fs-3 payment-form d-flex justify-content-between align-items-center u-border-1 p-3 u-border-r-3',
            {
              'u-border-color-bark2': !checked,
              'u-bg-secondary u-border-color-primary': checked,
            },
          )}
        >
          I want to receive emails{' '}
          <Toggle
            defaultChecked={checked}
            onChange={(e) => {
              onSubmit(e.target.checked);
              setChecked(e.target.checked);
            }}
            icons={false}
          />
        </div>

        <PaperlessDisclosure losId={loan.losId} companyId={loan.company.id} />
      </>
    );
  },
);

const NotificationSettingsPrimaryBorrower = NamedMemo<PrimaryBorrowerProps>(
  'NotificationSettingsPrimaryBorrower',
  ({ loan, borrower }) => {
    const [updateNotificationSettings] = useMutation(UpdateNotificationSettingsDocument);

    const { setValue, watch, handleSubmit } = useForm<PrimaryBorrowerForm>({
      defaultValues: {
        emailConsent: undefined,
        emailNotificationsEnabled: borrower.emailNotificationsEnabled,
        paperNotificationsEnabled: borrower.paperNotificationsEnabled,
      },
    });

    const onSubmit: SubmitHandler<PrimaryBorrowerForm> = async (formData) => {
      try {
        const { data, errors } = await updateNotificationSettings({
          variables: {
            loanId: loan.id,
            input: {
              companyId: loan.company.id,
              ...formData,
            },
          },
          refetchQueries: [PortalSelfDocument],
        });

        if (!data || errors) {
          throw new Error();
        }

        toast.success('Notification settings updated');
      } catch (err) {
        toast.error('Notification settings could not be saved. Please try again.');
      }
    };

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Radio
            label="Receive electronic notifications only"
            subLabel="All loan notifications will be delivered by email"
            checked={watch('emailNotificationsEnabled') && !watch('paperNotificationsEnabled')}
            onChecked={() => {
              setValue('emailNotificationsEnabled', true);
              setValue('emailConsent', true);
              setValue('paperNotificationsEnabled', false);
            }}
          />

          <Radio
            label="Receive paper notifications only"
            subLabel="All loan notifications will be delivered by paper mail"
            checked={!watch('emailNotificationsEnabled') && watch('paperNotificationsEnabled')}
            onChecked={() => {
              setValue('emailNotificationsEnabled', false);
              setValue('paperNotificationsEnabled', true);
            }}
          />

          <Radio
            label="Receive electronic & paper notifications"
            subLabel="All loan notifications will be delivered by email & paper mail"
            checked={watch('emailNotificationsEnabled') && watch('paperNotificationsEnabled')}
            onChecked={() => {
              setValue('emailNotificationsEnabled', true);
              setValue('emailConsent', true);
              setValue('paperNotificationsEnabled', true);
            }}
          />
        </Form.Group>

        <PaperlessDisclosure losId={loan.losId} companyId={loan.company.id} />

        <div className="d-flex justify-content-end mt-4">
          <button type="submit" className={s.button}>
            Save
          </button>
        </div>
      </Form>
    );
  },
);

interface RadioProps {
  checked: boolean;
  onChecked: () => void;
  label: string;
  subLabel: string;
}

const Radio = NamedMemo<RadioProps>('Radio', ({ checked, onChecked, label, subLabel }) => (
  <Form.Label
    className={classNames('d-flex u-border-1 p-3 u-border-r-3 u-pointer', {
      'u-border-color-bark2': !checked,
      'u-bg-secondary u-border-color-primary': checked,
    })}
  >
    <Form.Check.Input
      name="notification-setting"
      id={label}
      type="radio"
      checked={checked}
      className="checked:u-bg-primary checked:u-border-color-primary"
      onChange={(e) => e.target.checked && onChecked()}
    />
    <div className="ms-2">
      <p>{label}</p>
      <p className="u-color-bark3">{subLabel}</p>
    </div>
  </Form.Label>
));

const PaperlessDisclosure = NamedMemo<{ losId: string; companyId: string }>(
  'PaperlessDisclosure',
  ({ losId, companyId }) => (
    <div className="u-fs-2 u-color-bark3 mt-4">
      By enrolling to receive electronic notifications, you acknowledge that you have read the{' '}
      <ButtonAnchorLink
        underline
        variant="secondary"
        to={{
          pathname: PORTAL_LOAN_PATHS.myAccount.disclosures({ losId, companyId }),
          search: 'paperless',
        }}
      >
        Paperless Disclosures
      </ButtonAnchorLink>{' '}
      and agree to receive communications electronically.
    </div>
  ),
);
