import { compact, first, last } from 'lodash';

export const getFileBasename = (fileName: string): string => {
  const basename = first(fileName.split('.'));
  if (!basename) {
    throw new Error(`Missing file name`);
  }
  return basename;
};

export const getFileExtension = (fileName: string, fallback = 'pdf'): string => {
  const fileParts = compact(fileName.split('.'));
  if (fileParts.length < 2) return fallback;

  return last(fileParts) ?? fallback;
};
