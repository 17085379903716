import { Charge } from '@willow/types-iso';

/**
 * Filters charges that don't have a CHARGED status
 * By default deferred charges are not included
 */
export function getChargesDue<T extends Pick<Charge, 'status' | 'isDeferred'>>(
  charges: T[],
  includeDeferred?: boolean,
): T[] {
  return charges.filter((charge) => {
    const isChargedStatus = charge.status === 'CHARGED';
    return includeDeferred ? isChargedStatus : isChargedStatus && !charge.isDeferred;
  });
}
