import { InMemoryCacheConfig } from '@apollo/client';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { apolloConfig } from '@willow/graphql-iso/src/portal';
import { Auth0Domain } from '@willow/shared-iso';
import {
  AuthorizedProvider,
  getAppContext,
  InitLoader,
  initSentry,
  NamedMemo,
  UniversalLoader,
} from '@willow/shared-web';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

const APP_CONTEXT = getAppContext();
const AUTH0_DOMAIN = Auth0Domain.getCustomDomainForPortal(APP_CONTEXT);
const AUTH0_AUDIENCE = `https://${Auth0Domain.getForPortal(APP_CONTEXT)}/api/v2/`;
const GRAPHQL_ENDPOINT =
  process.env.NODE_ENV === 'production' ? '/api/portal/graphql' : 'http://localhost:8000/api/portal/graphql';
const PORTAL_CACHE_CONFIG: InMemoryCacheConfig = {
  typePolicies: {
    PortalLoanSnapshot: {
      keyFields: ['loanId'], // Declare index field so that Apollo merges responses correctly
    },
    Borrower: {
      keyFields: ['id', 'companyId'],
    },
  },
  possibleTypes: apolloConfig.possibleTypes,
};

const getAuth0ClientId = () => {
  if (APP_CONTEXT === 'production') {
    return 'Qzf9oX2KfqQfameYlg8C41d2FDNPNj3I';
  }
  if (APP_CONTEXT === 'staging' || APP_CONTEXT === 'flex') {
    return 'qk20Zbk5POQ3UKCR7TvL4k8fyaTLkPvi';
  }
  return 'zE2vw1tzc8aawtqxz4dctdRDJGKRFCzs';
};

export const PortalApp = NamedMemo('PortalApp', () => {
  if (!navigator.cookieEnabled) {
    return <>Please enable cookies to view this site</>;
  }

  useEffect(() => {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
    if (APP_CONTEXT === 'production') {
      initSentry();
    }
  });

  return (
    <Router>
      <HelmetProvider>
        <QueryParamProvider adapter={ReactRouter5Adapter}>
          <UniversalLoader loader={(isLoading) => <InitLoader visible={isLoading} />}>
            <AuthorizedProvider
              auth0Domain={AUTH0_DOMAIN}
              auth0ClientId={getAuth0ClientId()}
              auth0Audience={AUTH0_AUDIENCE}
              graphqlEndpoint={GRAPHQL_ENDPOINT}
              apolloCacheConfig={PORTAL_CACHE_CONFIG}
            >
              <App appContextEnv={APP_CONTEXT} />
            </AuthorizedProvider>
          </UniversalLoader>
        </QueryParamProvider>
      </HelmetProvider>
    </Router>
  );
});
