import * as z from 'zod';

import { zodDateOrString } from './utils';

export const AdhocMigration = z.object({
  id: z.string(),
  scriptName: z.string(),
  exitCode: z.number(),
  error: z.string().optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});

export type AdhocMigration = z.infer<typeof AdhocMigration>;
