import { CompanyWireSettings } from '@willow/graphql-iso/src/portal';
import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { WireSettings } from '../payment-form/wire-settings/WireSettings';
import { PaymentsPaused } from '../payments-paused/PaymentsPaused';
import { PaymentHeader } from './payments-accepted/payment-header/PaymentHeader';

interface Props {
  loan: PortalSelectedLoan;
}

export const MaturingLoan = NamedMemo<Props>('MaturingLoan', ({ loan }) => {
  const { company, wireSettings } = loan;

  if (!wireSettings) {
    return (
      <PaymentsPaused
        company={company}
        title="Payments have been paused"
        subtitle="Payments cannot be made online at this time"
        contactFormTitle="A payoff statement is processing, you will be notified when your final payment is due."
      />
    );
  }

  return (
    <>
      <section id="payment-header" aria-label="Payment Due Details" className="text-center mb-4">
        <PaymentHeader loan={loan} />
      </section>

      <section className="wire-settings mb-4 u-border-1 u-border-color-bark1">
        <div className="m-4">
          <h1 className="u-fs-4 fw-bold">Payment must be wired in full</h1>
          <div className="u-border-1 u-border-color-bark1 m-2">
            <WireSettings companyName={company.name} wireSettings={wireSettings as CompanyWireSettings} />
          </div>
          <h2 className="u-fs-4 fw-light">For questions, call us at {phoneNumberFormat(company.supportPhone)}</h2>
        </div>
      </section>
    </>
  );
});
