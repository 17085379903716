import { FetchResult } from '@apollo/client';

import { LogoutMutation } from '@willow/graphql-iso/src/portal';

import { PortalSelectedLoan } from '../../../App';
import { Banner } from '../banner/Banner';
import { PortalNav } from '../nav/Nav';

import './Header.scss';

interface Props {
  loan?: PortalSelectedLoan;
  hideSideNav?: boolean; // display reduced nav is no active loan selected (ie loan switch view)
  logout?: () => Promise<FetchResult<LogoutMutation>>;
}

export const Header = ({ loan, hideSideNav, logout }: Props) => {
  const { company, losId } = loan || {};
  const hasActiveLoan = Boolean(loan); // display reduced nav is no active loan found

  return (
    <header className="header">
      <a href="#main" className="skip-nav">
        Skip to Content
      </a>

      <Banner selectedLoanId={losId} hasActiveLoan={hasActiveLoan} company={company} logout={logout} />

      {!hideSideNav && <PortalNav selectedLoanId={losId} hasActiveLoan={hasActiveLoan} company={company} />}
    </header>
  );
};
