import { z } from 'zod';

import { BorrowerId, CompanyWireSettingsId, PaymentId, PropertyId } from '../BrandedIds';
import { InvestorRemittanceSplit } from '../loan/InvestorRemittanceSplit';
import { InterestAttribution } from '../PaymentDue';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { EmailString } from '../validations';
import { BaseLoanEvent } from './BaseLoanEvent';
import { FeeType } from './CreateChargeEvent';

const PaymentFeeLineItem = z.object({
  amount: z.number(),
  name: z.string(),
  type: FeeType,
  investorRemittanceSplit: InvestorRemittanceSplit.optional(),
  paymentId: zodBrandedUuid<PaymentId>().optional(),
});
export type PaymentFeeLineItem = z.infer<typeof PaymentFeeLineItem>;

export const CreatePayoffEvent = BaseLoanEvent.extend({
  type: z.literal('createPayoff'),
  expirationDate: zodDateOrString,
  borrowerNotifications: z.array(zodBrandedUuid<BorrowerId>()).optional(),
  additionalEmailNotifications: z.array(EmailString).optional(),
  outstandingPrincipal: z.number(),
  outstandingInterest: z.number(),
  feeLineItems: z.array(PaymentFeeLineItem),
  outstandingFeeAmount: z.number(),
  outstandingAdvanceAmount: z.number(),
  escrowBalance: z.number(),
  suspenseBalance: z.number(),
  reserveBalance: z.number(),
  totalDue: z.number(),
  interestAttribution: InterestAttribution.array().optional(),
  wireSettingsId: zodBrandedUuid<CompanyWireSettingsId>().optional(), // TODO require after migration
  ignoreDateCheck: z.boolean().optional(),
  isPartial: z.boolean().optional(),
  propertyIds: z.array(zodBrandedUuid<PropertyId>()).optional(),
});

export type CreatePayoffEvent = z.infer<typeof CreatePayoffEvent>;
