import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useContext } from 'react';
import Toggle from 'react-toggle';

import { DwollaBank, PortalLoanSnapshot } from '@willow/graphql-iso/src/portal';
import { LoanId, LoanStatus } from '@willow/types-iso';

import { AutopayContext } from '../../../contexts/autopayContext';
import { FeatureFlagContext } from '../../../contexts/featureFlagContext';
import { ConfirmAutopayModal } from '../../autopay/ConfirmAutopayModal';
import { DisableAutopayModal } from '../../autopay/DisableAutopayModal';
import { UserContext } from '../../context/AppContexts';

interface Props {
  autopaySettings: PortalLoanSnapshot['autopaySettings'];
  paymentBank?: DwollaBank;
  loanStatus: LoanStatus;
  loanId: LoanId;
  totalAmountDue: number;
  isPastDue?: boolean;
}

export const AutopaySettingForm = (props: Props) => {
  const { autopaySettings, paymentBank, loanStatus, loanId, totalAmountDue, isPastDue } = props;

  const { setShowConfirmAutopayModal, setShowDisableAutopayModal } = useContext(AutopayContext);

  const { ff_autopaySettings } = useContext(FeatureFlagContext);
  const { id: portalUserId } = useContext(UserContext);

  const autopayEnabledByCoBorrower = Boolean(
    autopaySettings && 'portalUserId' in autopaySettings && autopaySettings.portalUserId !== portalUserId,
  );
  const autopayEnabledByServicer = Boolean(autopaySettings && autopaySettings.type === 'usio');
  const autopayToggleIsLocked =
    !paymentBank ||
    autopayEnabledByCoBorrower ||
    autopayEnabledByServicer ||
    ['transferred', 'archived'].includes(loanStatus);

  const autopayToggleDisplay = Boolean(ff_autopaySettings && !['transferred', 'archived'].includes(loanStatus));

  const onToggleAutopay = () => {
    if (autopaySettings) {
      setShowDisableAutopayModal(true);
    } else {
      setShowConfirmAutopayModal(true);
    }
  };

  return (
    autopayToggleDisplay && (
      <>
        <h2>Enable auto-pay</h2>

        {autopayEnabledByCoBorrower && (
          <div className="payment-form__autopay-callout">
            <InfoCircleOutlined />A co-borrower currently has auto-pay enabled on this loan.
          </div>
        )}

        {autopayEnabledByServicer && (
          <div className="payment-form__autopay-callout">
            <InfoCircleOutlined />
            Your servicer has enabled auto-pay on this loan. If you believe this is an error, please contact your
            servicer immediately.
          </div>
        )}

        <div
          className={classNames('d-flex gap-3 payment-form__autopay', {
            'payment-form__autopay--enabled': !!autopaySettings,
            'payment-form__autopay--not-allowed': autopayToggleIsLocked,
          })}
        >
          <Toggle
            checked={!!autopaySettings}
            onChange={onToggleAutopay}
            disabled={autopayToggleIsLocked}
            icons={false}
          />
          {/* Display different copy depending on reason for being disabled */}
          {paymentBank ? (
            <>Automatically submit balance due on due date of each month</>
          ) : (
            <>Connect your bank account above to enable auto-pay</>
          )}
        </div>
        <ConfirmAutopayModal loanId={loanId} pastDueBalance={isPastDue ? totalAmountDue : undefined} />
        <DisableAutopayModal loanId={loanId} />
      </>
    )
  );
};
