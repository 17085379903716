import { z } from 'zod';

import { DisbursementId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseUpsertTransactionSideEffect } from './BaseUpsertTransactionSideEffect';

export const UpsertDisbursementSideEffectContext = BaseUpsertTransactionSideEffect.extend({
  disbursementId: zodBrandedUuid<DisbursementId>(),
});
export const UpsertDisbursementSideEffect = UpsertDisbursementSideEffectContext.extend({
  type: z.literal('upsertDisbursement'),
});
export type UpsertDisbursementSideEffect = z.infer<typeof UpsertDisbursementSideEffect>;
