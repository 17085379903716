import { z } from 'zod';

import { CompanyId, LoanId, TaskId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils';
import { EmptyCollectedData } from './collectedData/TaskCollectedData';
import { TaskStatus } from './TaskStatus';

export const BaseTask = z.object({
  id: zodBrandedUuid<TaskId>(),
  loanId: zodBrandedUuid<LoanId>().optional(),
  losId: z.string().optional(),
  companyId: zodBrandedUuid<CompanyId>(),
  status: TaskStatus,
  dueDate: zodDateOrString.optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
  assignees: z.array(zodBrandedUuid<UserId>()).optional(),
  details: z.string(),
  notes: z.string().optional(), // Additional comments
  primaryBorrowerLastName: z.string().optional(),
  associatedUserName: z.string().optional(), // audit log app user responsible for action
  title: z.string().optional(), // title of dialog box
  completedDate: zodDateOrString.optional(),
  collectedData: EmptyCollectedData,
});
