/*
  Company logos are stored in AWS (https://s3.console.aws.amazon.com/s3/buckets/willow-cdn-public) and should follow the naming convention
  1. ${environment}/clients/${companyId}/logo.jpg
  2. ${environment}/clients/${companyId}/email-logo.jpg

  Icons are stored under under /icons/*.svg
  Make sure you upload these to s3 before deploying your code!
*/

import { CompanyId } from '@willow/types-iso';

import { AppContext } from '../GlobalExecutionContext';

export type CdnFile =
  | 'dwollaCss'
  | 'emailLogo'
  | 'emailLogoDefault'
  | 'portalLogo'
  | 'portalLogoDefault'
  | 'willowServicingLogo'
  | 'noTasks'
  | 'helpCenter'
  | 'borrowerFAQ'
  | 'releaseNotes'
  | 'emptyStreet'
  | 'helpCenter'
  | 'houseDark'
  | 'houseLight'
  | 'preActiveStep1Illustration'
  | 'preActiveStep2Illustration'
  | 'preActiveStep3Illustration'
  | 'preActiveIllustration0'
  | 'preActiveIllustrationQuestionMark'
  | 'preActiveIllustration0Mobile'
  | 'preActiveNum1Mobile'
  | 'preActiveNum1'
  | 'preActiveNum2Mobile'
  | 'preActiveNum2'
  | 'preActiveNum3Mobile'
  | 'preActiveNum3'
  | 'preActiveTimelineMobile'
  | 'preActiveTimeline'
  | 'releaseNotes'
  | 'statement'
  | 'archive'
  | 'telescope'
  | 'transactions'
  | 'transferCsv'
  | 'uploadCsv'
  | 'willowLogoStaging'
  | 'willowLogo'
  | 'alertBell';
export const CDN_ROOT = 'https://drj8fwzx5487.cloudfront.net/';

export const getCdnUrlForFile = (file: CdnFile, appContext?: AppContext, companyId?: CompanyId) => {
  switch (file) {
    case 'dwollaCss':
      return `${CDN_ROOT}dwolla.css`;
    case 'emailLogoDefault':
      // Client hasn't uploaded custom email logo yet
      return `${CDN_ROOT}client-default-email-logo.jpg`;
    case 'emailLogo':
      return `${CDN_ROOT}${appContext}/clients/${companyId}/email-logo.jpg`;
    case 'portalLogoDefault':
      // Client hasn't uploaded custom portal logo yet
      return `${CDN_ROOT}client-default-portal-logo.jpg`;
    case 'portalLogo':
      return `${CDN_ROOT}${appContext}/clients/${companyId}/logo.jpg`;
    case 'willowServicingLogo':
      return `${CDN_ROOT}willow-servicing-logo.jpg`;
    case 'noTasks':
      return `${CDN_ROOT}icons/no-tasks.svg`;
    case 'helpCenter':
      return `${CDN_ROOT}icons/help-center.svg`;
    case 'borrowerFAQ':
      return `${CDN_ROOT}icons/borrower-faq.svg`;
    case 'releaseNotes':
      return `${CDN_ROOT}icons/release-notes.svg`;
    case 'emptyStreet':
      return `${CDN_ROOT}icons/empty-street.svg`;
    case 'houseDark':
      return `${CDN_ROOT}icons/house-dark.svg`;
    case 'houseLight':
      return `${CDN_ROOT}icons/house-light.svg`;
    case 'preActiveStep1Illustration':
      return `${CDN_ROOT}icons/pre-active-step1-illustration.svg`;
    case 'preActiveStep2Illustration':
      return `${CDN_ROOT}icons/pre-active-step2-illustration.svg`;
    case 'preActiveStep3Illustration':
      return `${CDN_ROOT}icons/pre-active-step3-illustration.svg`;
    case 'preActiveIllustration0':
      return `${CDN_ROOT}icons/pre-active-illustration0.svg`;
    case 'preActiveIllustrationQuestionMark':
      return `${CDN_ROOT}icons/pre-active-illustration-question-mark.svg`;
    case 'preActiveIllustration0Mobile':
      return `${CDN_ROOT}icons/pre-active-illustration0-mobile.svg`;
    case 'preActiveNum1Mobile':
      return `${CDN_ROOT}icons/pre-active-num1-mobile.svg`;
    case 'preActiveNum1':
      return `${CDN_ROOT}icons/pre-active-num1.svg`;
    case 'preActiveNum2Mobile':
      return `${CDN_ROOT}icons/pre-active-num2-mobile-v2.svg`;
    case 'preActiveNum2':
      return `${CDN_ROOT}icons/pre-active-num2-v2.svg`;
    case 'preActiveNum3Mobile':
      return `${CDN_ROOT}icons/pre-active-num3-mobile.svg`;
    case 'preActiveNum3':
      return `${CDN_ROOT}icons/pre-active-num3.svg`;
    case 'preActiveTimelineMobile':
      return `${CDN_ROOT}icons/pre-active-timeline-mobile.svg`;
    case 'preActiveTimeline':
      return `${CDN_ROOT}icons/pre-active-timeline.svg`;
    case 'statement':
      return `${CDN_ROOT}icons/statement.svg`;
    case 'archive':
      return `${CDN_ROOT}icons/archive.svg`;
    case 'telescope':
      return `${CDN_ROOT}icons/telescope.svg`;
    case 'transactions':
      return `${CDN_ROOT}icons/transactions.svg`;
    case 'transferCsv':
      return `${CDN_ROOT}icons/transfer-csv.svg`;
    case 'uploadCsv':
      return `${CDN_ROOT}icons/upload-csv.svg`;
    case 'willowLogoStaging':
      return `${CDN_ROOT}icons/willow-logo-staging.svg`;
    case 'willowLogo':
      return `${CDN_ROOT}icons/willow-logo.svg`;
    case 'alertBell':
      return `${CDN_ROOT}icons/alert-bell.svg`;

    default:
      throw new Error(`Unable to get file for type ${file}`);
  }
};
