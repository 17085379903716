import * as z from 'zod';

import { BorrowerId, PortalUserId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';

export const DwollaAutopaySettings = z.object({
  type: z.literal('dwolla'),
  bankUrl: z.string(),
  portalUserId: zodBrandedUuid<PortalUserId>(),
});
export type DwollaAutopaySettings = z.infer<typeof DwollaAutopaySettings>;

export const UsioAutopaySettings = z.object({
  type: z.literal('usio'),
  confirmationId: z.string(),
  borrowerId: zodBrandedUuid<BorrowerId>(),
  dayOfTheMonth: z.number().int().gt(0).lte(15).optional(),
});
export type UsioAutopaySettings = z.infer<typeof UsioAutopaySettings>;

export const AutopaySettings = z.discriminatedUnion('type', [DwollaAutopaySettings, UsioAutopaySettings]);
export type AutopaySettings = z.infer<typeof AutopaySettings>;
