import { z } from 'zod';

import { EscrowSettingId, PropertyId } from '../BrandedIds';
import { EscrowSettingInput } from '../loan/EscrowSetting';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanEvent } from './BaseLoanEvent';

export const UpdateEscrowSettingEvent = BaseLoanEvent.extend({
  type: z.literal('updateEscrowSetting'),
  propertyId: zodBrandedUuid<PropertyId>(),
  escrowSettingId: zodBrandedUuid<EscrowSettingId>().optional(), // TODO remove once migration is done
  newEscrowSetting: EscrowSettingInput,
});

export type UpdateEscrowSettingEvent = z.infer<typeof UpdateEscrowSettingEvent>;
