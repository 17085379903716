/*
    Each tab component in Manage Loan should use this header
*/

import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';

interface Props {
  title: string;
  subtitle: ReactNode;
}

export const ManageLoanHeader = NamedMemo<Props>('ManageLoanHeader', ({ title, subtitle }) => {
  return (
    <>
      <h2 className="u-fs-4 u-bold">{title}</h2>
      <h3 className="mt-1 mb-4">{subtitle}</h3>
    </>
  );
});
