import * as z from 'zod';

import { AuditLogId, EventId, LoanId, SideEffectId } from '../BrandedIds';
import { Actor } from '../events';
import { LoanEvent } from '../events/LoanEvent';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { AuditLogEventType, AuditLogSideEffectType } from './AuditLogType';

export const AuditLogSideEffectInput = z.object({
  loanId: zodBrandedUuid<LoanId>(),
  type: AuditLogSideEffectType,
  sideEffectId: zodBrandedUuid<SideEffectId>(),
  comment: z.string().optional(),
});
export type AuditLogSideEffectInput = z.infer<typeof AuditLogSideEffectInput>;

export const AuditLogEventInput = z.object({
  loanId: zodBrandedUuid<LoanId>(),
  type: AuditLogEventType,
  comment: z.string().optional(), // Optionally used for audit log only items
});
export type AuditLogEventInput = z.infer<typeof AuditLogEventInput>;

export type AuditLogInput = AuditLogSideEffectInput | AuditLogEventInput;

export const AuditLogSideEffect = AuditLogSideEffectInput.extend({
  id: zodBrandedUuid<AuditLogId>(),
  hidden: z.boolean(),
  comment: z.string().optional(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type AuditLogSideEffect = z.infer<typeof AuditLogSideEffect>;

export const AuditLogEvent = AuditLogEventInput.extend({
  id: zodBrandedUuid<AuditLogId>(),
  eventId: zodBrandedUuid<EventId>(),
  hidden: z.boolean(),
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
});
export type AuditLogEvent = z.infer<typeof AuditLogEvent>;

export const AuditLogItem = z.union([AuditLogEvent, AuditLogSideEffect]);
export type AuditLogItem = z.infer<typeof AuditLogItem>;

export type AuditLogEventWithActor = AuditLogEvent & { actor: Actor };
export type AuditLogItemWithActor = AuditLogEventWithActor | AuditLogSideEffect;

/** Hydrated includes the actual object of the event instead of just the id */
export type HydratedAuditLogEvent = AuditLogEvent & { event: LoanEvent };
export type HydratedAuditLogItem = HydratedAuditLogEvent | AuditLogSideEffect;
