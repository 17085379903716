import { z } from 'zod';

import { BaseTask } from '../BaseTask';
import {
  ReoStepContractStatusCollectedData,
  ReoStepCounterOfferDetailsCollectedData,
  ReoStepEvictionOfOccupiedPropertyCollectedData,
  ReoStepOfferEvaluationsCollectedData,
  ReoStepOrderInspectionAndValuationCollectedData,
  ReoStepOrderRepairsAndPreservationCollectedData,
  ReoStepPropertyListingCollectedData,
  ReoStepPropertyReductionCollectedData,
} from '../collectedData/ReoCollectedData';
import { EmptyContext } from '../EmptyContext';

export const ReoStepEvictionOfOccupiedPropertyType = z.literal('reoStepEvictionOfOccupiedProperty');
export const ReoStepOrderInspectionAndValuationType = z.literal('reoStepOrderInspectionAndValuation');
export const ReoStepRepairsAndPreservationType = z.literal('reoStepRepairsAndPreservation');
export const ReoStepPropertyListingType = z.literal('reoStepPropertyListing');
export const ReoStepPropertyReductionType = z.literal('reoStepPropertyReduction');
export const ReoStepOfferEvaluationsType = z.literal('reoStepOfferEvaluations');
export const ReoStepCounterOfferDetailsType = z.literal('reoStepCounterOfferDetails');
export const ReoStepContractStatusType = z.literal('reoStepContractStatus');
export const ReoStepType = z.union([
  ReoStepEvictionOfOccupiedPropertyType,
  ReoStepOrderInspectionAndValuationType,
  ReoStepRepairsAndPreservationType,
  ReoStepPropertyListingType,
  ReoStepPropertyReductionType,
  ReoStepOfferEvaluationsType,
  ReoStepCounterOfferDetailsType,
  ReoStepContractStatusType,
]);

export const ReoStepEvictionOfOccupiedProperty = BaseTask.extend({
  type: ReoStepEvictionOfOccupiedPropertyType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepEvictionOfOccupiedPropertyCollectedData]),
});
export type ReoStepEvictionOfOccupiedProperty = z.infer<typeof ReoStepEvictionOfOccupiedProperty>;

export const ReoStepOrderInspectionAndValuation = BaseTask.extend({
  type: ReoStepOrderInspectionAndValuationType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepOrderInspectionAndValuationCollectedData]),
});
export type ReoStepOrderInspectionAndValuation = z.infer<typeof ReoStepOrderInspectionAndValuation>;

export const ReoStepRepairsAndPreservation = BaseTask.extend({
  type: ReoStepRepairsAndPreservationType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepOrderRepairsAndPreservationCollectedData]),
});
export type ReoStepRepairsAndPreservation = z.infer<typeof ReoStepRepairsAndPreservation>;

export const ReoStepPropertyListing = BaseTask.extend({
  type: ReoStepPropertyListingType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepPropertyListingCollectedData]),
});
export type ReoStepPropertyListing = z.infer<typeof ReoStepPropertyListing>;

export const ReoStepPropertyReduction = BaseTask.extend({
  type: ReoStepPropertyReductionType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepPropertyReductionCollectedData]),
});
export type ReoStepPropertyReduction = z.infer<typeof ReoStepPropertyReduction>;

export const ReoStepOfferEvaluations = BaseTask.extend({
  type: ReoStepOfferEvaluationsType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepOfferEvaluationsCollectedData]),
});
export type ReoStepOfferEvaluations = z.infer<typeof ReoStepOfferEvaluations>;

export const ReoStepCounterOfferDetails = BaseTask.extend({
  type: ReoStepCounterOfferDetailsType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepCounterOfferDetailsCollectedData]),
});
export type ReoStepCounterOfferDetails = z.infer<typeof ReoStepCounterOfferDetails>;

export const ReoStepContractStatus = BaseTask.extend({
  type: ReoStepContractStatusType,
  context: EmptyContext,
  collectedData: z.union([EmptyContext, ReoStepContractStatusCollectedData]),
});
export type ReoStepContractStatus = z.infer<typeof ReoStepContractStatus>;

export const ReoStep = z.discriminatedUnion('type', [
  ReoStepEvictionOfOccupiedProperty,
  ReoStepOrderInspectionAndValuation,
  ReoStepRepairsAndPreservation,
  ReoStepPropertyListing,
  ReoStepPropertyReduction,
  ReoStepOfferEvaluations,
  ReoStepCounterOfferDetails,
  ReoStepContractStatus,
]);
