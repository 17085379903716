import * as SentryBrowser from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { memoize } from 'lodash';

export const initSentry = memoize(() => {
  // For some reason this gets called twice in local dev, and sentry does not like it.
  // By wrapping this in a memorize it can only be called once.
  Sentry.init({
    dsn: 'https://8e7260f0b109459cb0e96bb525e872ad@o1150834.ingest.sentry.io/6224646',
    integrations: [
      SentryBrowser.browserTracingIntegration(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 1.0, // Watch 100% of users
    replaysOnErrorSampleRate: 1.0, // And always record when there is a failure

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
});

export const initSentryAdminApp = memoize(() => {
  // For some reason this gets called twice in local dev, and sentry does not like it.
  // By wrapping this in a memorize it can only be called once.
  Sentry.init({
    dsn: 'https://8e7260f0b109459cb0e96bb525e872ad@o1150834.ingest.sentry.io/6224646',
    integrations: [
      SentryBrowser.browserTracingIntegration(),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 1.0, // Watch 100% of users
    replaysOnErrorSampleRate: 1.0, // But always record when there is a failure

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  });
});
