import { z } from 'zod';

import { BaseLoanEvent } from './BaseLoanEvent';

export const ForceUpdateEvent = BaseLoanEvent.extend({
  type: z.literal('forceUpdate'),
  snapshotConfig: z.any(), // LoanSnapshotConfig
  comment: z.string().optional(),
});
export type ForceUpdateEvent = z.infer<typeof ForceUpdateEvent>;
