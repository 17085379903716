import { AppContext } from './GlobalExecutionContext';

export namespace Auth0Domain {
  export const getForApp = (context: AppContext) => {
    if (context === 'production') {
      return 'willow-app-production.us.auth0.com';
    }
    if (context === 'staging' || context === 'flex') {
      return 'willow-app-staging.us.auth0.com';
    }
    return 'willow-app-dev.us.auth0.com';
  };

  export const getCustomDomainForApp = (context: AppContext) => {
    if (context === 'production') {
      // cname configurated in Route53 to point to willow-app-production.us.auth0.com
      return 'app-login.willowservicing.com';
    }
    if (context === 'staging' || context === 'flex') {
      return 'willow-app-staging.us.auth0.com';
    }
    return 'willow-app-dev.us.auth0.com';
  };

  export const getForPortal = (context: AppContext) => {
    if (context === 'production') {
      return 'willow-portal-production.us.auth0.com';
    }
    if (context === 'staging' || context === 'flex') {
      return 'willow-portal-staging.us.auth0.com';
    }
    return 'dev-oio4fmhw.us.auth0.com';
  };

  export const getCustomDomainForPortal = (context: AppContext) => {
    if (context === 'production') {
      // cname configurated in Route53 to point to willow-portal-production.us.auth0.com
      return 'portal-login.willowservicing.com';
    }
    if (context === 'staging' || context === 'flex') {
      return 'willow-portal-staging.us.auth0.com';
    }
    return 'dev-oio4fmhw.us.auth0.com';
  };
}
