import { ReactNode } from 'react';

import { NamedMemo } from '@willow/shared-web';
// eslint-disable-next-line no-restricted-imports
import { Modal } from '@willow/shared-web/bootstrap';

import './Modal.scss';

interface Props {
  children: ReactNode;
  showModal: boolean;
  onClose: () => void;
}

export const WillowModal = NamedMemo<Props>('WillowModal', ({ children, showModal, onClose }) => {
  return (
    <Modal show={showModal} onHide={onClose} className="willow-modal">
      {children}
    </Modal>
  );
});
