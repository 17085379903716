import { z } from 'zod';

import { DueMonthDay } from '../utils/Zod';

export const CityCountyDueDateMap = z.record(z.string(), z.array(DueMonthDay));

export type CityCountyDueDateMap = z.infer<typeof CityCountyDueDateMap>;

export const StateDueDateMap = z.object({
  dueDates: z.array(DueMonthDay).optional(),
  cities: CityCountyDueDateMap.optional(),
  counties: CityCountyDueDateMap.optional(),
  name: z.string(),
});

export type StateDueDateMap = z.infer<typeof StateDueDateMap>;

export const USDueDateMap = z.record(z.string(), StateDueDateMap);

export type USDueDateMap = z.infer<typeof USDueDateMap>;
