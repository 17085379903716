import * as z from 'zod';

import { zodDateOrString } from '../utils';

export const ExpectedPayment = z.object({
  paymentDate: zodDateOrString,
  totalAmountRemainingFromBorrower: z.number(),
  totalAmountRemainingFromCredit: z.number(),
});

export type ExpectedPayment = z.infer<typeof ExpectedPayment>;
