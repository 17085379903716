import { z } from 'zod';

import { DashboardTaskType, TaskContext, TaskStatus, TaskType } from '../tasks';
import { zodDateOrString } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const TaskSideEffectContext = BaseLoanIdSideEffect.extend({
  taskType: DashboardTaskType,
  taskStatus: TaskStatus.optional(),
  dueDate: zodDateOrString.optional(),
  details: z.string().optional(),
  notes: z.string().optional(),
  context: TaskContext,
  auditLogComment: z.string(),
  title: z.string().optional(),
});
export type TaskSideEffectContext = z.infer<typeof TaskSideEffectContext>;

export const CreateTaskSideEffect = TaskSideEffectContext.extend({
  type: z.literal('createTask'),
  archiveIfExists: z.boolean().optional(),
});
export type CreateTaskSideEffect = z.infer<typeof CreateTaskSideEffect>;

export const UpdateTaskSideEffect = TaskSideEffectContext.extend({
  type: z.literal('updateTask'),
});

export type UpdateTaskSideEffect = z.infer<typeof UpdateTaskSideEffect>;

export const ReopenTaskSideEffect = BaseLoanIdSideEffect.extend({
  type: z.literal('reopenTask'),
  taskType: TaskType,
  dueDate: zodDateOrString.optional(),
  context: TaskContext,
  auditLogComment: z.string(),
});
export type ReopenTaskSideEffect = z.infer<typeof ReopenTaskSideEffect>;

/* Bulk Actions */
export const BulkTasksSideEffect = BaseLoanIdSideEffect.extend({
  taskStatus: TaskStatus.optional(),
  auditLogComment: z.string(),
});
export type BulkTasksSideEffect = z.infer<typeof BulkTasksSideEffect>;

export const ArchiveTasksSideEffect = BulkTasksSideEffect.extend({
  type: z.literal('archiveTasks'),
});
export type ArchiveTasksSideEffect = z.infer<typeof ArchiveTasksSideEffect>;

export const BulkUpdateTasksByTypesSideEffect = BaseLoanIdSideEffect.extend({
  type: z.literal('bulkUpdateTasks'),
  updateType: z.union([z.literal('loanTransfer'), z.literal('rescindGoodbye')]),
});

export type BulkUpdateTasksByTypesSideEffect = z.infer<typeof BulkUpdateTasksByTypesSideEffect>;
