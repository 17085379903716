import { BankOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

import { CompanyWireSettings } from '@willow/graphql-iso/src/portal';
import { addressToString, stripObjectNullFromNullOrUndefined } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

const WireItem = ({ title, children }: { title: string; children: ReactNode }) => (
  <div className="d-flex gap-1">
    <h3 className="u-color-bark3 fw-bold">{title}:</h3>
    <div className="u-color-bark4">{children}</div>
  </div>
);

interface Props {
  companyName: string;
  wireSettings: CompanyWireSettings;
}

export const WireSettings = NamedMemo<Props>('WireSettings', ({ companyName, wireSettings }) => {
  return (
    <div className="m-2">
      <div className="d-flex fw-bold gap-3 align-items-center m-3">
        <BankOutlined className="fs-3" />
        <h2 className="u-fs-4"> Wire details</h2>
      </div>

      <div className="mb-4 mx-5 u-color-bark3 u-fs-3">
        <WireItem title="Company Name">{companyName}</WireItem>
        <WireItem title="Bank Name">{wireSettings.bankName}</WireItem>
        <WireItem title="Bank Address">
          {addressToString(stripObjectNullFromNullOrUndefined(wireSettings.bankAddress))}
        </WireItem>
        <WireItem title="Bank Routing (ABA) Number">{wireSettings.bankRoutingNumber}</WireItem>
        <WireItem title="Account Name">{wireSettings.accountName}</WireItem>
        <WireItem title="Account Number">{wireSettings.accountNumber}</WireItem>
        {wireSettings.instructions && <WireItem title="Wire Instructions">{wireSettings.instructions}</WireItem>}
      </div>
    </div>
  );
});
