import { compact } from 'lodash';
import { useCallback, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { NamedMemo, PORTAL_LOAN_PATHS } from '@willow/shared-web';
import { CompanyId, LosId } from '@willow/types-iso';

import { FeatureFlagContext } from '../../../contexts/featureFlagContext';

import './NavItems.scss';

interface Props {
  losId?: LosId;
  companyId?: CompanyId;
  hasActiveLoan?: boolean;
  onLinkClick?: () => void;
}

export const PortalNavLinks = NamedMemo<Props>('PortalNavLinks', ({ losId, companyId, hasActiveLoan, onLinkClick }) => {
  const { search } = useLocation();
  const featureFlagContext = useContext(FeatureFlagContext);
  const showFullNav = hasActiveLoan && losId && companyId;

  const navLinks = showFullNav
    ? compact([
        {
          title: 'Make a Payment',
          slug: PORTAL_LOAN_PATHS.root({ losId, companyId }, { search }),
          exact: true,
          ghost: 'payment',
        },
        {
          title: 'Transactions',
          slug: PORTAL_LOAN_PATHS.transactions({ losId, companyId }, { search }),
          ghost: 'transactions',
        },
        { title: 'Documents', slug: PORTAL_LOAN_PATHS.documents({ losId, companyId }, { search }), ghost: 'documents' },
        {
          title: 'Loan Overview',
          slug: PORTAL_LOAN_PATHS.loanOverview({ losId, companyId }, { search }),
          ghost: 'overview',
        },
        {
          title: 'Manage Loan',
          slug: PORTAL_LOAN_PATHS.manageLoan.root({ losId, companyId }, { search }),
          ghost: 'help',
        },
        { title: 'FAQ', slug: PORTAL_LOAN_PATHS.faq({ losId, companyId }, { search }), ghost: 'faq' },
        {
          title: 'My Account',
          slug: PORTAL_LOAN_PATHS.myAccount.root({ losId, companyId }, { search }),
          ghost: 'account',
        },
        featureFlagContext.ff_applicationsEnabled && {
          title: 'Applications',
          slug: PORTAL_LOAN_PATHS.applications({ losId, companyId }, { search }),
          ghost: 'applications',
        },
      ])
    : // Display reduced nav for inactive loan users
      [
        {
          title: 'Make a Payment',
          slug: losId && companyId ? PORTAL_LOAN_PATHS.root({ losId, companyId }) : '/',
          exact: true,
          ghost: 'payment',
        },
      ];

  const handleLinkClick = useCallback(() => {
    if (onLinkClick) onLinkClick();
  }, [onLinkClick]);

  return (
    <ul className="portal-nav__links">
      {navLinks.map((item, index) => {
        return (
          <li key={`portal-nav-item-${index}`}>
            <NavLink
              exact={item.exact}
              to={item.slug}
              className="link"
              activeClassName="active"
              data-ghost={`portal-nav--${item.ghost}`}
              onClick={() => handleLinkClick()}
            >
              {item.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
});
