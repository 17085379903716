import { z } from 'zod';

/**
 * dueOnFirst = billingDay on the 18th, paymentDue on the 1st
 * dueOnTwentyFifth = billingDay on the 1st, paymentDue on the 25th
 */
export const BillingSetting = z.enum(['dueOnFirst', 'dueOnTwentyFifth']);
export type BillingSetting = z.infer<typeof BillingSetting>;

export const CompanyBillingSettings = z.object({
  heloc: BillingSetting,
  construction: BillingSetting,
});
export type CompanyBillingSettings = z.infer<typeof CompanyBillingSettings>;

export const DEFAULT_BILLING_SETTINGS: CompanyBillingSettings = {
  heloc: 'dueOnFirst',
  construction: 'dueOnFirst',
};
