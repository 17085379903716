import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../App';
import { ManageLoanHeader } from './Header';
import { MessageContainer } from './MessageContainer';

interface Props {
  loan: PortalSelectedLoan;
}

export const ManageLoanMessaging = NamedMemo<Props>('ManageLoanContact', ({ loan }) => {
  const { name, supportPhone } = loan.company;

  return (
    <>
      <ManageLoanHeader
        title={`Message ${name}`}
        subtitle={
          <>
            Response times may vary. Support hours are M-F 9A-5P EST, holidays omitted. For immediate assistance please
            call us at{' '}
            <a href={`tel:${supportPhone}`} className="u-color-primary hover:u-color-primary">
              {phoneNumberFormat(supportPhone)}
            </a>
          </>
        }
      />

      <MessageContainer loanId={loan.id} />
    </>
  );
});
