import { useState } from 'react';

import { NamedMemo } from '@willow/shared-web';

import { Alert } from '../alert/Alert';

import './InterestReservePayment.scss';

interface Props {
  onAdditionalPayment: () => void;
}

export const InterestReservePayment = NamedMemo<Props>('InterestReservePayment', ({ onAdditionalPayment }) => {
  const [showPaymentButton, setShowPaymentButton] = useState(true);

  const onPaymentButtonClick = () => {
    setShowPaymentButton(false);
    onAdditionalPayment();
  };

  return (
    <>
      <div className="payment-form__alert">
        <Alert
          title="Your payment is scheduled"
          subtitle="Payment will be made from your interest reserve account"
          level="INFO"
        />
      </div>

      {showPaymentButton && (
        <button
          className="interest-reserve-payment__btn"
          data-ghost="portal-loan--make-a-payment-button"
          onClick={onPaymentButtonClick}
        >
          Make a Payment
        </button>
      )}
    </>
  );
});
