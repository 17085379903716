import { z } from 'zod';

import { EscrowCompany } from '../loan/EscrowCompany';
import { BaseLoanEvent } from './BaseLoanEvent';

const MortgageeLetterRecipient = z.object({
  escrowCompany: EscrowCompany,
  url: z.string(),
  fileName: z.string(),
});
type MortgageeLetterRecipient = z.infer<typeof MortgageeLetterRecipient>;

/**
 * @deprecated
 * mortgagee letters handled via generateLoanTransferDocuments
 */
export const SendEscrowCompanyEmailsEvent = BaseLoanEvent.extend({
  type: z.literal('sendEscrowCompanyEmails'),
  mortgageeLetterRecipients: z.array(MortgageeLetterRecipient),
});
export type SendEscrowCompanyEmailsEvent = z.infer<typeof SendEscrowCompanyEmailsEvent>;
